import { useMediaQuery, useTheme } from '@mui/material';
import { maxContainerWidth } from '@web/layouts/BaseLayout';

// Determine if screen size is like the container width of the website
export const useIsNotFullWidth = () => {
  const theme = useTheme();

  const isNotFullWidth = useMediaQuery(
    theme.breakpoints.down(maxContainerWidth),
  );

  return isNotFullWidth;
};
