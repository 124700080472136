import {
  DialogContent,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '@web/components/common/ui/DraggableDialog';
import MultiView from '@web/components/common/ui/MultiView';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { useCallback } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { CourtRecordEvidencesEditor } from '../case/CourtRecordEvidencesEditor';
import { CourtRecordProfilesEditor } from '../case/CourtRecordProfilesEditor';

const state = proxy({
  tab: 0,
});

export const CourtRecordDialog = () => {
  const {
    dialogs: { courtRecord },
  } = useMakerStore();

  const { tab } = useSnapshot(state);

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = useCallback(() => {
    makerStore.dialogs.courtRecord = false;
  }, []);

  return (
    <DraggableDialog
      titleComponent={
        <DraggableDialogDefaultTitle
          title="Court Record"
          onClose={handleClose}
        />
      }
      open={!!courtRecord}
      onClose={handleClose}
      maxWidth="sm"
      fullscreen={fullscreen}
      fullWidth
    >
      <DialogTabs />

      <DialogContent sx={{ p: { xs: 1, sm: 2 }, overflowY: 'unset' }}>
        <MultiView index={tab}>
          <CourtRecordEvidencesEditor />
          <CourtRecordProfilesEditor />
        </MultiView>
      </DialogContent>
    </DraggableDialog>
  );
};

const DialogTabs = () => {
  const { tab } = useSnapshot(state);

  return (
    <Tabs
      value={tab}
      onChange={(e, value) => (state.tab = value)}
      variant="fullWidth"
    >
      <Tab label="Evidence" value={0} />
      <Tab label="Profiles" value={1} />
    </Tabs>
  );
};
