import { Add, Remove, Timer } from '@mui/icons-material';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useState } from 'react';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';

export const PauseTag = ({ width }: { width: number }) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const [value, setValue] = useState<number>(35);
  const { insertTag } = useFrameEditor();

  const handleAddValue = (val: number) => {
    setValue((prev) => Math.max(Math.min(prev + val, 5000), 0));
  };

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <Timer />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 318 }}
        placement="top"
      >
        <Stack p={1}>
          <Typography>Pause</Typography>
          <Typography color="text.secondary" variant="body2">
            Pause at a certain point (in milliseconds)
          </Typography>
        </Stack>

        <Divider />

        <Grid container p={1} spacing={1}>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('p200')}
              fullWidth
            >
              200
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('p300')}
              fullWidth
            >
              300
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('p500')}
              fullWidth
            >
              500
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('p1000')}
              fullWidth
            >
              1000
            </Button>
          </Grid>
        </Grid>

        <Divider />

        <Stack p={1} spacing={1}>
          <Stack direction="row">
            <Typography variant="h4">{value}</Typography>

            <Timer />
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton size="small" onClick={() => handleAddValue(-25)}>
              <Remove />
            </IconButton>

            <Slider
              value={value}
              onChange={(e, v) => setValue(v as number)}
              step={25}
              min={0}
              max={5000}
            />

            <IconButton size="small" onClick={() => handleAddValue(25)}>
              <Add />
            </IconButton>
          </Stack>

          <Button
            color="accent"
            variant="contained"
            size="small"
            onClick={() => {
              insertTag(`p${value}`);
            }}
            fullWidth
          >
            Insert Tag
          </Button>
        </Stack>
      </Popper>
    </>
  );
};
