import { DoNotDisturbAlt } from '@mui/icons-material';
import { IconButton, Slider, Stack, Tooltip, Typography } from '@mui/material';
import {
  settingsStore,
  SettingsStoreType,
  useSettingsStore,
} from '@web/store/settings';
import { useMemo } from 'react';

type VolumeSliderProps = {
  setting: keyof SettingsStoreType['audio']['volume'];
  icon: React.ReactNode;
  showLabel?: boolean;
  label?: string;
};

export const VolumeSlider = ({
  setting,
  icon,
  showLabel = false,
  label,
}: VolumeSliderProps) => {
  const {
    audio: { volume, muted },
  } = useSettingsStore();

  const value = useMemo(() => volume[setting], [setting, volume]);
  const isMuted = useMemo(
    () => muted[setting] || (setting !== 'master' && muted.master),
    [muted, setting],
  );

  const handleChangeVolume = (event: Event, newValue: number | number[]) => {
    settingsStore.audio.volume[setting] = newValue as number;
  };

  const handleToggleMute = () => {
    settingsStore.audio.muted = {
      ...settingsStore.audio.muted,
      [setting]: !muted[setting],
    };
  };

  const disabled = muted.master && setting !== 'master';

  return (
    <Stack spacing={1}>
      {showLabel && label && <Typography variant="body2">{label}</Typography>}
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Tooltip
          title={!disabled && !showLabel ? setting : null}
          placement="top"
          disableInteractive
        >
          <span>
            <IconButton
              size="small"
              onClick={handleToggleMute}
              disabled={disabled}
            >
              {icon}
              {isMuted && (
                <DoNotDisturbAlt
                  color={muted[setting] ? 'error' : 'info'}
                  sx={{ position: 'absolute', width: '100%', height: '100%' }}
                />
              )}
            </IconButton>
          </span>
        </Tooltip>

        <Slider
          color="info"
          value={value}
          valueLabelDisplay="auto"
          onChange={handleChangeVolume}
          disabled={isMuted}
          aria-label={label || setting}
        />
      </Stack>
    </Stack>
  );
};
