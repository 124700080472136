import { Menu } from '@mui/icons-material';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { usePlayerBoundary } from '../hooks/usePlayerBoundary';
import { usePlayer } from '../providers/PlayerProvider';
import { HealthBar } from '../ui/HealthBar';
import { CaseMenuDialog } from './dialogs/CaseMenuDialog';

export const PlayerCaseTopMenu = () => {
  const { isCase } = usePlayer();

  if (!isCase) {
    return null;
  }

  return (
    <>
      <PlayerCaseTopMenuInner />
      <CaseMenuDialog />
    </>
  );
};

const PlayerCaseTopMenuInner = () => {
  const {
    playerUi: {
      state: { width },
    },
  } = usePlayer();

  const boundary = usePlayerBoundary(width);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="flex-end"
      px={boundary ? 0.5 : 0}
      my={1}
      flexGrow={1}
      width="100%"
      maxWidth={width}
    >
      <MenuButton />

      <PlayerHealthBar />
    </Stack>
  );
};

const MenuButton = () => {
  const small = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    playerCase: { state },
  } = usePlayer();

  const handleMenuOpen = () => {
    state.showMenu = true;
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      size={small ? 'small' : 'medium'}
      startIcon={<Menu />}
      onClick={handleMenuOpen}
    >
      Menu
    </Button>
  );
};

const PlayerHealthBar = () => {
  const {
    playerCase: {
      state: { health, healthFlashing },
    },
  } = usePlayer();

  const small = useMediaQuery((theme) => theme.breakpoints.down(600));
  const xSmall = useMediaQuery((theme) => theme.breakpoints.down(400));
  const width = xSmall ? 120 : small ? 160 : 200;

  return (
    <HealthBar width={width} health={health} flashingHealth={healthFlashing} />
  );
};
