import {
  QueueItem,
  TagItem,
  TagType,
} from '@web/components/maker/utils/frameText';
import pose_to_music_map from '@web/data/audio/pose_to_music_map';
import preset_music_volume from '@web/data/audio/preset_music_volume';
import { assetStore } from '@web/store/assets/state';
import { shakeFunctionParamMap } from '../PlayerCharacters';
import { usePlayer } from '../providers/PlayerProvider';
import { usePlayerDialogueBox } from './usePlayerDialogueBox';

type useDialogueBoxTagsProps = {
  dialogue: ReturnType<typeof usePlayerDialogueBox>;
};

export const useDialogueBoxTags = ({ dialogue }: useDialogueBoxTagsProps) => {
  const {
    state: playerState,
    playerDefaults,
    actions: { playMusic, stopMusic },
    audio,
    frame: { pose },
    timeline,
    playerUi,
  } = usePlayer();

  const runTag = async (tag: TagItem, item: QueueItem) => {
    switch (tag.type) {
      case TagType.Pause: {
        const delay = tag.param as number;

        dialogue.timeSinceBlip += delay;

        if (delay >= 100) {
          playerState.characterTalking = false;
        }

        await new Promise((resolve) => timeline.addEvent(resolve, delay));

        break;
      }
      case TagType.Shake: {
        const [shakeType, duration] = String(tag.param).split('|');

        playerUi.actions.addShake(
          shakeFunctionParamMap[shakeType],
          parseInt(duration),
        );

        break;
      }
      case TagType.ShakeLegacy:
        playerUi.actions.addShake(shakeFunctionParamMap[tag.param], 700);

        break;
      case TagType.Flash:
        playerUi.actions.setFlash(shakeFunctionParamMap[tag.param]);

        break;
      case TagType.PlayMusicDefault: {
        if (!pose) {
          return;
        }

        const music = pose_to_music_map[pose.id]
          ? `/Audio/Music/${pose_to_music_map[pose.id]}.ogg`
          : `/Audio/Music/trial.ogg`;

        const filename = music?.split('/').pop()?.split('.').shift();
        const volume =
          (preset_music_volume[filename as keyof typeof preset_music_volume] ??
            1) * 100;

        playMusic(undefined, music, volume);

        break;
      }
      case TagType.PlayMusic: {
        const music = assetStore.music.cache[tag.param as number];

        if (!music) {
          return;
        }

        playMusic(tag.param as number, music.url, music.volume);

        break;
      }
      case TagType.StopMusic:
        stopMusic();

        break;
      case TagType.FadeinMusic:
      case TagType.FadeoutMusic:
        if (!playerState.musicId) {
          return;
        }

        audio.fadeMusic(
          tag.type === TagType.FadeinMusic
            ? (assetStore.music.cache[playerState.musicId]?.volume ?? 100)
            : 0,
          1500,
        );

        break;
      case TagType.PlaySound: {
        const sound = assetStore.sound.cache[tag.param as number];

        if (!sound) {
          return;
        }

        audio.playSound(sound.url, sound.volume);

        break;
      }
      case TagType.StopSounds:
        audio.stopAllSounds();

        break;
      case TagType.TextSpeed:
        playerDefaults.textSpeed = tag.param as number;

        break;
      case TagType.ShowEvidence:
      case TagType.ShowEvidenceIcon:
        playerState.evidence = {
          id: tag.param as number,
          isIcon: tag.type === TagType.ShowEvidenceIcon,
        };

        break;
      case TagType.HideEvidence:
        playerState.evidence = undefined;

        break;
      default:
        console.warn('Unhandled tag', tag);
        break;
    }
  };

  return {
    runTag,
  };
};
