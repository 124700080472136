import { Media } from '@web/components/common/Media';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';

export const PlayerCaseVideo = () => {
  const { isCase } = usePlayer();

  if (!isCase) {
    return null;
  }

  return <VideoContent />;
};

const VideoContent = () => {
  const {
    playerCase: { state },
    actions: { endNext },
  } = usePlayer();

  const { videoUrl } = state;

  const handleVideoEnd = () => {
    state.videoUrl = undefined;

    endNext();
  };

  if (!videoUrl) {
    return null;
  }

  return (
    <Media
      type="video"
      url={videoUrl}
      style={{
        width: '100%',
        height: '100%',
        zIndex: ZIndex.CaseVideo,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'auto',
        objectFit: 'cover',
        display: 'block',
        margin: 0,
        padding: 0,
      }}
      onVideoEnd={handleVideoEnd}
      videoPlay
    />
  );
};
