import { useMediaQuery, useTheme } from '@mui/material';
import { useIsNotFullWidth } from '@web/hooks/useScreen';
import { useMakerStore } from '@web/store/maker/state';
import { isGameoverGroup, isNormalGroup } from '@web/store/maker/types';

export const useMakerButtonSizes = () => {
  const theme = useTheme();
  const isNotFullWidth = useIsNotFullWidth();

  const { group } = useMakerStore();

  const xSmallWidth =
    !isNormalGroup(group) && !isGameoverGroup(group) ? 353 : 0;

  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const xSmall = useMediaQuery(theme.breakpoints.down(xSmallWidth));

  return { small, xSmall, isNotFullWidth };
};
