import { Stack, TextField } from '@mui/material';
import { CaseAction_SetVariable } from '@shared/types';
import { useCaseActionInput } from '../CaseActionInputs';

export const SetVariableInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_SetVariable>();

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        label="Variable Name"
        value={caseAction.name || ''}
        onChange={(event) => updateCaseAction({ name: event.target.value })}
        size="small"
        variant="standard"
        fullWidth
      />

      <TextField
        label="Variable Value"
        value={caseAction.value || ''}
        onChange={(event) => updateCaseAction({ value: event.target.value })}
        size="small"
        variant="standard"
        fullWidth
      />
    </Stack>
  );
};
