/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Add,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Chip,
  DialogContent,
  Paper,
  Stack,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CaseFrame, Pair } from '@shared/types';
import { NumberSlider } from '@web/components/common/form/NumberSlider';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { ContextMenu } from '@web/components/common/ui/ContextMenu';
import { DeleteConfirmation } from '@web/components/common/ui/DeleteConfirmation';
import { DialogTransitionGrow } from '@web/components/common/ui/Dialog';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '@web/components/common/ui/DraggableDialog';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { useAssetStore } from '@web/store/assets/state';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { makerUtils } from '@web/store/maker/utils';
import { frameUtils } from '@web/utils/frame';
import { memo, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { BackgroundPicker } from '../ui/BackgroundPicker';
import { CharacterPicker } from '../ui/CharacterPicker';
import { PairingUtils } from '../utils/pairing';

const state = proxy({
  index: 0,
  characterIndex: 0,
  previewBackgroundId: undefined as number | undefined,
  previewBackgroundLeft: 0,
});

export const ProjectPairsDialog = memo(() => {
  const {
    dialogs: { projectPairs },
  } = useMakerStore();

  const handleClose = () => {
    makerStore.dialogs.projectPairs = false;
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={!!projectPairs}
      onClose={handleClose}
      titleComponent={<ProjectPairsDialogTitle />}
      TransitionComponent={DialogTransitionGrow}
      transitionDuration={200}
      maxWidth="sm"
      fullscreen={sm}
      hideBackdrop={false}
      fullWidth
    >
      <DialogContent sx={{ p: { xs: 1, sm: 2 } }}>
        <ProjectPairsDialogContent />
      </DialogContent>
    </DraggableDialog>
  );
});

const ProjectPairsDialogContent = memo(() => {
  const { project } = useMakerStore();

  const handleAddPair = useCallback(() => {
    if (!makerStore.project || makerStore.project.pairs.length > 300) return;

    state.characterIndex = 0;

    makerStore.project.pairs.push({
      id: uuidv4(),
      name: `Pair ${makerStore.project.pairs.length + 1}`,
      pairs: [{ characterId: 1, front: 0, offsetX: 0, offsetY: 0 }],
    });

    state.index = makerStore.project.pairs.length - 1;
  }, []);

  if (!project) return null;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={{ xs: 1, sm: 2 }} alignItems="end">
        <PairPicker />
        <Button
          variant="contained"
          color="info"
          onClick={handleAddPair}
          disabled={project.pairs?.length >= 300}
        >
          Add
        </Button>
      </Stack>

      <PairEditor />
    </Stack>
  );
});

const PairEditor = memo(() => {
  const snapshot = useSnapshot(makerStore);
  const { index } = useSnapshot(state);

  const pair = snapshot.project.pairs[index] as Pair | undefined;

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!makerStore.project) return;

      makerStore.project.pairs[index].name = e.target.value;
    },
    [index],
  );

  if (!pair) return null;

  return (
    <Stack spacing={2}>
      <TextField
        label="Name"
        value={pair.name}
        onChange={handleChangeName}
        variant="standard"
        fullWidth
      />

      <PairedCharactersSelector />

      <PairedCharacterPicker />

      <PairPreview />

      <PairedCharacterControls />

      <RemovePairButton />
    </Stack>
  );
});

const PairedCharacterControls = memo(() => {
  const { project } = useSnapshot(makerStore);
  const { index, characterIndex } = useSnapshot(state);

  const pair = project.pairs[index] as Pair | undefined;

  const handleChangeOffsetX = useCallback(
    (value: number) => {
      if (!makerStore.project) return;

      makerStore.project.pairs[index].pairs[characterIndex].offsetX = value;
    },
    [characterIndex, index],
  );

  const handleChangeOffsetY = useCallback(
    (value: number) => {
      if (!makerStore.project) return;

      makerStore.project.pairs[index].pairs[characterIndex].offsetY = value;
    },
    [characterIndex, index],
  );

  if (!pair || pair.pairs.length === 0) return null;

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      flexGrow={1}
      width="100%"
    >
      <Box flexGrow={1}>
        <NumberSlider
          label="Offset X"
          value={pair.pairs[characterIndex]?.offsetX}
          onChange={handleChangeOffsetX}
          min={-100}
          max={100}
          step={1}
        />
      </Box>

      <Box flexGrow={1}>
        <NumberSlider
          label="Offset Y"
          value={pair.pairs[characterIndex]?.offsetY}
          onChange={handleChangeOffsetY}
          min={-100}
          max={100}
          step={1}
        />
      </Box>
    </Stack>
  );
});

const PairedCharactersSelector = memo(() => {
  const { project } = useSnapshot(makerStore);

  const { index, characterIndex } = useSnapshot(state);

  const currentCharacters = useMemo(
    () =>
      project.pairs[index]?.pairs.map((m, i) => ({
        id: String(m.characterId),
        name: `Character ${i + 1}`,
      })) ?? [],
    [index, project.pairs],
  );

  const onClickChip = useCallback((index: number) => {
    state.characterIndex = index;
  }, []);

  const handleDelete = useCallback(() => {
    if (!makerStore.project) return;

    makerStore.project.pairs[index].pairs.splice(characterIndex, 1);

    if (characterIndex > 0) {
      state.characterIndex = characterIndex - 1;
    }
  }, [characterIndex, index]);

  return (
    <Stack
      direction="row"
      gap={{ xs: 1, sm: 1 }}
      alignItems="center"
      flexWrap="wrap"
    >
      {currentCharacters.map((m, i) => (
        <Chip
          key={i}
          label={`Character ${i + 1}`}
          size="small"
          color={i === characterIndex ? 'primary' : 'default'}
          onClick={() => onClickChip(i)}
          clickable
        />
      ))}

      <AddCharacterButton />
    </Stack>
  );
});

const AddCharacterButton = () => {
  const { project } = useSnapshot(makerStore);
  const { index } = useSnapshot(state);
  const currentLength = project.pairs[index]?.pairs.length ?? 0;

  const handleAdd = useCallback(() => {
    if (!makerStore.project || currentLength >= 5) return;

    makerStore.project.pairs[index].pairs.push({
      characterId: 1,
      front: currentLength,
      offsetX: 0,
      offsetY: 0,
    });

    state.characterIndex = currentLength;
  }, [currentLength, index]);

  makerUtils.getAllFrames().forEach((f) => {
    if (f.pair && f.pair.id === project.pairs[index].id && !f.pair.poseIds[1]) {
      f.pair.poseIds[1] = 1;
    }
  });

  return (
    <Chip
      label="Add"
      size="small"
      onClick={handleAdd}
      icon={<Add />}
      disabled={currentLength >= 5}
      clickable
    />
  );
};

const PairedCharacterPicker = memo(() => {
  const { project } = useSnapshot(makerStore);
  const { index, characterIndex } = useSnapshot(state);

  const selectedValue = useMemo(
    () => project.pairs[index]?.pairs[characterIndex]?.characterId ?? null,
    [characterIndex, index, project.pairs],
  );

  const handleChange = useCallback(
    (value: number | null) => {
      if (!makerStore.project) return;

      const projectPairs = makerStore.project.pairs[index];
      const characterPairs = projectPairs.pairs[characterIndex];

      characterPairs.characterId = value; // previous characterId

      const frames = makerUtils.getAllFrames();
      const pairId = projectPairs.id;

      frames.forEach((frame, index) => {
        if (frame.pair?.id !== pairId) return;

        if (value && frame.pair) {
          if (!frame.pair.poseIds[value]) {
            frame.pair.poseIds[value] = frameUtils.getDefaultPose(value)?.id;
          }
        }

        frames[index].pair = PairingUtils.removeUnusedPoses(
          frame,
          projectPairs,
        );
      });
    },
    [characterIndex, index],
  );

  return (
    <Stack direction="row" spacing={2}>
      <CharacterPicker value={selectedValue} onChange={handleChange} />
      <PairedCharacterFrontPicker />
    </Stack>
  );
});

const PairedCharacterFrontPicker = memo(() => {
  const { project } = useSnapshot(makerStore);
  const { index, characterIndex } = useSnapshot(state);

  const options = useMemo(
    () => [0, 1, 2, 3, 4].map((m) => ({ id: m, name: String(m + 1) })),
    [],
  );

  const selectedValue = useMemo(
    () =>
      options.find(
        (f) => f.id === project.pairs[index]?.pairs[characterIndex]?.front,
      ) ?? null,
    [characterIndex, index, options, project.pairs],
  );

  const handleChange = useCallback(
    (value?: number | null) => {
      if ((!value && value !== 0) || !makerStore.project) return;

      makerStore.project.pairs[index].pairs[characterIndex].front = value;
    },
    [characterIndex, index],
  );

  return (
    <SimpleSelect
      label="Front"
      options={options}
      value={selectedValue}
      onChange={(v) => handleChange(v?.id)}
      size="small"
    />
  );
});

const PairPicker = memo(() => {
  const snapshot = useSnapshot(makerStore);
  const { index } = useSnapshot(state);
  const options = useMemo(
    () => (snapshot.project.pairs || []) as Pair[],
    [snapshot.project.pairs],
  );

  const selectedValue = useMemo(
    () => (snapshot.project.pairs[index] ?? null) as Pair | null,
    [index, snapshot.project.pairs],
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: Pair | null) => {
      if (!value) return;

      state.characterIndex = 0;
      state.index =
        makerStore.project.pairs.findIndex((pair) => pair.id === value.id) ?? 0;
    },
    [],
  );

  return (
    <Autocomplete
      options={options}
      value={selectedValue}
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <RenderAutocompleteInput
          label="Pairs"
          params={params}
          variant="standard"
        />
      )}
      fullWidth
      disableClearable={selectedValue !== null}
    />
  );
});

const PairPreview = memo(() => {
  const { project } = useSnapshot(makerStore);
  const { index, previewBackgroundId, previewBackgroundLeft, characterIndex } =
    useSnapshot(state);

  const {
    character: { cache },
  } = useAssetStore();

  const pair = project.pairs[index] as Pair | undefined;
  const characterId = pair?.pairs[characterIndex]?.characterId ?? undefined;
  const poseId = characterId && cache[characterId]?.poses?.[0]?.id;

  const pairedPosesIds = useMemo(
    () =>
      pair?.pairs
        .filter((f) => f.characterId && f.characterId !== characterId)
        .reduce<Record<number, number | null>>((acc, curr) => {
          acc[curr.characterId!] =
            cache[curr.characterId!]?.poses[0]?.id ?? null;

          return acc;
        }, {}) || {},
    [cache, characterId, pair?.pairs],
  );

  const frame: CaseFrame | undefined = useMemo(
    () =>
      pair && pair.pairs.length > 0
        ? ({
            id: -1,
            backgroundId: previewBackgroundId,
            transition:
              previewBackgroundLeft || previewBackgroundLeft === 0
                ? { duration: 0, left: previewBackgroundLeft, easing: 'linear' }
                : undefined,
            text: '',
            characterId,
            poseId,
            pair: { id: pair.id, poseIds: pairedPosesIds },
          } satisfies CaseFrame)
        : undefined,
    [
      characterId,
      pair,
      pairedPosesIds,
      poseId,
      previewBackgroundId,
      previewBackgroundLeft,
    ],
  );

  if (!pair || !frame) return null;

  return (
    <ContextMenu>
      <Thumbnail frame={frame} pair={pair} serverThumbnails={false} />

      <BackgroundPreviewPicker />
    </ContextMenu>
  );
});

const BackgroundPreviewPicker = memo(() => {
  const {
    background: { cache },
  } = useAssetStore();
  const { previewBackgroundId } = useSnapshot(state);

  const handleChange = useCallback(
    (value: number | null) => {
      state.previewBackgroundId = value ?? undefined;

      if (!value || !cache[value]?.isWide) {
        state.previewBackgroundLeft = 0;
      }
    },
    [cache],
  );

  const handleChangeLeft = useCallback((amount: number) => {
    state.previewBackgroundLeft = amount;
  }, []);

  const isWide = previewBackgroundId && cache[previewBackgroundId]?.isWide;

  return (
    <Box sx={{ width: '350px', maxWidth: '100%' }}>
      <Stack spacing={2}>
        <BackgroundPicker
          value={previewBackgroundId ?? null}
          onChange={handleChange}
          placeholder="Preview Background"
          PaperComponent={(props) => <CustomPaper {...props} />}
          fullWidth
        />

        {isWide && (
          <ButtonGroup
            variant="outlined"
            sx={{ display: 'flex', justifyContent: 'center' }}
            color="inherit"
          >
            <Button onClick={() => handleChangeLeft(0)}>
              <FormatAlignLeft />
            </Button>
            <Button onClick={() => handleChangeLeft(50)}>
              <FormatAlignCenter />
            </Button>
            <Button onClick={() => handleChangeLeft(100)}>
              <FormatAlignRight />
            </Button>
          </ButtonGroup>
        )}
      </Stack>
    </Box>
  );
});

const RemovePairButton = () => {
  const { project } = useSnapshot(makerStore);
  const { index } = useSnapshot(state);

  const deleteConfirmation = useDeleteConfirmation();

  const usageCount = useMemo(
    () =>
      makerUtils
        .getAllFrames()
        .filter((f) => f.pair?.id === project.pairs[index].id).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index],
  );

  const handleRemove = useCallback(async () => {
    if (!makerStore.project) return;

    makerStore.project.pairs.splice(index, 1);

    state.characterIndex = 0;

    if (index > 0) {
      state.index = index - 1;
    }
  }, [index]);

  const handleRemoveClick = useCallback(() => {
    if (usageCount > 0) {
      deleteConfirmation.startConfirming();
    } else {
      handleRemove();
    }
  }, [deleteConfirmation, handleRemove, usageCount]);

  return (
    <Box pt={1} alignSelf="end">
      <DeleteConfirmation
        message={`This pair is used in ${usageCount} frames. Are you sure you want to remove it?`}
        deleteConfirmation={deleteConfirmation}
        handleDelete={handleRemove}
        variant="text"
      >
        <Button variant="text" color="error" onClick={handleRemoveClick}>
          Remove pair
        </Button>
      </DeleteConfirmation>
    </Box>
  );
};

const ProjectPairsDialogTitle = memo(() => {
  const handleClose = () => {
    makerStore.dialogs.projectPairs = false;
  };

  return <DraggableDialogDefaultTitle title="Pairs" onClose={handleClose} />;
});

const CustomPaper = styled(Paper, {
  shouldForwardProp: (prop) =>
    prop !== 'wide' &&
    prop !== 'setWide' &&
    prop !== 'filter' &&
    prop !== 'setFilter',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));
