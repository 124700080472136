import {
  Button,
  Card,
  CardContent,
  Fade,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { memo, useState } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';

export const PlayerCaseInputVariable = memo(() => {
  const {
    playerCase: {
      state: { userInput },
      actions: { onUserInput },
    },
    actions: { preloadThenNext },
  } = usePlayer();

  const open = userInput?.id === 12;

  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    if (!open) return;

    let value = event.target.value;

    if (userInput.type === 'int') {
      value = value.replace(/[^0-9]/g, '');
    }

    if (userInput.type === 'word') {
      value = value.replace(/\s/g, '');
    }

    setInputValue(value);
  };

  const handleSubmit = () => {
    if (!open) return;

    onUserInput<12>(
      preloadThenNext,
      userInput.type === 'int'
        ? parseInt(inputValue)
        : userInput.lowercase
          ? inputValue.toLowerCase()
          : inputValue,
    );

    setInputValue('');
  };

  if (!open) return null;

  const defaultPrompt =
    userInput.type === 'int'
      ? 'Enter a number'
      : userInput.type === 'word'
        ? 'Enter one word'
        : 'Enter text';

  return (
    <Fade in={open} timeout={200}>
      <Stack
        position="absolute"
        spacing={1.5}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.45)"
        zIndex={ZIndex.CaseInput}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          style={{
            maxWidth: 350,
            minWidth: 280,
            width: '100%',
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              <Typography
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                }}
              >
                {userInput.prompt || defaultPrompt}
              </Typography>

              <TextField
                value={inputValue}
                onChange={handleChange}
                variant="outlined"
                size="small"
                style={{ pointerEvents: 'auto' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && inputValue) {
                    handleSubmit();
                  }
                }}
                fullWidth
                autoFocus
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                size="small"
                style={{ pointerEvents: 'auto' }}
                disabled={!inputValue}
              >
                Submit
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Fade>
  );
});
