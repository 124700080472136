import { Box } from '@mui/material';
import { useRender } from '@web/hooks/useRender';
import { useSettingsStore } from '@web/store/settings';
import { useEffect, useRef } from 'react';
import { Image } from './ui/Image';

type MediaProps = {
  type: 'image' | 'video' | 'embed';
  url: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  videoPlay?: boolean;
  onVideoEnd?: () => void;
};

export const Media = ({
  type,
  url,
  onClick,
  style,
  onVideoEnd,
  videoPlay,
}: MediaProps) => {
  const howlerVolume = useRef(Howler.volume());
  const videoRef = useRef<HTMLVideoElement>(null);

  const { render } = useRender();

  const {
    audio: {
      muted: { master: masterMuted, sound: soundMuted },
      volume: { master: masterVolume, sound: soundVolume },
    },
  } = useSettingsStore();

  const videoVolume =
    (soundMuted ? 0 : soundVolume / 100) *
    (masterMuted ? 0 : masterVolume / 100);

  const handleVideoPlay = () => {
    Howler.volume(0);
  };

  const handleVideoEnd = () => {
    Howler.volume(howlerVolume.current);

    onVideoEnd?.();
  };

  useEffect(() => {
    if (videoRef.current) {
      if (videoPlay) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoPlay]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = videoVolume;
    }
  }, [videoVolume]);

  useEffect(() => {
    if (type !== 'video') return;

    howlerVolume.current = Howler.volume();

    return () => {
      Howler.volume(howlerVolume.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return type === 'image' ? (
    <Image src={url} style={style} onLoad={render} onClick={onClick} />
  ) : (
    <Box
      component="video"
      ref={videoRef}
      src={url}
      style={style}
      onPlay={handleVideoPlay}
      onEnded={handleVideoEnd}
      onLoadedData={render}
      onClick={onClick}
      controls
    />
  );
};
