import { DialogueBox } from '@web/types/project';
import { CSSProperties, useMemo } from 'react';

type useDialogueBoxStylesProps = {
  id: string;
  text: DialogueBox['text'];
  opacity?: number;
  wordSpacing?: number;
  letterSpacing?: number;
  size: number;
  centerText?: boolean;
  preview?: boolean;
};

export const useDialogueBoxStyles = ({
  id,
  text,
  opacity = 1,
  wordSpacing,
  letterSpacing,
  size,
  centerText,
  preview,
}: useDialogueBoxStylesProps) => {
  const wordSpacingSize = wordSpacing ? wordSpacing * size : undefined;
  const letterSpacingSize = letterSpacing ? letterSpacing * size : undefined;

  const containerStyle = useMemo<CSSProperties>(
    () => ({
      opacity,
      ...(wordSpacingSize && { wordSpacing: `${wordSpacingSize}em` }),
      ...(letterSpacingSize && { letterSpacing: `${letterSpacingSize}em` }),
    }),
    [letterSpacingSize, opacity, wordSpacingSize],
  );

  const textContainerStyle = useMemo<CSSProperties>(
    () => ({
      fontFamily: `font_${id}`,
      height: `${text.height}em`,
      textAlign: !centerText ? 'start' : 'center',
      fontSize: `${text.fontSize * size}px`,
      width: centerText
        ? `${text.widthCenter ?? text.width}%`
        : `${text.width}%`,
      left: centerText ? `${text.leftCenter ?? text.left}%` : `${text.left}%`,
      top: centerText ? `${text.topCenter ?? text.top}%` : `${text.top}%`,
      lineHeight: text.lineHeight ? `${text.lineHeight}em` : 'unset',
      ...(preview && {
        outline: '1px solid #FF8080',
      }),
    }),
    [
      centerText,
      id,
      preview,
      size,
      text.fontSize,
      text.height,
      text.left,
      text.leftCenter,
      text.lineHeight,
      text.top,
      text.topCenter,
      text.width,
      text.widthCenter,
    ],
  );

  return {
    containerStyle,
    textContainerStyle,
  };
};

type useNameplateStylesProps = {
  id: string;
  fontSize: number;
  top: number;
  right: number;
  style?: CSSProperties;
  letterSpacing?: number;
  size: number;
  preview?: boolean;
  color: string | null;
};

export const useNameplateStyles = ({
  id,
  fontSize,
  top,
  right,
  style,
  letterSpacing,
  size,
  color,
  preview,
}: useNameplateStylesProps) => {
  const letterSpacingSize = letterSpacing ? letterSpacing * size : undefined;

  const nameplateContainerStyle = useMemo<CSSProperties>(
    () => ({
      fontFamily: `nameplate_font_${id}`,
      top: `${top}%`,
      right: `${right}%`,
      fontSize: `${fontSize * size}px`,
      color: color ? `#${color}` : '#FFFFFF',
      ...(letterSpacingSize && { letterSpacing: `${letterSpacingSize}em` }),
      ...(preview && {
        outline: '1px solid #80FF80',
      }),
      ...style,
    }),
    [color, fontSize, id, letterSpacingSize, preview, right, size, style, top],
  );

  return {
    nameplateContainerStyle,
  };
};
