/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Conversation,
  CrossExaminationGroup,
  Examine,
  GameoverGroup,
  GroupType,
  InvestigationGroup,
  Location,
  NormalGroup,
  Project,
  ProjectType,
} from '../types';
import { Contradiction, Evidence } from '../types/evidence';
import { convertFramesToV5 } from './convertFrames';
import { OldProject } from './old-project/project';
import { OldFrame } from './types/project';
import { evidenceStringToEvidence } from './utils';

export const convertProjectToV5 = (
  oldProject: OldProject,
  type: ProjectType,
  nextFrameId: number,
) => {
  const project: Project = {
    id: oldProject.id,
    type: type || ProjectType.Scene,
    options: {
      ...oldProject.options,
      chatbox: String(oldProject.options.chatbox),
    },
    courtRecord: oldProject.courtRecord,
    aliases: oldProject.aliases.map((m) => ({ from: m.from, to: m.to })),
    pairs: oldProject.pairs.map((m) => ({
      id: m.id,
      name: m.name,
      pairs: [
        {
          characterId: m.characterId,
          offsetX: m.offsetX,
          offsetY: m.offsetY,
          front: m.front == true ? 1 : 0,
        },
        {
          characterId: m.characterId2,
          offsetX: m.offsetX2,
          offsetY: m.offsetY2,
          front: m.front == false ? 1 : 0,
        },
      ].filter((m) => !!m.characterId),
    })),
    groups: oldProject.groups.map((m) => {
      if (m.type == 'go') {
        const gameoverGroup: GameoverGroup = {
          id: m.id,
          type: GroupType.Gameover,
          name: m.name,
          frames: convertFramesToV5(m.frames, oldProject.pairs),
          comments: m.comments ?? {},
        };

        return gameoverGroup;
      } else if (m.type == 'ce') {
        const crossExaminationGroup: CrossExaminationGroup = {
          id: m.id,
          type: GroupType.CrossExamination,
          name: m.name,
          frames: convertFramesToV5(m.frames, oldProject.pairs),
          comments: m.comments ?? {},
          counselFrames: convertFramesToV5(m.counselFrames, oldProject.pairs),
          failureFrames: convertFramesToV5(m.failureFrames, oldProject.pairs),
          contradictions: (m.frames as OldFrame[])
            .filter((m) => !!m.contradictions && m.contradictions.length > 0)
            .reduce((acc, frame) => {
              acc[frame.id] = [];

              frame.contradictions?.forEach((cont) => {
                const contradiction: Contradiction = {
                  evidenceId: cont.eid.substring(2),
                  evidenceType:
                    cont.eid.split('-')[0] == 'e' ? 'evidence' : 'profile',
                  frameId: Number(cont.fid),
                };

                acc[frame.id].push(contradiction);
              });

              return acc;
            }, {}),
          pressFrames: (m.frames as OldFrame[])
            .filter((f) => !!f.pressFrames && f.pressFrames.length > 0)
            .reduce((acc, frame) => {
              acc[frame.id] = [];

              acc[frame.id] = convertFramesToV5(
                frame.pressFrames ?? [],
                oldProject.pairs,
              );

              return acc;
            }, {}),
        };

        return crossExaminationGroup;
      } else if (m.type == 'inv') {
        const investigationGroup: InvestigationGroup = {
          id: m.id,
          type: GroupType.Investigation,
          name: m.name,
          comments: m.comments ?? {},
          locations: m.locations.map(
            (location: any) =>
              ({
                id: location.id,
                name: location.name,
                backgroundId: location.background?.id || location.backgroundId,
                hide: location.hide,
                revealed: location.revealed,
                examine: location.examine?.map(
                  (examine: any) =>
                    ({
                      id: examine.id,
                      name: examine.name,
                      hide: examine.hide,
                      right: examine.right,
                      shape: examine.shape,
                      frames: convertFramesToV5(
                        examine.frames,
                        oldProject.pairs,
                      ),
                    }) as Examine,
                ),
                conversations: location.conversations?.map(
                  (conversation: any) =>
                    conversation.type == 'present'
                      ? ({
                          id: conversation.id,
                          name: conversation.name,
                          hide: conversation.hide,
                          frames: convertFramesToV5(
                            conversation.frames,
                            oldProject.pairs,
                          ),
                          present: conversation.eid.map(
                            (eid: string) =>
                              evidenceStringToEvidence(eid) as Evidence,
                          ),
                        } as Conversation)
                      : ({
                          id: conversation.id,
                          name: conversation.name,
                          hide: conversation.hide,
                          frames: convertFramesToV5(
                            conversation.frames,
                            oldProject.pairs,
                          ),
                        } as Conversation),
                ),
                frames: convertFramesToV5(location.frames, oldProject.pairs),
                completionFrames: convertFramesToV5(
                  location.completion,
                  oldProject.pairs,
                ),
                examineFailureFrames: convertFramesToV5(
                  location.examineFailure,
                  oldProject.pairs,
                ),
                presentFailureFrames: convertFramesToV5(
                  location.presentFailure,
                  oldProject.pairs,
                ),
              }) as Location,
          ),
        };

        return investigationGroup;
      } else {
        const normalGroup: NormalGroup = {
          id: m.id,
          type: GroupType.Normal,
          name: m.name,
          frames: convertFramesToV5(m.frames, oldProject.pairs),
          comments: m.comments ?? {},
        };

        return normalGroup;
      }
    }),
    nextFrameId,
    freeFrameIds: [],
    version: 5,
  };

  return project;
};
