import { Info } from '@mui/icons-material';
import { Stack, StackProps, Typography } from '@mui/material';

type TipProps = {
  text: string;
} & StackProps;

export const Tip = ({ text, ...props }: TipProps) => {
  return (
    <Stack direction="row" spacing={1} {...props}>
      <Info sx={{ fontSize: 16, color: 'text.secondary' }} />

      <Typography variant="caption" color="text.secondary">
        {text}
      </Typography>
    </Stack>
  );
};
