import { BackgroundDto } from '@web/api/api';
import { DragResize } from '@web/components/common/ui/DragResize';
import { Background } from '@web/components/player/ui/Background';
import { Container } from '@web/components/player/ui/Container';
import { Desk } from '@web/components/player/ui/Desk';
import { Evidence } from '@web/components/player/ui/Evidence';
import { Viewport } from '@web/components/player/ui/Viewport';
import { useMakerStore } from '@web/store/maker/state';
import { PointerEventHandler } from 'react';

type ImageAreaInputProps = {
  imageUrl: string;
  value?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  onChange?: (rect: {
    top: number;
    left: number;
    width: number;
    height: number;
  }) => void;
};

export const ImageAreaInput = ({
  imageUrl,
  value,
  onChange,
}: ImageAreaInputProps) => {
  const { aspectRatio } = useMakerStore();

  return (
    <Container aspectRatio={aspectRatio}>
      <Evidence url={imageUrl} size={1} />

      <DragResize value={value} onChange={onChange} />
    </Container>
  );
};

type BackgroundAreaInput = Omit<ImageAreaInputProps, 'imageUrl'> & {
  background: Pick<BackgroundDto, 'url' | 'deskUrl' | 'isWide'>;
  left?: number;
  onClick?: (x: number, y: number) => void;
};

export const BackgroundAreaInput = ({
  background,
  value,
  onChange,
  left,
  onClick,
}: BackgroundAreaInput) => {
  const { aspectRatio } = useMakerStore();

  const handleClick: PointerEventHandler<HTMLDivElement> = (event) => {
    if (!onClick) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const clientX = event.clientX;
    const clientY = event.clientY;

    const x = ((clientX - rect.left) / rect.width) * 100;
    const y = ((clientY - rect.top) / rect.height) * 100;

    onClick(x, y);
  };

  return (
    <Container aspectRatio={aspectRatio}>
      <Viewport
        fullWidth={!background.isWide}
        left={left}
        onPointerDown={handleClick}
      >
        <Background url={background.url} isWide={background.isWide} />
        <Desk url={background.deskUrl} isWide={background.isWide} />

        <DragResize
          value={value}
          onChange={onChange}
          minWidth={0}
          minHeight={0}
        />
      </Viewport>
    </Container>
  );
};
