/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { ProjectType } from '../types';
import { convertProjectToV5 } from './convertProject';
import { OldProject } from './old-project/project';
import {
  Old_CaseAction_ChooseEvidence,
  Old_CaseAction_HideEvidence,
  Old_CaseAction_InvestigationConfigureLocationCompletion,
  Old_CaseAction_InvestigationMarkUnvisited,
  Old_CaseAction_InvestigationSwapTalkConversation,
  Old_CaseAction_InvestigationToggleVisibility,
  Old_CaseAction_SetGameOverRedirection,
  Old_CaseAction_ShowEvidence,
  Old_CaseAction_ToggleEvidenceVisibility,
} from './types/caseActions';
import { OldFrame } from './types/project';
import { camelizeKeys } from './utils';

export class ProjectMigration {
  static migrate(data: any, type: ProjectType) {
    const oldProject = new OldProject(type);

    data = camelizeKeys(data);

    oldProject.loadData(data);

    const frames = oldProject.getAllFrames(undefined) as OldFrame[];

    migrateFrameIds(frames);
    migratePairs(oldProject, frames);
    migrateGroupIds(oldProject, frames);

    // migratePresetCharacters(frames);   // maybe later

    if (type == 'case') {
      migrateEvidenceIds(oldProject, frames);
      migrateLocationIds(oldProject, frames);
      migrateLocationElements(oldProject, frames);
    }

    const nextFrameId =
      frames.map((m) => m.id).reduce((acc, val) => Math.max(acc, val), 0) + 1;

    return convertProjectToV5(oldProject, type, nextFrameId);
  }
}

// maybe later
// const migratePresetCharacters = (frames: OldFrame[]) => {
//   const values = Object.values(migratedPresetCharacters);

//   frames.forEach((frame) => {
//     const character = values.find((f) => f.poses[frame.poseId]);

//     if (frame.poseId && !frame.characterId) {
//       frame.characterId = character?.id;
//       frame.poseId = character?.poses[frame.poseId];
//     }
//   });
// };

const migratePairs = (project: OldProject, frames: OldFrame[]) => {
  const map: Record<number, string> = {};

  project.pairs?.slice(0, 100).forEach((pair) => {
    const newId = uuidv4();

    if (pair.pairId) {
      map[pair.pairId] = newId;

      delete pair.pairId;
    }

    pair.id = newId;
  });

  frames.forEach((frame) => {
    if (frame.pairId) {
      frame.pairId = map[frame.pairId];
    }
  });
};

const migrateLocationElements = (project: OldProject, frames: OldFrame[]) => {
  const locations = project.groups
    ?.filter((m) => m.type == 'inv')
    .map((m) => m.locations)
    .flat();

  if (!locations || locations.length == 0) {
    return;
  }

  const mapExamine: Record<string, Record<number, string>> = locations.reduce(
    (acc, location) => {
      acc[location.id] = {};

      return acc;
    },
    {},
  );

  const mapConversation: Record<
    number,
    Record<number, string>
  > = locations.reduce((acc, location) => {
    acc[location.id] = {};

    return acc;
  }, {});

  locations.forEach((location) => {
    location.examine?.forEach((examine) => {
      if (!!examine.id && typeof examine.id === 'string') {
        return;
      }

      const newId = uuidv4();

      if (examine.iid) {
        mapExamine[location.id][examine.iid] = newId;

        delete examine.iid;
      }

      examine.id = newId;
    });

    location.conversations?.forEach((conversation) => {
      if (!!conversation.id && typeof conversation.id === 'string') {
        return;
      }

      const newId = uuidv4();

      if (conversation.iid) {
        mapConversation[location.id][conversation.iid] = newId;

        delete conversation.iid;
      }

      conversation.id = newId;
    });
  });

  frames.forEach((frame) => {
    if (!frame.caseAction || !frame.caseAction.id || !frame.caseAction.value) {
      return;
    }

    switch (frame.caseAction.id) {
      case 18: {
        const action: Old_CaseAction_InvestigationToggleVisibility =
          frame.caseAction;

        action.value.forEach((entry) => {
          if (entry.type == 'exam') {
            entry.hide?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapExamine[item.lid][item.id];
              }
            });

            entry.show?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapExamine[item.lid][item.id];
              }
            });
          } else if (entry.type == 'conv') {
            entry.hide?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapConversation[item.lid][item.id];
              }
            });

            entry.show?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapConversation[item.lid][item.id];
              }
            });
          }
        });

        break;
      }

      case 19: {
        const action: Old_CaseAction_InvestigationMarkUnvisited =
          frame.caseAction;

        action.value.forEach((entry) => {
          if (entry.type == 'exam') {
            entry.items?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapExamine[item.lid][item.id];
              }
            });
          } else if (entry.type == 'conv') {
            entry.items?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapConversation[item.lid][item.id];
              }
            });
          }
        });

        break;
      }

      case 20: {
        const action: Old_CaseAction_InvestigationSwapTalkConversation =
          frame.caseAction;

        action.value?.forEach((item) => {
          if (item.target?.id && item.target?.lid) {
            item.target.id = mapConversation[item.target.lid][item.target.id];
          }
          if (item.replacement?.id && item.replacement?.lid) {
            item.replacement.id =
              mapConversation[item.replacement.lid][item.replacement.id];
          }
        });

        break;
      }

      case 21: {
        const action: Old_CaseAction_InvestigationConfigureLocationCompletion =
          frame.caseAction;

        action.value.required?.forEach((required) => {
          if (required?.type == 'exam') {
            required.items?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapExamine[item.lid][item.id];
              }
            });
          } else if (required?.type == 'conv') {
            required.items?.forEach((item) => {
              if (item.id && item.lid) {
                item.id = mapConversation[item.lid][item.id];
              }
            });
          }
        });

        break;
      }
    }
  });
};

const migrateLocationIds = (project: OldProject, frames: OldFrame[]) => {
  const map: Record<number, string> = {};

  const locations = project.groups
    ?.filter((m) => m.type == 'inv')
    .map((m) => m.locations)
    .flat();

  if (!locations || locations.length == 0) {
    return;
  }

  locations.forEach((location) => {
    if (!!location.id && typeof location.id === 'string') {
      return;
    }

    const newId = uuidv4();

    if (location.iid) {
      map[location.iid] = newId;

      delete location.iid;
    }

    location.id = newId;
  });

  frames.forEach((frame) => {
    if (!frame.caseAction || !frame.caseAction.id || !frame.caseAction.value) {
      return;
    }

    switch (frame.caseAction.id) {
      case 18: {
        const action: Old_CaseAction_InvestigationToggleVisibility =
          frame.caseAction;

        action.value.forEach((entry) => {
          entry.hide?.forEach((item) => {
            if (item.lid) {
              item.lid = map[item.lid];
            }
          });

          entry.show?.forEach((item) => {
            if (item.lid) {
              item.lid = map[item.lid];
            }
          });
        });

        break;
      }

      case 19: {
        const action: Old_CaseAction_InvestigationMarkUnvisited =
          frame.caseAction;

        action.value.forEach((entry) => {
          entry.items?.forEach((item) => {
            if (item.lid) {
              item.lid = map[item.lid];
            }
          });
        });

        break;
      }

      case 20: {
        const action: Old_CaseAction_InvestigationSwapTalkConversation =
          frame.caseAction;

        action.value?.forEach((item) => {
          if (item.target?.lid) {
            item.target.lid = map[item.target.lid];
          }
          if (item.replacement?.lid) {
            item.replacement.lid = map[item.replacement.lid];
          }
        });

        break;
      }

      case 21: {
        const action: Old_CaseAction_InvestigationConfigureLocationCompletion =
          frame.caseAction;

        if (action.value.location?.lid) {
          action.value.location.lid = map[action.value.location.lid];
        }

        action.value.required?.forEach((required) => {
          if (required?.type != 'expression') {
            required.items?.forEach((item) => {
              if (item.lid) {
                item.lid = map[item.lid];
              }
            });
          }
        });

        break;
      }
    }
  });
};

const migrateEvidenceIds = (project: OldProject, frames: OldFrame[]) => {
  const mapEvidence: Record<number, string> = {};
  const mapProfile: Record<number, string> = {};

  if (!project.courtRecord) {
    return;
  }

  const evidence = project.courtRecord.evidence;
  const profiles = project.courtRecord.profiles;

  evidence?.forEach((evd) => {
    if (!!evd.id && typeof evd.id === 'string') {
      return;
    }

    const newId = uuidv4();

    if (evd.iid) {
      mapEvidence[evd.iid] = newId;

      delete evd.iid;
    }

    evd.id = newId;
  });

  profiles?.forEach((profile) => {
    if (!!profile.id && typeof profile.id === 'string') {
      return;
    }

    const newId = uuidv4();

    if (profile.iid) {
      mapProfile[profile.iid] = newId;

      delete profile.iid;
    }

    profile.id = newId;
  });

  frames.forEach((frame) => {
    if (!frame.caseAction || !frame.caseAction.id || !frame.caseAction.value) {
      return;
    }

    switch (frame.caseAction.id) {
      case 1: {
        const action: Old_CaseAction_ShowEvidence = frame.caseAction;

        frame.caseAction.value = migrateEvidenceReference(
          action.value,
          mapEvidence,
          mapProfile,
        );

        break;
      }

      case 2: {
        const action: Old_CaseAction_HideEvidence = frame.caseAction;

        frame.caseAction.value = migrateEvidenceReference(
          action.value,
          mapEvidence,
          mapProfile,
        );

        break;
      }

      case 8: {
        const action: Old_CaseAction_ChooseEvidence = frame.caseAction;

        action.value.items?.forEach((item) => {
          if (item.eid) {
            item.eid = migrateEvidenceReference(
              item.eid,
              mapEvidence,
              mapProfile,
            );
          }
        });

        break;
      }

      case 16: {
        const action: Old_CaseAction_ToggleEvidenceVisibility =
          frame.caseAction;

        if (action.value.show && Array.isArray(action.value.show)) {
          action.value.show = action.value.show.map((item) =>
            migrateEvidenceReference(item, mapEvidence, mapProfile),
          );
        }

        if (action.value.hide && Array.isArray(action.value.hide)) {
          action.value.hide = action.value.hide.map((item) =>
            migrateEvidenceReference(item, mapEvidence, mapProfile),
          );
        }
      }
    }
  });

  frames.forEach((frame) => {
    if (frame.contradictions) {
      frame.contradictions = frame.contradictions.map((m) => ({
        eid: migrateEvidenceReference(m.eid, mapEvidence, mapProfile),
        fid: m.fid,
      }));
    }
  });

  project.groups
    ?.filter((f) => f.type == 'inv')
    .forEach((group) => {
      const locations = group.locations ?? [];

      locations.forEach((location: any) => {
        location.conversations?.forEach((conversation: any) => {
          if (conversation.type == 'present') {
            conversation.eid = conversation.eid.map((eid: string) =>
              migrateEvidenceReference(eid, mapEvidence, mapProfile),
            );
          }
        });
      });
    });
};

const migrateEvidenceReference = (
  eid: string | null,
  mapEvidence: Record<number, string>,
  mapProfile: Record<number, string>,
) => {
  if (!eid) {
    return '';
  }

  const arr = eid.split('-');

  if (arr.length == 0) {
    return '';
  }

  const type = arr[0];
  const id = arr[1];

  if (type == 'e') {
    return `e-${mapEvidence[id]}`;
  }

  if (type == 'p') {
    return `p-${mapProfile[id]}`;
  }

  return '';
};

const migrateGroupIds = (project: OldProject, frames: OldFrame[]) => {
  const map: Record<number, string> = {};

  if (!project.groups || project.groups.length == 0) {
    return;
  }

  const groups = project.groups;

  groups.forEach((group) => {
    if (!!group.id && typeof group.id === 'string') {
      return;
    }

    const newId = uuidv4();

    if (group.iid) {
      map[group.iid] = newId;

      delete group.iid;
    }

    group.id = newId;
  });

  frames.forEach((frame) => {
    if (!frame.caseAction || !frame.caseAction.id || !frame.caseAction.value) {
      return;
    }

    switch (frame.caseAction.id) {
      case 15: {
        const action: Old_CaseAction_SetGameOverRedirection = frame.caseAction;

        action.value = action.value ? map[action.value] : null;

        break;
      }

      case 18: {
        const action: Old_CaseAction_InvestigationToggleVisibility =
          frame.caseAction;

        action.value.forEach((entry) => {
          entry.hide?.forEach((item) => {
            if (item.gid) {
              item.gid = map[item.gid];
            }
          });

          entry.show?.forEach((item) => {
            if (item.gid) {
              item.gid = map[item.gid];
            }
          });
        });

        break;
      }

      case 19: {
        const action: Old_CaseAction_InvestigationMarkUnvisited =
          frame.caseAction;

        action.value.forEach((entry) => {
          entry.items?.forEach((item) => {
            if (item.gid) {
              item.gid = map[item.gid];
            }
          });
        });

        break;
      }

      case 20: {
        const action: Old_CaseAction_InvestigationSwapTalkConversation =
          frame.caseAction;

        action.value?.forEach((item) => {
          if (item.target?.gid) {
            item.target.gid = map[item.target.gid];
          }
          if (item.replacement?.gid) {
            item.replacement.gid = map[item.replacement.gid];
          }
        });

        break;
      }

      case 21: {
        const action: Old_CaseAction_InvestigationConfigureLocationCompletion =
          frame.caseAction;

        if (action.value.location?.gid) {
          action.value.location.gid = map[action.value.location.gid];
        }

        action.value.required?.forEach((required) => {
          if (required?.type != 'expression') {
            required.items?.forEach((item) => {
              if (item.gid) {
                item.gid = map[item.gid];
              }
            });
          }
        });

        break;
      }
    }
  });
};

const migrateFrameIds = (frames: OldFrame[]) => {
  frames.forEach((frame) => {
    if (frame.iid) {
      frame.id = frame.iid;
    }

    delete frame.iid;
  });
};

// UID - decided to keep incremental ids
// const migrateFrameIds = (oldProject: OldProject, frames: OldFrame[]) => {
//   const map: Record<number, string> = {};

//   frames.forEach((frame) => {
//     if (!!frame.id && typeof frame.id === 'string') {
//       return;
//     }

//     const newId = uuidv4();

//     if (frame.iid) {
//       map[frame.iid] = newId;

//       delete frame.iid;
//     }

//     frame.id = newId;
//   });

//   frames.forEach((frame) => {
//     if (!frame.caseAction || !frame.caseAction.id || !frame.caseAction.value) {
//       return;
//     }

//     switch (frame.caseAction.id) {
//       case 3: {
//         // toggle visibility
//         const action: Old_CaseAction_ToggleFramesVisibility = frame.caseAction;
//         const showFrameIds = getFrameIdsFromString(action.value.show);
//         const hideFrameIds = getFrameIdsFromString(action.value.hide);

//         frame.caseAction.value.show = showFrameIds
//           .map((id) => map[id])
//           .join(',');
//         frame.caseAction.value.hide = hideFrameIds
//           .map((id) => map[id])
//           .join(',');

//         break;
//       }

//       case 4: {
//         // jump to frame
//         const action: Old_CaseAction_FrameJump = frame.caseAction;
//         frame.caseAction.value = action.value ? map[action.value] : null;

//         break;
//       }

//       case 8: {
//         const action: Old_CaseAction_ChooseEvidence = frame.caseAction;
//         action.value.items?.forEach((item) => {
//           item.fid = item.fid ? map[item.fid] : null;
//         });

//         action.value.falseFid = frame.caseAction.value.falseFid
//           ? map[frame.caseAction.value.falseFid]
//           : null;

//         break;
//       }

//       case 9: {
//         // choose option
//         const action: Old_CaseAction_ChooseAnswer = frame.caseAction;
//         action.value.forEach((item) => {
//           item.fid = item.fid ? map[item.fid] : null;
//         });

//         break;
//       }

//       case 13: {
//         // eval variable
//         const action: Old_CaseAction_EvaluateVariable = frame.caseAction;
//         action.value.trueFid = frame.caseAction.value.trueFid
//           ? map[frame.caseAction.value.trueFid]
//           : null;
//         action.value.falseFid = frame.caseAction.value.falseFid
//           ? map[frame.caseAction.value.falseFid]
//           : null;
//         break;
//       }

//       case 14: {
//         // eval expression
//         const action: Old_CaseAction_EvaluateExpression = frame.caseAction;
//         action.value.trueFid = frame.caseAction.value.trueFid
//           ? map[frame.caseAction.value.trueFid]
//           : null;
//         action.value.falseFid = frame.caseAction.value.falseFid
//           ? map[frame.caseAction.value.falseFid]
//           : null;

//         break;
//       }

//       case 17: {
//         // point area
//         const action: Old_CaseAction_PointArea = frame.caseAction;

//         action.value.areas?.forEach((area) => {
//           area.fid = area.fid ? map[area.fid] : null;
//         });

//         action.value.falseFid = action.value.falseFid
//           ? map[action.value.falseFid]
//           : undefined;

//         break;
//       }
//     }
//   });

//   frames.forEach((frame) => {
//     if (frame.contradictions) {
//       frame.contradictions = frame.contradictions.map((m) => ({
//         eid: m.eid,
//         fid: map[m.fid],
//       }));
//     }
//   });

//   oldProject.groups?.forEach((group) => {
//     if (group.comments) {
//       group.comments = Object.entries(group.comments).reduce(
//         (acc, [key, value]) => {
//           acc[map[key]] = value;
//           return acc;
//         },
//         {},
//       );
//     }
//   });
// };
