import { CaseFrame } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import easings from '@web/data/easings';
import { isNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { FadeType } from '../types/fades';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

export const useCharactersFades = (skipping?: boolean) => {
  // Define character targets array
  const characterTargets = useMemo(
    () => [
      SceneTargets.CHARACTER_1,
      SceneTargets.CHARACTER_2,
      SceneTargets.CHARACTER_3,
      SceneTargets.CHARACTER_4,
      SceneTargets.CHARACTER_5,
    ],
    [],
  );

  // Create state and refs for each character target
  const fades = useRef<
    Record<number, Omit<FadeType, 'color' | 'zIndex'> | undefined>
  >({});

  // Create individual refs
  const character1Api = useSpringRef();
  const character2Api = useSpringRef();
  const character3Api = useSpringRef();
  const character4Api = useSpringRef();
  const character5Api = useSpringRef();

  // Create a stable reference to the apis object using useMemo
  const apis = useMemo(
    () => ({
      [SceneTargets.CHARACTER_1]: character1Api,
      [SceneTargets.CHARACTER_2]: character2Api,
      [SceneTargets.CHARACTER_3]: character3Api,
      [SceneTargets.CHARACTER_4]: character4Api,
      [SceneTargets.CHARACTER_5]: character5Api,
    }),
    [character1Api, character2Api, character3Api, character4Api, character5Api],
  );

  // Initialize animations for each character
  const fadeAnimations = {
    [SceneTargets.CHARACTER_1]: useSpring({
      ref: apis[SceneTargets.CHARACTER_1],
      from: { opacity: 1 },
    }),
    [SceneTargets.CHARACTER_2]: useSpring({
      ref: apis[SceneTargets.CHARACTER_2],
      from: { opacity: 1 },
    }),
    [SceneTargets.CHARACTER_3]: useSpring({
      ref: apis[SceneTargets.CHARACTER_3],
      from: { opacity: 1 },
    }),
    [SceneTargets.CHARACTER_4]: useSpring({
      ref: apis[SceneTargets.CHARACTER_4],
      from: { opacity: 1 },
    }),
    [SceneTargets.CHARACTER_5]: useSpring({
      ref: apis[SceneTargets.CHARACTER_5],
      from: { opacity: 1 },
    }),
  };

  // Register reset for all characters
  usePlayerResetRegister({
    name: 'allCharactersFade',
    onReset: () => {
      fades.current = {};

      characterTargets.forEach((target) => {
        apis[target].start({
          to: { opacity: 1 },
          immediate: true,
        });
      });
    },
  });

  // Register save/load for all characters
  usePlayerSaveLoadRegister({
    name: 'allCharactersFade',
    onSave: () => {
      const opacities: Record<number, number> = {};
      characterTargets.forEach((target) => {
        opacities[target] = fadeAnimations[target].opacity.get();
      });
      return { fades: fades.current, opacities };
    },
    onLoad: (data) => {
      fades.current = data.fades || {};

      characterTargets.forEach((target) => {
        if (isNumber(data.opacities?.[target])) {
          // First set the current opacity immediately
          apis[target].start({
            to: { opacity: data.opacities[target] },
            immediate: true,
          });

          // Then continue any in-progress fade animations
          const fade = fades.current[target];

          if (fade?.fadeType && fade.duration) {
            const currentOpacity = data.opacities[target];
            const targetOpacity = fade.fadeType === 'fadeIn' ? 1 : 0;
            const startOpacity = fade.fadeType === 'fadeIn' ? 0 : 1;

            // Calculate progress and remaining duration
            const progress = Math.abs(
              (currentOpacity - startOpacity) / (targetOpacity - startOpacity),
            );
            const remainingDuration = fade.duration * (1 - progress);

            // Only start animation if there's remaining duration
            if (remainingDuration > 0) {
              apis[target].start({
                to: { opacity: targetOpacity },
                config: {
                  duration: remainingDuration,
                  easing: fade.easing,
                },
              });
            }
          }
        }
      });
    },
  });

  // Process frame changes and set fades accordingly
  const processFades = useCallback(
    (frame: CaseFrame, skipping: boolean) => {
      if (
        !frame.fade ||
        frame.fade?.type === 'fadeOutIn' ||
        frame.fade?.target !== 'characters'
      )
        return;

      const fadeType = frame.fade?.type;
      const duration = frame.fade?.duration || 1500;
      const easing = frame.fade?.easing
        ? easings.find((f) => f.id === frame.fade!.easing)?.value
        : (t: number) => t;

      // Check which characters are targeted and set their fades
      characterTargets.forEach((target) => {
        if (
          frame.fade?.characters &&
          (frame.fade.characters & target) === target
        ) {
          fades.current[target] = {
            fadeType,
            duration,
            easing,
          };
        }
      });

      // Process fade animations for each character
      characterTargets.forEach((target) => {
        const fade = fades.current[target];

        if (!fade?.fadeType) return;

        const isValidTarget =
          frame?.fade?.characters &&
          (frame.fade.characters & target) === target;

        if (!isValidTarget) return;

        apis[target].start({
          from: {
            opacity: fade.fadeType === 'fadeIn' ? 0 : 1,
          },
          to: {
            opacity: fade.fadeType === 'fadeIn' ? 1 : 0,
          },
          config: {
            duration: !skipping ? fade.duration : 0,
            easing: fade.easing,
          },
        });
      });
    },
    [fades, characterTargets, apis],
  );

  // Handle skipping
  useEffect(() => {
    if (skipping) {
      characterTargets.forEach((target) => {
        const fade = fades.current[target];

        if (fade && apis[target]) {
          apis[target].set({
            opacity: fade.fadeType === 'fadeIn' ? 1 : 0,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipping, fades]);

  return {
    fadeAnimations,
    processFades,
  };
};
