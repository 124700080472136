import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Switch as MuiSwitch,
} from '@mui/material';

type SwitchProps = {
  label: string;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
} & React.ComponentProps<typeof MuiSwitch>;

const Switch = ({ label, labelProps, ...props }: SwitchProps) => {
  return (
    <FormGroup>
      <Box display="inline-block">
        <FormControlLabel
          {...labelProps}
          control={<MuiSwitch {...props} />}
          label={label}
          sx={{ marginRight: 0, userSelect: 'none', ...labelProps?.sx }}
        />
      </Box>
    </FormGroup>
  );
};

export default Switch;
