import { useEffect, useRef } from 'react';
import { usePlayerSaveLoad } from '../providers/PlayerSaveLoadProvider';

type SaveLoad<T> = {
  name: string;
  onSave: () => T;
  onLoad: (state: T) => void;
};

export const usePlayerSaveLoadRegister = <T>({
  name,
  onSave,
  onLoad,
}: SaveLoad<T>) => {
  const context = usePlayerSaveLoad();

  const onSaveRef = useRef(onSave);
  const onLoadRef = useRef(onLoad);

  // Update refs when callbacks change
  useEffect(() => {
    onSaveRef.current = onSave;
    onLoadRef.current = onLoad;
  }, [onSave, onLoad]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const { register } = context;

    // Register with wrapper functions that call the current ref values
    register(
      name,
      () => onSaveRef.current(),
      (state: T) => onLoadRef.current(state),
    );

    // Only depend on context and name, not the callbacks
  }, [context, name]);
};
