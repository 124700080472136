import { clearObjectProperties } from '@web/utils/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import { proxy, useSnapshot } from 'valtio';

type PlayerSizeState = {
  aspectRatio: number;
  width: number;
  size: number;
  playerSize: number;
};

const initialState = (
  aspectRatio: number | undefined,
  width: number | undefined,
) => ({
  playerSize: 1,
  size: 1,
  aspectRatio: aspectRatio || 3 / 2,
  width: width || 960,
});

export const usePlayerSize = (
  playerContainerRef: React.RefObject<HTMLDivElement>,
  aspectRatio: number | undefined,
  width: number | undefined,
) => {
  const state = useRef(
    proxy<PlayerSizeState>(initialState(aspectRatio, width)),
  ).current;

  const snapshot = useSnapshot(state);

  const setPlayerSize = useCallback(() => {
    if (playerContainerRef.current) {
      const newPlayerSize =
        playerContainerRef.current.clientWidth / state.width;
      const newSize = (state.width / 960) * newPlayerSize;

      state.playerSize = newPlayerSize;
      state.size = newSize;
    }
  }, [playerContainerRef, state]);

  useEventListener('resize', setPlayerSize);

  const reset = useCallback(() => {
    clearObjectProperties(state);

    Object.assign(state, initialState(aspectRatio, width));
  }, [aspectRatio, state, width]);

  const actions = useMemo(
    () => ({ setPlayerSize, reset }),
    [setPlayerSize, reset],
  );

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio, width]);

  useEffect(() => {
    setPlayerSize();
  }, [setPlayerSize]);

  return { state: { ...snapshot }, actions };
};
