import { CaseFrame, Effect, Fade, FrameAction, FramePair } from '../types';
import { SceneTargets } from '../types/scene-targets';
import { migrateCaseAction } from './convertCaseAction';
import { OldFrame, OldPairs } from './types/project';
import { removeFalsyProperties } from './utils';

export const convertFramesToV5 = (
  frames: OldFrame[],
  pairs: OldPairs,
): CaseFrame[] => {
  return frames.map((m) => {
    const frame: CaseFrame = {
      id: m.id,
      text: m.text,
      poseId: m.poseId,
      blip: m.blip,
      noPoseAnimation: !m.poseAnimation,
      moveToNext: m.goNext,
      mergeWithNext: m.mergeNext,
      doNotTalk: m.doNotTalk,
      noPauseForAnimation: m.keepDialogue,
      flipped: m.flipped
        ? m.flipped
            .padStart(3, '0')
            .split('')
            .reduce((result, bit, index) => {
              if (bit === '1') {
                if (index === 0) return result | SceneTargets.BACKGROUND;
                if (index === 1) return result | SceneTargets.CHARACTER_1;
                if (index === 2) return result | SceneTargets.CHARACTER_2;
              }
              return result;
            }, SceneTargets.NONE)
        : undefined,
      speechBubble: m.bubbleType,
      customName: m.username,
      backgroundId: m.backgroundId,
      characterId: m.characterId,
      pair: m.pairId ? migratePair(m, pairs) : undefined,
      popupId: m.popupId,
      actions: m.frameActions
        ? m.frameActions.map(
            (m) =>
              ({
                actionId: m.actionId,
                actionParam: m.actionParam,
              }) as FrameAction,
          )
        : [],
      fade: migrateFade(m.frameFades?.[0]),
      transition: m.transition,
      effect: migrateEffect(m.filter),
      caseAction: migrateCaseAction(m.caseAction),
      hide: m.hide,
    };

    return removeFalsyProperties(frame, ['id', 'text']) as CaseFrame;
  });
};

const migrateEffect = (
  effect:
    | {
        type: 'grayscale' | 'invert' | 'sepia' | 'hue-rotate';
        target: number;
        amount: number;
      }
    | undefined,
): Effect | undefined => {
  if (!effect) {
    return undefined;
  }

  const targets = {
    0: SceneTargets.BACKGROUND,
    1:
      SceneTargets.CHARACTER_1 |
      SceneTargets.CHARACTER_2 |
      SceneTargets.CHARACTER_3 |
      SceneTargets.CHARACTER_4 |
      SceneTargets.CHARACTER_5,
    2:
      SceneTargets.BACKGROUND |
      SceneTargets.CHARACTER_1 |
      SceneTargets.CHARACTER_2 |
      SceneTargets.CHARACTER_3 |
      SceneTargets.CHARACTER_4 |
      SceneTargets.CHARACTER_5,
  };

  return {
    target: targets[effect.target],
    type: effect.type,
    amount: effect.amount,
  };
};

const migratePair = (
  frame: OldFrame,
  pairs: OldPairs,
): FramePair | undefined => {
  const oldPair = pairs.find((m) => m.id == frame.pairId);

  if (!oldPair?.id || !frame.pairPoseId) {
    return undefined;
  }

  const otherCharacterId =
    oldPair.characterId == frame.characterId
      ? oldPair.characterId2
      : oldPair.characterId;

  return {
    id: oldPair.id.toString(),
    poseIds: {
      [otherCharacterId]: frame.pairPoseId,
    },
  };
};

const migrateFade = (
  fade:
    | {
        fade: number;
        target: number;
        duration: number;
        color: string;
        easing: string;
      }
    | undefined,
): Fade | undefined => {
  if (!fade) {
    return undefined;
  }

  const types = {
    0: 'fadeOut',
    1: 'fadeIn',
    2: 'fadeOutIn',
  };

  const targets = {
    0: SceneTargets.BACKGROUND,
    1: SceneTargets.CHARACTER_1,
    2:
      SceneTargets.BACKGROUND |
      SceneTargets.CHARACTER_1 |
      SceneTargets.CHARACTER_2 |
      SceneTargets.CHARACTER_3 |
      SceneTargets.CHARACTER_4 |
      SceneTargets.CHARACTER_5,
    3: SceneTargets.ALL,
    4: SceneTargets.CHARACTER_2,
    5: SceneTargets.CHARACTER_1 | SceneTargets.CHARACTER_2,
  };

  const type = types[fade.fade];

  // fade out in
  if (type === 'fadeOutIn') {
    return {
      target: 'everything',
      type: type,
      color: fade.color,
      duration: fade.duration,
      easing: fade.easing,
    };
  }

  if ([1, 4, 5].includes(fade.target)) {
    // characters
    const result: Fade = {
      target: 'characters',
      type: type,
      characters: targets[fade.target],
      duration: fade.duration,
      easing: fade.easing,
    };

    return result;
  } else if ([0, 2].includes(fade.target)) {
    // background or scene
    const result: Fade = {
      target: fade.target == 0 ? 'background' : 'scene',
      type: type,
      color: fade.color,
      duration: fade.duration,
      easing: fade.easing,
    };

    return result;
  } else if (fade.target == 3) {
    // everything
    const result: Fade = {
      target: 'everything',
      type: type,
      color: fade.color,
      duration: fade.duration,
      easing: fade.easing,
    };

    return result;
  }

  return undefined;
};
