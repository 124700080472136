import {
  Box,
  Fade,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper, { PaperProps } from '@mui/material/Paper';
import * as React from 'react';

type PopperProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
  children: React.ReactNode;
  paperProps?: PaperProps;
  transitionDuration?: number;
  persist?: boolean;
} & MuiPopperProps;

export const Popper = ({
  anchorEl,
  open,
  onClose,
  onExited,
  children,
  paperProps,
  transitionDuration,
  persist,
  ...props
}: PopperProps) => {
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      persist ||
      (anchorEl && anchorEl.contains(event.target as HTMLElement))
    ) {
      return;
    }

    onClose();
  };

  // What's this?
  // const prevOpen = React.useRef(open);

  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorEl?.focus();
  //   }

  //   prevOpen.current = open;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl}
      role="menu"
      transition
      {...props}
      sx={{
        zIndex: 200,
        ...props.sx,
      }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          timeout={transitionDuration !== undefined ? transitionDuration : 175}
          onExited={() => {
            onExited?.();

            // Ensure MUI's internal cleanup still runs
            TransitionProps?.onExited?.();
          }}
        >
          <Paper
            {...paperProps}
            sx={{
              transform: 'translateZ(0)', // Force GPU rendering
              ...paperProps?.sx,
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Box>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </MuiPopper>
  );
};
