import {
  ArrowBack,
  AutoStories,
  Close,
  Equalizer,
  GitHub,
  MusicNote,
  Pause,
  PlayArrow,
  RecordVoiceOver,
  Save,
  Upload,
  VolumeUp,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Slide,
  Stack,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useQueryClient } from '@tanstack/react-query';
import { SaveDto } from '@web/api/api';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { enqueueSnackbar } from 'notistack';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import MultiView from '../../../common/ui/MultiView';
import { usePlayerMeta } from '../../providers/PlayerMetaProvider';
import { usePlayer } from '../../providers/PlayerProvider';
import { VolumeSlider } from '../../VolumeSlider';
import { SavedGamesList } from '../SavedGamesList';

enum MenuView {
  MAIN,
  VOLUME,
  SAVE,
  LOAD,
}

export const CaseMenuDialog = () => {
  const {
    playerCase: { state },
    state: { isAutoplay },
    actions: { update },
  } = usePlayer();

  const { showMenu } = state;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuView, setMenuView] = useState<MenuView>(MenuView.MAIN);

  const handleClose = useCallback(() => {
    state.showMenu = false;
  }, [state]);

  const handleTransitionExited = useCallback(() => {
    setMenuView(MenuView.MAIN);
  }, [setMenuView]);

  const handleCourtRecord = useCallback(() => {
    state.showMenu = false;
    state.showCourtRecord = true;
  }, [state]);

  const toggleAutoplay = useCallback(() => {
    update({ isAutoplay: !isAutoplay });
  }, [update, isAutoplay]);

  const dialogTitle = useMemo(() => {
    switch (menuView) {
      case MenuView.MAIN:
        return 'Game Menu';
      case MenuView.VOLUME:
        return 'Volume Controls';
      case MenuView.SAVE:
        return 'Save Game';
      case MenuView.LOAD:
        return 'Load Game';
      default:
        return 'Game Menu';
    }
  }, [menuView]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={!!showMenu}
      onClose={handleClose}
      onTransitionExited={handleTransitionExited}
      TransitionComponent={Transition}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{dialogTitle}</Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            {menuView !== MenuView.MAIN && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setMenuView(MenuView.MAIN)}
                aria-label="back to menu"
              >
                <ArrowBack />
              </IconButton>
            )}

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent sx={menuView === MenuView.VOLUME ? {} : undefined}>
        <MultiView index={menuView}>
          <MainMenuContent
            handleCourtRecord={handleCourtRecord}
            toggleAutoplay={toggleAutoplay}
            isAutoplay={!!isAutoplay}
            setMenuView={setMenuView}
          />
          <VolumeMenuContent />
          <SaveGameContent />
          <LoadGameContent />
        </MultiView>
      </DialogContent>
    </Dialog>
  );
};

const GameTitleCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
}));

type MainMenuContentProps = {
  handleCourtRecord: () => void;
  toggleAutoplay: () => void;
  isAutoplay: boolean;
  setMenuView: (view: MenuView) => void;
};

const MainMenuContent = memo(
  ({
    handleCourtRecord,
    toggleAutoplay,
    isAutoplay,
    setMenuView,
  }: MainMenuContentProps) => {
    const {
      state: { isPlaying, startedPlaying },
    } = usePlayer();

    const playerMeta = usePlayerMeta();
    const { loggedIn } = useAuth();

    return (
      <>
        <GameTitleCard elevation={0}>
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontWeight: 'bold',
              lineHeight: 1.3,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {playerMeta?.title || 'Untitled Case'}
          </Typography>
        </GameTitleCard>

        <List component="nav" sx={{ pt: 1 }}>
          <ListItemButton
            onClick={handleCourtRecord}
            disabled={!startedPlaying}
          >
            <ListItemIcon>
              <AutoStories />
            </ListItemIcon>
            <ListItemText primary="Court Record" />
          </ListItemButton>

          <ListItemButton onClick={toggleAutoplay}>
            <ListItemIcon>
              {isAutoplay ? <Pause /> : <PlayArrow />}
            </ListItemIcon>
            <ListItemText
              primary={isAutoplay ? 'Disable Autoplay' : 'Enable Autoplay'}
            />
          </ListItemButton>

          <ListItemButton onClick={() => setMenuView(MenuView.VOLUME)}>
            <ListItemIcon>
              <VolumeUp />
            </ListItemIcon>
            <ListItemText primary="Volume Controls" />
          </ListItemButton>

          {!loggedIn && (
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="caption" color="text.disabled">
                    Login required to save or load games
                  </Typography>
                }
              />
            </ListItem>
          )}

          {import.meta.env.VITE_BUGS_TRACKER_URL && playerMeta?.isPreview && (
            <ListItemButton
              href={import.meta.env.VITE_BUGS_TRACKER_URL}
              target="_blank"
            >
              <ListItemIcon>
                <GitHub />
              </ListItemIcon>
              <ListItemText primary="Report a Bug" />
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => setMenuView(MenuView.SAVE)}
            disabled={
              !loggedIn || playerMeta?.isPreview || isPlaying || !startedPlaying
            }
          >
            <ListItemIcon>
              <Save />
            </ListItemIcon>
            <ListItemText primary="Save Game" />
          </ListItemButton>

          <ListItemButton
            onClick={() => setMenuView(MenuView.LOAD)}
            disabled={!loggedIn || playerMeta?.isPreview || isPlaying}
          >
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText primary="Load Game" />
          </ListItemButton>
        </List>
      </>
    );
  },
);

MainMenuContent.displayName = 'MainMenuContent';

const VolumeMenuContent = memo(() => {
  return (
    <Stack spacing={3} pt={1}>
      <VolumeSlider
        setting="master"
        icon={<Equalizer />}
        showLabel
        label="Master Volume"
      />
      <VolumeSlider
        setting="music"
        icon={<MusicNote />}
        showLabel
        label="Music Volume"
      />
      <VolumeSlider
        setting="sound"
        icon={<VolumeUp />}
        showLabel
        label="Sound Effects Volume"
      />
      <VolumeSlider
        setting="blip"
        icon={<RecordVoiceOver />}
        showLabel
        label="Speech Blip Volume"
      />
    </Stack>
  );
});

VolumeMenuContent.displayName = 'VolumeMenuContent';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveGameContent = memo(() => {
  const {
    playerSaveLoad: { saveGame },
  } = usePlayer();

  const [savingSlot, setSavingSlot] = useState<number | null>(null);

  const queryClient = useQueryClient();

  const handleSave = async (slot: number) => {
    setSavingSlot(slot);

    try {
      await saveGame(slot);

      queryClient.invalidateQueries({ queryKey: ['savedGames'] });
    } catch (error) {
      enqueueSnackbar('Failed to save game', { variant: 'error' });
    } finally {
      setSavingSlot(null);
    }
  };

  return (
    <SavedGamesList
      actionType="save"
      onSave={handleSave}
      savingSlot={savingSlot}
    />
  );
});

SaveGameContent.displayName = 'SaveGameContent';

const LoadGameContent = memo(() => {
  const {
    playerSaveLoad: { loadGame },
  } = usePlayer();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoad = useCallback(
    async (game: SaveDto) => {
      setIsLoading(true);

      try {
        loadGame(game.id);
      } catch (error) {
        enqueueSnackbar('Failed to load game', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [loadGame],
  );

  return (
    <SavedGamesList
      actionType="load"
      onLoad={handleLoad}
      isLoading={isLoading}
    />
  );
});

LoadGameContent.displayName = 'LoadGameContent';
