import { DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { SaveDto } from '@web/api/api';
import { SavedGamesList } from '@web/components/player/case/SavedGamesList';
import { rootStore, useRootStore } from '@web/store/root/state';
import { dashify } from '@web/utils/utils';
import { useNavigate } from 'react-router-dom';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '../ui/DraggableDialog';

export const MySavedGamesDialog = () => {
  const {
    dialogs: { mySavedGames },
  } = useRootStore();

  const navigate = useNavigate();

  const handleClose = () => {
    rootStore.dialogs.mySavedGames = false;
  };

  const handleLoadSave = (game: SaveDto) => {
    navigate(`/case/${game.caseId}/${dashify(game.title)}`, {
      state: { load: game },
    });

    handleClose();
  };

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={mySavedGames}
      onClose={handleClose}
      titleComponent={
        <DraggableDialogDefaultTitle
          title="Saved Games"
          onClose={handleClose}
        />
      }
      maxWidth="xs"
      fullscreen={fullscreen}
      persist
      fullWidth
    >
      <DialogContent>
        <SavedGamesList actionType="load" onLoad={handleLoadSave} />
      </DialogContent>
    </DraggableDialog>
  );
};
