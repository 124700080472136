import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Button,
  Card,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { xSmallButtonStyle } from '@web/components/common/ui/Buttons';
import { Popper } from '@web/components/common/ui/Popper';
import { GameCategory } from '@web/components/player/types/state';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { memo } from 'react';
import { useMakerButtonSizes } from '../hooks/useMakerButtonSizes';

export const CrossExaminationCategoryPicker = memo(() => {
  const { anchorEl, close, toggle } = useAnchorMenu();

  const {
    framesTarget: { category },
  } = useMakerStore();

  const { small, xSmall } = useMakerButtonSizes();

  const handleChange = (category: GameCategory | undefined) => {
    makerStore.framesTarget = {
      groupId: makerStore.framesTarget.groupId,
      category,
    };
    makerStore.page = 1;

    close();
  };

  const title = !category
    ? 'Statements'
    : category === GameCategory.CrossExaminationCounsel
      ? 'Counsel'
      : 'Failure';
  const open = !!anchorEl;

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        size={small ? 'small' : 'medium'}
        style={{ borderRadius: 0, ...(xSmall ? xSmallButtonStyle : {}) }}
        onClick={toggle}
        endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
      >
        {title}
      </Button>

      <Popper
        anchorEl={anchorEl}
        onClose={close}
        open={!!anchorEl}
        popperOptions={{ placement: 'bottom-start' }}
        disablePortal
      >
        <Card
          sx={{
            maxWidth: '95vw',
            minWidth: 300,
            width: 350,
            boxShadow: 3,
          }}
        >
          <List dense>
            <ListItemButton
              selected={!category}
              onClick={() => handleChange(undefined)}
              dense
            >
              <ListItemText>
                <Typography fontSize="0.825rem" fontWeight="bold">
                  Statements
                </Typography>
                <Typography fontSize="0.825rem" color="text.secondary">
                  The statements made by a witness for the cross-examination.
                </Typography>
              </ListItemText>
            </ListItemButton>

            <ListItemButton
              selected={category === GameCategory.CrossExaminationCounsel}
              onClick={() => handleChange(GameCategory.CrossExaminationCounsel)}
              dense
            >
              <ListItemText>
                <Typography fontSize="0.825rem" fontWeight="bold">
                  Counsel Conversation
                </Typography>
                <Typography fontSize="0.825rem" color="text.secondary">
                  The conversation with the defense counsel upon reaching the
                  end of the statements.
                </Typography>
              </ListItemText>
            </ListItemButton>

            <ListItemButton
              selected={
                category === GameCategory.CrossExaminationEvidenceFailure
              }
              onClick={() =>
                handleChange(GameCategory.CrossExaminationEvidenceFailure)
              }
              dense
            >
              <ListItemText>
                <Typography fontSize="0.825rem" fontWeight="bold">
                  Failure Conversation
                </Typography>
                <Typography fontSize="0.825rem" color="text.secondary">
                  The conversation when the user presents a wrong piece of
                  evidence.
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Card>
      </Popper>
    </>
  );
});
