import {
  Equalizer,
  MusicNote,
  RecordVoiceOver,
  VolumeUp,
} from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { Popper } from '../common/ui/Popper';
import { VolumeSlider } from './VolumeSlider';

export const PlayerVolumeControl = () => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="success"
        onClick={toggle}
        sx={{ minWidth: 50 }}
      >
        <VolumeUp />
      </Button>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ minWidth: 300 }}
        placement="auto-start"
        transitionDuration={100}
        disablePortal
      >
        <Stack spacing={1} p={1.5}>
          <VolumeSlider setting="master" icon={<Equalizer />} />
          <VolumeSlider setting="music" icon={<MusicNote />} />
          <VolumeSlider setting="sound" icon={<VolumeUp />} />
          <VolumeSlider setting="blip" icon={<RecordVoiceOver />} />
        </Stack>
      </Popper>
    </>
  );
};
