import { WbSunny } from '@mui/icons-material';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';

export const FlashTag = ({ width }: { width: number }) => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  const { insertTag } = useFrameEditor();

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <WbSunny />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 250 }}
        placement="top"
      >
        <Stack p={1}>
          <Typography>Flash</Typography>
          <Typography color="text.secondary" variant="body2">
            Perform flash at a certain point
          </Typography>
        </Stack>

        <Divider />

        <Grid container p={1} spacing={1}>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => {
                insertTag('fs');
              }}
              fullWidth
            >
              Small
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => {
                insertTag('fm');
              }}
              fullWidth
            >
              Medium
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => {
                insertTag('fl');
              }}
              fullWidth
            >
              Large
            </Button>
          </Grid>
        </Grid>
      </Popper>
    </>
  );
};
