import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  Chip,
  TextFieldVariants,
} from '@mui/material';
import { AdornmentButton } from '@web/components/maker/ui/AdornmentButton';
import { RenderAutocompleteInput } from '../ui/RenderAutocompleteInput';

export type WithIdAndName = {
  id: string | number | null;
  name: string;
};

type SimpleMultiSelectProps<
  T,
  FreeSolo extends boolean | undefined = undefined,
> = {
  label?: string;
  value: T[];
  onChange: (value: T[]) => void;
  options: T[];
  variant?: TextFieldVariants | undefined;
  error?: string;
  clearable?: boolean;
  disableRenderTags?: boolean;
} & Omit<
  AutocompleteProps<T, true, false, FreeSolo>,
  'renderInput' | 'onChange'
>;

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const SimpleMultiSelect = <T extends WithIdAndName>({
  label,
  value,
  onChange,
  options,
  error,
  variant,
  clearable,
  renderOption: customRenderOption,
  disableRenderTags,
  ...props
}: SimpleMultiSelectProps<T>) => {
  const { sx, ...other } = props;

  return (
    <Autocomplete
      {...other}
      options={options}
      multiple
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label={label}
          variant={variant ?? 'outlined'}
          error={!!error}
          helperText={error}
          InputProps={
            clearable && value.length > 0
              ? {
                  endAdornment: (
                    <AdornmentButton
                      size="small"
                      sx={{ height: 28, width: 28 }}
                      onClick={() => onChange([])}
                    />
                  ),
                }
              : {}
          }
        />
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;

        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      renderTags={
        disableRenderTags
          ? (value, getTagProps) => (
              <Chip
                label={`${value.length} ${value.length === 1 ? 'item' : 'items'}`}
                size="small"
              />
            )
          : undefined
      }
      getOptionLabel={(option) => option.name || ''}
      getOptionKey={(option) => option.id || 'null'}
      disableCloseOnSelect
    />
  );
};
