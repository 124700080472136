import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { CaseAction_ChooseAnswer } from '@shared/types/case-action';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { useCaseActionInput } from '../CaseActionInputs';

export const ChooseAnswerInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_ChooseAnswer>();

  const handleAddAnswerRow = () => {
    const answers = [...(caseAction.answers || []), { text: '', frameId: 0 }];

    updateCaseAction({ answers });
  };

  const handleRemoveAnswerRow = (i: number) => {
    const answers = [...(caseAction.answers || [])];

    answers.splice(i, 1);

    updateCaseAction({ answers });
  };

  const handleChangeAnswerText = (i: number, value: string) => {
    const answers = [...(caseAction.answers || [])];

    answers[i] = { ...answers[i], text: value };

    updateCaseAction({ answers });
  };

  const handleChangeFrameId = (i: number, value: string) => {
    const answers = [...(caseAction.answers || [])];

    answers[i] = { ...answers[i], frameId: parseInt(value) };

    updateCaseAction({ answers });
  };

  return (
    <Stack spacing={2} pt={1}>
      <Stack spacing={1}>
        {caseAction.answers?.map((answer, i) => (
          <Stack key={i} direction="row" spacing={1} alignItems="end">
            <TextField
              label={`Choice ${i + 1}`}
              value={answer.text || ''}
              onChange={(event) =>
                handleChangeAnswerText(i, event.target.value)
              }
              variant="standard"
              size="small"
              fullWidth
            />

            <NumberTextField
              label="Target Frame ID"
              value={answer.frameId || ''}
              onChange={(event) => handleChangeFrameId(i, event.target.value)}
              variant="standard"
              size="small"
              sx={{ width: 200 }}
            />

            <IconButton size="small" onClick={() => handleRemoveAnswerRow(i)}>
              <Delete />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <Box>
        <Button
          variant="contained"
          color="violet"
          size="small"
          startIcon={<Add />}
          disabled={caseAction.answers?.length === 4}
          onClick={handleAddAnswerRow}
        >
          Add
        </Button>
      </Box>
    </Stack>
  );
};
