import { DialogueBox } from '@web/types/project';

export const PresetDialogueBoxes = Object.freeze<DialogueBox[]>([
  {
    id: '1',
    name: 'Ace Attorney Trilogy',
    url: '/Images/UI/Chatbox/Trilogy/textbox.png',
    imageOpacity: 0.92,
    wordSpacing: 0,
    defaultColor: null,
    red: null,
    green: null,
    blue: null,
    nameplate: {
      fontSize: 29,
      url: '/Images/UI/Chatbox/Trilogy/nameplate.png',
      top: 62.9,
      right: 31.7,
      // fontFamily: 'Trilogy-body',
      fontUrl: '/Fonts/Trilogy-body.woff2',
      replaceBox: false,
      color: 'FFFFFF',
    },
    text: {
      fontSize: 34,
      // fontFamily: 'Trilogy-body',
      width: 67,
      widthCenter: 98,
      height: 4.5179,
      left: 23.2,
      leftCenter: 1,
      top: 69,
      topCenter: 68.5,
      fontUrl: '/Fonts/Trilogy-body.woff2',
    },
    nextButton: {
      iconAnimDuration: 0.5,
      icon: 'keyboarddoublearrowright',
      iconSize: 3.75,
      iconColor: 'FFD560',
      top: 75.7,
      left: 91,
    },
  },
  {
    id: '2',
    name: 'Apollo Justice',
    url: '/Images/UI/Chatbox/AJ/textbox-nameless.png',
    wordSpacing: 0.5,
    defaultColor: 'DCDCDC',
    red: null,
    green: null,
    blue: null,
    nameplate: {
      fontSize: 27,
      url: '/Images/UI/Chatbox/AJ/textbox.png',
      top: 71,
      right: 27.6,
      // fontFamily: 'AAAJNameplate',
      fontUrl: '/Fonts/AJ-nameplate.ttf',
      replaceBox: true,
      color: 'FFFFFF',
    },
    text: {
      fontSize: 28,
      width: 69,
      height: 4.2,
      left: 15.5,
      top: 77.5,
      topCenter: 77.8,
      lineHeight: 1.4,
      // fontFamily: 'AAAJBody',
      fontUrl: '/Fonts/AJ_body.woff2',
    },
    nextButton: {
      url: '/Images/UI/Chatbox/AJ/continue.png',
      iconAnimDuration: 0.7,
      icon: 'playarrow',
      iconSize: 5.8125,
      iconColor: 'FFFFFF',
      top: 83,
      left: 88.8,
    },
  },
  {
    id: '3',
    name: 'The Great Ace Attorney',
    url: '/Images/UI/Chatbox/TGAA/textbox-nameless.png',
    letterSpacing: 0.05,
    wordSpacing: 0,
    defaultColor: 'E0E0E0',
    red: 'FF6D18',
    green: '80FB0A',
    blue: '18D7FA',
    nameplate: {
      fontSize: 24,
      url: '/Images/UI/Chatbox/TGAA/textbox.png',
      top: 69,
      right: 27.8,
      // fontFamily: 'TGAA-nameplate',
      fontUrl: '/Fonts/TGAA-nameplate.woff2',
      replaceBox: true,
      letterSpacing: 0.05,
      style: {
        WebkitTextStroke: '0.016em #81743b',
      },
      color: 'FFFFFF',
    },
    text: {
      fontSize: 30,
      // fontFamily: 'TGAA-body',
      width: 73,
      height: 2.8,
      left: 13.6,
      top: 76.5,
      fontUrl: '/Fonts/TGAA-body.otf',
      lineHeight: 1.4,
    },
    nextButton: {
      iconAnimDuration: 0.5,
      icon: 'chevronright',
      iconSize: 2.5,
      iconColor: 'DAD79F',
      top: 80.1,
      left: 86,
    },
  },
  {
    id: '4',
    name: 'DS Style',
    url: '/Images/UI/Chatbox/DS/textbox-nameless.png',
    wordSpacing: 0,
    letterSpacing: 0.05,
    opacity: 0.85,
    defaultColor: null,
    red: null,
    green: null,
    blue: null,
    nameplate: {
      fontSize: 26,
      url: '/Images/UI/Chatbox/DS/textbox.png',
      top: 61.7,
      right: 39.7,
      // fontFamily: 'DS-nameplate',
      fontUrl: '/Fonts/DS-nameplate.ttf',
      replaceBox: true,
      letterSpacing: 0.05,
      color: 'FFFFFF',
    },
    text: {
      fontSize: 50,
      // fontFamily: 'DS-body',
      width: 92,
      height: 3.6,
      left: 4,
      top: 70,
      fontUrl: '/Fonts/DS.ttf',
      lineHeight: 1.2,
    },
    nextButton: {
      iconAnimDuration: 0.3,
      icon: '►',
      iconSize: 2.75,
      iconColor: 'FFFFFF',
      top: 87,
      left: 96.25,
    },
  },
]);

export const presetDialogueBoxesOptions = Object.freeze([
  {
    text: 'Classic',
    value: '0',
    hint: 'Text will auto resize if needed. Nameplate has a flexible width',
  },
  {
    text: 'Ace Attorney Trilogy',
    value: '1',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'Apollo Justice',
    value: '2',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'The Great Ace Attorney',
    value: '3',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'DS Style',
    value: '4',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
]);
