import { Box, Stack } from '@mui/material';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Player } from '@web/components/player/Player';
import { PlayerMetaProvider } from '@web/components/player/providers/PlayerMetaProvider';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

const state = proxy({
  project: undefined as SceneProjectDto | CaseProjectDto | undefined,
  title: '',
});

const EmbedPage = () => {
  return (
    <Box>
      <ObjectionPlayer />
    </Box>
  );
};

const ObjectionPlayer = memo(() => {
  const { id } = useParams();
  const { project, title } = useProxy(state);

  const ref = useRef<PlayerActionsType>(null);

  useEffect(() => {
    if (!id) return;

    fetchObjection(id);
  }, [id]);

  useEffect(() => {
    if (!project) return;

    ref.current?.reset();
    ref.current?.init(project);
  }, [project]);

  if (!id) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <PlayerMetaProvider value={{ title }}>
        <Player
          ref={ref}
          width={project?.options.width}
          aspectRatio={project?.options.aspectRatio}
        />
      </PlayerMetaProvider>
    </Stack>
  );
});

const fetchObjection = async (id: string) => {
  try {
    const isCase = isNaN(parseInt(id));

    const response = !isCase
      ? await ApiClient.scene.get(parseInt(id))
      : await ApiClient.case.get(id);

    state.project = response.data.data;
    state.title = response.data.title;
  } catch (error) {
    enqueueSnackbar('Failed to fetch objection', { variant: 'error' });
  }
};

export default EmbedPage;
