/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alias, CaseFrame, Character_Side } from '@shared/types';
import {
  BackgroundDto,
  CaseProjectDto,
  OptionsDto,
  SceneProjectDto,
} from '@web/api/api';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import { usePlayerAudio } from '@web/components/player/hooks/usePlayerAudio';
import { useTimeline } from '@web/components/player/hooks/useTimeline';
import { assetStore } from '@web/store/assets/state';
import { isInvestigationGroup } from '@web/store/maker/types';
import { FramesTarget } from '@web/types/project';
import { generateDefaultBackground } from '@web/utils/project';
import { clearObjectProperties } from '@web/utils/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { runCaseActions } from '../case/hooks/useCaseActions';
import { usePlayerAutoSave } from '../case/hooks/usePlayerAutoSave';
import { usePlayerCase } from '../case/hooks/usePlayerCase';
import { usePlayerViewport } from '../case/hooks/usePlayerViewport';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import {
  PlayerActionsType,
  PlayerClickType,
  PlayerContextType,
  PlayerStateType,
  Step,
} from '../providers/PlayerProvider';
import { usePlayerReset } from '../providers/PlayerResetProvider';
import { usePlayerSaveLoad as usePlayerSaveLoadContext } from '../providers/PlayerSaveLoadProvider';
import { PlayerSaveType } from '../types/playerSave';
import { GameState } from '../types/state';
import { defaultPlayerDefaults } from '../utils/constants';
import { GameUtils } from '../utils/game-utils';
import { useCharactersFades } from './useCharactersFades';
import { useFrame } from './useFrame';
import { runFrameActions } from './useFrameActions';
import { usePlayerEffects } from './usePlayerEffects';
import { usePlayerLoading } from './usePlayerLoading';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoad } from './usePlayerSaveLoad';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';
import { usePlayerUi } from './usePlayerUi';
import { useProjectPlayer } from './useProjectPlayer';

const initialState: PlayerStateType = {
  galleryImageUrls: {} as Record<Character_Side, string>,
  galleryImageUrlsAj: {} as Record<Character_Side, string>,
};

export type PlayerProps = {
  id?: string;
  autoStart?: boolean;
  width?: number;
  aspectRatio?: string;
  hideVolume?: boolean;
  hideAutoplay?: boolean;
  controls?: React.ReactNode;
};

export const usePlayer = ({
  autoStart,
  width,
  aspectRatio,
}: PlayerProps): PlayerContextType => {
  const state = useRef(proxy<PlayerStateType>(initialState)).current;
  const playerDefaults = useRef({ ...defaultPlayerDefaults }).current;

  const aliases = useRef<Alias[]>([]).current;
  const timeoutAutoplay = useRef<NodeJS.Timeout>();
  const snapshot = useProxy(state);
  const playerMeta = usePlayerMeta();

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const playerViewportRef = useRef<HTMLDivElement>(null);

  const timeline = useTimeline();
  const audio = usePlayerAudio();
  const playerReset = usePlayerReset();

  const savedSession = useRef<any>();

  const {
    id: framePlayId,
    project,
    state: projectState,
    snapshot: projectSnapshot,
    actions: projectActions,
    frame: projectFrame,
    activeFrameIndex,
    group,
  } = useProjectPlayer();

  const playerCase = usePlayerCase(
    projectFrame,
    group,
    state,
    projectState,
    projectSnapshot,
    projectActions,
    playerContainerRef,
    playerViewportRef,
  );

  const {
    loading: playerCaseLoading,
    caseFrame,
    previousCaseFrame,
  } = playerCase.state;

  const currentFrame = caseFrame || projectFrame;
  const previousFrame = previousCaseFrame || projectSnapshot.previousFrame;

  const playerLoading = usePlayerLoading({
    project,
    framesTarget: projectSnapshot, // TODO: change this line to be projectSnapshot.framesTarget ?
    frameIndex: projectSnapshot.frameIndex,
    isEnded: projectSnapshot.isEnded,
  });

  const { onFrameEndAutoSave } = usePlayerAutoSave(
    project,
    group,
    projectState,
  );

  usePlayerSaveLoadRegister<PlayerSaveType>({
    name: 'player',
    onSave: () =>
      ({
        state: {
          ...snapshot,
          isAutoplay: false,
          startedPlaying: true,
          skipping: false,
          steps: undefined,
          stepIndex: undefined,
        },
        playerDefaults,
        aliases,
      }) satisfies PlayerSaveType,
    onLoad: (data) => {
      clearObjectProperties(state);
      clearObjectProperties(playerDefaults);

      Object.assign(state, data.state);
      Object.assign(playerDefaults, data.playerDefaults);

      aliases.splice(0, aliases.length, ...data.aliases);
    },
  });

  const playerSaveLoadContext = usePlayerSaveLoadContext();

  usePlayerResetRegister({
    name: 'player',
    onReset: () => {
      timeline.clearEvents();

      audio.reset();

      clearObjectProperties(state);
      clearObjectProperties(playerDefaults);

      Object.assign(state, structuredClone(initialState));
      Object.assign(playerDefaults, structuredClone(defaultPlayerDefaults));

      playerLoading.reset();
      playerUi.actions.reset();

      aliases.splice(0, aliases.length);
    },
  });

  const canAdvance = useCallback(
    () =>
      snapshot.startedPlaying &&
      playerLoading.initialized &&
      !projectState.isEnded &&
      !playerCase.state.userInput &&
      !playerCase.state.videoUrl &&
      !projectState.investigationMenu &&
      !playerLoading.preloadStuck &&
      !playerCaseLoading,
    [
      snapshot.startedPlaying,
      playerLoading.initialized,
      playerLoading.preloadStuck,
      projectState.isEnded,
      projectState.investigationMenu,
      playerCase.state.userInput,
      playerCase.state.videoUrl,
      playerCaseLoading,
    ],
  );

  const step = useMemo(
    () =>
      snapshot.steps
        ? snapshot.stepIndex || snapshot.stepIndex === 0
          ? snapshot.steps[snapshot.stepIndex]
          : undefined
        : undefined,
    [snapshot.stepIndex, snapshot.steps],
  );

  state.isPlaying =
    snapshot.startedPlaying && step !== undefined && step !== 'finished';

  const playerUi = usePlayerUi(
    playerContainerRef,
    aspectRatio,
    width,
    timeline.addEvent,
  );

  const defaultBackground = useMemo<BackgroundDto>(
    () => ({
      id: 0,
      url: generateDefaultBackground(
        playerUi.state.width,
        playerUi.state.aspectRatio,
      ),
      name: 'Default',
    }),
    [playerUi.state.aspectRatio, playerUi.state.width],
  );

  const frame = useFrame(
    currentFrame,
    previousFrame,
    aliases,
    project?.pairs,
    playerCase.variables,
  );

  const playerViewport = usePlayerViewport();
  const playerCharactersFades = useCharactersFades(!!snapshot.skipping);
  const playerEffects = usePlayerEffects(frame.frame, step);

  const setSteps = useCallback(() => {
    const steps: Step[] = ['start'];

    if (previousFrame?.fade?.type === 'fadeOutIn') {
      steps.push('fade_out');
    }

    if (frame.shouldShowSpeechBubble) {
      steps.push('speech_bubble');
    }

    if (!frame.offscreenFrame) {
      if (frame.transitionDuration && frame.transitionDuration > 0) {
        steps.push('transition');
      }

      steps.push('character');
    }

    if (previousFrame?.fade?.type === 'fadeOutIn') {
      steps.push('fade_in');
    }

    if (!frame.offscreenFrame) {
      if (frame.transitionDuration && frame.transitionDuration > 0) {
        steps.push('transition_wait');
      }

      if (frame.shouldRunDelayedActions) {
        steps.push('delayed_actions');
      }

      steps.push('dialogue');

      if (frame.shouldPlayEndPoseAnimation) {
        steps.push('pose_end_animation');
      }
    }

    steps.push('finished');

    state.steps = steps;
    state.stepIndex = 0;
  }, [
    frame.offscreenFrame,
    frame.shouldPlayEndPoseAnimation,
    frame.shouldRunDelayedActions,
    frame.shouldShowSpeechBubble,
    frame.transitionDuration,
    previousFrame?.fade?.type,
    state,
  ]);

  const playNextSound = useCallback(() => {
    if (project?.options.continueSoundUrl && canAdvance()) {
      audio.playSound(project.options.continueSoundUrl, 50, 100);
    }
  }, [audio, canAdvance, project?.options.continueSoundUrl]);

  const next = useCallback(
    (expectedFrameIndex?: number) => {
      if (state.isPlaying || !canAdvance()) {
        return;
      }

      // workaround: during jump frame both endNext and next are called, the endNext is from useEffect of preloadStuck
      // If the previous frame is empty/move to next then next would be called twice (endNext has this functionality)
      if (
        expectedFrameIndex !== undefined &&
        projectState.frameIndex === expectedFrameIndex + 1
      ) {
        return;
      }

      timeline.flushCancellableEvents();

      projectActions.nextFrame();
    },
    [
      state.isPlaying,
      canAdvance,
      timeline,
      projectState.frameIndex,
      projectActions,
    ],
  );

  const preloadThenNext = useCallback(
    async (state: GameState | undefined) => {
      if (!project || playerLoading.preloadStuck || state?.isEnded) {
        return;
      }

      if (state) {
        await playerLoading.preloadTarget(project, state, state.frameIndex + 1);
      }

      next(state?.frameIndex);
    },
    [next, playerLoading, project],
  );

  const preloadFramesThenNext = useCallback(
    async (frames: CaseFrame[]) => {
      if (!project || playerLoading.preloadStuck) {
        return;
      }

      if (frames.length > 0) {
        await playerLoading.preloadFrames(
          frames,
          project.pairs,
          project.options,
          0,
          frames.length,
        );
      }

      next();
    },
    [next, playerLoading, project],
  );

  const previous = useCallback(() => {
    if (state.isPlaying || !canAdvance()) {
      return;
    }
    timeline.flushCancellableEvents();

    projectActions.previousFrame();
  }, [state.isPlaying, canAdvance, timeline, projectActions]);

  // TODO: separate reset from saveLoad
  const reset = useCallback(async () => {
    playerLoading.reset();

    if (playerReset) {
      playerReset.reset();
    }

    savedSession.current = undefined;
  }, [playerLoading, playerReset]);

  const setPlayerDefaultsFromOptions = useCallback(
    (options: OptionsDto) => {
      playerDefaults.chatbox = options.chatbox ?? defaultPlayerDefaults.chatbox;
      playerDefaults.textSpeed =
        options.textSpeed ?? defaultPlayerDefaults.textSpeed;
      playerDefaults.defaultTextSpeed = playerDefaults.textSpeed;
      playerDefaults.textBlipFrequency =
        options.textBlipFrequency ?? defaultPlayerDefaults.textBlipFrequency;
      playerDefaults.autoplaySpeed =
        options.autoplaySpeed ?? defaultPlayerDefaults.autoplaySpeed;
    },
    [playerDefaults],
  );

  const showButtons = useCallback(() => {
    state.showNextButton =
      !frame.frame?.moveToNext &&
      !playerLoading.preloadStuck &&
      !state.isAutoplay &&
      frame.plainText.length > 0 &&
      canAdvance() &&
      !playerMeta?.isRecording;

    state.showPreviousButton =
      state.showNextButton &&
      playerCase.isCrossExaminationStatements &&
      activeFrameIndex > 0;
  }, [
    activeFrameIndex,
    canAdvance,
    frame.frame?.moveToNext,
    frame.plainText.length,
    playerCase.isCrossExaminationStatements,
    playerLoading.preloadStuck,
    playerMeta?.isRecording,
    state,
  ]);

  const endNext = useCallback(() => {
    showButtons();

    const offscreenFrame = frame.frame?.actions?.find((f) => f.actionId === 6);

    // why if frame is undefined after frame jump? frame.frame
    if (!playerLoading.preloadStuck && frame.frame) {
      if (
        frame.frame?.moveToNext ||
        frame.plainText?.length === 0 ||
        offscreenFrame
      ) {
        next();
      } else {
        onFrameEndAutoSave();

        if (state.isAutoplay || playerMeta?.isRecording) {
          clearTimeout(timeoutAutoplay.current);

          timeoutAutoplay.current = setTimeout(() => {
            next();
          }, playerDefaults.autoplaySpeed);
        }
      }
    }
  }, [
    showButtons,
    frame.frame,
    frame.plainText?.length,
    playerLoading.preloadStuck,
    next,
    onFrameEndAutoSave,
    state.isAutoplay,
    playerMeta?.isRecording,
    playerDefaults.autoplaySpeed,
  ]);

  const initialPlayerDefaults = useCallback(() => {
    playerDefaults.textSpeed = playerDefaults.defaultTextSpeed;

    delete playerDefaults.muteSpeechBlip;
    delete playerDefaults.centerText;
  }, [playerDefaults]);

  const onFramePlayEnd = useCallback(() => {
    state.skipping = false;

    const isEnded = projectActions.onFramePlayEnd(currentFrame);

    if (isEnded) {
      state.showNextButton = false;
      state.showPreviousButton = false;

      return;
    }

    if (project?.type === 'case') {
      playerCase.actions.onFramePlayEnd(
        frame.nameplate,
        frame.plainText,
        frame.frame?.characterId,
      );

      if (frame.frame?.caseAction) {
        const shouldSkip = runCaseActions(
          frame.frame.id,
          frame.frame.caseAction,
          project,
          preloadThenNext,
          projectActions,
          playerCase,
          state,
        );

        if (shouldSkip) {
          return;
        }
      }
    }

    endNext();
  }, [
    currentFrame,
    endNext,
    frame,
    playerCase,
    preloadThenNext,
    project,
    projectActions,
    state,
  ]);

  const init = useCallback(
    (
      project: SceneProjectDto | CaseProjectDto,
      startingFrameOrState?: number | GameState,
      savedSessionData?: any,
    ) => {
      if (snapshot.startedPlaying) {
        return;
      }

      const clonedProject: SceneProjectDto | CaseProjectDto = JSON.parse(
        JSON.stringify(project),
      );

      let framesTarget: FramesTarget | undefined = startingFrameOrState
        ? typeof startingFrameOrState === 'number'
          ? GameUtils.getFrameById(startingFrameOrState, clonedProject)
          : startingFrameOrState
        : undefined;

      let frameIndex = 0;

      if (framesTarget) {
        const targetFrames = GameUtils.getTargetFrames(
          framesTarget,
          clonedProject,
        );

        frameIndex =
          targetFrames && startingFrameOrState
            ? typeof startingFrameOrState === 'number'
              ? targetFrames.findIndex(
                  (frame) => frame.id === startingFrameOrState,
                )
              : startingFrameOrState?.frameIndex
            : 0;
      } else {
        const startingGroup =
          GameUtils.getStartingGroup(clonedProject) || clonedProject.groups[0];

        framesTarget = startingGroup
          ? {
              groupId: startingGroup.id,
              locationId: isInvestigationGroup(startingGroup)
                ? startingGroup.locations[0].id
                : undefined,
            }
          : undefined;
      }

      setPlayerDefaultsFromOptions(clonedProject.options);

      aliases.splice(0, aliases.length, ...clonedProject.aliases);

      projectActions.init(clonedProject, framesTarget, frameIndex);
      playerLoading.init(
        clonedProject,
        framesTarget,
        frameIndex,
        savedSessionData,
      );

      playerUi.actions.init();

      playerCase.actions.init(clonedProject);

      state.options = { ...clonedProject.options };

      if (savedSessionData) {
        savedSession.current = savedSessionData;
      } else {
        savedSession.current = undefined;
      }
    },
    [
      snapshot.startedPlaying,
      setPlayerDefaultsFromOptions,
      aliases,
      projectActions,
      playerLoading,
      playerCase.actions,
      playerUi.actions,
      state,
    ],
  );

  const playerSaveLoad = usePlayerSaveLoad(project);

  const start = useCallback(() => {
    if (
      state.isPlaying ||
      snapshot.startedPlaying ||
      projectState.isEnded ||
      !playerLoading.initialized
    ) {
      return;
    }

    state.startedPlaying = true;

    if (!savedSession.current) {
      next();
    } else {
      const savedSessionMusicId = savedSession.current?.player?.state?.musicId;
      const savedSessionMusic = savedSessionMusicId
        ? assetStore.music.cache[savedSessionMusicId]
        : undefined;

      playerSaveLoadContext?.load(savedSession.current);

      if (savedSessionMusic) {
        audio.playMusic(savedSessionMusic.url, savedSessionMusic.volume);

        state.musicId = savedSessionMusicId;
      }
    }
  }, [
    audio,
    next,
    playerLoading.initialized,
    playerSaveLoadContext,
    projectState.isEnded,
    snapshot.startedPlaying,
    state,
  ]);

  const onClick = useCallback(
    ({ x, y, direction, isRightClick }: PlayerClickType) => {
      if (
        !canAdvance() ||
        (state.isPlaying && !playerDefaults.enableSkipping) ||
        playerMeta?.isRecording
      )
        return;

      if (state.isPlaying) {
        state.skipping = true;

        timeline.flushAllEvents();

        audio.stopAllSounds();

        return;
      }

      if (
        x < 50 &&
        playerCase.isCrossExaminationStatements &&
        activeFrameIndex > 0
      ) {
        previous();
      } else {
        next();
      }

      playNextSound();
    },
    [
      canAdvance,
      playerDefaults.enableSkipping,
      playerMeta?.isRecording,
      playerCase.isCrossExaminationStatements,
      activeFrameIndex,
      playNextSound,
      state,
      timeline,
      audio,
      previous,
      next,
    ],
  );

  const playMusic = useCallback(
    (id: number | undefined, url: string, volume: number) => {
      audio.playMusic(url, volume);

      if (id) {
        state.musicId = id;
      }
    },
    [audio, state],
  );

  const stopMusic = useCallback(() => {
    audio.stopMusic();

    state.musicId = undefined;
  }, [audio, state]);

  const nextStep = useCallback(() => {
    state.stepIndex = (state.stepIndex || 0) + 1;
  }, [state]);

  const setCharacterStep = useCallback(
    (characterStep: PlayerStateType['characterStep']) => {
      state.characterStep = characterStep;
    },
    [state],
  );

  const update = useCallback(
    (newState: Partial<PlayerStateType>) => {
      Object.assign(state, newState);
    },
    [state],
  );

  const actions: PlayerActionsType = useMemo(
    () => ({
      init,
      onClick,
      start,
      next,
      preloadThenNext,
      preloadFramesThenNext,
      previous,
      update,
      reset,
      nextStep,
      setCharacterStep,
      playMusic,
      stopMusic,
      endNext,
    }),
    [
      init,
      onClick,
      start,
      next,
      preloadThenNext,
      preloadFramesThenNext,
      previous,
      update,
      reset,
      nextStep,
      setCharacterStep,
      playMusic,
      stopMusic,
      endNext,
    ],
  );

  useEffect(() => {
    if (!currentFrame || !snapshot.startedPlaying || projectSnapshot.isEnded) {
      return;
    }

    // clear the save session so the fades don't take the loaded session data (character fades)
    savedSession.current = undefined;

    // moved it here  outside of start step
    state.showNextButton = false;
    state.showPreviousButton = false;

    if (project?.type === 'case' && playerCase.actions.onFramePlayStart()) {
      state.showDialoguebox = false;

      return;
    }

    setSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [framePlayId]);

  // step handler for internal stuff
  useEffect(() => {
    if (step === 'start') {
      if (
        frame.frame &&
        FrameTextUtils.isStopMusicTagAtStart(frame.frame.text)
      ) {
        audio.stopMusic();
      }

      if (frame.shouldPlayPoseAnimation) {
        audio.stopAllSounds();
      }

      initialPlayerDefaults();

      // ignore caseFrame (id 0)
      // retain previous background left (for investigations)
      state.backgroundLeft =
        frame.frame && frame.frame.id > 0
          ? (frame.frame.transition?.left ?? state.backgroundLeft)
          : state.backgroundLeft;

      if (frame.shouldRunStartActions && frame.frame?.actions) {
        runFrameActions(
          frame.frame.actions,
          state,
          step,
          actions,
          update,
          audio,
          timeline,
          playerDefaults,
          true,
        );
      }

      if (frame.frame) {
        // TODO: also add the player interaction skipping
        playerCharactersFades.processFades(frame.frame, !!state.skipping);
      }

      nextStep();
    }

    if (
      step === 'delayed_actions' &&
      frame.shouldRunDelayedActions &&
      frame.frame?.actions
    ) {
      runFrameActions(
        frame.frame.actions,
        state,
        step,
        actions,
        update,
        audio,
        timeline,
        playerDefaults,
        false,
      );
    }

    if (step === 'finished') {
      onFramePlayEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // if preload is unstuck
  useEffect(() => {
    if (projectSnapshot.isEnded) return;

    if (
      state.startedPlaying &&
      !state.isPlaying &&
      playerLoading.initialized &&
      !playerLoading.preloadStuck
    ) {
      // instead of automatically going next which causes a lot of issues just show the buttons
      setTimeout(showButtons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerLoading.preloadStuck]);

  useEffect(() => {
    if (!projectSnapshot.isEnded) {
      return;
    }

    if (playerMeta?.isRecording) {
      console.log('DONE'); // for recording
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSnapshot.isEnded]);

  useEffect(() => {
    if ((autoStart || state.isAutoplay) && playerLoading.initialized) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerLoading.initialized]);

  // TODO: move to usePlayerCase?
  useEffect(() => {
    if (
      project &&
      (projectSnapshot.investigationMenu ||
        projectSnapshot.resumeConversations ||
        projectSnapshot.resumeExamine)
    ) {
      playerCase.actions.checkLocationCompletion(
        undefined,
        project,
        preloadThenNext,
      );
    }
  }, [
    playerCase.actions,
    preloadThenNext,
    project,
    projectSnapshot.investigationMenu,
    projectSnapshot.resumeConversations,
    projectSnapshot.resumeExamine,
  ]);

  // cleanup on dismount
  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: snapshot,
    projectSnapshot,
    projectActions,
    step,
    playerDefaults,
    aliases,
    frame,
    timeline,
    audio,
    actions,
    playerUi,
    playerLoading,
    playerViewport,
    playerCharactersFades,
    playerEffects,
    playerContainerRef,
    playerViewportRef,
    playerCase,
    playerSaveLoad,
    defaultBackground,
    isEnded: projectSnapshot.isEnded,
    isCase: project?.type === 'case',
    savedSession: savedSession.current,
  };
};
