import { TextField } from '@mui/material';
import { CaseAction_PlayVideo } from '@shared/types';
import { useCaseActionInput } from '../CaseActionInputs';

export const PlayVideoInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_PlayVideo>();

  return (
    <TextField
      label="Video URL"
      value={caseAction.videoUrl || ''}
      onChange={(event) => updateCaseAction({ videoUrl: event.target.value })}
      size="small"
      fullWidth
    />
  );
};
