import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CaseAction_EvaluateExpression } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { useState } from 'react';
import { ExpressionInput, useCaseActionInput } from '../CaseActionInputs';

export const EvaluateExpressionInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_EvaluateExpression>();

  return (
    <Stack spacing={2}>
      <ExpressionInput
        value={caseAction.expression || ''}
        onChange={(value) => updateCaseAction({ expression: value })}
      />

      <Stack direction="row" spacing={1}>
        <NumberTextField
          label="True Frame ID"
          value={caseAction.trueFrameId || ''}
          onChange={(event) =>
            updateCaseAction({ trueFrameId: parseInt(event.target.value) })
          }
          size="small"
          variant="standard"
          fullWidth
        />

        <NumberTextField
          label="False Frame ID"
          value={caseAction.falseFrameId || ''}
          onChange={(event) =>
            updateCaseAction({ falseFrameId: parseInt(event.target.value) })
          }
          size="small"
          variant="standard"
          fullWidth
        />
      </Stack>

      <ExpressionTips />
    </Stack>
  );
};

export const ExpressionTips = () => {
  const [showTips, setShowTips] = useState(false);

  return (
    <>
      <Box>
        <Button
          variant="text"
          color="info"
          onClick={() => setShowTips((prev) => !prev)}
          startIcon={showTips ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          size="small"
        >
          {showTips ? 'Hide Tips' : 'Show Tips'}
        </Button>
      </Box>

      <Collapse in={showTips}>
        <Typography variant="caption" color="textSecondary">
          You can use the following:
        </Typography>

        <Box component="ul" m={0}>
          <Typography component="li" variant="caption" color="textSecondary">
            Variable names
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            Math operators <Code>+ - * / %</Code>
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            Comparisons <Code>{'> < >= <= == !='}</Code>
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            Logical operators <Code>&& || !</Code>
          </Typography>
        </Box>

        <Typography variant="caption" color="textSecondary">
          Examples:
        </Typography>

        <Box component="ul" m={0}>
          <Typography component="li" variant="caption" color="textSecondary">
            oranges == 5
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            {'oranges > 0 && apples > 0'}
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            {'oranges + 5 > apples || apples != 0'}
          </Typography>

          <Typography component="li" variant="caption" color="textSecondary">
            my_string_variable == 'tomato'
          </Typography>
        </Box>
      </Collapse>
    </>
  );
};

const Code = ({ children }: { children: string }) => {
  const theme = useTheme();

  return (
    <Box component="code" bgcolor={theme.palette.background.default} px={0.5}>
      {children}
    </Box>
  );
};
