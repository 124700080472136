import { Button, styled } from '@mui/material';

export const XSmallButton = styled(Button)(({ theme }) => ({
  padding: '2px 6px',
  fontSize: '0.75rem',
  minWidth: 'auto',
}));

export const xSmallButtonStyle: React.CSSProperties = {
  padding: '2px 6px',
  fontSize: '0.75rem',
  minWidth: 'auto',
};
