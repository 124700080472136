import { Add, Remove, Vibration } from '@mui/icons-material';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useState } from 'react';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';

export const ShakeTag = ({ width }: { width: number }) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const [value, setValue] = useState<number>(600);
  const { insertTag } = useFrameEditor();

  const handleAddValue = (val: number) => {
    setValue((prev) => Math.max(Math.min(prev + val, 5000), 50));
  };

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <Vibration />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 318 }}
        placement="top"
      >
        <Stack p={1}>
          <Typography>Shake</Typography>
          <Typography color="text.secondary" variant="body2">
            Perform shake at a certain point
          </Typography>
        </Stack>

        <Divider />

        <Grid container p={1} spacing={1}>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('ss')}
              fullWidth
            >
              Small
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('sm')}
              fullWidth
            >
              Medium
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => insertTag('sl')}
              fullWidth
            >
              Large
            </Button>
          </Grid>
        </Grid>

        <Divider />

        <Stack p={1} spacing={1}>
          <Stack direction="row">
            <Typography variant="h4">{value}</Typography>

            <Vibration />
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton size="small" onClick={() => handleAddValue(-50)}>
              <Remove />
            </IconButton>

            <Slider
              value={value}
              onChange={(e, v) => setValue(v as number)}
              step={50}
              min={50}
              max={5000}
            />

            <IconButton size="small" onClick={() => handleAddValue(50)}>
              <Add />
            </IconButton>
          </Stack>

          <Button
            color="accent"
            variant="contained"
            size="small"
            onClick={() => {
              insertTag(`sm${value}`);
            }}
            fullWidth
          >
            Insert Tag
          </Button>
        </Stack>
      </Popper>
    </>
  );
};
