import { Stack } from '@mui/material';
import { CaseAction_IncrementVariable } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { useCaseActionInput, VariablePicker } from '../CaseActionInputs';

export const IncrementVariableInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_IncrementVariable>();

  return (
    <Stack direction="row" spacing={1}>
      <VariablePicker
        value={caseAction.name || null}
        onChange={(value) => updateCaseAction({ name: value || undefined })}
      />

      <NumberTextField
        label="Increment Amount"
        value={caseAction.value || ''}
        onChange={(event) =>
          updateCaseAction({ value: parseInt(event.target.value) })
        }
        size="small"
        variant="standard"
        fullWidth
      />
    </Stack>
  );
};
