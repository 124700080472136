import { Add, Delete, FormatQuote } from '@mui/icons-material';
import {
  Button,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Contradiction, Evidence } from '@shared/types/evidence';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import React, { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { CourtRecordEvidencePicker } from '../case/CourtRecordEvidencePicker';
import { FrameTextUtils } from '../utils/frameText';

export const ContradictionsDialog = React.memo(() => {
  const snapshot = useSnapshot(makerStore);

  const handleAddContradiction = useCallback(() => {
    if (!makerStore.crossExaminationGroup) return;

    const focusedFrameId = makerStore.focusedFrame?.id;

    if (!focusedFrameId) return;

    if (!makerStore.crossExaminationGroup.contradictions[focusedFrameId]) {
      makerStore.crossExaminationGroup.contradictions[focusedFrameId] = [];
    }

    makerStore.crossExaminationGroup.contradictions[focusedFrameId].push({
      evidenceId: '',
      evidenceType: 'evidence',
      frameId: undefined,
    });
  }, []);

  const handleClose = useCallback(() => {
    makerStore.dialogs.contradictions = false;
  }, []);

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!snapshot.crossExaminationGroup || !snapshot.focusedFrame) return null;

  const contradictions =
    snapshot.crossExaminationGroup.contradictions[snapshot.focusedFrame?.id] ||
    [];

  return (
    <DraggableDialog
      titleComponent={<ContradictionsDialogTitle />}
      open={!!snapshot.dialogs.contradictions}
      onClose={handleClose}
      maxWidth="xs"
      fullscreen={fullscreen}
      fullWidth
    >
      <DialogContent>
        <Stack spacing={2}>
          <QuoteFrame />

          <Button
            variant="contained"
            color="info"
            startIcon={<Add />}
            onClick={handleAddContradiction}
          >
            Add Contradiction
          </Button>

          <Stack minHeight="65vh" spacing={1} overflow="auto">
            {contradictions.map((contradiction, index) => (
              <ContradictionRow
                key={index}
                contradiction={contradiction}
                index={index}
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </DraggableDialog>
  );
});

type ContradictionRowProps = {
  contradiction: Contradiction;
  index: number;
};

const ContradictionRow = memo(
  ({ contradiction, index }: ContradictionRowProps) => {
    const { focusedFrame } = useMakerStore();

    const handleRemoveContradiction = useCallback(
      (index: number) =>
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();

          if (!focusedFrame) return;

          makerStore.crossExaminationGroup?.contradictions[
            focusedFrame.id
          ].splice(index, 1);
        },
      [focusedFrame],
    );

    const handleEvidenceChange = useCallback(
      (value: Evidence | null) => {
        if (!focusedFrame || !makerStore.crossExaminationGroup) return;

        makerStore.crossExaminationGroup.contradictions[focusedFrame.id][
          index
        ].evidenceId = value?.evidenceId || '';
      },
      [focusedFrame, index],
    );

    const handleFrameIdChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!focusedFrame || !makerStore.crossExaminationGroup) return;

        makerStore.crossExaminationGroup.contradictions[focusedFrame.id][
          index
        ].frameId = parseInt(e.target.value);
      },
      [focusedFrame, index],
    );

    return (
      <Stack spacing={1} direction="row" alignItems="end">
        <CourtRecordEvidencePicker
          value={contradiction.evidenceId}
          onChange={handleEvidenceChange}
        />

        <NumberTextField
          label="Target Frame ID"
          value={contradiction.frameId || ''}
          variant="standard"
          onChange={handleFrameIdChange}
          size="small"
          fullWidth
        />

        <IconButton size="small" onClick={handleRemoveContradiction(index)}>
          <Delete />
        </IconButton>
      </Stack>
    );
  },
);

const QuoteFrame = memo(() => {
  const { focusedFrame } = useMakerStore();

  const text = focusedFrame
    ? FrameTextUtils.getPlainText(focusedFrame.text)
    : '';

  return (
    <Stack direction="row">
      <FormatQuote
        sx={{
          fontSize: '1rem',
          transform: 'rotate(180deg)',
          alignSelf: 'start',
        }}
      />

      <Typography
        variant="body1"
        sx={{
          mx: 1,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 3,
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </Typography>

      <FormatQuote sx={{ fontSize: '1rem', alignSelf: 'end' }} />
    </Stack>
  );
});

const ContradictionsDialogTitle = memo(() => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    makerStore.dialogs.contradictions = false;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6">Contradictions</Typography>

      <Stack direction="row">
        <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
      </Stack>
    </Stack>
  );
});
