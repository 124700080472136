import {
  CloudDone,
  CloudOff,
  ContactEmergency,
  Group,
  InsertDriveFile,
  MenuOutlined,
  NoteAdd,
  QuestionMark,
  RemoveRedEye,
  Reply,
  Save,
  Settings,
  UploadFile,
  VideoSettings,
  Videocam,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { MenuList } from '@web/components/common/ui/MenuList';
import { ConvertProjectDialog } from '@web/components/maker/dialogs/ConvertProjectDialog';
import { LoadDialog } from '@web/components/maker/dialogs/LoadDialog';
import { NewProjectDialog } from '@web/components/maker/dialogs/NewProjectDialog';
import { SaveDialog } from '@web/components/maker/dialogs/SaveDialog';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { rootStore } from '@web/store/root/state';
import { useSettingsStore } from '@web/store/settings';
import { useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { BreakpointRender } from '../common/Breakpoint';
import { ProjectOptionsDialog } from './dialogs/ProjectOptionsDialog';
import { useProjectConversion } from './hooks/useProjectConvertion';

export const MakerAppBar = () => {
  const handlePreview = () => {
    makerStore.previewFrameId = undefined;
    makerStore.dialogs.preview = true;
  };

  const handleShowAssets = () => {
    rootStore.dialogs.assets = true;
  };

  return (
    <AppBar color="info" position="static" elevation={0}>
      <Toolbar disableGutters>
        <ProjectMenuButton />

        <Box sx={{ flexGrow: 1 }} />

        <BreakpointRender breakpoint={500}>
          <AppBarButton
            startIcon={<InsertDriveFile />}
            onClick={handleShowAssets}
          >
            Assets
          </AppBarButton>
          <AppBarButton onClick={handleShowAssets}>
            <InsertDriveFile />
          </AppBarButton>
        </BreakpointRender>

        <BreakpointRender breakpoint={500}>
          <AppBarButton startIcon={<RemoveRedEye />} onClick={handlePreview}>
            Preview
          </AppBarButton>
          <AppBarButton onClick={handlePreview}>
            <RemoveRedEye />
          </AppBarButton>
        </BreakpointRender>

        <SubmitButton />
      </Toolbar>
    </AppBar>
  );
};

type MenuItem =
  | {
      icon: JSX.Element;
      text: string;
      shortcut?: string;
      disabled?: boolean;
      onClick: () => void;
    }
  | { divider: true };

const ProjectMenuButton = () => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const { canConvert } = useProjectConversion();
  const { enableBackups } = useSettingsStore();
  const { loggedIn } = useAuth();

  const handleOpenDialog = useCallback(
    (key: keyof typeof makerStore.dialogs) => {
      makerStore.dialogs[key] = true;
      close();
    },
    [close],
  );

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        icon: <ContactEmergency />,
        text: 'Alias Names',
        shortcut: 'I',
        onClick: () => handleOpenDialog('aliases'),
      },
      {
        icon: <Group />,
        text: 'Pairs',
        shortcut: 'R',
        onClick: () => handleOpenDialog('projectPairs'),
      },
      {
        icon: <Settings />,
        text: 'Options',
        shortcut: 'O',
        onClick: () => handleOpenDialog('projectOptions'),
      },
      { divider: true },
      {
        icon: <NoteAdd />,
        text: 'New',
        shortcut: 'CTRL + ALT + N',
        onClick: () => handleOpenDialog('newProject'),
      },
      {
        icon: <Save />,
        text: 'Save',
        shortcut: 'CTRL + S',
        onClick: () => handleOpenDialog('saveProject'),
      },
      {
        icon: <UploadFile />,
        text: 'Load',
        shortcut: 'CTRL + L',
        onClick: () => handleOpenDialog('loadProject'),
      },
      ...(canConvert
        ? [
            {
              icon:
                makerStore.project.type === 'scene' ? (
                  <VideoSettings />
                ) : (
                  <Videocam />
                ),
              text:
                makerStore.project.type === 'scene'
                  ? 'Convert to Case'
                  : 'Convert to Scene',
              shortcut: 'CTRL + ALT + A',
              onClick: () => handleOpenDialog('convertProject'),
            },
          ]
        : []),
      { divider: true },
      {
        icon: enableBackups && loggedIn ? <CloudDone /> : <CloudOff />,
        text: 'Backups',
        onClick: () => handleOpenDialog('projectBackups'),
        disabled: !loggedIn,
      },
      {
        icon: <QuestionMark />,
        text: 'Help / Information',
        onClick: () => handleOpenDialog('help'),
      },
    ],
    [canConvert, enableBackups, handleOpenDialog, loggedIn],
  );

  return (
    <>
      <BreakpointRender breakpoint={500}>
        <AppBarButton startIcon={<MenuOutlined />} onClick={toggle}>
          Project
        </AppBarButton>
        <AppBarButton onClick={toggle}>
          <MenuOutlined />
        </AppBarButton>
      </BreakpointRender>

      <MenuList
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        placement="bottom-start"
        paperProps={{ sx: { width: 320 } }}
      >
        {menuItems.map((item, index) =>
          'divider' in item ? (
            <Divider key={index} sx={{ my: '0 !important' }} />
          ) : (
            <MenuItem
              key={index}
              onClick={item.onClick}
              sx={{ py: 2 }}
              disabled={item.disabled}
            >
              <ListItemIcon sx={{ marginRight: 2 }}>{item.icon}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>

              {!isMobile && item.shortcut && (
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  fontWeight={400}
                >
                  {item.shortcut}
                </Typography>
              )}
            </MenuItem>
          ),
        )}
      </MenuList>

      <NewProjectDialog />
      <ConvertProjectDialog />
      <SaveDialog />
      <LoadDialog />
      <ProjectOptionsDialog />
    </>
  );
};

const SubmitButton = () => {
  const { editId } = useMakerStore();

  const handleClickSubmit = () => {
    makerStore.dialogs.submit = true;
  };

  return (
    <AppBarButton startIcon={<Reply />} onClick={handleClickSubmit}>
      {editId ? 'Edit' : 'Submit'}
    </AppBarButton>
  );
};
