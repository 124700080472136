import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { CaseAction_InvestigationToggleVisibility } from '@shared/types';
import {
  InvestigationElementsPicker,
  InvestigationElementTypePicker,
  useCaseActionInput,
} from '../CaseActionInputs';

export const InvestigationToggleVisibilityInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_InvestigationToggleVisibility>();

  return (
    <Stack spacing={1}>
      {caseAction.items.map((item, i) => (
        <Stack key={i} spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box width="100%" maxWidth={280}>
              <InvestigationElementTypePicker
                value={item.type}
                onChange={(value) =>
                  updateCaseAction({
                    items: caseAction.items.map((currentItem, index) =>
                      index === i
                        ? { ...currentItem, type: value, hide: [], show: [] }
                        : currentItem,
                    ),
                  })
                }
              />
            </Box>

            <IconButton
              size="small"
              onClick={() =>
                updateCaseAction({
                  items: caseAction.items.filter((_, index) => i !== index),
                })
              }
            >
              <Delete />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={1}>
            <InvestigationElementsPicker
              label="Hide"
              type={item.type}
              values={item.hide}
              onChange={(values) =>
                updateCaseAction({
                  items: caseAction.items.map((currentItem, index) =>
                    index === i
                      ? { ...currentItem, hide: values }
                      : currentItem,
                  ) as CaseAction_InvestigationToggleVisibility['items'],
                })
              }
            />

            <InvestigationElementsPicker
              label="Show"
              type={item.type}
              values={item.show}
              onChange={(values) =>
                updateCaseAction({
                  items: caseAction.items.map((currentItem, index) =>
                    index === i
                      ? { ...currentItem, show: values }
                      : currentItem,
                  ) as CaseAction_InvestigationToggleVisibility['items'],
                })
              }
            />
          </Stack>
        </Stack>
      ))}

      <Box pt={1}>
        <Button
          variant="contained"
          color="violet"
          startIcon={<Add />}
          size="small"
          onClick={() =>
            updateCaseAction({
              items: [
                ...caseAction.items,
                { type: 'location', show: [], hide: [] },
              ],
            })
          }
          disabled={caseAction.items.length >= 3}
        >
          Add
        </Button>
      </Box>
    </Stack>
  );
};
