import { Autocomplete } from '@mui/material';
import { Evidence } from '@shared/types/evidence';
import { SimpleMultiSelect } from '@web/components/common/form/SimpleMultiSelect';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { useMakerStore } from '@web/store/maker/state';
import { useMemo } from 'react';

export const CourtRecordEvidencePicker = ({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (value: Evidence | null) => void;
}) => {
  const {
    caseProject: {
      courtRecord: { evidence, profiles },
    },
  } = useMakerStore();

  const options = useMemo(
    () => [
      ...evidence.map((e) => ({ id: e.id, name: e.name, type: 'evidence' })),
      ...profiles.map((p) => ({ id: p.id, name: p.name, type: 'profile' })),
    ],
    [evidence, profiles],
  );

  const selectedOption = useMemo(
    () => options.find((f) => f.id === value),
    [options, value],
  );

  const handleChange = (value: typeof selectedOption | null) => {
    onChange(
      value
        ? {
            evidenceId: value.id,
            evidenceType: value.type as 'evidence' | 'profile',
          }
        : null,
    );
  };

  return (
    <Autocomplete
      value={selectedOption || null}
      options={options}
      onChange={(_, v) => handleChange(v)}
      size="small"
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label="Evidence"
          variant="standard"
        />
      )}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      groupBy={(option) =>
        option.type === 'evidence' ? 'Evidence' : 'Profiles'
      }
      //   ListboxProps={{ sx: { pt: 0 } }}
      disableClearable={selectedOption !== null}
      blurOnSelect
      fullWidth
    />
  );
};

export const CourtRecordEvidencesPicker = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: Evidence[];
  onChange: (value: Evidence[]) => void;
}) => {
  const {
    caseProject: {
      courtRecord: { evidence, profiles },
    },
  } = useMakerStore();

  const options = useMemo(
    () => [
      ...evidence.map((e) => ({ id: e.id, name: e.name, type: 'evidence' })),
      ...profiles.map((p) => ({ id: p.id, name: p.name, type: 'profile' })),
    ],
    [evidence, profiles],
  );

  const selectedOptions = options.filter((o) =>
    value.some((s) => s.evidenceId === o.id),
  );

  const handleChange = (value: typeof selectedOptions) => {
    onChange(
      value.map((v) => ({
        evidenceId: v.id,
        evidenceType: v.type as 'evidence' | 'profile',
      })),
    );
  };

  return (
    <SimpleMultiSelect
      label={label}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      size="small"
      variant="standard"
      groupBy={(option) =>
        option.type === 'evidence' ? 'Evidence' : 'Profiles'
      }
      disableRenderTags
      fullWidth
    />
  );
};
