import { TextField, TextFieldProps } from '@mui/material';
import { useCallback, useRef } from 'react';

export const ControlledTextField = ({
  value,
  onChange,
  ...props
}: TextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;

      onChange?.(e);

      // Restore cursor position after state update
      requestAnimationFrame(() => {
        inputRef.current?.setSelectionRange(selectionStart, selectionEnd);
      });
    },
    [onChange],
  );

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      inputRef={inputRef}
    />
  );
};
