import { useState } from 'react';

export const useRender = () => {
  const [, setRenderKey] = useState(0);

  const render = () => {
    setRenderKey((prev) => prev + 1);
  };

  return { render };
};
