import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  PaperProps,
  Popper,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { SettingsStoreType, useSettingsStore } from '@web/store/settings';
import { settingsActions } from '@web/store/settings/actions';
import { WithIdAndName } from '@web/types';
import React, { useCallback, useRef } from 'react';
import { RenderAutocompleteInput } from '../ui/RenderAutocompleteInput';
import { RenderAutocompleteOptionWithFavorite } from '../ui/RenderAutocompleteOptionWithFavorite';

type AutocompleteWithFavoritesProps<
  T,
  DisableClearable extends boolean | undefined = boolean,
  Multiple extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  label: string;
  type: keyof SettingsStoreType['favorite'];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    optionState: AutocompleteRenderOptionState,
    favoriteList: (string | number)[],
    handleFavoriteToggle: (id: string | number | null) => void,
  ) => JSX.Element;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'renderOption' | 'PaperComponent'
> & {
    renderInput?: AutocompleteProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >['renderInput'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    PaperComponent?: PaperProps & any;
  };

export const StyledAutocompletePopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export const AutocompleteWithFavorites = <
  T extends WithIdAndName<string | number>,
>({
  label,
  type,
  renderOption: customRenderOption,
  ...props
}: AutocompleteWithFavoritesProps<T>) => {
  const listboxRef = useRef<HTMLDivElement>(null);

  const {
    favorite: { [type]: favoriteList },
  } = useSettingsStore();

  const handleFavoriteToggle = useCallback(
    (id: string | number | null) => {
      if (!id) return;

      const scrollPosition = listboxRef.current?.scrollTop;
      const isFavorite = favoriteList.includes(id);

      if (isFavorite) {
        settingsActions.removeFavorite(id, type);
      } else {
        settingsActions.addFavorite(id, type);
      }

      if (scrollPosition !== undefined) {
        requestAnimationFrame(() => {
          if (listboxRef.current) {
            listboxRef.current.scrollTop = scrollPosition;
          }
        });
      }
    },
    [favoriteList, type],
  );

  const defaultRenderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: T,
      optionState: AutocompleteRenderOptionState,
    ) => {
      return (
        <RenderAutocompleteOptionWithFavorite
          key={option.id}
          props={props}
          optionId={option.id}
          optionName={option.name}
          optionState={optionState}
          favoriteList={favoriteList}
          handleFavoriteToggle={handleFavoriteToggle}
        />
      );
    },
    [favoriteList, handleFavoriteToggle],
  );

  return (
    <Autocomplete
      ListboxProps={{ ref: listboxRef }}
      renderOption={
        customRenderOption
          ? (props, option, state) =>
              customRenderOption(
                props,
                option,
                state,
                favoriteList,
                handleFavoriteToggle,
              )
          : defaultRenderOption
      }
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label={label}
          variant="outlined"
        />
      )}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id || 'null'}
      blurOnSelect
      {...props}
    />
  );
};
