import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';
import { CSSProperties, useMemo } from 'react';
import { NextButtonIcon, NextButtonImage } from '../ui/NextButton';
import { NextButtonIcons } from './NextIcons';

type NextButtonProps = {
  id: string;
  url?: string | null;
  icon: string;
  iconSize: number;
  iconColor: string;
  iconAnimDuration: number;
  top: number;
  left: number;
  onClick?: () => void;
  show?: boolean;
  size?: number;
  preview?: boolean;
  iconStyle?: CSSProperties;
  isPreviousButton?: boolean;
};

// Maybe move outside of player folder?
// BaseNextButton?
export const NextButton = ({
  id,
  url,
  icon,
  iconSize,
  iconColor,
  iconAnimDuration,
  top,
  left,
  onClick,
  show = true,
  size = 1,
  preview,
  iconStyle,
  isPreviousButton,
}: NextButtonProps) => {
  const iconType = useMemo(() => getNextButtonType(icon), [icon]);
  const resolvedIcon = iconType === 'none' ? 'playarrow' : icon; // if iconType is none, use playarrow icon for clickability (invisible icon)

  return (
    <>
      {url && (
        <NextButtonImage
          display={show ? 'flex' : 'none'}
          src={url}
          style={isPreviousButton ? { transform: 'scaleX(-1)' } : {}}
          alt="next"
        />
      )}

      <NextButtonIcon
        style={{
          display: show ? 'flex' : 'none',
          top: `${top}%`,
          ...(preview && {
            outline: '1px solid #8080FF',
          }),
          ...(!isPreviousButton
            ? { left: `${left}%` }
            : { right: `${left}%`, transform: 'scaleX(-1)' }),
        }}
        onClick={onClick}
      >
        {iconType === 'icon' || iconType === 'none' ? (
          NextButtonIcons[resolvedIcon]({
            sx: {
              animation: `${continueButtonAnimation} ease-in-out infinite both`,
            },
            style: {
              fontSize: `${iconSize * size}em`,
              color: `#${iconColor}`,
              animationDuration: `${iconAnimDuration}s`,
              opacity: iconType === 'none' ? 0 : 1,
              ...iconStyle,
            },
          })
        ) : (
          <Box
            component="span"
            sx={{
              animation: `${continueButtonAnimation} ease-in-out infinite both`,
            }}
            style={{
              fontFamily: `font_${id}`,
              fontSize: `${iconSize * size}em`,
              color: `#${iconColor}`,
              animationDuration: `${iconAnimDuration}s`,
            }}
          >
            {resolvedIcon}
          </Box>
        )}
      </NextButtonIcon>
    </>
  );
};

export const getNextButtonType = (icon: string) => {
  if (icon === 'none') {
    return 'none';
  }

  if (NextButtonIcons[icon]) {
    return 'icon';
  }

  return 'text';
};

export const continueButtonAnimation = keyframes`
0% {
  transform: translate(0, 0);
}

20% {
  transform: translate(1.25%, 0);
}

30% {
  transform: translate(1.875%, 0);
}

40% {
  transform: translate(2.8125%, 0);
}

60% {
  transform: translate(1.875%, 0);
}

80% {
  transform: translate(0.9375%, 0);
}

100% {
  transform: translate(0, 0);
}
`;
