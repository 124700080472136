import { ContentPaste } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { CaseAction_FrameJump } from '@shared/types';
import { AdornmentButton } from '../../ui/AdornmentButton';
import { useCaseActionInput } from '../CaseActionInputs';

export const FrameJumpInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_FrameJump>();

  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      const frameId = parseInt(text);

      if (isNaN(frameId)) {
        return;
      }

      updateCaseAction({ frameId });
    });
  };

  return (
    <TextField
      placeholder="Frame ID"
      value={caseAction.frameId || ''}
      onChange={(event) =>
        updateCaseAction({ frameId: parseInt(event.target.value) })
      }
      slotProps={{
        input: {
          endAdornment: (
            <AdornmentButton onClick={handlePaste} icon={<ContentPaste />} />
          ),
        },
      }}
      size="small"
      fullWidth
    />
  );
};
