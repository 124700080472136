import { Box, Card, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { CaseFrame, Pair } from '@shared/types';
import {
  makerStore,
  updateFrame as makerUpdateFrame,
} from '@web/store/maker/state';
import { useWindowSize } from 'usehooks-ts';
import { useSnapshot } from 'valtio';
import { Thumbnail } from '../../thumbnail/Thumbnail';
import { FrameEditorProvider } from '../providers/FrameEditorProvider';
import { EditorUtils } from '../utils/editor';
import { FrameEditorAppBar } from './FrameEditorAppBar';
import { FrameTextEditor } from './FrameTextEditor';
import { FrameTextEditorToolbar } from './FrameTextEditorToolbar';

type FrameEditorProps = {
  id: number;
  index: number;
  focused: boolean;
};

type FrameEditorContext = {
  editorRef: React.RefObject<HTMLTextAreaElement>;
  thumbnailRef: React.RefObject<HTMLImageElement>;
  focused?: boolean;
};

const refsContext = createContext<FrameEditorContext>({
  editorRef: { current: null },
  thumbnailRef: { current: null },
});

const RefsContextProvider = refsContext.Provider;
const useRefsContext = () => useContext(refsContext);

export const FrameEditor = React.memo(
  ({ id, index, focused }: FrameEditorProps) => {
    const thumbnailRef = useRef<HTMLImageElement>(null);
    const editorRef = useRef<HTMLTextAreaElement>(null);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down(600));

    const updateFrame = useCallback(
      (frame: Partial<CaseFrame>) => {
        makerUpdateFrame(frame, index);
      },
      [index],
    );

    const surroundTextWithColor = useCallback(
      (tag: string) => {
        updateFrame({
          text: EditorUtils.surroundTextWithColor(editorRef.current!, tag),
        });
      },
      [updateFrame],
    );

    const insertTag = useCallback(
      (tag: string) => {
        const { text, cursorPos } = EditorUtils.insertTag(
          editorRef.current!,
          tag,
        );

        updateFrame({ text });

        setTimeout(() => {
          editorRef.current!.selectionStart = cursorPos;
          editorRef.current!.selectionEnd = cursorPos;
        }, 0);
      },
      [updateFrame],
    );

    const handleClickThumbnail = useCallback(() => {
      makerStore.sceneEditorFrameIndex = index;
      makerStore.dialogs.sceneEditor = true;
    }, [index]);

    return (
      <Card
        sx={(theme) => ({
          borderColor: theme.palette.secondary.main,
          overflow: 'visible',
        })}
      >
        <FrameEditorProvider
          value={{
            editor: editorRef,
            frameId: id,
            frameIndex: index,
            updateFrame,
            surroundTextWithColor,
            insertTag,
          }}
        >
          <FrameEditorAppBar />

          <Grid container>
            <Grid
              item
              xs={4}
              sm={3}
              md={1}
              sx={{ minWidth: { xs: 100, sm: 180 } }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FrameEditorThumbnail
                  ref={thumbnailRef}
                  index={index}
                  onClick={handleClickThumbnail}
                />
              </Box>
            </Grid>

            <Grid item xs overflow="hidden">
              <Stack flexGrow={1} height="100%">
                {focused && !sm && <FrameTextEditorToolbar />}

                <RefsContextProvider
                  value={{ focused, editorRef, thumbnailRef }}
                >
                  <ResizableFrameTextEditor />
                </RefsContextProvider>
              </Stack>
            </Grid>
          </Grid>

          {focused && sm && <FrameTextEditorToolbar />}
        </FrameEditorProvider>
      </Card>
    );
  },
);

const FrameEditorThumbnail = memo(
  forwardRef(
    ({ index, onClick }: { index: number; onClick: () => void }, ref) => {
      const {
        project: { pairs },
        frames,
      } = useSnapshot(makerStore);
      const frame = frames[index];
      const pair = useMemo<Pair | undefined>(() => {
        if (!frame.pair) return undefined;

        return pairs.find((pair) => pair.id === frame.pair?.id) as
          | Pair
          | undefined;
      }, [frame.pair, pairs]);

      return (
        <Thumbnail
          ref={ref}
          frame={frame as CaseFrame}
          pair={pair}
          onClick={onClick}
        />
      );
    },
  ),
);

// textarea doesn't need this, but in the future if it was replaced by editor that uses div contenteditable, this would be useful
const ResizableFrameTextEditor = () => {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { focused, editorRef, thumbnailRef } = useRefsContext();

  const windowSize = useWindowSize();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(600));

  useEffect(() => {
    if (!thumbnailRef.current || !editorContainerRef?.current) return;

    editorContainerRef.current.style.height = `${
      thumbnailRef.current.clientHeight - (!sm && focused ? 28 : 0)
    }px`;
  }, [thumbnailRef, windowSize, sm, focused]);

  return (
    <Box ref={editorContainerRef}>
      <FrameTextEditor ref={editorRef} />
    </Box>
  );
};
