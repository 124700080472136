import {
  AppBar,
  Dialog,
  DialogProps,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import React, { useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { AppBarButton } from './AppBarButton';

type DraggableDialogProps = {
  children: React.ReactNode;
  titleComponent: React.ReactNode;
  fullscreen?: boolean;
  disableDraggable?: boolean;
  persist?: boolean;
  open: boolean;
  onClose: () => void;
  denseToolbar?: boolean;
} & DialogProps;

export const DraggableDialog = React.memo(
  ({
    children,
    titleComponent,
    fullscreen,
    disableDraggable,
    persist,
    open,
    onClose,
    denseToolbar,
    ...props
  }: DraggableDialogProps) => {
    const id = useMemo(
      () => `drag-dialog-${Math.random()}`.replace('.', ''),
      [],
    );

    const handleClose = (
      event: {},
      reason: 'backdropClick' | 'escapeKeyDown',
    ) => {
      if (!persist) {
        onClose();
      }
    };

    const { PaperProps, ...other } = props;

    const paperPropsWithDisableDraggable: DraggableDialogPaperProps = {
      ...PaperProps,
      id,
      disableDraggable: disableDraggable || fullscreen,
      elevation: 1,
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        PaperProps={paperPropsWithDisableDraggable}
        fullScreen={fullscreen}
        disableEnforceFocus
        hideBackdrop
        {...other}
      >
        <AppBar
          position="static"
          id={id}
          sx={{ cursor: !disableDraggable && !fullscreen ? 'move' : 'initial' }}
          color="info"
          elevation={0}
          enableColorOnDark
        >
          <Toolbar
            sx={{ paddingInline: 2 }}
            variant={denseToolbar ? 'dense' : 'regular'}
            disableGutters
          >
            {titleComponent}
          </Toolbar>
        </AppBar>

        {children}
      </Dialog>
    );
  },
);

export type DraggableDialogPaperProps = {
  disableDraggable?: boolean;
} & PaperProps;

const PaperComponent = (props: DraggableDialogPaperProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  const { id, disableDraggable, ...other } = props;

  return (
    <Draggable
      handle={`#${id}`}
      nodeRef={ref}
      cancel={'[class*="MuiDialogContent-root"], .MuiButton-root'}
      bounds="html"
      position={disableDraggable ? { x: 0, y: 0 } : position}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
      disabled={disableDraggable}
    >
      <Paper {...other} ref={ref} />
    </Draggable>
  );
};

type DraggableDialogDefaultTitleProps = {
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
};

export const DraggableDialogDefaultTitle = ({
  title,
  onClose,
  children,
}: DraggableDialogDefaultTitleProps) => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onClose();
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6" noWrap>
        {title}
      </Typography>

      <Stack direction="row">
        {children}
        <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
      </Stack>
    </Stack>
  );
};
