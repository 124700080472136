import {
  DirectionsRun,
  Forum,
  Forward,
  MenuBook,
  PanToolAlt,
  RecordVoiceOver,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid2,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Backward } from '@web/components/common/Icons';
import easings from '@web/data/easings';
import { assetStore } from '@web/store/assets/state';
import { useMemo } from 'react';
import { usePlayerBoundary } from '../hooks/usePlayerBoundary';
import { usePlayer } from '../providers/PlayerProvider';

type MENU = 'normal' | 'cross-examination' | 'investigation' | 'examine';

const useSize = () => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const xSmall = useMediaQuery(theme.breakpoints.down(348));

  return {
    size: small ? 'small' : 'medium',
    ...(xSmall
      ? { sx: { padding: '2px 6px', fontSize: '0.75rem', minWidth: 'auto' } }
      : {}),
  } as const;
};

export const PlayerCaseControls = () => {
  const { isCase } = usePlayer();

  if (!isCase) return null;

  return <PlayerCaseControlsInner />;
};

const PlayerCaseControlsInner = () => {
  const {
    playerCase: {
      state: { userInput, showExamine },
      isCrossExaminationStatements,
    },
    projectSnapshot: { investigationMenu },
    playerUi: {
      state: { width },
    },
  } = usePlayer();

  const boundary = usePlayerBoundary(width);

  const menuType = useMemo<MENU>(() => {
    if (showExamine) {
      return 'examine';
    } else if (userInput) {
      return 'normal';
    } else if (isCrossExaminationStatements) {
      return 'cross-examination';
    } else if (investigationMenu) {
      return 'investigation';
    }

    return 'normal';
  }, [investigationMenu, isCrossExaminationStatements, showExamine, userInput]);

  return (
    <Stack
      spacing={1}
      mt={1}
      px={boundary ? 0.5 : 0}
      flexGrow={1}
      width="100%"
      maxWidth={width}
    >
      <PlayerCaseControlsType type={menuType} />
      <ControlsDivider />
    </Stack>
  );
};

const PlayerCaseControlsType = ({ type }: { type: MENU }) => {
  switch (type) {
    case 'normal':
      return <PlayerCaseControlsTypeNormal />;
    case 'cross-examination':
      return <PlayerCaseControlsTypeCrossExamination />;
    case 'investigation':
      return <PlayerCaseControlsTypeInvestigation />;
    case 'examine':
      return <InvestigationBackButton />;
    default:
      return <PlayerCaseControlsTypeNormal />;
  }
};

const PlayerCaseControlsTypeNormal = () => {
  return (
    <Box>
      <CourtRecordButton />
    </Box>
  );
};

const CourtRecordButton = () => {
  const {
    playerCase: { state },
    state: { startedPlaying },
  } = usePlayer();

  return (
    <Button
      color="secondary"
      variant="contained"
      startIcon={<MenuBook />}
      onClick={() => {
        state.showCourtRecord = !state.showCourtRecord;
      }}
      style={{ transition: 'none' }}
      disabled={!startedPlaying}
      {...useSize()}
    >
      Court Record
    </Button>
  );
};

const PlayerCaseControlsTypeCrossExamination = () => {
  const {
    actions: { preloadThenNext },
    state: { isPlaying, isAutoplay },
    playerCase: {
      state,
      hasPress,
      actions: { onPress },
    },
  } = usePlayer();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <CourtRecordButton />

      <Stack direction="row" spacing={1}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<RecordVoiceOver />}
          disabled={isPlaying || isAutoplay || !hasPress}
          style={{ transition: 'none' }}
          onClick={() => onPress(preloadThenNext)}
          {...useSize()}
        >
          Press
        </Button>

        <Button
          color="secondary"
          variant="contained"
          startIcon={<PanToolAlt />}
          disabled={isPlaying || isAutoplay}
          onClick={() => {
            state.showPresent = !state.showPresent;
          }}
          style={{ transition: 'none' }}
          {...useSize()}
        >
          Present
        </Button>
      </Stack>
    </Stack>
  );
};

const PlayerCaseControlsTypeInvestigation = () => {
  const {
    actions: { preloadFramesThenNext },
    playerCase: {
      location,
      locations,
      examines,
      conversations,
      state,
      actions: { centerCursor, startExamine },
    },
    state: playerState,
  } = usePlayer();

  const small = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!location) return null;

  const backgroundLeft = playerState.backgroundLeft || 0;

  const disableMove =
    locations.filter((f) => f.id !== location.id && f.frames.length).length ===
    0;

  const disableExamine = examines.length === 0;
  const disableTalk = conversations.filter((f) => !f.present).length === 0;
  const disablePresent = conversations.filter((f) => f.present).length === 0;

  const handleStartExamine = async () => {
    await startExamine(preloadFramesThenNext);

    setTimeout(
      centerCursor,
      backgroundLeft > 0 && backgroundLeft < 99.98 ? 650 : 50,
    );
  };

  return (
    <Grid2 spacing={{ xs: 1, sm: 2 }} container>
      <Grid2 size={{ xs: 6, sm: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Search />}
          onClick={handleStartExamine}
          disabled={disableExamine}
          size={small ? 'small' : 'medium'}
          fullWidth
        >
          Examine
        </Button>
      </Grid2>

      <Grid2 size={{ xs: 6, sm: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<DirectionsRun />}
          onClick={() => {
            state.showMove = !state.showMove;
            state.showConversations = false;
          }}
          size={small ? 'small' : 'medium'}
          disabled={disableMove}
          fullWidth
        >
          Move
        </Button>
      </Grid2>

      <Grid2 size={{ xs: 6, sm: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Forum />}
          onClick={() => {
            state.showConversations = !state.showConversations;
          }}
          size={small ? 'small' : 'medium'}
          disabled={disableTalk}
          fullWidth
        >
          Talk
        </Button>
      </Grid2>

      <Grid2 size={{ xs: 6, sm: 3 }}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<PanToolAlt />}
          onClick={() => {
            state.showPresent = !state.showPresent;
          }}
          size={small ? 'small' : 'medium'}
          disabled={disablePresent}
          fullWidth
        >
          Present
        </Button>
      </Grid2>
    </Grid2>
  );
};

const InvestigationBackButton = () => {
  const {
    state: playerState,
    playerCase: {
      state,
      actions: { centerCursor },
    },
    frame,
    projectActions: { update },
    playerViewport: { isAnimating, startAnimation },
  } = usePlayer();

  const { isPlaying } = playerState;

  const isWide =
    frame.background &&
    assetStore.background.cache[frame.background.id]?.isWide;

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    state.showExamine = false;
    state.showMove = false;
    state.showConversations = false;

    update({ investigationMenu: true });
  };

  const handleChangeLeft = (value: number) => {
    playerState.backgroundLeft = value;

    startAnimation({
      left: playerState.backgroundLeft,
      config: { duration: 500, easing: easings[0].value },
      onRest: () => {
        centerCursor();
      },
    });
  };

  const backgroundLeft = playerState.backgroundLeft || 0;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Button
        color="secondary"
        variant="contained"
        onClick={handleClick}
        startIcon={<Backward />}
        size={small ? 'small' : 'medium'}
        disabled={isPlaying || isAnimating}
      >
        Back
      </Button>

      {isWide && (
        <Stack direction="row" spacing={1}>
          <Button
            color="secondary"
            variant="contained"
            size={small ? 'small' : 'medium'}
            onClick={() => handleChangeLeft(0)}
            disabled={backgroundLeft <= 0 || isPlaying || isAnimating}
          >
            <Backward />
          </Button>

          <Button
            color="secondary"
            variant="contained"
            size={small ? 'small' : 'medium'}
            onClick={() => handleChangeLeft(99.98)}
            disabled={backgroundLeft >= 99.98 || isPlaying || isAnimating}
          >
            <Forward />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const ControlsDivider = () => {
  return (
    <>
      <Box />
      <Divider />
      <Box />
    </>
  );
};
