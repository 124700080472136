/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Remove } from '@mui/icons-material';
import {
  ButtonGroup,
  IconButton,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import { forwardRef } from 'react';

const TextNumberInput = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const NumberTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const { InputProps, ...other } = props;

    return (
      <TextNumberInput
        InputProps={{ ...InputProps, type: 'number' }}
        {...other}
        ref={ref}
        value={props.value}
      />
    );
  },
);

export const NumberTextFieldWithControls = forwardRef<
  HTMLInputElement,
  TextFieldProps & { min?: number; max?: number; step: number }
>((props, ref) => {
  const { value, onChange, max, min, step, ...other } = props;

  const onIncrement = () => {
    if (typeof value !== 'number' || isNaN(value)) {
      return;
    }

    const newValue = Math.min(value + step, max !== undefined ? max : Infinity);

    onChange?.({ target: { value: String(newValue) } } as any);
  };

  const onDecrement = () => {
    if (typeof value !== 'number' || isNaN(value)) {
      return;
    }

    const newValue = Math.max(
      value - step,
      min !== undefined ? min : -Infinity,
    );

    onChange?.({ target: { value: String(newValue) } } as any);
  };

  return (
    <TextNumberInput
      {...other}
      value={value ?? ''}
      onChange={onChange}
      ref={ref}
      InputProps={{
        endAdornment: (
          <ButtonGroup>
            <IconButton onClick={onDecrement} size="small">
              <Remove />
            </IconButton>
            <IconButton onClick={onIncrement} size="small">
              <Add />
            </IconButton>
          </ButtonGroup>
        ),
        type: 'number',
      }}
    />
  );
});
