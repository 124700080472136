import easings from '@web/data/easings';
import { forwardRef, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { usePlayerResetRegister } from './hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './hooks/usePlayerSaveLoadRegister';
import { usePlayer } from './providers/PlayerProvider';
import { Viewport } from './ui/Viewport';

export const PlayerViewport = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  const [fullWidth, setFullWidth] = useState<boolean | undefined>(false);

  const {
    frame: { pose, background, shouldPanBackground, transitionDuration, frame },
    step,
    actions: { nextStep },
    timeline: { addEvent },
    state: { skipping, evidence, backgroundLeft },
    playerCase: { state: playerCaseState },
    playerViewport: { api },
  } = usePlayer();

  const isFullscreenEvidence = !!evidence?.id && !evidence?.isIcon;
  const isFullscreen = fullWidth || isFullscreenEvidence;

  const leftAnimation = useSpring({
    ref: api,
    from: {
      left: 0,
    },
  });

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (playerCaseState.userInput?.id !== 17 && !playerCaseState.showExamine)
      return;

    const rect = event.currentTarget.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    playerCaseState.cursorPosition.x = x;
    playerCaseState.cursorPosition.y = y;
  };

  usePlayerSaveLoadRegister({
    name: 'viewport',
    onSave: () => ({ fullWidth, left: leftAnimation.left.get() }),
    onLoad: (data) => {
      setFullWidth(data.fullWidth);

      api.start({
        from: {
          left: 0,
        },
        to: {
          left: data.left,
        },
        immediate: true,
      });
    },
  });

  usePlayerResetRegister({
    name: 'viewport',
    onReset: () => {
      setFullWidth(true);

      api.start({
        to: {
          left: 0,
        },
        immediate: true,
      });
    },
  });

  useEffect(() => {
    if (step !== 'character') return;

    setFullWidth(!background?.isWide || pose?.isSpeedlines);

    if (
      ((frame && shouldPanBackground) ||
        leftAnimation.left.get() !== backgroundLeft) &&
      !transitionDuration
    ) {
      api.start({
        left: backgroundLeft ?? 0,
        immediate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (step !== 'transition') return;

    if (
      frame &&
      transitionDuration &&
      leftAnimation.left.get() !== backgroundLeft
    ) {
      const left = backgroundLeft ?? 0;
      const easing = frame?.transition?.easing
        ? easings.find((f) => f.id === frame.transition!.easing)?.value
        : (t: number) => t;

      api.start({
        left: left,
        config: { duration: transitionDuration, easing: easing },
        onStart: () => {
          addEvent(() => {
            nextStep();
          }, transitionDuration / 2);
        },
      });
    } else {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, backgroundLeft]);

  useEffect(() => {
    if (step !== 'transition_wait') return;

    addEvent(() => {
      nextStep();
    }, transitionDuration / 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!skipping) return;

    api.start({
      immediate: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipping]);

  // TODO: the left animation works but its sus
  // The left={leftAnimation.left} fixes the issue of previous frames not having a wide bg and current frame having a wide bg
  // The styled left fixes starting examine in a case while needing to go to left 0 (startExamine in usePlayerCase)
  return (
    <AnimatedViewport
      ref={ref}
      left={leftAnimation.left}
      style={{
        width: isFullscreen ? '100%' : 'auto',
        left: leftAnimation.left.to((left) => `${Math.min(left, 99.98)}%`),
        transform: leftAnimation.left.to((left) =>
          left !== 0 ? `translateX(-${Math.min(left, 99.98)}%)` : `none`,
        ),
        pointerEvents: 'auto',
      }}
      fullWidth={isFullscreen}
      onPointerDown={handleClick}
    >
      {children}
    </AnimatedViewport>
  );
});

const AnimatedViewport = animated(Viewport);
