import { Add, ArrowLeft, CropFree, Delete } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { CaseAction_PointArea } from '@shared/types/case-action';
import { PresetColorsInput } from '@web/components/common/form/ColorInput';
import { ComboBox } from '@web/components/common/form/ComboBox';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { assetStore } from '@web/store/assets/state';
import { defaultBackground } from '@web/utils/project';
import { useMemo, useState } from 'react';
import { useCaseActionInput } from '../CaseActionInputs';
import { ImageAreaInput } from '../ImageAreaInput';

export const PointAreaInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_PointArea>();

  const [resizeIndex, setResizeIndex] = useState(
    undefined as number | undefined,
  );

  const backgroundOptions = useMemo(
    () =>
      [...assetStore.background.preset, ...assetStore.background.user]
        .map((m) => ({
          id: String(m.id),
          name: m.name,
          value: m.url,
          group: 'Backgrounds',
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  const previewImageOptions = useMemo(
    () =>
      assetStore.evidence.user
        .filter((f) => !f.isIcon)
        .map((m) => ({
          id: String(m.id),
          name: m.name,
          value: m.url,
          group: 'Evidence',
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .concat(backgroundOptions),
    [backgroundOptions],
  );

  const colorOptions = useMemo(
    () => [
      ['#FF0000', '#AA0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000055'],
    ],
    [],
  );

  const handleAddPointArea = () => {
    const areas = [
      ...(caseAction.areas || []),
      { shape: { top: 50, left: 50, width: 10, height: 10 } },
    ] satisfies typeof caseAction.areas;

    updateCaseAction({ areas });
  };

  return (
    <MultiHideView index={resizeIndex === undefined ? 0 : 1}>
      <Stack spacing={1}>
        <ComboBox
          label="Image URL (for preview)"
          options={previewImageOptions}
          value={caseAction.imageUrl || null}
          onChange={(value) =>
            updateCaseAction({ imageUrl: value || undefined })
          }
          variant="standard"
          groupBy={(option) => option.group}
        />

        <Stack direction="row" spacing={1} alignItems="flex-end">
          <TextField
            label="Prompt"
            value={caseAction.prompt || ''}
            onChange={(event) =>
              updateCaseAction({ prompt: event.target.value })
            }
            size="small"
            variant="standard"
            fullWidth
          />

          <PresetColorsInput
            value={caseAction.color}
            onChange={(value) => updateCaseAction({ color: value })}
            label="Cursor"
            colors={colorOptions}
          />
        </Stack>

        <Stack spacing={1}>
          {caseAction.areas?.map((area, i) => (
            <Stack
              key={i}
              direction="row"
              spacing={1}
              alignItems="end"
              justifyContent="space-between"
              flexGrow={1}
            >
              <NumberTextField
                label="Target Frame ID"
                value={area.frameId || ''}
                onChange={(event) =>
                  updateCaseAction({
                    areas: caseAction.areas?.map((a, j) =>
                      i === j
                        ? { ...a, frameId: parseInt(event.target.value) }
                        : a,
                    ),
                  })
                }
                size="small"
                variant="standard"
              />

              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ minWidth: 50 }}
                  disabled={!caseAction.imageUrl}
                  onClick={() => setResizeIndex(i)}
                >
                  <CropFree />
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() =>
                    updateCaseAction({
                      areas: caseAction.areas?.filter((_, j) => i !== j),
                    })
                  }
                  sx={{ minWidth: 50 }}
                >
                  <Delete />
                </Button>
              </Stack>
            </Stack>
          ))}

          <Box pt={1}>
            <Button
              variant="contained"
              color="violet"
              size="small"
              startIcon={<Add />}
              onClick={handleAddPointArea}
            >
              Add
            </Button>
          </Box>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="baseline"
          flexWrap="wrap"
        >
          <Typography color="textSecondary" flexGrow={1}>
            Present anything else
          </Typography>

          <NumberTextField
            label="Target Frame ID"
            value={caseAction.incorrectFrameId || ''}
            onChange={(event) =>
              updateCaseAction({
                incorrectFrameId: parseInt(event.target.value),
              })
            }
            size="small"
            variant="standard"
          />
        </Stack>
      </Stack>

      {resizeIndex !== undefined ? (
        <Stack spacing={1}>
          <Box>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              startIcon={<ArrowLeft />}
              onClick={() => setResizeIndex(undefined)}
            >
              Back
            </Button>
          </Box>

          <ImageAreaInput
            imageUrl={caseAction.imageUrl || defaultBackground.url}
            value={caseAction.areas?.[resizeIndex]?.shape}
            onChange={(shape) =>
              updateCaseAction({
                areas: caseAction.areas?.map((area, i) =>
                  i === resizeIndex ? { ...area, shape } : area,
                ),
              })
            }
          />
        </Stack>
      ) : (
        <Box />
      )}
    </MultiHideView>
  );
};
