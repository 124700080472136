import {
  CaseAction,
  CaseAction_InvestigationMarkUnvisited,
  CaseAction_InvestigationToggleVisibility,
} from '../types/case-action';
import { Old_CaseAction } from './types/caseActions';
import { evidenceStringToEvidence, referenceToString } from './utils';

export const migrateCaseAction = (
  caseAction: Old_CaseAction | undefined,
): CaseAction | undefined => {
  if (!caseAction || !caseAction.id || !caseAction.value) {
    return undefined;
  }

  const mapHealthType = {
    0: 'set',
    1: 'increase',
    2: 'decrease',
  };

  if ((caseAction.id as number) === 5) {
    return { id: 5 };
  }

  switch (caseAction.id) {
    case 1: {
      return { id: 1, evidence: evidenceStringToEvidence(caseAction.value) };
    }

    case 2: {
      return { id: 2, evidence: evidenceStringToEvidence(caseAction.value) };
    }

    case 3: {
      return {
        id: 3,
        show: caseAction.value.show || '',
        hide: caseAction.value.hide || '',
      };
    }

    case 4: {
      return {
        id: 4,
        frameId: caseAction.value
          ? parseInt(String(caseAction.value))
          : undefined,
      };
    }

    // for some reason typescript is not happy with this
    // case 5: {
    //   return { id: 5 };
    // }

    case 6: {
      return {
        id: 6,
        type: mapHealthType[caseAction.value.type ?? 0],
        amount: caseAction.value.amount ?? 0,
      };
    }

    case 7: {
      return { id: 7, amount: caseAction.value };
    }

    case 8: {
      return {
        id: 8,
        items: caseAction.value.items.map((item) => ({
          evidence: evidenceStringToEvidence(item.eid),
          frameId: item.fid ? parseInt(String(item.fid)) : undefined,
        })),
        incorrectFrameId: caseAction.value.falseFid
          ? parseInt(String(caseAction.value.falseFid))
          : undefined,
        allowEvidence: caseAction.value.evidence,
        allowProfiles: caseAction.value.profiles,
      };
    }

    case 9: {
      return {
        id: 9,
        answers: caseAction.value.map((answer) => ({
          text: answer.text ?? '',
          frameId: answer.fid ? parseInt(String(answer.fid)) : undefined,
        })),
      };
    }

    case 10: {
      return {
        id: 10,
        name: caseAction.value.name ?? undefined,
        value: caseAction.value.value ?? undefined,
      };
    }

    case 11: {
      return {
        id: 11,
        name: caseAction.value.name ?? undefined,
        value: caseAction.value.value ?? 0,
      };
    }

    case 12: {
      return {
        id: 12,
        name: caseAction.value.name ?? undefined,
        type: caseAction.value.type,
        lowercase: caseAction.value.lowercase,
      };
    }

    case 13: {
      return {
        id: 13,
        name: caseAction.value.name ?? undefined,
        type: caseAction.value.type,
        value: caseAction.value.value ?? undefined,
        trueFrameId: caseAction.value.trueFid
          ? parseInt(String(caseAction.value.trueFid))
          : undefined,
        falseFrameId: caseAction.value.falseFid
          ? parseInt(String(caseAction.value.falseFid))
          : undefined,
      };
    }

    case 14: {
      return {
        id: 14,
        expression: caseAction.value.expression,
        trueFrameId: caseAction.value.trueFid
          ? parseInt(String(caseAction.value.trueFid))
          : undefined,
        falseFrameId: caseAction.value.falseFid
          ? parseInt(String(caseAction.value.falseFid))
          : undefined,
      };
    }

    case 15: {
      return {
        id: 15,
        groupId: referenceToString(caseAction.value),
      };
    }

    case 16: {
      return {
        id: 16,
        show: caseAction.value.show
          .map((m) => evidenceStringToEvidence(m))
          .filter((f) => f !== undefined),
        hide: caseAction.value.hide
          .map((m) => evidenceStringToEvidence(m))
          .filter((f) => f !== undefined),
      };
    }

    case 17: {
      return {
        id: 17,
        imageUrl: caseAction.value.imageUrl ?? undefined,
        prompt: caseAction.value.prompt ?? undefined,
        color: caseAction.value.color ?? undefined,
        incorrectFrameId: caseAction.value.falseFid
          ? parseInt(String(caseAction.value.falseFid))
          : undefined,
        areas: caseAction.value.areas
          .filter((area) => area.fid !== undefined && area.shape !== undefined)
          .map((area) => ({
            frameId: area.fid ? parseInt(String(area.fid)) : undefined,
            shape: {
              top: area.shape.top ?? 0,
              left: area.shape.left ?? 0,
              width: area.shape.width ?? 0,
              height: area.shape.height ?? 0,
            },
          })),
      };
    }

    case 18: {
      return {
        id: 18,
        items: caseAction.value.reduce(
          (acc: CaseAction_InvestigationToggleVisibility['items'], item) => {
            if (item.type === 'loc') {
              acc.push({
                type: 'location',
                show: item.show.map((m) => ({
                  id: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
                hide: item.hide.map((m) => ({
                  id: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            } else if (item.type === 'conv') {
              acc.push({
                type: 'conversation',
                show: item.show.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
                hide: item.hide.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            } else if (item.type === 'exam') {
              acc.push({
                type: 'examine',
                show: item.show.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
                hide: item.hide.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            }
            return acc;
          },
          [],
        ),
      };
    }

    case 19: {
      return {
        id: 19,
        items: caseAction.value.reduce(
          (acc: CaseAction_InvestigationMarkUnvisited['items'], item) => {
            if (item.type === 'loc') {
              acc.push({
                type: 'location',
                items: item.items.map((m) => ({
                  id: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            } else if (item.type === 'conv') {
              acc.push({
                type: 'conversation',
                items: item.items.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            } else if (item.type === 'exam') {
              acc.push({
                type: 'examine',
                items: item.items.map((m) => ({
                  id: referenceToString(m.id),
                  locationId: referenceToString(m.lid),
                  groupId: referenceToString(m.gid),
                })),
              });
            }

            return acc;
          },
          [],
        ),
      };
    }

    case 20: {
      return {
        id: 20,
        items: caseAction.value.map((item) => ({
          target: {
            id: referenceToString(item.target?.id),
            locationId: referenceToString(item.target?.lid),
            groupId: referenceToString(item.target?.gid),
          },
          replacement: {
            id: referenceToString(item.replacement?.id),
            locationId: referenceToString(item.replacement?.lid),
            groupId: referenceToString(item.replacement?.gid),
          },
        })),
      };
    }

    case 21: {
      if (!caseAction.value?.location) return undefined;

      return {
        id: 21,
        location: {
          id: referenceToString(caseAction.value.location.lid),
          groupId: referenceToString(caseAction.value.location.gid),
        },
        required: caseAction.value.required?.map((m) => {
          if (m.type === 'conv') {
            return {
              type: 'conversation',
              items: m.items.map((m) => ({
                id: referenceToString(m.id),
                groupId: referenceToString(m.gid),
                locationId: referenceToString(m.lid),
              })),
            };
          } else if (m.type === 'exam') {
            return {
              type: 'examine',
              items: m.items.map((m) => ({
                id: referenceToString(m.id),
                groupId: referenceToString(m.gid),
                locationId: referenceToString(m.lid),
              })),
            };
          } else if (m.type === 'expression') {
            return {
              type: 'expression',
              expression: m.expression,
            };
          } else if (m.type === 'loc') {
            return {
              type: 'location',
              items: m.items.map((m) => ({
                id: referenceToString(m.lid),
                groupId: referenceToString(m.gid),
              })),
            };
          } else {
            return {
              type: 'expression',
              expression: '',
            };
          }
        }),
      };
    }
  }

  return undefined;
};
