import { KeyboardArrowLeft } from '@mui/icons-material';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';

type BackMenuProps = {
  onClick: () => void;
  text: string;
  buttonText?: string;
  small?: boolean;
};

export const BackMenu = ({
  onClick,
  text,
  buttonText,
  small,
}: BackMenuProps) => {
  const height = small ? 28 : 36;

  return (
    <AppBar
      variant="outlined"
      position="static"
      color="accent"
      enableColorOnDark
      elevation={0}
      style={{ border: 0 }}
    >
      <Toolbar
        variant="dense"
        sx={{ height: height, minHeight: height }}
        disableGutters
      >
        <Button
          startIcon={<KeyboardArrowLeft />}
          color="inherit"
          sx={{
            height: height,
            borderRadius: 0,
            color: 'inherit',
            '&.MuiButtonBase-root': { lineHeight: 0 },
            '.MuiButton-startIcon': { marginBottom: '1px' },
          }}
          onClick={onClick}
        >
          {buttonText || 'Back'}
        </Button>

        <Typography
          mx={1}
          fontWeight={300}
          variant={small ? 'body2' : 'body1'}
          noWrap
        >
          {text}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
