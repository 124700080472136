import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CaseAction_ChooseEvidence } from '@shared/types/case-action';
import { Evidence } from '@shared/types/evidence';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { useCaseActionInput } from '../CaseActionInputs';
import { CourtRecordEvidencePicker } from '../CourtRecordEvidencePicker';

export const ChooseEvidenceInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_ChooseEvidence>();

  const handleAddEvidenceRow = () => {
    const items = [
      ...(caseAction.items || []),
      { evidence: undefined, frameId: 0 },
    ] satisfies typeof caseAction.items;

    updateCaseAction({ items });
  };

  const handleRemoveEvidenceRow = (i: number) => {
    const items = [...(caseAction.items || [])];

    items.splice(i, 1);

    updateCaseAction({ items });
  };

  const handleChangeEvidence = (i: number, value: Evidence | null) => {
    const items = [...(caseAction.items || [])];

    items[i] = { ...items[i], evidence: value || undefined };

    updateCaseAction({ items });
  };

  const handleChangeFrameId = (i: number, value: string) => {
    const items = [...(caseAction.items || [])];

    items[i] = { ...items[i], frameId: parseInt(value) };

    updateCaseAction({ items });
  };

  return (
    <Stack spacing={2} pt={1}>
      <Stack spacing={1}>
        {caseAction.items?.map((item, i) => (
          <Stack key={i} direction="row" spacing={1} alignItems="end">
            <CourtRecordEvidencePicker
              value={item.evidence?.evidenceId || null}
              onChange={(value) => handleChangeEvidence(i, value)}
            />

            <NumberTextField
              label="Target Frame ID"
              value={item.frameId || ''}
              onChange={(event) => handleChangeFrameId(i, event.target.value)}
              variant="standard"
              size="small"
            />

            <IconButton size="small" onClick={() => handleRemoveEvidenceRow(i)}>
              <Delete />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <Box>
        <Button
          variant="contained"
          color="violet"
          size="small"
          startIcon={<Add />}
          disabled={caseAction.items?.length === 10}
          onClick={handleAddEvidenceRow}
        >
          Add
        </Button>
      </Box>

      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="baseline"
        flexWrap="wrap"
      >
        <Typography color="textSecondary" flexGrow={1}>
          Present anything else
        </Typography>

        <NumberTextField
          label="Target Frame ID"
          value={caseAction.incorrectFrameId || ''}
          onChange={(event) =>
            updateCaseAction({ incorrectFrameId: parseInt(event.target.value) })
          }
          size="small"
          variant="standard"
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Checkbox
          label="Allow Evidence"
          value={caseAction.allowEvidence || false}
          onChange={(checked) => updateCaseAction({ allowEvidence: checked })}
          size="small"
        />

        <Checkbox
          label="Allow Profiles"
          value={caseAction.allowProfiles || false}
          onChange={(checked) => updateCaseAction({ allowProfiles: checked })}
          size="small"
        />
      </Stack>
    </Stack>
  );
};
