import {
  Button,
  DialogContent,
  Grid2,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { useAssetStore } from '@web/store/assets/state';

import { useState } from 'react';

import { Close, DirectionsRun, QuestionMark } from '@mui/icons-material';
import { Location } from '@shared/types';
import 'swiper/css';
import 'swiper/css/pagination';
import { usePlayer } from '../../providers/PlayerProvider';
import { Background } from '../../ui/Background';
import { Container } from '../../ui/Container';
import { Desk } from '../../ui/Desk';
import { ZIndex } from '../../utils/constants';

type LocationMoveDialogProps = {
  open: boolean;
  locations: Location[];
  onMove?: (value: Location) => void;
  onClose: () => void;
};

export const LocationMoveDialog = ({
  open,
  locations,
  onMove,
  onClose,
}: LocationMoveDialogProps) => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      titleComponent={<DialogTitle onClose={onClose} />}
      transitionDuration={0}
      maxWidth={false}
      sx={{ '& .MuiDialog-paper': { maxWidth: '960px' } }}
      fullscreen={fullscreen}
      fullWidth
      disableDraggable
      disableEnforceFocus
      disableScrollLock
      disableAutoFocus
      denseToolbar
      persist
    >
      <DialogMainContent
        locations={locations}
        onMove={onMove}
        onClose={onClose}
      />
    </DraggableDialog>
  );
};

const DialogMainContent = ({
  locations,
  onMove,
  onClose,
}: {
  locations: Location[];
  onMove?: (value: Location) => void;
  onClose: () => void;
}) => {
  const [selectedLocation, setSelectedLocation] = useState<Location>(
    locations[0],
  );

  const handleLocationClick = (location: Location) => {
    if (selectedLocation?.id === location.id) {
      onMove?.(location);
    } else {
      setSelectedLocation(location);
    }
  };

  return (
    <DialogContent sx={{ p: 0 }}>
      <Grid2
        container
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          height: { xs: '100%', md: 480 },
        }}
      >
        <Grid2
          size={{ xs: 12, md: 6 }}
          flexShrink={0}
          sx={{
            height: { xs: 'auto', md: '100%' },
            minHeight: { xs: '100px', md: 'auto' },
          }}
        >
          <Stack spacing={2} justifyContent="space-between" height="100%">
            <LocationPreview
              location={selectedLocation}
              onMove={onMove}
              onClose={onClose}
            />
          </Stack>
        </Grid2>

        <Grid2
          size={{ xs: 12, md: 6 }}
          flexGrow={1}
          sx={{
            height: { xs: '1px', md: '100%' },
          }}
        >
          <List
            sx={{
              p: 0,
              maxHeight: { xs: '100%', md: 480 },
              height: '100%',
              overflow: 'auto',
            }}
          >
            {locations.map((location) => (
              <ListItemButton
                key={location.id}
                selected={selectedLocation?.id === location.id}
                onClick={() => handleLocationClick(location)}
              >
                <ListItemText
                  primary={<Typography noWrap>{location.name}</Typography>}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid2>
      </Grid2>
    </DialogContent>
  );
};

const LocationPreview = ({
  location,
  onMove,
  onClose,
}: {
  location: Location;
  onMove?: (value: Location) => void;
  onClose: () => void;
}) => {
  const {
    background: { cache },
  } = useAssetStore();

  const {
    playerUi: {
      state: { aspectRatio },
    },
    playerCase: {
      state: {
        investigation: { locationState },
      },
    },
  } = usePlayer();

  const background = cache[location.backgroundId];

  if (!background) return null;

  const visited = locationState[location.id]?.visited;

  return (
    <Container aspectRatio={aspectRatio}>
      {!location.revealed && !visited && <UnknownLocation />}
      <Background url={background.url} isWide={background.isWide} />
      <Desk url={background.url} isWide={background.isWide} />

      <MoveButtons
        selectedLocation={location}
        onMove={onMove}
        onClose={onClose}
      />
    </Container>
  );
};

const UnknownLocation = () => {
  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      bgcolor="grey"
      zIndex={ZIndex.Desk + 1}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <QuestionMark
        sx={{
          color: 'red',
          width: '50%',
          height: '50%',
          fontSize: 'inherit',
        }}
      />
    </Stack>
  );
};

const MoveButtons = ({
  selectedLocation,
  onMove,
  onClose,
}: {
  selectedLocation: Location;
  onMove?: (value: Location) => void;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      direction="row"
      position="absolute"
      top={0}
      left={0}
      zIndex={ZIndex.Desk + 1}
      width="100%"
      height="100%"
      alignItems="flex-end"
      justifyContent="space-between"
      p={2}
    >
      <Button
        variant="outlined"
        size={medium ? 'medium' : 'large'}
        color="inherit"
        onClick={onClose}
        startIcon={<Close />}
        style={{
          pointerEvents: 'auto',
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        }}
        disableElevation={false}
      >
        Cancel
      </Button>

      <Button
        variant="outlined"
        size={medium ? 'medium' : 'large'}
        color="inherit"
        onClick={() => onMove?.(selectedLocation)}
        startIcon={<DirectionsRun />}
        style={{
          pointerEvents: 'auto',
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        }}
        disableElevation={false}
      >
        Move
      </Button>
    </Stack>
  );
};

type DialogTitleProps = {
  onClose: () => void;
};

const DialogTitle = ({ onClose }: DialogTitleProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6" fontSize={16} noWrap>
        Move
      </Typography>

      <Button variant="text" color="inherit" onClick={onClose}>
        Close
      </Button>
    </Stack>
  );
};
