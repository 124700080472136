import { useSettingsStore } from '@web/store/settings';
import { clearObjectProperties } from '@web/utils/utils';
import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { usePlayer } from '../providers/PlayerProvider';
import { getVolume } from '../utils/audio';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

type DialogueType = {
  text: string;
  color?: string;
};

type useDialogueBoxState = {
  dialogueId: string;
  dialogues: DialogueType[];
  invalidSpeechBlip?: boolean;
  timeSinceBlip: number;
  nameplate: string;
  showNameplate: boolean;
  plainText: string; // for current frame
  fullPlaintext: string; // for current frame and previous
};

const defaultState: useDialogueBoxState = {
  dialogueId: '',
  dialogues: [],
  timeSinceBlip: 0,
  nameplate: '.',
  showNameplate: false,
  plainText: '',
  fullPlaintext: '',
};

export const usePlayerDialogueBox = () => {
  const {
    state: playerState,
    playerDefaults,
    timeline,
    frame: { speechBlipUrl, plainText, nameplate },
  } = usePlayer();

  const {
    audio: {
      muted: { blip: blipMuted },
      volume: { blip: blipVolume },
    },
  } = useSettingsStore();

  const state = useRef(proxy<useDialogueBoxState>(defaultState)).current;

  const snapshot = useProxy(state);
  const speechBlip = useRef<Howl | null>(null);
  const textBlipFrequency = playerDefaults.textBlipFrequency;

  usePlayerSaveLoadRegister<useDialogueBoxState>({
    name: 'dialogue',
    onSave: () => JSON.parse(JSON.stringify(snapshot)),
    onLoad: (s) => {
      clearObjectProperties(state);

      Object.assign(state, s);
    },
  });

  usePlayerResetRegister({
    name: 'dialogue',
    onReset: () => {
      clearObjectProperties(state);

      Object.assign(state, defaultState);
    },
  });

  const clearDialogues = useCallback(() => {
    state.dialogues = [];
  }, [state]);

  const typeDialogue = useCallback(
    async (text: string, textSpeed: number, color?: string) => {
      color = color?.toLocaleLowerCase();

      const appendToLast =
        state.dialogues.length > 0 &&
        state.dialogues[state.dialogues.length - 1].color === color;

      let dialogue = appendToLast
        ? state.dialogues[state.dialogues.length - 1]
        : undefined;

      if (!dialogue) {
        dialogue = { text: '', color };

        state.dialogues.push(dialogue);
      }

      let delay = 0;
      let isFirst = true;

      state.nameplate = nameplate;
      state.showNameplate = nameplate !== '.';

      playerState.characterTalking = textSpeed > 0;

      for (const char of text.split('')) {
        const humanizerTextspeed =
          textSpeed !== 0 && !playerState.skipping ? humanizer(textSpeed) : 0;

        delay += humanizerTextspeed;

        // eslint-disable-next-line no-loop-func
        timeline.addEvent(() => {
          state.dialogues[state.dialogues.length - 1].text += char;

          state.plainText = state.plainText.substring(1);

          if (state.invalidSpeechBlip || humanizerTextspeed === 0) {
            return;
          }

          if (
            textBlipFrequency <= 1000 &&
            (state.timeSinceBlip >= textBlipFrequency ||
              (isFirst &&
                state.plainText.length * humanizerTextspeed <
                  textBlipFrequency))
          ) {
            isFirst = false;

            if (!playerState.skipping) {
              speechBlip.current?.play();
            }

            state.timeSinceBlip = 0;
          } else {
            state.timeSinceBlip += humanizerTextspeed;
          }
        }, delay);
      }

      timeline.addEvent(() => {
        playerState.characterTalking = false;
      }, delay);

      await new Promise<void>((resolve) => timeline.addEvent(resolve, delay));
    },
    [nameplate, playerState, state, textBlipFrequency, timeline],
  );

  const initSpeechBlip = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentSrc = (speechBlip as any | null)?._src || '';

    if (speechBlip && currentSrc !== speechBlipUrl) {
      speechBlip.current?.unload();
      speechBlip.current = null;
    }

    speechBlip.current = new Howl({
      src: speechBlipUrl,
      volume: playerDefaults.muteSpeechBlip ? 0 : getVolume('blip'),
      html5: true,
      onload: () => {
        const duration = speechBlip.current?.duration() || 0;

        state.invalidSpeechBlip =
          duration > 1 && speechBlipUrl !== '/Audio/blip-echo.wav';
      },
      onloaderror: () => {
        state.invalidSpeechBlip = true;
      },
    });
  }, [playerDefaults.muteSpeechBlip, speechBlipUrl, state]);

  const newDialogue = useCallback(
    (clear?: boolean) => {
      if (clear) {
        clearDialogues();
      }

      state.dialogueId = uuidv4();
      state.plainText = plainText || '';
      state.fullPlaintext =
        state.dialogues.map((d) => d.text).join('') + plainText;
      state.timeSinceBlip = 0;
      state.invalidSpeechBlip = false;

      initSpeechBlip();
    },
    [clearDialogues, initSpeechBlip, plainText, state],
  );

  useEffect(() => {
    if (snapshot.invalidSpeechBlip) {
      speechBlip.current?.stop();
    }
  }, [snapshot.invalidSpeechBlip]);

  useEffect(() => {
    if (!speechBlip.current) return;

    speechBlip.current.volume(
      playerDefaults.muteSpeechBlip ? 0 : getVolume('blip'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blipMuted, blipVolume]);

  return {
    ...snapshot,
    newDialogue,
    typeDialogue,
    initSpeechBlip,
    clearDialogues,
  };
};

const humanizer = (delay: number) => {
  return Math.round((Math.random() * delay) / 2) + delay;
};
