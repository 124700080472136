import { Add, Delete } from '@mui/icons-material';
import { Box, Button, Grid2, IconButton, Stack } from '@mui/material';
import { CaseAction_InvestigationSwapTalkConversation } from '@shared/types';
import {
  InvestigationElementPicker,
  useCaseActionInput,
} from '../CaseActionInputs';

export const InvestigationSwapTalkConversationInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_InvestigationSwapTalkConversation>();

  return (
    <Stack spacing={1}>
      {caseAction.items.map((item, i) => (
        <Grid2 container key={i} spacing={1} alignItems="flex-end">
          <Grid2 size={{ xs: 5, sm: 5 }}>
            <InvestigationElementPicker
              label="Target"
              type="conversation"
              value={item.target?.id || null}
              onChange={(value) =>
                updateCaseAction({
                  items: caseAction.items.map((currentItem, index) =>
                    index === i
                      ? { ...currentItem, target: value }
                      : currentItem,
                  ),
                })
              }
            />
          </Grid2>

          <Grid2 size={{ xs: 7, sm: 6 }}>
            <InvestigationElementPicker
              label="Replacement"
              type="conversation"
              value={item.replacement?.id || null}
              onChange={(value) =>
                updateCaseAction({
                  items: caseAction.items.map((currentItem, index) =>
                    index === i
                      ? { ...currentItem, replacement: value }
                      : currentItem,
                  ),
                })
              }
            />
          </Grid2>

          <Grid2
            size={{ xs: 12, sm: 1 }}
            display="flex"
            justifyContent="flex-end"
          >
            <IconButton
              size="small"
              onClick={() =>
                updateCaseAction({
                  items: caseAction.items.filter((_, index) => i !== index),
                })
              }
            >
              <Delete />
            </IconButton>
          </Grid2>
        </Grid2>
      ))}

      <Box pt={1}>
        <Button
          variant="contained"
          color="violet"
          startIcon={<Add />}
          size="small"
          onClick={() =>
            updateCaseAction({
              items: [...caseAction.items, {}],
            })
          }
          disabled={caseAction.items.length >= 10}
        >
          Add
        </Button>
      </Box>
    </Stack>
  );
};
