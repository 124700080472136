import { useEffect, useRef, useState } from 'react';
import { usePlayer } from '../../providers/PlayerProvider';

export const usePlayerCaseCursor = () => {
  const {
    playerCase: { state: playerCaseState },
  } = usePlayer();

  const ref = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(true);

  const { cursorPosition } = playerCaseState;

  useEffect(() => {
    setShow(false);

    const timer = setTimeout(() => setShow(true), 3200);

    return () => clearTimeout(timer);
  }, [cursorPosition]);

  return { ref, show };
};
