import { Box } from '@mui/material';
import { Character_Side } from '@shared/types';
import { assetStore, useAssetStore } from '@web/store/assets/state';
import React, { useCallback, useEffect } from 'react';
import { FrameTextUtils, QueueItem, TagType } from '../maker/utils/frameText';
import { ClassicDialogueBox } from './dialogue/ClassicDialogueBox';
import { DialogueBox } from './dialogue/DialogueBox';
import { useDialogueBoxTags } from './hooks/useDialogueBoxTags';
import { usePlayerDialogueBox } from './hooks/usePlayerDialogueBox';
import { PlayerDialogueBoxContext } from './providers/PlayerDialogueBoxContext';
import { usePlayer } from './providers/PlayerProvider';
import {
  DefaultDialogueBlueTextColor,
  DefaultDialogueGreenTextColor,
  DefaultDialogueRedTextColor,
  DefaultDialogueTextColor,
} from './utils/constants';

export const PlayerDialogueBox = () => {
  const {
    actions: { nextStep, update: updatePlayerState },
    frame: {
      text,
      shouldClearDialogue,
      shouldPlayPoseAnimation,
      shouldWaitForPoseAnimation,
      character,
    },
    state: { showDialoguebox },
    step,
    playerDefaults,
  } = usePlayer();

  const dialogue = usePlayerDialogueBox();
  const { runTag } = useDialogueBoxTags({ dialogue });

  const dialogueBoxId = playerDefaults.chatbox ?? '0';

  const processQueueItem = useCallback(
    async (item: QueueItem) => {
      const tag = FrameTextUtils.getTagType(item);

      const defaultColor = getDefaultColor(dialogueBoxId);

      switch (tag.type) {
        case TagType.Text:
          updatePlayerState({ showDialoguebox: true });

          await dialogue.typeDialogue(
            item.contents,
            playerDefaults.textSpeed,
            defaultColor,
          );
          break;
        case TagType.ColorText:
          updatePlayerState({ showDialoguebox: true });

          await dialogue.typeDialogue(
            item.contents,
            playerDefaults.textSpeed,
            getColor(String(tag.param), dialogueBoxId) ?? defaultColor,
          );
          break;
        default:
          await runTag(tag, item);
          break;
      }
    },
    [
      dialogue,
      dialogueBoxId,
      playerDefaults.textSpeed,
      runTag,
      updatePlayerState,
    ],
  );

  const dialogueStep = useCallback(async () => {
    dialogue.newDialogue(shouldClearDialogue);

    const queue = FrameTextUtils.parseText(text ?? '');

    for (const item of queue) {
      await processQueueItem(item);
    }

    nextStep();
  }, [dialogue, nextStep, processQueueItem, shouldClearDialogue, text]);

  useEffect(() => {
    // why twice?
    if (step === undefined) return;

    if (
      step === 'speech_bubble' ||
      step === 'transition' ||
      (step === 'character' &&
        shouldPlayPoseAnimation &&
        shouldWaitForPoseAnimation) ||
      (step === 'character' && character?.side === Character_Side.Gallery)
    ) {
      updatePlayerState({ showDialoguebox: false });
    }

    if (step === 'dialogue') {
      dialogueStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <PlayerDialogueBoxContext.Provider value={dialogue}>
      <Box display={showDialoguebox ? 'inherit' : 'none'}>
        <DialogueBoxComponent id={dialogueBoxId} />
      </Box>
    </PlayerDialogueBoxContext.Provider>
  );
};

const DialogueBoxComponent = React.memo(({ id }: { id: string }) => {
  const {
    dialogueBox: { cache },
  } = useAssetStore();

  const dialogueBox = cache[id];

  if (!dialogueBox || id === '0') return <ClassicDialogueBox />;

  return <DialogueBox {...dialogueBox} />;
});

const getColor = (color: string, dialogueBoxId: string) => {
  if (color?.length > 1) return color;

  if (!dialogueBoxId || dialogueBoxId === '0') {
    // Classic dialogue box
    switch (color?.toLowerCase()) {
      case 'r':
        return DefaultDialogueRedTextColor;
      case 'g':
        return DefaultDialogueGreenTextColor;
      case 'b':
        return DefaultDialogueBlueTextColor;
      default:
        return DefaultDialogueTextColor;
    }
  }

  const dialogueBox = assetStore.dialogueBox.cache[dialogueBoxId];

  switch (color?.toLowerCase()) {
    case 'r':
      return dialogueBox.red
        ? `#${dialogueBox.red}`
        : DefaultDialogueRedTextColor;
    case 'g':
      return dialogueBox.green
        ? `#${dialogueBox.green}`
        : DefaultDialogueGreenTextColor;
    case 'b':
      return dialogueBox.blue
        ? `#${dialogueBox.blue}`
        : DefaultDialogueBlueTextColor;
    default:
      return undefined;
  }
};

const getDefaultColor = (dialogueBoxId: string) => {
  if (!dialogueBoxId || dialogueBoxId === '0') return DefaultDialogueTextColor; // Classic dialogue box

  const dialogueBox = assetStore.dialogueBox.cache[dialogueBoxId];

  return dialogueBox.defaultColor
    ? `#${dialogueBox.defaultColor}`
    : DefaultDialogueTextColor;
};
