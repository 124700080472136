import { Box, Button } from '@mui/material';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import Dialog, {
  DialogTransitionSlide,
} from '@web/components/common/ui/Dialog';
import { Player } from '@web/components/player/Player';
import { PlayerMetaProvider } from '@web/components/player/providers/PlayerMetaProvider';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { maxContainerWidth } from '@web/layouts/BaseLayout';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { memo, useEffect, useMemo, useRef } from 'react';

type PreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  project: SceneProjectDto | CaseProjectDto;
};

export const PreviewDialog = memo(
  ({ open, onClose, project }: PreviewDialogProps) => {
    const ref = useRef<PlayerActionsType>(null);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (open) {
          ref.current?.init(project, makerStore.previewFrameId);
        } else {
          ref.current?.reset();
        }
      }, 0);

      return () => {
        clearTimeout(timeout);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const width = Math.min(project.options.width || 960, maxContainerWidth);

    return (
      <Dialog
        open={open}
        onClose={onClose}
        title="Preview Objection"
        fullScreen={true}
        disableContentPadding={true}
        TransitionComponent={DialogTransitionSlide}
      >
        <Box
          width="100%"
          maxWidth={width}
          ml="auto"
          mr="auto"
          py={{ xs: project.type === 'case' ? 2 : 0, md: 2 }}
        >
          <PlayerMetaProvider value={{ isPreview: true }}>
            <Player
              ref={ref}
              autoStart={true}
              width={project.options.width}
              aspectRatio={project.options.aspectRatio}
              controls={<ClosePreviewButton onClose={onClose} />}
            />
          </PlayerMetaProvider>
        </Box>
      </Dialog>
    );
  },
);

const ClosePreviewButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Button size="small" color="error" variant="contained" onClick={onClose}>
      Close preview
    </Button>
  );
};

export const MakerPreviewDialog = () => {
  const {
    dialogs: { preview },
  } = useMakerStore();

  const handleClose = () => {
    makerStore.dialogs.preview = false;
  };

  const project = useMemo(
    () => makerStore.project,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preview],
  );

  if (!project) {
    return null;
  }

  return (
    <PreviewDialog open={!!preview} onClose={handleClose} project={project} />
  );
};
