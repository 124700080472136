import { rootStore } from './state';
import { ConfirmationDialogOptions } from './types';

export const rootActions = {
  toggleTheme() {
    rootStore.theme = rootStore.theme === 'dark' ? 'light' : 'dark';
  },
  showConfirmationDialog(options: ConfirmationDialogOptions) {
    rootStore.confirmationDialogOptions = options;
    rootStore.dialogs.confirmation = true;
  },
};
