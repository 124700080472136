import { Stack, TextField } from '@mui/material';
import { CaseAction_SetInputVariable } from '@shared/types';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { useCaseActionInput } from '../CaseActionInputs';

export const SetInputVariableInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_SetInputVariable>();

  const typeOptions = [
    { id: 'int', name: 'Integer' },
    { id: 'word', name: 'Word' },
    { id: 'string', name: 'String' },
  ];

  const selectedType =
    typeOptions.find((o) => o.id === caseAction.type) || null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <TextField
          label="Variable Name"
          value={caseAction.name || ''}
          onChange={(event) => updateCaseAction({ name: event.target.value })}
          size="small"
          fullWidth
        />

        <SimpleSelect
          label="Type"
          options={typeOptions}
          value={selectedType}
          onChange={(value) =>
            updateCaseAction({ type: value?.id as 'int' | 'word' | 'string' })
          }
          size="small"
          fullWidth
        />
      </Stack>

      <TextField
        label="Prompt"
        value={caseAction.prompt || ''}
        onChange={(event) => updateCaseAction({ prompt: event.target.value })}
        size="small"
        variant="standard"
        fullWidth
      />

      {(caseAction.type === 'word' || caseAction.type === 'string') && (
        <Checkbox
          label="Convert to lowercase"
          value={caseAction.lowercase || false}
          onChange={(checked) => updateCaseAction({ lowercase: checked })}
          size="small"
        />
      )}
    </Stack>
  );
};
