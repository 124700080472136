import { Grid2 } from '@mui/material';
import { CaseAction_ToggleEvidenceVisibility } from '@shared/types';
import { useCaseActionInput } from '../CaseActionInputs';
import { CourtRecordEvidencesPicker } from '../CourtRecordEvidencePicker';

export const ToggleEvidenceVisibilityInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_ToggleEvidenceVisibility>();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CourtRecordEvidencesPicker
          label="Show Evidence"
          value={caseAction.show || []}
          onChange={(value) => updateCaseAction({ show: value })}
        />
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 6 }}>
        <CourtRecordEvidencesPicker
          label="Hide Evidence"
          value={caseAction.hide || []}
          onChange={(value) => updateCaseAction({ hide: value })}
        />
      </Grid2>
    </Grid2>
  );
};
