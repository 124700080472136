/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material';
import { Pair } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { SliderWithInput } from '@web/components/common/form/SliderWithInput';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { memo, useCallback, useEffect, useMemo } from 'react';

export const FrameEffectEditor = memo(() => {
  return (
    <Stack spacing={2}>
      <FrameFilterOptionInput />
      <FrameFilterSelectedOptionInputs />
    </Stack>
  );
});

const FrameFilterSelectedOptionInputs = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const effect = focusedFrame?.effect;

  const handleChangeAmount = useCallback(
    (value: number | number[]) => {
      if (!focusedFrame) return;

      makerStore.frames[focusedFrameIndex!].effect!.amount =
        (value as number) || 0;
    },
    [focusedFrame, focusedFrameIndex],
  );

  useEffect(() => {
    if (!effect) return;

    makerStore.frames[focusedFrameIndex!].effect!.amount =
      effect.amount > 100 && effect.type !== 'hue-rotate' ? 100 : effect.amount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect?.type]);

  if (!effect) return null;

  return (
    <>
      <FrameFilterTargets />

      <SliderWithInput
        label="Amount"
        value={effect.amount}
        onChange={handleChangeAmount}
        min={0}
        max={effect?.type === 'hue-rotate' ? 360 : 100}
      />

      <FramePreview />
    </>
  );
});

const FrameFilterOptionInput = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();

  const options = useMemo(
    () => [
      { name: 'None', id: '' },
      { name: 'Grayscale', id: 'grayscale' },
      { name: 'Invert', id: 'invert' },
      { name: 'Sepia', id: 'sepia' },
      { name: 'Hue Rotate', id: 'hue-rotate' },
    ],
    [],
  );

  const selectedFilterOption = useMemo(
    () =>
      options.find((o) => o.id === focusedFrame?.effect?.type) || options[0],
    [options, focusedFrame?.effect],
  );

  const handleChangeFilterOption = useCallback(
    (
      value: {
        name: string;
        id: string;
      } | null,
    ) => {
      if (!focusedFrame) return;

      if (!value || !value.id) {
        makerStore.frames[focusedFrameIndex!].effect = undefined;

        return;
      }

      if (!makerStore.frames[focusedFrameIndex!].effect) {
        makerStore.frames[focusedFrameIndex!].effect = {
          type: value.id as any,
          target: SceneTargets.BACKGROUND,
          amount: 100,
        };
      } else {
        makerStore.frames[focusedFrameIndex!].effect!.type = value.id as any;
      }
    },
    [focusedFrame, focusedFrameIndex],
  );

  return (
    <SimpleSelect
      label="Filter"
      options={options}
      value={selectedFilterOption}
      onChange={handleChangeFilterOption}
      variant="standard"
    />
  );
});

const FrameFilterTargets = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const effect = focusedFrame?.effect;

  const checked = {
    1:
      (effect?.target && effect.target & SceneTargets.BACKGROUND) ===
      SceneTargets.BACKGROUND,
    2:
      (effect?.target && effect.target & SceneTargets.CHARACTER_1) ===
      SceneTargets.CHARACTER_1,
    4:
      (effect?.target && effect.target & SceneTargets.CHARACTER_2) ===
      SceneTargets.CHARACTER_2,
    8:
      (effect?.target && effect.target & SceneTargets.CHARACTER_3) ===
      SceneTargets.CHARACTER_3,
    16:
      (effect?.target && effect.target & SceneTargets.CHARACTER_4) ===
      SceneTargets.CHARACTER_4,
    32:
      (effect?.target && effect.target & SceneTargets.CHARACTER_5) ===
      SceneTargets.CHARACTER_5,
  };

  const handleCheck = useCallback(
    (checked: boolean, target: SceneTargets) => {
      if (!focusedFrame) return;

      let newTargets = effect?.target || SceneTargets.NONE;

      if (checked) {
        newTargets |= target;
      } else {
        newTargets &= ~target;
      }

      makerStore.frames[focusedFrameIndex!].effect!.target = newTargets;
    },
    [focusedFrame, effect, focusedFrameIndex],
  );

  if (!focusedFrame?.effect) return null;

  return (
    <Stack
      direction="row"
      gap={{ xs: 1, sm: 2 }}
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Checkbox
        label="Background"
        value={checked[SceneTargets.BACKGROUND]}
        onChange={(checked) => handleCheck(checked, SceneTargets.BACKGROUND)}
        size="small"
      />

      {!focusedFrame.pair && (
        <Checkbox
          label="Character"
          value={checked[SceneTargets.CHARACTER_1]}
          onChange={(checked) => handleCheck(checked, SceneTargets.CHARACTER_1)}
          size="small"
        />
      )}

      {focusedFrame.pair &&
        [
          SceneTargets.CHARACTER_1,
          SceneTargets.CHARACTER_2,
          SceneTargets.CHARACTER_3,
          SceneTargets.CHARACTER_4,
          SceneTargets.CHARACTER_5,
        ].map((target, index) => (
          <Checkbox
            key={target}
            label={`Character ${index + 1}`}
            value={checked[target]}
            onChange={(checked) => handleCheck(checked, target)}
            size="small"
          />
        ))}
    </Stack>
  );
});

const FramePreview = () => {
  const { focusedFrame, project } = useMakerStore();

  const pair = useMemo<Pair | undefined>(() => {
    if (!focusedFrame || !focusedFrame?.pair) return undefined;

    return project.pairs?.find((pair) => pair.id === focusedFrame.pair?.id);
  }, [focusedFrame, project.pairs]);

  if (!focusedFrame) return null;

  return (
    <Thumbnail frame={focusedFrame} pair={pair} serverThumbnails={false} />
  );
};
