import { GroupType } from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectPlayer } from '../../hooks/useProjectPlayer';
import { usePlayerMeta } from '../../providers/PlayerMetaProvider';
import { usePlayerSaveLoad } from '../../providers/PlayerSaveLoadProvider';

export const usePlayerAutoSave = (
  project: SceneProjectDto | CaseProjectDto | undefined,
  group: ReturnType<typeof useProjectPlayer>['group'],
  projectPlayerState: ReturnType<typeof useProjectPlayer>['state'],
) => {
  // auto save every 3 minutes
  const lastAutoSaveTime = useRef(Date.now());

  const { id: caseId } = useParams();
  const { loggedIn } = useAuth();

  const playerMeta = usePlayerMeta();
  const playerSaveLoad = usePlayerSaveLoad();

  const validAutoSave = useCallback(() => {
    return (
      loggedIn &&
      playerSaveLoad &&
      !projectPlayerState.isEnded &&
      group?.type !== GroupType.Gameover &&
      projectPlayerState.category !== 'failure' &&
      !playerMeta?.isPreview
    );
  }, [
    group?.type,
    loggedIn,
    playerMeta?.isPreview,
    playerSaveLoad,
    projectPlayerState.category,
    projectPlayerState.isEnded,
  ]);

  const autoSave = useCallback(async () => {
    lastAutoSaveTime.current = Date.now();

    if (
      !validAutoSave ||
      !playerSaveLoad ||
      !caseId ||
      project?.type !== 'case'
    ) {
      return;
    }

    try {
      await ApiClient.caseSave.create({
        caseId,
        slot: 9,
        title: playerMeta?.title || 'Untitled Case',
        data: { data: project, session: playerSaveLoad.save() },
      });
    } catch (e) {
      console.error(e);
    }
  }, [caseId, playerMeta?.title, playerSaveLoad, project, validAutoSave]);

  const onFrameEndAutoSave = useCallback(() => {
    const currentTime = Date.now();
    const timeSinceLastAutoSave = currentTime - lastAutoSaveTime.current;

    if (timeSinceLastAutoSave > 180 * 1000) {
      autoSave();
    }
  }, [autoSave]);

  return { onFrameEndAutoSave };
};
