import { TextField } from '@mui/material';
import { CaseFrame } from '@shared/types';
import { CaseAction } from '@shared/types/case-action';
import { GroupType, InvestigationGroup } from '@shared/types/groups';
import { SimpleMultiSelect } from '@web/components/common/form/SimpleMultiSelect';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { GameUtils } from '@web/components/player/utils/game-utils';
import { makerStore } from '@web/store/maker/state';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import evaluate from 'simple-evaluate';
import { useFrameAndIndexContext } from '../providers/FrameContextProvider';
import { CaseActionShowHideType, InvestigationElementType } from './types';

export const useCaseActionInput = <T extends CaseAction>() => {
  const { frame, index } = useFrameAndIndexContext();
  const caseAction = frame.caseAction as T;

  const updateCaseAction = (updates: Partial<T>) => {
    if (!makerStore.frames[index].caseAction) return;

    makerStore.frames[index].caseAction = {
      ...caseAction,
      ...updates,
    } as T;
  };

  return { caseAction, updateCaseAction };
};

export const VariablePicker = ({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (value: string | null) => void;
}) => {
  const variableNames = useMemo(
    () =>
      getAllVariableNames(
        GameUtils.getAllFrames(makerStore.caseProject.groups),
      ).map((name) => ({
        id: name,
        name,
      })),
    [],
  );

  const selectedVariableName = useMemo(
    () => variableNames.find((v) => v.id === value) || null,
    [value, variableNames],
  );

  return (
    <SimpleSelect
      label="Variable Name"
      options={variableNames}
      value={selectedVariableName}
      onChange={(value) => onChange(value?.id || null)}
      size="small"
      noOptionsText="No variables have been set"
      variant="standard"
      fullWidth
    />
  );
};

export const InvestigationElementTypePicker = ({
  value,
  onChange,
}: {
  value: InvestigationElementType;
  onChange: (value: InvestigationElementType) => void;
}) => {
  const options = useMemo(
    () => [
      { id: 'location', name: 'Location' },
      { id: 'conversation', name: 'Conversation' },
      { id: 'examine', name: 'Examine' },
    ],
    [],
  );

  const selectedOption = useMemo(
    () => options.find((v) => v.id === value) || null,
    [options, value],
  );

  return (
    <SimpleSelect
      label="Type"
      options={options}
      value={selectedOption}
      onChange={(value) => onChange(value!.id as InvestigationElementType)}
      size="small"
      variant="standard"
      fullWidth
    />
  );
};

export const InvestigationElementsPicker = <
  T extends InvestigationElementType,
>({
  label,
  values,
  onChange,
  type,
}: {
  label: string;
  values: CaseActionShowHideType<T>[];
  onChange: (value: CaseActionShowHideType<T>[]) => void;
  type: T;
}) => {
  const currentGroup = makerStore.investigationGroup;

  const investigationGroups = useMemo(
    () =>
      makerStore.caseProject.groups.filter(
        (group): group is InvestigationGroup =>
          group.type === GroupType.Investigation,
      ),
    [],
  );

  const sortedGroups = useMemo(
    () =>
      currentGroup
        ? investigationGroups.sort((a, b) =>
            a.id === currentGroup.id ? -1 : b.id === currentGroup.id ? 1 : 0,
          )
        : investigationGroups,
    [currentGroup, investigationGroups],
  );

  const options = useMemo(() => {
    let opts: {
      id: string;
      name: string;
      locationId?: string;
      groupId: string;
    }[] = [];

    if (type === 'location') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.map((location) => ({
          id: location.id,
          name: location.name,
          groupId: group.id,
        })),
      );
    } else if (type === 'conversation') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.flatMap((location) =>
          location.conversations.map((conversation) => ({
            id: conversation.id,
            name: conversation.name,
            locationId: location.id,
            groupId: group.id,
          })),
        ),
      );
    } else if (type === 'examine') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.flatMap((location) =>
          location.examine.map((examine) => ({
            id: examine.id,
            name: examine.name,
            locationId: location.id,
            groupId: group.id,
          })),
        ),
      );
    }

    return opts;
  }, [type, sortedGroups]);

  const selectedOptions = useMemo(() => {
    return options.filter((o) =>
      values.some((v) => {
        if (type === 'location') {
          return v.id === o.id && v.groupId === o.groupId;
        } else {
          return (
            'locationId' in v &&
            v.id === o.id &&
            v.locationId === o.locationId &&
            v.groupId === o.groupId
          );
        }
      }),
    );
  }, [values, options, type]);

  const handleChange = (value: typeof options) => {
    const transformedValues = value.map(({ id, locationId, groupId }) =>
      type === 'location'
        ? { id, groupId }
        : { id, locationId: locationId!, groupId },
    ) as CaseActionShowHideType<T>[];

    onChange(transformedValues);
  };

  return (
    <SimpleMultiSelect
      label={label}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      size="small"
      variant="standard"
      fullWidth
      disableRenderTags
    />
  );
};

export const InvestigationElementPicker = <T extends InvestigationElementType>({
  label,
  value,
  onChange,
  type,
}: {
  label: string;
  value: CaseActionShowHideType<T>['id'] | null;
  onChange: (value: CaseActionShowHideType<T>) => void;
  type: T;
}) => {
  const currentGroup = makerStore.investigationGroup;

  const investigationGroups = useMemo(
    () =>
      makerStore.caseProject.groups.filter(
        (group): group is InvestigationGroup =>
          group.type === GroupType.Investigation,
      ),
    [],
  );

  const sortedGroups = useMemo(
    () =>
      currentGroup
        ? investigationGroups.sort((a, b) =>
            a.id === currentGroup.id ? -1 : b.id === currentGroup.id ? 1 : 0,
          )
        : investigationGroups,
    [currentGroup, investigationGroups],
  );

  const options = useMemo(() => {
    let opts: {
      id: string;
      name: string;
      locationId?: string;
      groupId: string;
    }[] = [];

    if (type === 'location') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.map((location) => ({
          id: location.id,
          name: location.name,
          groupId: group.id,
        })),
      );
    } else if (type === 'conversation') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.flatMap((location) =>
          location.conversations.map((conversation) => ({
            id: conversation.id,
            name: conversation.name,
            locationId: location.id,
            groupId: group.id,
          })),
        ),
      );
    } else if (type === 'examine') {
      opts = sortedGroups.flatMap((group) =>
        group.locations.flatMap((location) =>
          location.examine.map((examine) => ({
            id: examine.id,
            name: examine.name,
            locationId: location.id,
            groupId: group.id,
          })),
        ),
      );
    }

    return opts;
  }, [type, sortedGroups]);

  const selectedOption = useMemo(
    () => options.find((o) => o.id === value) || null,
    [options, value],
  );

  const handleChange = (value: (typeof options)[number] | null) => {
    if (!value) return;

    const transformedValue: CaseActionShowHideType<T> =
      type === 'location'
        ? ({
            id: value.id,
            groupId: value.groupId,
          } as CaseActionShowHideType<T>)
        : ({
            id: value.id,
            locationId: value.locationId!,
            groupId: value.groupId,
          } as CaseActionShowHideType<T>);

    onChange(transformedValue);
  };

  return (
    <SimpleSelect
      label={label}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      size="small"
      variant="standard"
      fullWidth
    />
  );
};

export const ExpressionInput = ({
  value,
  onChange,
  autoFocus,
}: {
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
}) => {
  const [error, setError] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const testEvaluateExpression = useCallback((expression: string) => {
    setError(undefined);

    if (!expression) return;

    try {
      evaluate(null, expression);
    } catch (error) {
      if (error instanceof Error) {
        setError('Invalid expression, check the tips below for help');
      } else {
        setError('An error occurred');
      }
    }
  }, []);

  const debouncedTestEvaluateExpression = useMemo(
    () => debounce(testEvaluateExpression, 500),
    [testEvaluateExpression],
  );

  useEffect(() => {
    testEvaluateExpression(value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [autoFocus]);

  return (
    <TextField
      label="Expression"
      value={value}
      onChange={(event) => {
        onChange(event.target.value);

        debouncedTestEvaluateExpression(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') event.preventDefault();
      }}
      minRows={2}
      size="small"
      error={!!error}
      helperText={error}
      autoFocus={autoFocus}
      fullWidth
      multiline
      inputRef={inputRef}
    />
  );
};

// utils

const getAllVariableNames = (frames: CaseFrame[]): string[] => {
  const variableNames = new Set<string>();

  frames.forEach((frame) => {
    if (frame.caseAction?.id === 10) {
      const action = frame.caseAction;

      if (action.name) {
        variableNames.add(action.name);
      }
    }
  });

  return Array.from(variableNames);
};
