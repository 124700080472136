import { memo } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import {
  PlayerCaseChooseAnswer,
  PlayerCaseChooseConversation,
} from './PlayerCaseChooseAnswer';
import { PlayerCaseInputVariable } from './PlayerCaseInputVariable';
import { PlayerCasePointArea } from './PlayerCasePointArea';
import { PlayerCourtRecordDialog } from './PlayerCourtRecordDialog';
import { PlayerLocationMoveDialog } from './PlayerLocationMoveDialog';

export const PlayerCaseUserInput = memo(() => {
  const { isCase } = usePlayer();

  if (!isCase) return null;

  return (
    <>
      <PlayerCourtRecordDialog />
      <PlayerCaseChooseAnswer />
      <PlayerCaseChooseConversation />
      <PlayerCaseInputVariable />
      <PlayerCasePointArea />
      <PlayerLocationMoveDialog />
    </>
  );
});
