import { Grid2, TextField } from '@mui/material';
import { CaseAction_ToggleFramesVisibility } from '@shared/types/case-action';
import { useCaseActionInput } from '../CaseActionInputs';

export const ToggleFramesVisibilityInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_ToggleFramesVisibility>();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <TextField
          label="Show Frames"
          value={caseAction.show || ''}
          onChange={(event) => updateCaseAction({ show: event.target.value })}
          size="small"
          variant="standard"
          fullWidth
        />
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 6 }}>
        <TextField
          label="Hide Frames"
          value={caseAction.hide || ''}
          onChange={(event) => updateCaseAction({ hide: event.target.value })}
          size="small"
          variant="standard"
          fullWidth
        />
      </Grid2>
    </Grid2>
  );
};
