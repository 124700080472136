import { CheckCircle } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { pointWithinArea } from '@web/utils/utils';
import { memo, useMemo } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';
import { usePlayerCaseCursor } from './hooks/usePlayerCaseCursor';

export const PlayerCaseInputCursor = memo(
  ({ containerRef }: { containerRef: React.RefObject<HTMLDivElement> }) => {
    const {
      playerCase: { state: playerCaseState },
    } = usePlayer();

    const { userInput, cursorPosition } = playerCaseState;

    const open = userInput?.id === 17;

    const { ref, show } = usePlayerCaseCursor();

    if (!open) return null;

    const color = userInput?.color ?? '#FF0000';

    return (
      <PlayerOverlay ref={ref}>
        <TargetIconContainer
          style={{
            top: `${cursorPosition.y}%`,
            left: `${cursorPosition.x}%`,
          }}
        >
          <TargetIcon color={color} />

          <Box display={show ? 'none' : 'block'}>
            <HorizontalLine style={{ backgroundColor: color }} />
            <VerticalLine style={{ backgroundColor: color }} />
          </Box>
        </TargetIconContainer>
      </PlayerOverlay>
    );
  },
);

export const PlayerCaseExamineCursor = memo(
  ({ containerRef }: { containerRef: React.RefObject<HTMLDivElement> }) => {
    const {
      playerCase: { state: playerCaseState, location, locationState },
      playerViewport: { isAnimating },
    } = usePlayer();

    const { cursorPosition, showExamine } = playerCaseState;

    const { ref, show } = usePlayerCaseCursor();

    const pointWithinExamineArea = useMemo(() => {
      if (!showExamine || !location || !locationState) return undefined;

      return location.examine
        .filter((f) => !locationState.examines[f.id].hidden)
        .find((f) => pointWithinArea(cursorPosition, f.shape));
    }, [cursorPosition, location, locationState, showExamine]);

    const visitedExamine = useMemo(() => {
      if (!locationState || !pointWithinExamineArea) return false;

      return locationState.examines[pointWithinExamineArea.id].visited;
    }, [locationState, pointWithinExamineArea]);

    if (!showExamine || isAnimating) return null;

    const color = pointWithinExamineArea ? '#FFC211' : '#124D94';
    const linesColor = '#FFFFFF';

    return (
      <PlayerOverlay ref={ref}>
        <TargetIconContainer
          style={{
            top: `${cursorPosition.y}%`,
            left: `${cursorPosition.x}%`,
          }}
        >
          {visitedExamine && <CheckIcon />}

          <TargetIcon color={color} />

          <Box display={show ? 'none' : 'block'}>
            <HorizontalLine style={{ backgroundColor: linesColor }} />
            <VerticalLine style={{ backgroundColor: linesColor }} />
          </Box>
        </TargetIconContainer>
      </PlayerOverlay>
    );
  },
);

const PlayerOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: ZIndex.CaseInput,
});

const TargetIconContainer = styled(Box)(({ theme }) => ({
  width: 60,
  height: 60,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  zIndex: ZIndex.CaseInput + 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'top 0.25s ease-out, left 0.25s ease-out',
  pointerEvents: 'none',
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 40,
  },
}));

const TargetIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width: '100%',
      height: '100%',
    }}
  >
    <path
      d="M22.08,11.04H20.08V4H13.05V2H11.04V4H4V11.04H2V13.05H4V20.08H11.04V22.08H13.05V20.08H20.08V13.05H22.08V11.04M18.07,18.07H13.05V16.06H11.04V18.07H6V13.05H8.03V11.04H6V6H11.04V8.03H13.05V6H18.07V11.04H16.06V13.05H18.07V18.07M13.05,12.05A1,1 0 0,1 12.05,13.05C11.5,13.05 11.04,12.6 11.04,12.05C11.04,11.5 11.5,11.04 12.05,11.04C12.6,11.04 13.05,11.5 13.05,12.05Z"
      fill={color}
      stroke="white"
      strokeWidth={0.6}
      style={{ transition: 'fill 0.25s ease-out, stroke 0.25s ease-out' }}
    />
  </svg>
);

const CheckIcon = styled(CheckCircle)(({ theme }) => ({
  position: 'absolute',
  width: '50%',
  height: '50%',
  top: '-9%',
  left: '63%',
  color: 'red',
  backgroundColor: 'white',
  borderRadius: '50%',
}));

const HorizontalLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  width: '2000vw',
  height: 2,
  transform: 'translateX(-50%) translateY(-50%)',
  willChange: 'width',
  [theme.breakpoints.down('sm')]: {
    height: 1,
  },
}));

const VerticalLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  width: 2,
  height: '2000vh',
  transform: 'translateX(-50%) translateY(-50%)',
  willChange: 'height',
  [theme.breakpoints.down('sm')]: {
    width: 1,
  },
}));
