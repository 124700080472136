import { Add, ArrowLeft, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid2,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { CaseAction_InvestigationConfigureLocationCompletion } from '@shared/types';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import {
  ExpressionInput,
  InvestigationElementPicker,
  InvestigationElementsPicker,
  useCaseActionInput,
} from '../CaseActionInputs';
import { ExpressionTips } from './EvaluateExpressionInput';

const state = proxy({
  expressionIndex: undefined as number | undefined,
});

export const InvestigationConfigureLocationCompletionInput = () => {
  const { expressionIndex } = useSnapshot(state);

  useEffect(() => {
    return () => {
      state.expressionIndex = undefined;
    };
  }, []);

  return (
    <MultiHideView index={expressionIndex === undefined ? 0 : 1}>
      <MainEditor />
      <ExpressionEditor />
    </MultiHideView>
  );
};

const MainEditor = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_InvestigationConfigureLocationCompletion>();

  const handleChangeLocation = (
    value: NonNullable<typeof caseAction.location>,
  ) => {
    updateCaseAction({
      location: value,
      ...(!caseAction.required || caseAction.required.length < 1
        ? { required: [{ type: 'conversation', items: [] }] }
        : {}),
    });
  };

  const handleAddCondition = () => {
    updateCaseAction({
      required: [
        ...(caseAction.required || []),
        { type: 'conversation', items: [] },
      ],
    });
  };

  const handleUpdateCondition = (
    index: number,
    update: Partial<
      CaseAction_InvestigationConfigureLocationCompletion['required'][number]
    >,
  ) => {
    updateCaseAction({
      required: caseAction.required?.map((condition, i) =>
        i === index ? { ...condition, ...update } : condition,
      ) as CaseAction_InvestigationConfigureLocationCompletion['required'],
    });
  };

  const handleDeleteCondition = (index: number) => {
    updateCaseAction({
      required: caseAction.required?.filter((_, i) => i !== index),
    });
  };

  const conditionTypes = useMemo<
    {
      id: CaseAction_InvestigationConfigureLocationCompletion['required'][number]['type'];
      name: string;
    }[]
  >(
    () => [
      { id: 'conversation', name: 'Conversation' },
      { id: 'examine', name: 'Examine' },
      { id: 'expression', name: 'Expression' },
    ],
    [],
  );

  const handleFocusExpression = (index: number) => {
    state.expressionIndex = index;
  };

  return (
    <Stack spacing={2}>
      <InvestigationElementPicker
        label="Location"
        type="location"
        value={caseAction.location?.id || null}
        onChange={handleChangeLocation}
      />

      {caseAction.location && (
        <Stack spacing={1}>
          {caseAction.required?.map((condition, index) => (
            <Grid2 container key={index} spacing={1} alignItems="flex-end">
              <Grid2 size={{ xs: 5, sm: 3 }}>
                <SimpleSelect
                  label="Type"
                  options={conditionTypes}
                  value={
                    conditionTypes.find((type) => type.id === condition.type) ||
                    null
                  }
                  onChange={(value) =>
                    handleUpdateCondition(index, { type: value!.id })
                  }
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </Grid2>

              <Grid2 size={{ xs: 7, sm: 8 }}>
                {condition.type === 'expression' ? (
                  <TextField
                    label="Expression"
                    value={condition.expression || ''}
                    size="small"
                    variant="standard"
                    onFocus={() => handleFocusExpression(index)}
                    slotProps={{
                      htmlInput: {
                        style: { textOverflow: 'ellipsis' },
                        readOnly: true,
                      },
                    }}
                    fullWidth
                  />
                ) : (
                  <InvestigationElementsPicker
                    label="Required"
                    type={condition.type}
                    values={condition.items || []}
                    onChange={(value) =>
                      handleUpdateCondition(index, { items: value })
                    }
                  />
                )}
              </Grid2>

              <Grid2
                size={{ xs: 12, sm: 1 }}
                display="flex"
                justifyContent="flex-end"
              >
                <IconButton
                  size="small"
                  onClick={() => handleDeleteCondition(index)}
                >
                  <Delete />
                </IconButton>
              </Grid2>
            </Grid2>
          ))}

          <Box pt={1}>
            <Button
              variant="contained"
              color="violet"
              startIcon={<Add />}
              size="small"
              onClick={handleAddCondition}
              disabled={caseAction.required?.length >= 3}
            >
              Add Condition
            </Button>
          </Box>
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={1}
      >
        <Checkbox
          label="Perform Fade"
          value={!!caseAction.fade}
          onChange={(value) => updateCaseAction({ fade: value })}
        />

        <Checkbox
          label="Hide Conversations"
          value={!!caseAction.autoHideConversations}
          onChange={(value) =>
            updateCaseAction({ autoHideConversations: value })
          }
        />

        <Checkbox
          label="Hide Examine"
          value={!!caseAction.autoHideExamine}
          onChange={(value) => updateCaseAction({ autoHideExamine: value })}
        />
      </Stack>
    </Stack>
  );
};

const ExpressionEditor = () => {
  const { expressionIndex } = useSnapshot(state);

  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_InvestigationConfigureLocationCompletion>();

  if (
    expressionIndex === undefined ||
    caseAction.required[expressionIndex].type !== 'expression'
  ) {
    return null;
  }

  const handleBack = () => {
    state.expressionIndex = undefined;
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<ArrowLeft />}
          onClick={handleBack}
        >
          Back
        </Button>
      </Box>

      <ExpressionInput
        value={caseAction.required[expressionIndex].expression || ''}
        onChange={(value) => {
          updateCaseAction({
            required: caseAction.required.map((condition, i) =>
              i === expressionIndex
                ? { ...condition, expression: value }
                : condition,
            ),
          });
        }}
        autoFocus
      />

      <ExpressionTips />
    </Stack>
  );
};
