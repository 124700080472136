import {
  AnyGroup,
  CaseFrame,
  CrossExaminationGroup,
  GameoverGroup,
  GroupType,
  InvestigationGroup,
  NormalGroup,
} from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { GameUtils } from '@web/components/player/utils/game-utils';
import { getAspectRatioNumber } from '@web/components/player/utils/utils';
import { FramesTarget } from '@web/types/project';
import { projectUtils } from '@web/utils/project';
import { proxy } from 'valtio';
import { subscribeKey, useProxy } from 'valtio/utils';
import {
  isCrossExaminationGroup,
  isGameoverGroup,
  isInvestigationGroup,
  isNormalGroup,
} from './types';

type MakerStoreType = {
  project: SceneProjectDto | CaseProjectDto;
  title?: string;
  editId?: string | number;
  framesLimit: number;
  groupsLimit: number;
  page: number;
  perPage: number;
  focusedFrameIndex?: number;
  sceneEditorFrameIndex?: number;
  frameActionsFrameIndex?: number;
  previewFrameId?: number;
  framesTarget: FramesTarget;
  changesDetected?: boolean;
  dialogs: {
    newProject?: boolean;
    saveProject?: boolean;
    loadProject?: boolean;
    preview?: boolean;
    sceneEditor?: boolean;
    frameActions?: boolean;
    frameEffects?: boolean;
    convertProject?: boolean;
    projectOptions?: boolean;
    projectPairs?: boolean;
    projectBackups?: boolean;
    aliases?: boolean;
    courtRecord?: boolean;
    contradictions?: boolean;
    locations?: boolean;
    submit?: boolean;
    help?: boolean;
  };
  caseProject: CaseProjectDto;
  group?: AnyGroup;
  normalGroup?: NormalGroup;
  crossExaminationGroup?: CrossExaminationGroup;
  investigationGroup?: InvestigationGroup;
  gameOverGroup?: GameoverGroup;
  location?: InvestigationGroup['locations'][0];
  locations: InvestigationGroup['locations'];
  frames: CaseFrame[];
  pageFrames: CaseFrame[];
  focusedFrame?: CaseFrame;
  copiedFrame?: CaseFrame;
  newCommentFrameId?: number;
  disableAddFrame: boolean;
  isCaseProject: boolean;
  deletedFrame?: {
    frame: CaseFrame;
    index: number;
    comment?: string;
  };
  showPressPresent?: boolean;
  aspectRatio: number;
  canConvertToCase: boolean;
  canConvertToScene: boolean;
};

export const makerStore: MakerStoreType = proxy({
  project: projectUtils.getSceneProject([]),
  framesLimit: 10000,
  groupsLimit: 100,
  page: 1,
  perPage: parseInt(localStorage.getItem('makerPerPage') || '10'),
  focusedFrameIndex: 0,
  sceneEditorFrameIndex: 0,
  frameActionsFrameIndex: 0,
  framesTarget: {},
  dialogs: {},
  get caseProject() {
    return makerStore.project as CaseProjectDto;
  },
  get group() {
    return makerStore.project.groups.find(
      (f) => f.id === makerStore.framesTarget.groupId,
    ) as AnyGroup | undefined;
  },
  // these typed groups somehow re-render a lot, do not use unless the component dismounts (e.g. in a dialog)
  get normalGroup() {
    return isNormalGroup(makerStore.group) ? makerStore.group : undefined;
  },
  get gameOverGroup() {
    return isGameoverGroup(makerStore.group) ? makerStore.group : undefined;
  },
  get crossExaminationGroup() {
    return isCrossExaminationGroup(makerStore.group)
      ? makerStore.group
      : undefined;
  },
  get investigationGroup() {
    return isInvestigationGroup(makerStore.group)
      ? makerStore.group
      : undefined;
  },
  get location() {
    return makerStore.investigationGroup?.locations.find(
      (f) => f.id === makerStore.framesTarget.locationId,
    );
  },
  get locations() {
    return makerStore.investigationGroup?.locations ?? [];
  },
  get frames() {
    if (!makerStore.project) return [];

    return (
      GameUtils.getTargetFrames(makerStore.framesTarget, makerStore.project) ??
      []
    );
  },
  get pageFrames() {
    const start = (makerStore.page - 1) * makerStore.perPage;
    const end = start + makerStore.perPage;

    return (makerStore.frames ?? []).slice(start, end);
  },
  get focusedFrame() {
    if (
      !makerStore.frames ||
      (!makerStore.focusedFrameIndex && makerStore.focusedFrameIndex !== 0)
    )
      return;

    return makerStore.frames[makerStore.focusedFrameIndex];
  },
  get disableAddFrame() {
    const group = makerStore.group;

    if (!group) return true;

    if (group?.type !== GroupType.Investigation) return false;

    return (
      (group as InvestigationGroup).locations.length <= 0 ||
      !makerStore?.framesTarget?.locationId
    );
  },
  get isCaseProject() {
    return makerStore.project.type === 'case';
  },
  get canConvertToCase() {
    return !makerStore.editId && makerStore.project.type !== 'case';
  },
  get canConvertToScene() {
    return (
      !makerStore.editId &&
      makerStore.project.type !== 'scene' &&
      makerStore.project.groups?.length === 1 &&
      makerStore.project.groups?.[0]?.type === GroupType.Normal
    );
  },
  get showPressPresent() {
    return (
      isCrossExaminationGroup(makerStore.group) &&
      !makerStore.framesTarget.pressFrame &&
      !makerStore.framesTarget.category
    );
  },
  get aspectRatio() {
    return makerStore.project
      ? getAspectRatioNumber(makerStore.project.options.aspectRatio || '3:2')
      : 1.5;
  },
});

export const updateFrame = (frame: Partial<CaseFrame>, index: number) => {
  makerStore.frames[index] = {
    ...makerStore.frames[index],
    ...frame,
  };

  // delete undefined values
  Object.keys(makerStore.frames[index]).forEach((key) => {
    const value = makerStore.frames[index][key as keyof CaseFrame];

    if (
      value === undefined ||
      value === null ||
      value === '' ||
      value === false
    ) {
      delete makerStore.frames[index][key as keyof CaseFrame];
    }
  });
};

subscribeKey(makerStore, 'perPage', () => {
  localStorage.setItem('makerPerPage', String(makerStore.perPage));
});

export const useMakerStore = () => useProxy(makerStore);
