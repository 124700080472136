import {
  AnyGroup,
  CrossExaminationGroup,
  GameoverGroup,
  GroupType,
  InvestigationGroup,
  NormalGroup,
} from '@shared/types';
import { BaseGroupDto } from '@web/api/api';

export const isNormalGroup = (
  group?: AnyGroup | BaseGroupDto,
): group is NormalGroup => group?.type === GroupType.Normal;

export const isGameoverGroup = (
  group?: AnyGroup | BaseGroupDto,
): group is GameoverGroup => group?.type === GroupType.Gameover;

export const isCrossExaminationGroup = (
  group?: AnyGroup | BaseGroupDto,
): group is CrossExaminationGroup => group?.type === GroupType.CrossExamination;

export const isInvestigationGroup = (
  group?: AnyGroup | BaseGroupDto,
): group is InvestigationGroup => group?.type === GroupType.Investigation;
