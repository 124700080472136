import { Box, Stack, Typography } from '@mui/material';
import { useMakerStore } from '@web/store/maker/state';
import { isInvestigationGroup } from '@web/store/maker/types';
import { useMakerButtonSizes } from '../hooks/useMakerButtonSizes';
import { InvestigationConversationsEditor } from './InvestigationConversationsEditor';
import { InvestigationExamineEditor } from './InvestigationExamineEditor';

export const InvestigationLocationEditor = () => {
  const {
    group,
    framesTarget: { category, conversationId, examineId },
  } = useMakerStore();

  if (
    !isInvestigationGroup(group) ||
    !!category ||
    !!conversationId ||
    !!examineId
  )
    return null;

  return <InvestigationLocationEditorInner />;
};

const InvestigationLocationEditorInner = () => {
  const { group } = useMakerStore();

  const { isNotFullWidth } = useMakerButtonSizes();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={isNotFullWidth ? 0.5 : 0}
    >
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Typography variant="h6">{group?.name}</Typography>
        <Typography variant="body2" fontWeight="bold">
          Introduction Frames
        </Typography>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        flexGrow={{ xs: 1, sm: 0 }}
      >
        <InvestigationConversationsEditor />
        <InvestigationExamineEditor />
      </Stack>
    </Stack>
  );
};
