/* eslint-disable @typescript-eslint/no-explicit-any */
import { Edit, Share } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CaseProjectDto, SaveDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Player } from '@web/components/player/Player';
import { PlayerMetaProvider } from '@web/components/player/providers/PlayerMetaProvider';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { PlayerSaveLoadProvider } from '@web/components/player/providers/PlayerSaveLoadProvider';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { AxiosError } from 'axios';
import { memo, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { ShareDialog } from './Scene';

const state = proxy({
  project: undefined as CaseProjectDto | undefined,
  title: '',
  savedSession: undefined as any | undefined,
  shareDialog: false,
  error: undefined as 404 | 500 | undefined,
});

const CasePage = () => {
  const { project } = useProxy(state);

  const theme = useTheme();
  const breakpoint = useMediaQuery(
    theme.breakpoints.down((project?.options.width || 960) + 16),
  );

  return (
    <>
      <Box mt={project?.type === 'case' ? 1 : breakpoint ? 0 : 1}>
        <ErrorAlert />
        <CasePlayer />
      </Box>

      <ObjectionShareDialog />
    </>
  );
};

const CasePlayer = memo(() => {
  const { id } = useParams();
  const { title, project } = useProxy(state);

  const ref = useRef<PlayerActionsType>(null);

  const { state: locationState } = useLocation();

  useEffect(() => {
    if (!id) return;

    const start = async () => {
      if (locationState?.load) {
        await fetchSavedGame(locationState.load);

        // reset location state
        window.history.replaceState({}, '');
      } else {
        await fetchCase(id);
      }

      if (state.error || !state.project) return;

      // TODO: for now using setTimeout, need to fix init and usage of state instead of snapshot
      setTimeout(() => {
        if (state.error || !state.project) return;

        ref.current?.reset();
        ref.current?.init(
          state.project,
          state.savedSession?.projectPlayer,
          state.savedSession,
        );
      });
    };

    state.error = undefined;

    start();
  }, [id, locationState?.load]);

  if (!id) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <PlayerMetaProvider value={{ title }}>
        <PlayerSaveLoadProvider>
          <Player
            ref={ref}
            controls={<CasePlayerControls />}
            width={project?.options.width}
            aspectRatio={project?.options.aspectRatio}
          />
        </PlayerSaveLoadProvider>
      </PlayerMetaProvider>
    </Stack>
  );
});

const CasePlayerControls = () => {
  const handleClickShare = () => {
    state.shareDialog = true;
  };

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      <Button
        variant="contained"
        size="small"
        color="info"
        startIcon={<Share />}
        sx={{ height: 32 }}
        onClick={handleClickShare}
      >
        Share
      </Button>

      <EditCaseButton />
    </Stack>
  );
};

const EditCaseButton = () => {
  const { id } = useParams();

  const { loggedIn } = useAuth();

  const { data } = useQuery({
    queryKey: ['case', 'isMine', id],
    queryFn: () => ApiClient.case.isMine(id!),
    enabled: !!id && loggedIn,
    staleTime: Infinity,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    if (!id) return;

    navigate(`/edit/case/${id}`);
  };

  if (!data?.data) return null;

  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      sx={{ height: 32 }}
      onClick={handleClick}
      startIcon={<Edit />}
    >
      Edit
    </Button>
  );
};

const ObjectionShareDialog = () => {
  const { project, shareDialog } = useProxy(state);

  const handleClose = () => {
    state.shareDialog = false;
  };

  return (
    <ShareDialog project={project} open={shareDialog} onClose={handleClose} />
  );
};

const ErrorAlert = () => {
  const { project, error } = useSnapshot(state);

  const theme = useTheme();
  const breakpoint = useMediaQuery(
    theme.breakpoints.down((project?.options.width || 960) + 16),
  );

  if (!error) return null;

  return (
    <Alert
      severity="error"
      variant="filled"
      sx={{ borderRadius: 0, mb: !breakpoint ? 1 : 0 }}
    >
      {error === 404
        ? 'Case not found or has been deleted'
        : 'An error occurred while fetching the case'}
    </Alert>
  );
};

const fetchCase = async (id: string) => {
  try {
    state.error = undefined;

    const response = await ApiClient.case.get(id);

    state.project = response.data.data;
    state.title = response.data.title;

    // clear saved session
    state.savedSession = undefined;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      state.error = 404;

      return;
    }

    state.error = 500;
  }
};

const fetchSavedGame = async (game: SaveDto) => {
  try {
    state.error = undefined;

    const response = (await ApiClient.caseSave.get(game.id)).data;

    const saveData = response.data as any;

    const project: CaseProjectDto = saveData.data;

    state.project = project;
    state.savedSession = saveData.session;
    state.title = response.title;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      state.error = 404;

      return;
    }

    state.error = 500;
  }
};

export default CasePage;
