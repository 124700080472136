import { usePlayer } from '../providers/PlayerProvider';
import { EvidenceRecord } from '../types/playerCase';
import { CourtRecordDialog } from './dialogs/CourtRecordDialog';

export const PlayerCourtRecordDialog = () => {
  const {
    playerCase,
    actions: { preloadThenNext },
  } = usePlayer();

  const { userInput, courtRecord, messagesLog, showCourtRecord, showPresent } =
    playerCase.state;

  const open = userInput?.id === 8 || !!showCourtRecord || !!showPresent;

  const onPresent = (value: EvidenceRecord) => {
    if (playerCase.state.userInput && userInput?.id === 8) {
      playerCase.actions.onUserInput<8>(preloadThenNext, value);
    } else if (showPresent) {
      playerCase.actions.onPresent(preloadThenNext, value);
    }
  };

  const onClose = () => {
    playerCase.state.showCourtRecord = false;
    playerCase.state.showPresent = false;
  };

  return (
    <CourtRecordDialog
      open={open}
      courtRecord={courtRecord}
      onPresent={!showCourtRecord || showPresent ? onPresent : undefined}
      onClose={showCourtRecord || showPresent ? onClose : undefined}
      messagesLog={messagesLog}
    />
  );
};
