import { useFont } from '@web/hooks/useFont';
import { type DialogueBox as DialogueboxType } from '@web/types/project';
import { debounce } from 'lodash';
import { CSSProperties, useEffect, useRef } from 'react';
import { useDialogueBoxStyles } from '../hooks/useDialogueBoxStyles';
import { useNextButton, usePreviousButton } from '../hooks/useNextButton';
import { usePlayerDialogueBoxContext } from '../providers/PlayerDialogueBoxContext';
import { usePlayer } from '../providers/PlayerProvider';
import {
  DialogueBoxContainer,
  DialogueBoxImage as DialogueBoxImageComponent,
  DialogueBoxTextContainer,
} from '../ui/Dialogue';
import { DialogueBoxText } from './DialogueBoxText';
import { Nameplate } from './Nameplate';
import { NextButton as NextButtonComponent } from './NextButton';

type DialogueBoxProps = DialogueboxType;

export const DialogueBox = ({
  id,
  text,
  nameplate,
  nextButton,
  url,
  opacity = 1,
  imageOpacity = 1,
  wordSpacing,
  letterSpacing,
}: DialogueBoxProps) => {
  const hiddenTextRef = useRef<HTMLDivElement>(null);
  const visibleTextRef = useRef<HTMLDivElement>(null);

  const {
    playerUi: {
      state: { size },
    },
    playerDefaults: { centerText },
  } = usePlayer();

  useFont(`font_${id}`, text.fontUrl);
  useFont(`nameplate_font_${id}`, nameplate.fontUrl);

  const handleAutoScroll = () => {
    const hiddenTextDiv = hiddenTextRef.current;
    const visibleTextDiv = visibleTextRef.current;

    if (hiddenTextDiv && visibleTextDiv) {
      // TODO: This is a temporary fix for the auto-scrolling issue, if height is exactly N x lineHeight, it scrolls slightly
      // This might not work for high player width (currently max is 1280)
      const shouldScroll =
        hiddenTextDiv.scrollHeight - hiddenTextDiv.clientHeight > 10;

      if (shouldScroll) {
        hiddenTextDiv.scrollTop =
          hiddenTextDiv.scrollHeight - hiddenTextDiv.clientHeight;

        visibleTextDiv.scrollTop = hiddenTextDiv.scrollTop;
      }
    }
  };

  const debouncedHandleAutoScroll = debounce(handleAutoScroll, 30);

  useEffect(() => {
    const hiddenTextDiv = hiddenTextRef.current;

    if (hiddenTextDiv) {
      const observer = new MutationObserver(() => {
        debouncedHandleAutoScroll();
      });

      observer.observe(hiddenTextDiv, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      const resizeObserver = new ResizeObserver(() => {
        debouncedHandleAutoScroll();
      });

      resizeObserver.observe(hiddenTextDiv);

      return () => {
        observer.disconnect();
        resizeObserver.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { containerStyle, textContainerStyle } = useDialogueBoxStyles({
    id,
    text,
    opacity,
    wordSpacing,
    letterSpacing,
    size,
    centerText,
  });

  return (
    <>
      <DialogueBoxContainer style={containerStyle}>
        <DialogueBoxImage
          url={url}
          nameplate={nameplate}
          opacity={imageOpacity}
        />

        <DialogueBoxTextContainer
          ref={visibleTextRef}
          style={textContainerStyle}
          dir="auto"
        >
          <DialogueBoxText />
        </DialogueBoxTextContainer>

        <NextButton id={id} nextButton={nextButton} />
        <PreviousButton id={id} nextButton={nextButton} />
      </DialogueBoxContainer>

      <DialogueBoxContainer
        sx={{ visibility: 'hidden' }}
        style={containerStyle}
      >
        <DialogueBoxImage url={url} nameplate={nameplate} opacity={0} />

        <DialogueBoxTextContainer
          ref={hiddenTextRef}
          style={textContainerStyle}
          dir="auto"
        >
          <DialogueBoxText disableLeadingTransparentText />
        </DialogueBoxTextContainer>
      </DialogueBoxContainer>

      <Nameplate {...nameplate} id={id} />
    </>
  );
};

type NextButtonProps = {
  id: string;
  nextButton: DialogueBoxProps['nextButton'];
};

const NextButton = ({ id, nextButton }: NextButtonProps) => {
  const {
    playerDefaults: { chatbox },
  } = usePlayer();

  const { showNextButton, size, onClick } = useNextButton();

  return (
    <NextButtonComponent
      {...nextButton}
      id={id}
      size={size}
      show={showNextButton}
      onClick={onClick}
      iconStyle={iconStyle[chatbox]}
    />
  );
};

const PreviousButton = ({ id, nextButton }: NextButtonProps) => {
  const {
    playerDefaults: { chatbox },
  } = usePlayer();

  const { showPreviousButton, size, onClick } = usePreviousButton();

  return (
    <NextButtonComponent
      {...nextButton}
      id={id}
      size={size}
      show={showPreviousButton}
      onClick={onClick}
      iconStyle={iconStyle[chatbox]}
      isPreviousButton
    />
  );
};

const DialogueBoxImage = ({
  url,
  opacity,
  nameplate,
}: {
  url: string;
  opacity: number;
  nameplate: DialogueboxType['nameplate'];
}) => {
  const { showNameplate } = usePlayerDialogueBoxContext();

  const dialogueBoxUrl =
    nameplate.replaceBox && showNameplate ? nameplate.url : url;

  return (
    <DialogueBoxImageComponent
      alt="Dialogue Box"
      src={dialogueBoxUrl}
      style={{
        opacity,
      }}
    />
  );
};

const iconStyle: Record<string, CSSProperties> = {
  '1': {
    lineHeight: 1,
    filter: `drop-shadow(-0.015em 0 #b58a18) 
         drop-shadow(0 0.015em #b58a18) 
         drop-shadow(0.015em 0 #b58a18) 
         drop-shadow(0 -0.015em #b58a18)`,
  },
};
