import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, Delete, DragHandle, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Conversation } from '@shared/types';
import { disableParentRipple } from '@web/components/common';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { GameCategory } from '@web/components/player/types/state';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { ChangeEvent, CSSProperties, MouseEvent, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { CourtRecordEvidencesPicker } from './CourtRecordEvidencePicker';
import { XSmallChip } from './GroupsEditor';
import { InvestigationPopper } from './InvestigationPopper';

enum Menu {
  MAIN,
  FORM,
}

const state = proxy({
  menu: Menu.MAIN,
  form: {} as Pick<Conversation, 'name' | 'hide' | 'present'> & { id?: string },
  deleteId: '',
});

export const InvestigationConversationsEditor = () => {
  const { menu } = useSnapshot(state);

  const onExit = () => {
    state.menu = Menu.MAIN;
  };

  return (
    <InvestigationPopper label="Conversations" onExit={onExit}>
      {({ close }) => (
        <MultiHideView index={menu}>
          <ConversationsMainEditor handleClose={close} />
          <ConversationsFormEditor />
        </MultiHideView>
      )}
    </InvestigationPopper>
  );
};

const ConversationsMainEditor = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const handleClickAdd = () => {
    state.form = { name: '', hide: false };
    state.menu = Menu.FORM;
  };

  return (
    <>
      <ConversationsList />

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button color="error" onClick={handleClose}>
          Close
        </Button>

        <Button color="success" startIcon={<Add />} onClick={handleClickAdd}>
          Add Conversation
        </Button>
      </CardActions>
    </>
  );
};

const ConversationsList = () => {
  const { location } = useMakerStore();

  const handleDragEnd = useCallback(
    (sourceId: UniqueIdentifier, destinationId?: UniqueIdentifier) => {
      if (!makerStore.location) return;

      const oldIndex = makerStore.location.conversations.findIndex(
        (i) => i.id === sourceId,
      );
      const newIndex = makerStore.location.conversations.findIndex(
        (i) => i.id === destinationId,
      );

      makerStore.location.conversations = arrayMove(
        makerStore.location.conversations,
        oldIndex,
        newIndex,
      );
    },
    [],
  );

  return (
    <List sx={{ maxHeight: '50vh', overflowY: 'auto' }} dense>
      <SortableDndProvider
        items={location?.conversations || []}
        onDragEnd={handleDragEnd}
      >
        {location?.conversations.length === 0 && (
          <>
            <ListItem>
              <ListItemText>
                <Typography variant="caption" color="textSecondary">
                  The conversations the player can choose from through the Talk
                  menu. A maximum of 4 are shown at a time, they can be swapped
                  by using a case action.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText sx={{ mt: 0 }}>
                <Typography variant="caption" color="textSecondary">
                  In addition, you can have conversations for evidence the
                  player presents.
                </Typography>
              </ListItemText>
            </ListItem>

            <Divider />
          </>
        )}

        {location?.conversations.map((conversation, index) => (
          <ConversationRow key={conversation.id} conversation={conversation} />
        ))}
      </SortableDndProvider>

      <MoreConversations />
    </List>
  );
};

const ConversationRow = ({ conversation }: { conversation: Conversation }) => {
  const { deleteId } = useSnapshot(state);
  const { investigationGroup } = useMakerStore();

  const onEditClick = (event: MouseEvent, conversation: Conversation) => {
    event.stopPropagation();

    state.form = {
      id: conversation.id,
      name: conversation.name,
      hide: conversation.hide,
      present: conversation.present,
    };

    state.menu = Menu.FORM;
  };

  const onDeleteClick = (event: MouseEvent, conversation: Conversation) => {
    event.stopPropagation();

    state.deleteId = conversation.id;
  };

  const onChangeConversation = () => {
    if (!investigationGroup) return;

    changeConversation(conversation.id);
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: conversation.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (deleteId === conversation.id) {
    return <ConversationDeleteRow />;
  }

  return (
    <ListItemButton
      ref={setNodeRef}
      sx={{ height: 60 }}
      style={style}
      onClick={onChangeConversation}
    >
      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <Typography component="div" mb={0.75}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Box
                display="flex"
                sx={{ touchAction: 'none' }}
                {...attributes}
                {...listeners}
              >
                <DragHandle sx={{ cursor: 'move' }} />
              </Box>

              <Typography
                component="div"
                variant="subtitle2"
                fontWeight="bold"
                mb={0.5}
                noWrap
              >
                {conversation.name}
              </Typography>
            </Stack>
          </Typography>
        }
        secondary={
          <Typography component="div" color="text.secondary">
            <Stack direction="row" spacing={1} alignItems="center">
              <XSmallChip label={conversation.present ? 'Present' : 'Talk'} />

              <Typography variant="caption" lineHeight="unset">
                {conversation.frames.length} Frames
              </Typography>
            </Stack>
          </Typography>
        }
      />

      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          onClick={(e) => onEditClick(e, conversation)}
          {...disableParentRipple}
        >
          <Edit />
        </IconButton>

        <IconButton
          size="small"
          onClick={(e) => onDeleteClick(e, conversation)}
          {...disableParentRipple}
        >
          <Delete />
        </IconButton>
      </Stack>
    </ListItemButton>
  );
};

const MoreConversations = () => {
  const { location } = useMakerStore();

  return (
    <>
      <ListItemButton sx={{ height: 60 }} onClick={goToCompletionFrames}>
        <ListItemText
          primary={
            <Typography variant="subtitle2" fontWeight="bold" mb={0.5}>
              Completion Conversation
            </Typography>
          }
          secondary={
            <Typography variant="caption" color="text.secondary">
              {location?.completionFrames?.length} Frames
            </Typography>
          }
        />
      </ListItemButton>

      <ListItemButton sx={{ height: 60 }} onClick={goToPresentFailureFrames}>
        <ListItemText
          primary={
            <Typography variant="subtitle2" fontWeight="bold" mb={0.5}>
              Present Anything Else
            </Typography>
          }
          secondary={
            <Typography variant="caption" color="text.secondary">
              {location?.presentFailureFrames?.length} Frames
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};

const ConversationDeleteRow = () => {
  const handleDelete = () => {
    if (!makerStore.location) return;

    if (makerStore.framesTarget.conversationId === state.deleteId) {
      const conversationIndex = makerStore.location.conversations.findIndex(
        (conversation) => conversation.id === state.deleteId,
      );

      const nextConversation =
        makerStore.location.conversations[conversationIndex + 1];
      const previousConversation =
        makerStore.location.conversations[conversationIndex - 1];

      if (nextConversation) {
        changeConversation(nextConversation.id);
      } else if (previousConversation) {
        changeConversation(previousConversation.id);
      }
    }

    makerStore.location.conversations =
      makerStore.location.conversations.filter(
        (conversation) => conversation.id !== state.deleteId,
      );
  };

  const handleCancel = () => {
    state.deleteId = '';
  };

  return (
    <ListItem sx={{ height: 60 }}>
      <Typography variant="caption" pr={0.5}>
        Delete this conversation? All frames in this conversation will be
        deleted.
      </Typography>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <XSmallButton variant="contained" color="error" onClick={handleDelete}>
          Delete
        </XSmallButton>

        <XSmallButton
          variant="contained"
          color="success"
          onClick={handleCancel}
        >
          Cancel
        </XSmallButton>
      </Stack>
    </ListItem>
  );
};

const ConversationsFormEditor = () => {
  const { form } = useProxy(state);

  const handleSave = () => {
    if (!makerStore.location) return;

    if (state.form.id) {
      makerStore.location.conversations = makerStore.location.conversations.map(
        (conversation) => {
          if (conversation.id === state.form.id) {
            conversation.name = state.form.name;
            conversation.hide = state.form.hide;
            conversation.present = state.form.present;
          }
          return conversation;
        },
      );
    } else {
      const newId = uuidv4();

      makerStore.location.conversations.push({
        ...state.form,
        id: newId,
        frames: [],
      });
    }

    state.menu = Menu.MAIN;
  };

  const conversationType = form.present ? 'present' : 'talk';

  const handleChangeConversationType = (event: ChangeEvent, value: string) => {
    if (value === 'present' && !!state.form.present) return;

    state.form.present = value === 'present' ? [] : undefined;
  };

  const disableSave = !form.name;

  return (
    <>
      <CardContent>
        <Stack spacing={2}>
          <RadioGroup
            value={conversationType}
            onChange={handleChangeConversationType}
            row
          >
            <FormControlLabel
              value="talk"
              control={<Radio />}
              label="Talk"
              sx={{ mr: 4 }}
            />

            <FormControlLabel
              value="present"
              control={<Radio />}
              label="Present"
            />
          </RadioGroup>

          <TextField
            label="Conversation Name"
            value={form.name || ''}
            onChange={(e) => {
              state.form.name = e.target.value;
            }}
            size="small"
            variant="standard"
            fullWidth
          />

          {form.present && (
            <CourtRecordEvidencesPicker
              label="Present Evidence"
              value={form.present || []}
              onChange={(value) => {
                state.form.present = value;
              }}
            />
          )}

          <Checkbox
            value={!!form.hide}
            onChange={(value) => {
              state.form.hide = value;
            }}
            label="Hide"
            size="small"
          />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          color="error"
          onClick={() => {
            state.menu = Menu.MAIN;
          }}
        >
          Cancel
        </Button>

        <Button color="success" disabled={disableSave} onClick={handleSave}>
          {form.id ? 'Edit' : 'Add'}
        </Button>
      </CardActions>
    </>
  );
};

const changeConversation = (conversationId: string) => {
  if (!makerStore.investigationGroup || !makerStore.location) return;

  makerStore.framesTarget = {
    groupId: makerStore.investigationGroup.id,
    locationId: makerStore.location.id,
    conversationId,
  };

  makerStore.page = 1;
};

const goToCompletionFrames = () => {
  makerStore.framesTarget = {
    ...makerStore.framesTarget,
    category: GameCategory.InvestigationCompletion,
    conversationId: undefined,
  };

  makerStore.page = 1;
};

const goToPresentFailureFrames = () => {
  makerStore.framesTarget = {
    ...makerStore.framesTarget,
    category: GameCategory.InvestigationPresentFailure,
    conversationId: undefined,
  };

  makerStore.page = 1;
};
