import { CaseFrame, Options, Pair } from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { assetActions } from '@web/store/assets/actions';
import { useAllPresetsLoaded } from '@web/store/assets/state';
import { FramesTarget } from '@web/types/project';
import { clearObjectProperties, isNumber } from '@web/utils/utils';
import { useCallback, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { proxy, useSnapshot } from 'valtio';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import { GameUtils } from '../utils/game-utils';
import { PreloaderUtils } from '../utils/preloader';

type usePlayerLoadingType = {
  initialized: boolean | undefined;
  loadingPercentage: number;
  preloading: boolean;
  preloadIndex: number;
  preloadedImages: string[];
};

const defaultState = {
  initialized: undefined as boolean | undefined,
  loadingPercentage: 0,
  preloading: false,
  preloadIndex: 0,
  preloadedImages: [] as string[], // to put in DOM
};

type usePlayerLoadingProps = {
  project?: SceneProjectDto | CaseProjectDto;
  frameIndex: number;
  framesTarget?: FramesTarget;
  isEnded?: boolean;
};

export const usePlayerLoading = ({
  project,
  frameIndex,
  framesTarget,
  isEnded,
}: usePlayerLoadingProps) => {
  const state = useRef(proxy<usePlayerLoadingType>(defaultState)).current;
  const abortController = useRef(new AbortController());
  const snapshot = useSnapshot(state);
  const presetsLoaded = useAllPresetsLoaded();
  const playerMeta = usePlayerMeta();

  const preloadAmount = playerMeta?.isRecording ? 50 : isMobile ? 10 : 16;
  const preloadStuck =
    framesTarget && !isEnded && frameIndex >= snapshot.preloadIndex - 1;

  const appendPreloadedImages = useCallback(
    (images: string[]) => {
      const maxSize = isMobile ? 40 : 80;

      // Filter out images that already exist in the array
      const uniqueNewImages = images.filter(
        (image) => !state.preloadedImages.includes(image),
      );

      const newPreloadedImages = [...state.preloadedImages, ...uniqueNewImages];

      if (newPreloadedImages.length > maxSize) {
        // Remove oldest images (from beginning) to stay within maxSize
        state.preloadedImages = newPreloadedImages.slice(
          newPreloadedImages.length - maxSize,
        );
      } else {
        state.preloadedImages = newPreloadedImages;
      }
    },
    [state],
  );

  const preloadFrames = useCallback(
    async (
      frames: CaseFrame[],
      pairs: Pair[],
      projectOptions: Options,
      index: number,
      amount = preloadAmount,
    ) => {
      let append = true;

      if (abortController.current.signal.aborted) {
        abortController.current.abort();
        abortController.current = new AbortController();
      }

      const images = await PreloaderUtils.preloadFrames(
        frames.slice(index, index + amount) || [],
        pairs,
        projectOptions,
        undefined,
        abortController.current,
        8000,
        () => {
          append = false;
        },
      );

      if (!append) {
        abortController.current.abort();

        return;
      }

      appendPreloadedImages(images);
    },
    [appendPreloadedImages, preloadAmount],
  );

  const preloadFramesEffect = useCallback(
    async (
      frames: CaseFrame[],
      pairs: Pair[],
      projectOptions: Options,
      frameIndex: number,
    ) => {
      state.preloading = true;

      await preloadFrames(frames, pairs, projectOptions, frameIndex);

      state.preloadIndex = frameIndex + preloadAmount;
      state.preloading = false;
    },
    [preloadAmount, preloadFrames, state],
  );

  const init = useCallback(
    async (
      project: SceneProjectDto | CaseProjectDto,
      framesTarget?: FramesTarget,
      frameIndex?: number,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      savedSession?: any,
    ) => {
      if (!project || !framesTarget || state.initialized !== undefined) {
        return;
      }

      const preloadIndex = frameIndex ? frameIndex : 0;

      const targetPreloadIndex = preloadIndex + preloadAmount;

      state.initialized = false;
      state.preloadIndex = preloadIndex;

      const frames = GameUtils.getTargetFrames(framesTarget, project);

      if (!frames || frames.length === 0) {
        return;
      }

      state.loadingPercentage = 0;

      if (abortController.current.signal.aborted) {
        abortController.current.abort();
        abortController.current = new AbortController();
      }

      let append = true;

      const framesToPreload =
        frames.slice(preloadIndex, preloadIndex + preloadAmount) || [];

      // add music to preload from saved game
      const savedSessionMusicId = savedSession?.player?.state?.musicId;

      if (savedSessionMusicId && isNumber(savedSessionMusicId)) {
        framesToPreload.push({
          id: 0,
          text: `[#bgm${savedSessionMusicId}]`,
        });
      }

      const images = await PreloaderUtils.preloadFrames(
        framesToPreload,
        project.pairs,
        project.options,
        (percentage) => {
          // TODO: this still updates the state after the timeout (check if issue or not)
          state.loadingPercentage = percentage;
        },
        abortController.current,
        10000,
        () => {
          append = false;
        },
      );

      state.initialized = !abortController.current.signal.aborted;

      if (abortController.current.signal.aborted) {
        state.loadingPercentage = 0;
      } else {
        // Only set preloadIndex once and to the correct target value
        state.preloadIndex = targetPreloadIndex;

        if (!append) {
          abortController.current.abort();
        } else {
          appendPreloadedImages(images);
        }

        if (playerMeta?.isRecording) {
          console.log('LOADED'); // for recording
        }
      }
    },
    [appendPreloadedImages, playerMeta?.isRecording, preloadAmount, state],
  );

  const preloadTarget = useCallback(
    async (
      project: SceneProjectDto | CaseProjectDto,
      framesTarget: FramesTarget,
      frameIndex: number,
    ) => {
      state.preloadIndex = frameIndex;

      await preloadFramesEffect(
        GameUtils.getTargetFrames(framesTarget, project) ?? [],
        project.pairs,
        project.options,
        frameIndex,
      );
    },
    [preloadFramesEffect, state],
  );

  const reset = useCallback(() => {
    clearObjectProperties(state);

    Object.assign(state, defaultState);

    abortController.current.abort();
    abortController.current = new AbortController();
  }, [state]);

  useEffect(() => {
    if (state.loadingPercentage < 100 || state.preloading) return;

    if (
      frameIndex >= state.preloadIndex - preloadAmount / 2 &&
      project &&
      framesTarget
    ) {
      state.preloading = true;

      preloadFramesEffect(
        GameUtils.getTargetFrames(framesTarget, project) ?? [],
        project.pairs,
        project.options,
        frameIndex,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frameIndex]);

  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: don't make multiple requests if having multiple players on the page
  useEffect(() => {
    if (presetsLoaded) return;

    assetActions.loadPresetAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingPercentage: snapshot.loadingPercentage,
    initialized: snapshot.initialized && presetsLoaded,
    preloadedImages: snapshot.preloadedImages,
    init,
    reset,
    preloadTarget,
    preloadFrames,
    preloadStuck,
  };
};
