import { AnyGroup, CaseFrame } from '@shared/types';
import { GameUtils } from '@web/components/player/utils/game-utils';

import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { FramesTarget } from '@web/types/project';
import { clearObjectProperties } from '@web/utils/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { GameState } from '../types/state';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

const defaultState: GameState = {
  frameIndex: -1,
};

export const useProjectPlayer = () => {
  const [id, setId] = useState<string>(generateId());
  const [project, setProject] = useState<
    SceneProjectDto | CaseProjectDto | undefined
  >(undefined);

  const state = useRef(proxy<GameState>(defaultState)).current;
  const snapshot = useProxy(state);

  usePlayerSaveLoadRegister<GameState>({
    name: 'projectPlayer',
    onSave: () => state,
    onLoad: (newState) => {
      clearObjectProperties(state);

      Object.assign(state, newState);
    },
  });

  const group = useMemo(
    () =>
      project?.groups.find((f) => f.id === snapshot.groupId) as
        | AnyGroup
        | undefined,
    [project?.groups, snapshot.groupId],
  );

  const targetFrames = useMemo(() => {
    if (!project) return [];

    return GameUtils.getTargetFrames(snapshot, project) ?? [];
  }, [project, snapshot]);

  const frame = targetFrames[snapshot.frameIndex ?? 0];

  // activeFrameIndex is the index of the frame ignoring the hidden frames
  const activeFrameIndex = useMemo(() => {
    if (!frame) return -1;

    let visibleIndex = -1;

    for (let i = 0; i < targetFrames.length; i++) {
      if (!targetFrames[i].hide) {
        visibleIndex++;
        if (targetFrames[i].id === frame.id) {
          return visibleIndex;
        }
      }
    }

    return -1;
  }, [frame, targetFrames]);

  const nextFrame = useCallback(() => {
    if (!project) return;

    let newState: ReturnType<typeof GameUtils.getNextFrames>;
    let gameState: GameState = state;

    do {
      newState = GameUtils.getNextFrames(1, project, gameState);
      gameState = newState.state;
    } while (newState.frames[0]?.hide && newState.frames.length > 0);

    // this fixes previousFrame being just stuck with `frame` value for unknown reason during skipping
    // maybe in the future also fix currentFrame (in usePlayer) if needed
    const currentFrame = GameUtils.getTargetFrames(state, project)?.[
      state.frameIndex
    ];

    if (currentFrame) {
      newState.state.previousFrame = { ...currentFrame };
    }

    GameUtils.clearState(state);

    Object.assign(state, newState.state);

    setId(generateId());
  }, [project, state]);

  const previousFrame = useCallback(() => {
    if (!project) return;

    const targetFrames = GameUtils.getTargetFrames(state, project);

    if (!targetFrames) return;

    let index = state.frameIndex;

    // algoithm copied from previous codebase

    const shortJump =
      index > 0 &&
      (targetFrames[index - 1].moveToNext || targetFrames[index - 1].hide);

    index--;

    while (index > 0) {
      const previousMerge =
        targetFrames[index - 1].mergeWithNext ||
        targetFrames[index - 1].moveToNext ||
        targetFrames[index - 1].hide;

      const currentMerge =
        targetFrames[index].mergeWithNext ||
        targetFrames[index].moveToNext ||
        targetFrames[index].hide;

      if (currentMerge && !previousMerge) {
        if (shortJump) index--;

        break;
      } else if (previousMerge) {
        index--;
      } else {
        break;
      }
    }

    state.frameIndex = index;
    state.previousFrame = targetFrames[state.frameIndex];

    setId(generateId());
  }, [project, state]);

  const resume = useCallback(() => {
    setId(generateId());
  }, []);

  const jumpToFrame = useCallback(
    (frameId: number) => {
      if (!project) return undefined;

      const newState = GameUtils.getFrameById(frameId, project);

      if (!newState) return undefined;

      newState.frameIndex--;

      GameUtils.clearState(state);

      Object.assign(state, newState);

      return newState;
    },
    [project, state],
  );

  const onFramePlayEnd = useCallback(
    (frame: CaseFrame) => {
      if (!project) return true;

      const newState = GameUtils.getNextFrames(1, project, snapshot);

      // don't end the game if we will jump (even if its not successful)
      const allowCaseActions = [4, 8, 9, 13, 14, 17];

      if (
        newState.frames.length <= 0 &&
        !newState.state.investigationMenu &&
        !newState.state.resumeConversations &&
        !newState.state.resumeExamine &&
        (!frame.caseAction || !allowCaseActions.includes(frame.caseAction.id))
      ) {
        state.isEnded = true;

        return true;
      }

      return false;
    },
    [project, snapshot, state],
  );

  const init = useCallback(
    (
      newProject: SceneProjectDto | CaseProjectDto,
      framesTarget?: FramesTarget,
      frameIndex?: number,
    ) => {
      setProject(newProject);

      if (!newProject || !framesTarget) {
        state.isEnded = true;

        return;
      }

      state.groupId = framesTarget.groupId;
      state.locationId = framesTarget.locationId;
      state.conversationId = framesTarget.conversationId;
      state.examineId = framesTarget.examineId;
      state.pressFrame = framesTarget.pressFrame;
      state.category = framesTarget.category;
      state.frameIndex = frameIndex ? frameIndex - 1 : -1;
      state.isEnded = false;
    },
    [state],
  );

  const update = useCallback(
    (newState: Partial<GameState>) => {
      Object.assign(state, newState);
    },
    [state],
  );

  const reset = useCallback(() => {
    clearObjectProperties(state);

    Object.assign(state, defaultState);

    setProject(undefined);
  }, [state]);

  usePlayerResetRegister({
    name: 'projectPlayer',
    onReset: reset,
  });

  const actions = useMemo(
    () => ({
      init,
      reset,
      nextFrame,
      previousFrame,
      resume,
      jumpToFrame,
      onFramePlayEnd,
      update,
    }),
    [
      init,
      reset,
      nextFrame,
      previousFrame,
      resume,
      jumpToFrame,
      onFramePlayEnd,
      update,
    ],
  );

  return {
    id,
    state,
    snapshot,
    frame,
    group,
    activeFrameIndex,
    project,
    actions,
  };
};

const generateId = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);
