import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid2,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import {
  CourtRecordEvidenceDto,
  CourtRecordEvidenceDtoTypeEnum,
} from '@web/api/api';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { Media } from '@web/components/common/Media';
import { Image } from '@web/components/common/ui/Image';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { useAssetStore } from '@web/store/assets/state';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { WithIdAndName } from '@web/types';
import { CSSProperties, memo, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';

const state = proxy({
  expanded: false as number | false,
});

export const CourtRecordEvidencesEditor = () => {
  const {
    caseProject: {
      courtRecord: { evidence },
    },
  } = useMakerStore();

  const handleAddEvidence = () => {
    makerStore.caseProject.courtRecord.evidence.push({
      id: uuidv4(),
      name: 'New Evidence',
      type: CourtRecordEvidenceDtoTypeEnum.Image,
      description: '',
      iconUrl: '',
      url: '',
      hide: false,
    });

    state.expanded = makerStore.caseProject.courtRecord.evidence.length - 1;
  };

  const handleDragEnd = useCallback(
    (
      sourceId: UniqueIdentifier,
      destinationId?: UniqueIdentifier | undefined,
    ) => {
      const oldIndex = makerStore.caseProject.courtRecord.evidence.findIndex(
        (i) => i.id === sourceId,
      );
      const newIndex = makerStore.caseProject.courtRecord.evidence.findIndex(
        (i) => i.id === destinationId,
      );

      makerStore.caseProject.courtRecord.evidence = arrayMove(
        makerStore.caseProject.courtRecord.evidence,
        oldIndex,
        newIndex,
      );
    },
    [],
  );

  return (
    <Stack spacing={2}>
      <Button
        variant="contained"
        color="info"
        onClick={handleAddEvidence}
        disabled={evidence.length >= 100}
        fullWidth
      >
        Add Evidence
      </Button>

      <Box>
        <SortableDndProvider items={evidence} onDragEnd={handleDragEnd}>
          {evidence.map((evidence, index) => (
            <EvidenceEditorRow
              key={evidence.id}
              evidence={evidence}
              index={index}
            />
          ))}
        </SortableDndProvider>
      </Box>
    </Stack>
  );
};

const EvidenceEditorRow = memo(
  ({
    evidence,
    index,
  }: {
    evidence: CourtRecordEvidenceDto;
    index: number;
  }) => {
    const { expanded } = useSnapshot(state);

    const handleExpand = (index: number) => {
      state.expanded = state.expanded === index ? false : index;
    };

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: evidence.id });

    const style: CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <Accordion
        ref={setNodeRef}
        style={style}
        expanded={index === expanded}
        onChange={() => handleExpand(index)}
        slotProps={{ transition: { unmountOnExit: true } }}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          {evidence.name}

          <Box flexGrow={1} />

          <Box
            display="flex"
            sx={{ touchAction: 'none' }}
            {...attributes}
            {...listeners}
          >
            <DragHandle sx={{ mx: 1, cursor: 'move' }} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <EvidenceEditorForm index={index} />
        </AccordionDetails>
      </Accordion>
    );
  },
);

const EvidenceEditorForm = ({ index }: { index: number }) => {
  const {
    caseProject: {
      courtRecord: { evidence: evidences },
    },
  } = useMakerStore();

  const evidence = evidences[index];

  const handleRemoveEvidence = () => {
    makerStore.caseProject.courtRecord.evidence.splice(index, 1);
  };

  return (
    <Stack spacing={2}>
      <ChooseEvidencePicker index={index} />

      <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 9 }}>
          <Stack spacing={2}>
            <EvidenceTypeOptions index={index} />

            <TextField
              label="Name"
              value={evidence.name}
              onChange={(e) =>
                (makerStore.caseProject.courtRecord.evidence[index].name =
                  e.target.value)
              }
              size="small"
              fullWidth
            />

            <TextField
              label="Icon Image URL"
              value={evidence.iconUrl}
              onChange={(e) =>
                (makerStore.caseProject.courtRecord.evidence[index].iconUrl =
                  e.target.value)
              }
              size="small"
              fullWidth
            />

            <TextField
              label={
                evidence.type === 'image'
                  ? 'Check Image URL'
                  : 'Check Video URL'
              }
              value={evidence.url}
              onChange={(e) =>
                (makerStore.caseProject.courtRecord.evidence[index].url =
                  e.target.value)
              }
              size="small"
              fullWidth
            />
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 3 }}>
          <EvidencePreview evidence={evidence} />
        </Grid2>
      </Grid2>

      <TextField
        label="Description"
        value={evidence.description}
        onChange={(e) =>
          (makerStore.caseProject.courtRecord.evidence[index].description =
            e.target.value)
        }
        size="small"
        rows={3}
        multiline
        fullWidth
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Checkbox
          label="Hide"
          value={evidence.hide}
          onChange={(value) =>
            (makerStore.caseProject.courtRecord.evidence[index].hide = value)
          }
        />

        <Button variant="text" color="error" onClick={handleRemoveEvidence}>
          Remove Evidence
        </Button>
      </Stack>
    </Stack>
  );
};

const EvidenceTypeOptions = ({ index }: { index: number }) => {
  const {
    caseProject: {
      courtRecord: { evidence: evidences },
    },
  } = useMakerStore();

  const evidence = evidences[index];

  const evidenceTypeOptions = useMemo<WithIdAndName<string>[]>(() => {
    return [
      { id: CourtRecordEvidenceDtoTypeEnum.Image, name: 'Image' },
      { id: CourtRecordEvidenceDtoTypeEnum.Video, name: 'Video' },
      // { value: CourtRecordEvidenceDtoTypeEnum.Embed, label: 'Embed' },
    ];
  }, []);

  const selectedEvidenceType = useMemo(
    () =>
      evidenceTypeOptions.find(
        (o) => o.id === evidence.type,
        [evidence.type],
      ) || null,
    [evidence.type, evidenceTypeOptions],
  );

  const handleChange = (value: WithIdAndName<string> | null) => {
    makerStore.caseProject.courtRecord.evidence[index].type = String(
      value?.id || 'image',
    ) as CourtRecordEvidenceDtoTypeEnum;
  };

  return (
    <SimpleSelect
      options={evidenceTypeOptions}
      value={selectedEvidenceType}
      onChange={handleChange}
      label="Type"
      size="small"
      fullWidth
    />
  );
};

const EvidencePreview = ({
  evidence,
}: {
  evidence: CourtRecordEvidenceDto;
}) => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  const isOpen = !!anchorEl;

  return (
    <Stack justifyContent="space-between" alignItems="center" height="100%">
      {evidence.iconUrl ? (
        <Image
          src={evidence.iconUrl}
          style={{ width: '100%', maxHeight: 100, objectFit: 'contain' }}
        />
      ) : (
        <Box />
      )}

      <Button
        variant="contained"
        color="info"
        size="small"
        disabled={!evidence.url}
        onClick={toggle}
        fullWidth
      >
        Preview
      </Button>

      <Popper
        anchorEl={anchorEl}
        open={isOpen}
        sx={{ zIndex: zIndex.modal }}
        placement="auto"
        onClose={close}
      >
        <Box
          style={{
            maxWidth: '80vw',
          }}
        >
          <Media
            url={evidence.url}
            type={evidence.type}
            style={
              evidence.type === 'image'
                ? {
                    width: '100%',
                    cursor: 'pointer',
                  }
                : { width: '100%', height: 300 }
            }
            videoPlay={isOpen}
            onClick={close}
          />
        </Box>
      </Popper>
    </Stack>
  );
};

const ChooseEvidencePicker = ({ index }: { index: number }) => {
  const {
    evidence: { user },
  } = useAssetStore();

  const sortedOptions = useMemo(() => {
    return [...user].sort((a, b) => a.name.localeCompare(b.name));
  }, [user]);

  const setFromUserEvidence = useCallback(
    (evidenceIndex: number) => {
      makerStore.caseProject.courtRecord.evidence[index] = {
        ...makerStore.caseProject.courtRecord.evidence[index],
        name: sortedOptions[evidenceIndex].name,
        type: 'image',
        ...(sortedOptions[evidenceIndex].isIcon
          ? { iconUrl: sortedOptions[evidenceIndex].url }
          : { url: sortedOptions[evidenceIndex].url }),
      };
    },
    [index, sortedOptions],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setFromUserEvidence(Number(event.target.value));
    },
    [setFromUserEvidence],
  );

  return (
    <Select
      value=""
      displayEmpty
      onChange={handleChange}
      renderValue={() => <em>Choose</em>}
      variant="standard"
      size="small"
      disableUnderline
    >
      <MenuItem disabled>
        <Typography variant="caption">
          Choosing an item will overwrite your evidence details
        </Typography>
      </MenuItem>

      {sortedOptions.map((evd, index) => (
        <MenuItem key={evd.id} value={index}>
          {evd.name}
        </MenuItem>
      ))}
    </Select>
  );
};
