import { PlayerDefaults } from '../types/playerDefaults';

const PAIRS_AMOUNT = 5;

export enum ZIndex {
  Background = 0,
  Character = 1,
  Desk = 2 + PAIRS_AMOUNT,
  Evidence = 3 + PAIRS_AMOUNT,
  DialogueBox = 4 + PAIRS_AMOUNT,
  SpeechBubble = 5 + PAIRS_AMOUNT,
  NextButton = 10 + PAIRS_AMOUNT,
  CaseInput = 11 + PAIRS_AMOUNT,
  CaseVideo = 12 + PAIRS_AMOUNT,
}

export const defaultPlayerDefaults: PlayerDefaults = Object.freeze({
  chatbox: '0',
  textSpeed: 35,
  defaultTextSpeed: 35,
  textBlipFrequency: 64,
  speechBubbleRepeat: true,
  autoplaySpeed: 500,
  enableSkipping: true,
});

export const DefaultDialogueTextColor = '#FFFFFF';
export const DefaultDialogueRedTextColor = '#F77337';
export const DefaultDialogueGreenTextColor = '#00F61C';
export const DefaultDialogueBlueTextColor = '#6BC7F6';
