import { Button, Card } from '@mui/material';
import { xSmallButtonStyle } from '@web/components/common/ui/Buttons';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useMakerButtonSizes } from '../hooks/useMakerButtonSizes';

type InvestigationPopperProps = {
  label: string;
  width?: number;
  children: (props: { close: () => void }) => React.ReactNode;
  onExit?: () => void;
};

export const InvestigationPopper = ({
  label,
  width,
  onExit,
  children,
}: InvestigationPopperProps) => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  const { small, xSmall } = useMakerButtonSizes();

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        style={{ borderRadius: 0, ...(xSmall ? xSmallButtonStyle : {}) }}
        onClick={toggle}
        size={small ? 'small' : 'medium'}
      >
        {label}
      </Button>

      <Popper
        anchorEl={anchorEl}
        onClose={close}
        onExited={onExit}
        open={!!anchorEl}
        popperOptions={{ placement: 'bottom-start' }}
        keepMounted={false}
        disablePortal
      >
        <Card
          sx={{
            maxWidth: '95vw',
            minWidth: 300,
            width: width || 400,
            boxShadow: 3,
          }}
        >
          {children({ close })}
        </Card>
      </Popper>
    </>
  );
};
