import { Grid2, Stack, TextField } from '@mui/material';
import { CaseAction_EvaluateVariable } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { useCaseActionInput, VariablePicker } from '../CaseActionInputs';

export const EvaluateVariableInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_EvaluateVariable>();

  const typeOptions = [
    { id: 'equals', name: 'Equals' },
    { id: 'notEquals', name: 'Not Equals' },
    { id: 'greaterThan', name: 'Greater Than' },
    { id: 'lessThan', name: 'Less Than' },
  ];

  const selectedType =
    typeOptions.find((o) => o.id === caseAction.type) || null;

  return (
    <Stack spacing={2}>
      <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 6, sm: 4 }}>
          <VariablePicker
            value={caseAction.name || null}
            onChange={(value) => updateCaseAction({ name: value || undefined })}
          />
        </Grid2>

        <Grid2 size={{ xs: 6, sm: 4 }}>
          <SimpleSelect
            label=" "
            options={typeOptions}
            value={selectedType}
            onChange={(value) =>
              updateCaseAction({
                type: value?.id as
                  | 'equals'
                  | 'notEquals'
                  | 'greaterThan'
                  | 'lessThan',
              })
            }
            size="small"
            variant="standard"
            fullWidth
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            label="Value"
            value={caseAction.value || ''}
            onChange={(event) =>
              updateCaseAction({ value: event.target.value })
            }
            size="small"
            variant="standard"
            fullWidth
          />
        </Grid2>
      </Grid2>

      <Stack direction="row" spacing={1}>
        <NumberTextField
          label="True Frame ID"
          value={caseAction.trueFrameId || ''}
          onChange={(event) =>
            updateCaseAction({ trueFrameId: parseInt(event.target.value) })
          }
          size="small"
          variant="standard"
          fullWidth
        />

        <NumberTextField
          label="False Frame ID"
          value={caseAction.falseFrameId || ''}
          onChange={(event) =>
            updateCaseAction({ falseFrameId: parseInt(event.target.value) })
          }
          size="small"
          variant="standard"
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
