export default Object.freeze([
  {
    id: 2,
    text: 'Hide a piece of evidence',
    input: 'evidence',
    hide: true,
    group: 'Court Record',
  },
  {
    id: 16,
    text: 'Toggle evidence visibility',
    input: 'toggleEvidence',
    group: 'Court Record',
  },
  {
    id: 3,
    text: 'Toggle frame(s) visibility',
    input: 'toggleFrames',
    group: 'Game Flow',
  },
  {
    id: 4,
    text: 'Jump to a frame',
    input: 'frame',
    group: 'Game Flow',
  },
  {
    id: 15,
    text: 'Set the game over redirection',
    input: 'gameOver',
    group: 'Game Flow',
  },
  {
    id: 5,
    text: 'End the game',
    group: 'Game Flow',
  },
  {
    id: 6,
    text: 'Modify health points',
    input: 'health',
    group: "Player's Health",
  },
  {
    id: 7,
    text: 'Set flashing health points',
    input: 'number',
    group: "Player's Health",
  },
  {
    id: 8,
    text: 'Ask the player to present evidence',
    input: 'playerEvidence',
    group: 'Player Input',
  },
  {
    id: 9,
    text: 'Ask the player to choose an answer',
    input: 'playerChoice',
    group: 'Player Input',
  },
  {
    id: 12,
    text: 'Ask the player to input a value into a variable',
    input: 'setInputVariable',
    group: 'Player Input',
  },
  {
    id: 17,
    text: 'Ask the player to point to an area',
    input: 'pointArea',
    group: 'Player Input',
  },
  {
    id: 10,
    text: 'Set a variable',
    input: 'setVariable',
    group: 'Variables',
  },
  {
    id: 11,
    text: 'Increment a numerical variable',
    input: 'incrementVariable',
    group: 'Variables',
  },
  {
    id: 13,
    text: 'Evaluate a variable',
    input: 'evalVariable',
    group: 'Variables',
  },
  {
    id: 14,
    text: 'Evaluate an expression (advanced)',
    input: 'evalExpression',
    group: 'Variables',
  },
  {
    id: 22,
    text: 'Play a video',
    input: 'text',
    placeholder: 'Video direct URL',
    group: 'Media',
  },
  {
    id: 18,
    text: 'Toggle visibility of investigation elements',
    input: 'caseElementsVisibility',
    group: 'Investigation',
  },
  {
    id: 19,
    text: 'Mark investigation elements as unvisited',
    input: 'markUnvisited',
    group: 'Investigation',
  },
  {
    id: 20,
    text: 'Swap talk conversations',
    input: 'swapTalk',
    group: 'Investigation',
  },
  {
    id: 21,
    text: 'Configure completion conditions for a location',
    input: 'locationCompletion',
    group: 'Investigation',
  },
  {
    id: 1,
    text: 'Reveal a piece of evidence',
    input: 'evidence',
    hide: true,
    group: 'Court Record',
  },
]);
