import { Button, Stack } from '@mui/material';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import {
  isCrossExaminationGroup,
  isInvestigationGroup,
} from '@web/store/maker/types';
import { memo, useMemo } from 'react';
import { BackMenu } from '../common/BackMenu';
import { xSmallButtonStyle } from '../common/ui/Buttons';
import { GameCategory } from '../player/types/state';
import { GameUtils } from '../player/utils/game-utils';
import { CrossExaminationCategoryPicker } from './case/CrossExaminationCategoryPicker';
import { GroupsEditor } from './case/GroupsEditor';
import { InvestigationLocationEditor } from './case/InvestigationLocationEditor';
import { LocationsEditor } from './case/LocationsEditor';
import { ContradictionsDialog } from './dialogs/ContradictionsDialog';
import { CourtRecordDialog } from './dialogs/CourtRecordDialog';
import { useMakerButtonSizes } from './hooks/useMakerButtonSizes';
import { FrameTextUtils } from './utils/frameText';

export const MakerCaseMenu = () => {
  const {
    project: { type },
  } = useMakerStore();

  if (type !== 'case') return null;

  return (
    <>
      <Stack spacing={1}>
        <MainAndCrossExaminationEditors />

        <InvestigationLocationEditor />

        <BackAppBar />
      </Stack>

      <CaseMakerDialogs />
    </>
  );
};

const MainAndCrossExaminationEditors = memo(() => {
  const {
    group,
    framesTarget: { pressFrame },
  } = useMakerStore();

  const handleOpenCourtRecord = () => {
    makerStore.dialogs.courtRecord = true;
  };

  const { small, xSmall, isNotFullWidth } = useMakerButtonSizes();

  return (
    <Stack
      direction="row"
      spacing={{ xs: 1, sm: 2 }}
      justifyContent="space-between"
      px={isNotFullWidth ? 0.5 : 0}
    >
      <Stack direction="row" spacing={1}>
        <GroupsEditor />

        <Button
          color="secondary"
          variant="contained"
          size={small ? 'small' : 'medium'}
          style={{ borderRadius: 0, ...(xSmall ? xSmallButtonStyle : {}) }}
          onClick={handleOpenCourtRecord}
        >
          Court Record
        </Button>
      </Stack>

      {isInvestigationGroup(group) && <LocationsEditor />}

      {isCrossExaminationGroup(group) && !pressFrame && (
        <CrossExaminationCategoryPicker />
      )}
    </Stack>
  );
});

const CaseMakerDialogs = memo(() => {
  return (
    <>
      <CourtRecordDialog />
      <ContradictionsDialog />
    </>
  );
});

const BackAppBar = memo(() => {
  const {
    framesTarget: { pressFrame, category, conversationId, examineId },
  } = useMakerStore();

  const text = useMemo(() => {
    const { pressFrame, ...target } = makerStore.framesTarget;
    const array = GameUtils.getTargetFrames(target, makerStore.project);
    const frame = array?.find((f) => f.id === pressFrame);

    const textPressFrame = frame
      ? `Press "${FrameTextUtils.getPlainText(frame.text)}"`
      : '';

    const textCategory =
      category === GameCategory.InvestigationCompletion
        ? 'Location Completed Conversation'
        : category === GameCategory.InvestigationPresentFailure
          ? 'Present Anything Else'
          : category === GameCategory.InvestigationExamineFailure
            ? 'Examine Anything Else'
            : '';

    const textConversation = conversationId
      ? `Conversation "${makerStore.location?.conversations.find((f) => f.id === conversationId)?.name}"`
      : '';

    const textExamine = examineId
      ? `Examine "${makerStore.location?.examine.find((f) => f.id === examineId)?.name}"`
      : '';

    return textPressFrame || textCategory || textConversation || textExamine;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressFrame, category, conversationId, examineId]);

  if (!text) return null;

  const handleBack = () => {
    makerStore.framesTarget.pressFrame = undefined;
    makerStore.framesTarget.category = undefined;
    makerStore.framesTarget.conversationId = undefined;
    makerStore.framesTarget.examineId = undefined;
    makerStore.page = 1;
  };

  return <BackMenu text={text} onClick={handleBack} />;
});
