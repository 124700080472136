import { AnyGroup, CaseFrame, GroupType, Location } from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import easings from '@web/data/easings';
import {
  isCrossExaminationGroup,
  isInvestigationGroup,
} from '@web/store/maker/types';
import { ExaminePosition } from '@web/types/project';
import { clearObjectProperties, pointWithinArea } from '@web/utils/utils';
import { enqueueSnackbar } from 'notistack';
import { RefObject, useCallback, useMemo, useRef } from 'react';
import evaluate from 'simple-evaluate';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { usePlayerResetRegister } from '../../hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from '../../hooks/usePlayerSaveLoadRegister';
import { useProjectPlayer } from '../../hooks/useProjectPlayer';
import { usePlayer } from '../../providers/PlayerProvider';
import {
  CaseState,
  EvidenceRecord,
  HiddenVisited,
  LocationState,
} from '../../types/playerCase';

const defaultState: CaseState = {
  investigation: {
    locationState: {},
  },
  health: 100,
  healthFlashing: 0,
  courtRecord: { evidence: [], profiles: [] },
  cursorPosition: { x: 0, y: 0 },
  messagesLog: [],
};

export const usePlayerCase = (
  frame: CaseFrame | undefined,
  group: AnyGroup | undefined,
  playerState: ReturnType<typeof usePlayer>['state'],
  projectPlayerState: ReturnType<typeof useProjectPlayer>['state'],
  projectPlayerSnapshot: ReturnType<typeof useProjectPlayer>['snapshot'],
  projectPlayerActions: ReturnType<typeof useProjectPlayer>['actions'],
  playerContainerRef: RefObject<HTMLDivElement>,
  playerViewportRef: RefObject<HTMLDivElement>,
) => {
  const state = useRef<CaseState>(proxy(defaultState)).current;
  const ranCaseActions = useRef<number[]>([]).current;
  const variables = useRef<Record<string, string | number>>({}).current;

  const snapshot = useProxy(state);

  const { locationId, category, pressFrame } = projectPlayerSnapshot;

  usePlayerResetRegister({
    name: 'case',
    onReset: () => {
      clearObjectProperties(state);
      clearObjectProperties(variables);

      Object.assign(state, structuredClone(defaultState));

      ranCaseActions.length = 0;
    },
  });

  usePlayerSaveLoadRegister({
    name: 'case',
    onSave: () => {
      return {
        state: {
          ...state,
          showCourtRecord: false,
          showMenu: false,
          showMove: false,
          showPresent: false,
        } satisfies CaseState,
        variables,
        ranCaseActions,
      };
    },
    onLoad: (data) => {
      clearObjectProperties(state);
      clearObjectProperties(variables);

      Object.assign(state, data.state);
      Object.assign(variables, data.variables);

      ranCaseActions.length = 0;
      ranCaseActions.push(...data.ranCaseActions);
    },
  });

  const location = useMemo(
    () =>
      group && isInvestigationGroup(group) && locationId
        ? group.locations.find((f) => f.id === locationId)
        : undefined,
    [group, locationId],
  );

  const locationState = location
    ? snapshot.investigation.locationState[location.id]
    : undefined;

  const isCrossExaminationStatements = useMemo(
    () => group && isCrossExaminationGroup(group) && !category && !pressFrame,
    [group, category, pressFrame],
  );

  const hasPress = useMemo(
    () =>
      group &&
      frame &&
      isCrossExaminationStatements &&
      isCrossExaminationGroup(group) &&
      group.pressFrames[frame.id]?.length > 0,
    [frame, group, isCrossExaminationStatements],
  );

  const examines = useMemo(
    () =>
      location?.examine.filter((f) => !locationState?.examines[f.id].hidden) ||
      [],
    [location?.examine, locationState?.examines],
  );

  const conversations = useMemo(
    () =>
      location?.conversations.filter(
        (f) => !locationState?.conversations[f.id].hidden,
      ) || [],
    [location?.conversations, locationState?.conversations],
  );

  const locations = useMemo(
    () =>
      isInvestigationGroup(group) && locationState
        ? group.locations.filter(
            (f) => !snapshot.investigation.locationState[f.id].hidden,
          )
        : [],
    [group, locationState, snapshot.investigation.locationState],
  );

  const init = useCallback(
    (project: SceneProjectDto | CaseProjectDto) => {
      if (project.type !== 'case') {
        return;
      }

      state.gameoverGroupId = project.groups.find(
        (f) => f.type === GroupType.Gameover,
      )?.id;

      state.investigation.locationState = project.groups
        .filter((f) => isInvestigationGroup(f))
        .flatMap((m) => m.locations)
        .reduce<Record<string, LocationState>>((acc, location) => {
          acc[location.id] = {
            hidden: location.hide,
            visited: false,
            conversations: location.conversations.reduce<
              Record<string, HiddenVisited>
            >((acc, conversation) => {
              acc[conversation.id] = {
                hidden: conversation.hide,
                visited: false,
              };
              return acc;
            }, {}),
            examines: location.examine.reduce<Record<string, HiddenVisited>>(
              (acc, examine) => {
                acc[examine.id] = {
                  hidden: examine.hide,
                  visited: false,
                };
                return acc;
              },
              {},
            ),
            conversationsIdsOrder: location.conversations.map((f) => f.id),
          };

          return acc;
        }, {});

      state.courtRecord = project.courtRecord;
    },
    [state],
  );

  const addMessageLog = useCallback(
    (username: string, message: string, characterId?: number) => {
      state.messagesLog.push({ username, message, characterId });

      if (state.messagesLog.length > 100) {
        state.messagesLog.shift();
      }
    },
    [state.messagesLog],
  );

  const onUserInput = useCallback(
    async <T extends keyof UserInputMapping>(
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
      value: UserInputMapping[T],
    ) => {
      if (!state.userInput) return;

      switch (state.userInput.id) {
        case 8: {
          const evidenceRecord = value as UserInputMapping[8];
          const found = state.userInput.items?.find(
            (f) => f.evidence?.evidenceId === evidenceRecord.id,
          );

          const newState = found?.frameId
            ? projectPlayerActions.jumpToFrame(found.frameId)
            : state.userInput.incorrectFrameId
              ? projectPlayerActions.jumpToFrame(
                  state.userInput.incorrectFrameId,
                )
              : undefined;

          state.userInput = undefined;

          preloadThenNext(newState);

          break;
        }
        case 9: {
          const answer = value as UserInputMapping[9];
          const found = state.userInput.answers?.find((f) => f.text === answer);

          const newState = found?.frameId
            ? projectPlayerActions.jumpToFrame(found.frameId)
            : undefined;

          state.userInput = undefined;

          preloadThenNext(newState);

          break;
        }
        case 12: {
          if (!state.userInput.name) break;

          const answer = value as UserInputMapping[12];

          variables[state.userInput.name] = answer;

          state.userInput = undefined;

          preloadThenNext(undefined);

          break;
        }
        case 17: {
          if (!state.userInput?.areas) break;

          const position = value as UserInputMapping[17];
          const foundArea = state.userInput.areas.find(
            (f) => f.shape && pointWithinArea(position, f.shape),
          );

          const newState = foundArea?.frameId
            ? projectPlayerActions.jumpToFrame(foundArea.frameId)
            : state.userInput.incorrectFrameId
              ? projectPlayerActions.jumpToFrame(
                  state.userInput.incorrectFrameId,
                )
              : undefined;

          state.userInput = undefined;

          preloadThenNext(newState);

          break;
        }
        default:
          break;
      }
    },
    [projectPlayerActions, state, variables],
  );

  const onPresent = useCallback(
    (
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
      evidence: EvidenceRecord,
    ) => {
      if (group && isCrossExaminationGroup(group) && frame) {
        state.showPresent = false;

        const found = (group.contradictions[frame.id] || []).find(
          (f) => f.evidenceId === evidence.id,
        );

        const newState = found
          ? found.frameId
            ? projectPlayerActions.jumpToFrame(found.frameId)
            : undefined
          : group.failureFrames?.length > 0
            ? projectPlayerActions.jumpToFrame(group.failureFrames[0].id)
            : undefined;

        preloadThenNext(newState);
      } else if (location) {
        state.showPresent = false;
        state.showConversations = false;

        const found = location.conversations.find((f) =>
          f.present?.find((f) => f.evidenceId === evidence.id),
        );

        const newState = found
          ? found.frames.length > 0
            ? projectPlayerActions.jumpToFrame(found.frames[0].id)
            : undefined
          : location.presentFailureFrames.length > 0
            ? projectPlayerActions.jumpToFrame(
                location.presentFailureFrames[0].id,
              )
            : undefined;

        preloadThenNext(newState);
      }
    },
    [frame, group, location, projectPlayerActions, state],
  );

  const onPress = useCallback(
    (
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
    ) => {
      if (!hasPress || !isCrossExaminationGroup(group) || !frame) return;

      const found = group.pressFrames[frame.id];

      if (!found || found.length === 0) return;

      const newState = projectPlayerActions.jumpToFrame(found[0].id);

      if (newState) {
        newState.pressIndex = group.frames.findIndex((f) => f.id === frame.id);
      }

      preloadThenNext(newState);

      state.showPresent = false;
    },
    [frame, group, hasPress, projectPlayerActions, state],
  );

  const onExamine = useCallback(
    (
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
      position: ExaminePosition,
    ) => {
      if (!location || !state.showExamine) return;

      const found = location.examine.find(
        (f) =>
          !locationState?.examines[f.id].hidden &&
          pointWithinArea(position, f.shape),
      );

      if (!found && location.examineFailureFrames.length === 0) return;

      const newState = found
        ? found.frames.length > 0
          ? projectPlayerActions.jumpToFrame(found.frames[0].id)
          : undefined
        : location.examineFailureFrames.length > 0
          ? projectPlayerActions.jumpToFrame(
              location.examineFailureFrames[0].id,
            )
          : undefined;

      if (!newState) return;

      if (found) {
        state.investigation.locationState[location.id].examines[
          found.id
        ].visited = true;
      }

      projectPlayerActions.update({ investigationMenu: false });

      state.showExamine = false;

      preloadThenNext(newState);
    },
    [location, locationState?.examines, projectPlayerActions, state],
  );

  const onConversation = useCallback(
    (
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
      conversationId: string,
    ) => {
      if (!location || !state.showConversations) return;

      const conversation = location.conversations.find(
        (f) => f.id === conversationId,
      );

      if (!conversation) return;

      state.investigation.locationState[location.id].conversations[
        conversation.id
      ].visited = true;

      const newState =
        conversation.frames.length > 0
          ? projectPlayerActions.jumpToFrame(conversation.frames[0].id)
          : undefined;

      projectPlayerActions.update({ investigationMenu: false });

      preloadThenNext(newState);
    },
    [location, projectPlayerActions, state],
  );

  const onMove = useCallback(
    async (
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
      preloadFramesThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadFramesThenNext'],
      locationId: string,
    ) => {
      if (!group || !isInvestigationGroup(group)) return;

      const location = group.locations.find((f) => f.id === locationId);

      if (!location) return;

      // when moving to new location, reset background position
      playerState.backgroundLeft = 0;

      projectPlayerActions.update({ investigationMenu: false });

      const visited = state.investigation.locationState[location.id].visited;
      const lastFrame =
        state.investigation.locationState[location.id].lastFrame;

      const fadeTime = 800;

      state.previousCaseFrame = {
        id: 0,
        text: '',
        fade: {
          type: 'fadeOutIn',
          duration: fadeTime,
          target: 'everything',
          color: '#000000',
        },
      };

      if (playerState.evidence) {
        setTimeout(() => {
          playerState.evidence = undefined;
        }, fadeTime / 2);
      }

      state.loading = true;

      if (!visited) {
        const newState =
          location.frames.length > 0
            ? projectPlayerActions.jumpToFrame(location.frames[0].id)
            : undefined;

        await preloadThenNext(newState);
      } else if (lastFrame) {
        const newState = projectPlayerActions.jumpToFrame(lastFrame.id);

        projectPlayerActions.update(newState || {});

        state.caseFrame = { ...lastFrame };

        await preloadFramesThenNext([state.caseFrame]);
      } else {
        await preloadThenNext(undefined);
      }

      state.loading = false;
    },
    [group, playerState, projectPlayerActions, state],
  );

  const resumeConversations = useCallback(() => {
    if (!location) return;

    projectPlayerState.resumeConversations = false;

    projectPlayerActions.update({ investigationMenu: true });
  }, [location, projectPlayerActions, projectPlayerState]);

  const startExamine = useCallback(
    async (
      preloadFramesThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadFramesThenNext'],
    ) => {
      if (!location) return;

      projectPlayerState.investigationMenu = false;

      state.loading = true;

      state.showConversations = false;
      state.showPresent = false;

      playerState.evidence = undefined;

      const invalidLeft =
        playerState.backgroundLeft !== undefined &&
        playerState.backgroundLeft > 0 &&
        playerState.backgroundLeft < 99.98;

      state.caseFrame = {
        id: 0,
        text: '',
        backgroundId: location?.backgroundId,
        transition: invalidLeft
          ? {
              left: 0,
              duration: 600,
              easing: easings[4].id!,
            }
          : undefined,
      };

      if (invalidLeft) {
        // because usePlayer ignores frame with id 0
        playerState.backgroundLeft = 0;
      }

      await preloadFramesThenNext([state.caseFrame]);

      state.showExamine = true;

      state.loading = false;
    },
    [location, playerState, projectPlayerState, state],
  );

  const resumeExamine = useCallback(() => {
    state.showExamine = true;
    state.caseFrame = { id: 0, text: '', backgroundId: location?.backgroundId };

    projectPlayerState.resumeExamine = false;

    projectPlayerActions.resume();
  }, [location?.backgroundId, projectPlayerActions, projectPlayerState, state]);

  const centerCursor = useCallback(() => {
    const containerRect = playerContainerRef.current?.getBoundingClientRect();
    const viewportRect = playerViewportRef.current?.getBoundingClientRect();

    if (!containerRect || !viewportRect) return;

    const percentageX =
      ((containerRect.left + containerRect.width / 2 - viewportRect.left) /
        viewportRect.width) *
      100;
    const percentageY =
      ((containerRect.top + containerRect.height / 2 - viewportRect.top) /
        viewportRect.height) *
      100;

    state.cursorPosition = { x: percentageX, y: percentageY };
  }, [playerContainerRef, playerViewportRef, state]);

  const saveLocationState = useCallback(() => {
    if (!location || !frame) return;

    let musicTag = '[#bgms]';

    if (location.frames.length > 0) {
      for (let i = location.frames.length - 1; i >= 0; i--) {
        const mCommand = FrameTextUtils.getMusicTagCommands(
          location.frames[i].text,
        );

        if (mCommand.length > 0) {
          const tag = mCommand[mCommand.length - 1];

          musicTag = `[#${tag}]`;

          break;
        }
      }
    }

    const {
      actions,
      caseAction,
      customName,
      fade,
      mergeWithNext,
      moveToNext,
      speechBubble,
      doNotTalk,
      ...restFrame
    } = frame || {};

    state.investigation.locationState[location.id] = {
      ...state.investigation.locationState[location.id],
      visited: true,
      lastFrame: {
        ...restFrame,
        text: musicTag,
        noPoseAnimation: true,
      },
    };
  }, [frame, location, state.investigation.locationState]);

  const checkLocationCompletion = useCallback(
    (
      targetLocation: Location | undefined = location,
      project: SceneProjectDto | CaseProjectDto,
      preloadThenNext: ReturnType<
        typeof usePlayer
      >['actions']['preloadThenNext'],
    ) => {
      if (!targetLocation) return false;

      const locationState =
        state.investigation.locationState[targetLocation.id];

      if (
        !locationState ||
        !locationState.completion ||
        targetLocation.completionFrames.length === 0
      )
        return false;

      const isCompleted = locationState.completion.required.every(
        (condition) => {
          if (condition.type === 'expression') {
            try {
              return evaluate(variables, condition.expression);
            } catch (e) {
              enqueueSnackbar(
                'Error evaluating expression in completion conditions',
                {
                  variant: 'error',
                  preventDuplicate: true,
                },
              );

              return false;
            }
          } else if (condition.type === 'location') {
            return condition.items.every((item) => {
              const group = project.groups.find((f) => f.id === item.groupId);

              if (!group || !isInvestigationGroup(group)) return false;

              return checkLocationCompletion(
                group.locations.find((f) => f.id === item.id),
                project,
                preloadThenNext,
              );
            });
          } else if (condition.type === 'conversation') {
            return condition.items.every((item) => {
              const group = project.groups.find((f) => f.id === item.groupId);

              if (!group || !isInvestigationGroup(group)) return false;

              return state.investigation.locationState[item.locationId]
                .conversations[item.id].visited;
            });
          } else if (condition.type === 'examine') {
            return condition.items.every((item) => {
              const group = project.groups.find((f) => f.id === item.groupId);

              if (!group || !isInvestigationGroup(group)) return false;

              return state.investigation.locationState[item.locationId]
                .examines[item.id].visited;
            });
          } else {
            return false;
          }
        },
      );

      if (!isCompleted) return false;

      if (locationState.completion.fade) {
        state.previousCaseFrame = {
          id: 0,
          text: '',
          fade: {
            type: 'fadeOutIn',
            duration: 2250,
            target: 'everything',
            color: '#000000',
            easing: easings[4].id!,
          },
        };
      }

      if (locationState.completion.autoHideConversations) {
        Object.values(locationState.conversations).forEach((conversation) => {
          conversation.hidden = true;
        });
      }

      if (locationState.completion.autoHideExamine) {
        Object.values(locationState.examines).forEach((examine) => {
          examine.hidden = true;
        });
      }

      if (locationState.lastFrame) {
        locationState.lastFrame = {
          ...locationState.lastFrame,
          characterId: undefined,
          poseId: undefined,
          pair: undefined,
          text: '[#bgms]',
        };
      }

      locationState.completion = undefined;

      state.showExamine = false;
      state.showConversations = false;
      state.showPresent = false;

      projectPlayerActions.update({ investigationMenu: false });

      const newState = projectPlayerActions.jumpToFrame(
        targetLocation.completionFrames[0].id,
      );

      preloadThenNext(newState);
    },
    [location, projectPlayerActions, state, variables],
  );

  const onFramePlayStart = useCallback(() => {
    if (state.caseFrame) return false;

    if (projectPlayerState.investigationMenu) {
      return true;
    }

    if (projectPlayerState.resumeConversations) {
      resumeConversations();

      return true;
    }

    if (projectPlayerState.resumeExamine) {
      resumeExamine();

      return true;
    }

    return false;
  }, [
    projectPlayerState.investigationMenu,
    projectPlayerState.resumeConversations,
    projectPlayerState.resumeExamine,
    resumeConversations,
    resumeExamine,
    state.caseFrame,
  ]);

  const onFramePlayEnd = useCallback(
    (nameplate?: string, plainText?: string, characterId?: number) => {
      if (nameplate && plainText && !isCrossExaminationStatements) {
        addMessageLog(nameplate, plainText, characterId);
      }

      if (state.caseFrame) {
        state.caseFrame = undefined;
      }

      if (state.previousCaseFrame) {
        state.previousCaseFrame = undefined;
      }

      // last frame in introduction frame of location
      if (
        location &&
        !projectPlayerState.conversationId &&
        !projectPlayerState.examineId &&
        !projectPlayerState.investigationMenu &&
        !projectPlayerState.resumeExamine &&
        !projectPlayerState.category &&
        !state.investigation.locationState[location.id].visited &&
        projectPlayerState.frameIndex ===
          location.frames.filter((f) => !f.hide).length - 1
      ) {
        saveLocationState();
      }
    },
    [
      addMessageLog,
      isCrossExaminationStatements,
      location,
      projectPlayerState.category,
      projectPlayerState.conversationId,
      projectPlayerState.examineId,
      projectPlayerState.frameIndex,
      projectPlayerState.investigationMenu,
      projectPlayerState.resumeExamine,
      saveLocationState,
      state,
    ],
  );

  const memoizedActions = useMemo(
    () => ({
      init,
      onUserInput,
      onPresent,
      onPress,
      onExamine,
      onConversation,
      onMove,
      startExamine,
      resumeExamine,
      onFramePlayStart,
      onFramePlayEnd,
      centerCursor,
      checkLocationCompletion,
      addMessageLog,
    }),
    [
      init,
      onUserInput,
      onPresent,
      onPress,
      onExamine,
      onConversation,
      onMove,
      startExamine,
      resumeExamine,
      onFramePlayStart,
      onFramePlayEnd,
      centerCursor,
      checkLocationCompletion,
      addMessageLog,
    ],
  );

  return {
    state: snapshot,
    actions: memoizedActions,
    variables,
    ranCaseActions,
    isCrossExaminationStatements,
    hasPress,
    location,
    locationState,
    examines,
    conversations,
    locations,
  };
};

type UserInputMapping = {
  8: EvidenceRecord;
  9: string;
  12: string | number;
  17: { x: number; y: number };
};
