import {
  CaseAction_HideEvidence,
  CaseAction_ShowEvidence,
} from '@shared/types/case-action';
import { useCaseActionInput } from '../CaseActionInputs';
import { CourtRecordEvidencePicker } from '../CourtRecordEvidencePicker';

export const EvidenceInput = () => {
  const { caseAction, updateCaseAction } = useCaseActionInput<
    CaseAction_ShowEvidence | CaseAction_HideEvidence
  >();

  return (
    <CourtRecordEvidencePicker
      value={caseAction.evidence?.evidenceId || null}
      onChange={(value) => updateCaseAction({ evidence: value || undefined })}
    />
  );
};
