import { Mms } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { EvidenceDto } from '@web/api/api';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { assetStore } from '@web/store/assets/state';
import { rootStore } from '@web/store/root/state';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';
import { EvidencePicker } from '../ui/EvidencePicker';

type EvidenceTagProps = {
  width: number;
};

export const EvidenceTag = ({ width }: EvidenceTagProps) => {
  const [value, setValue] = useState<number | null>(null);

  const { anchorEl, toggle, close } = useAnchorMenu();
  const { insertTag } = useFrameEditor();

  const handleChangeValue = (newValue: number | null) => {
    if (!newValue) {
      setValue(null);
    }

    setValue(newValue);
  };

  const snapshot = useSnapshot<EvidenceDto[]>(assetStore.evidence.user);

  const handleManageClick = () => {
    rootStore.assetsDialogTab = 'evidence';
    rootStore.dialogs.assets = true;

    close();
  };

  useEffect(() => {
    if (!value) {
      setValue(snapshot[0]?.id || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot]);

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <Mms />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 320 }}
        placement="top"
      >
        <Stack p={1} spacing={1}>
          <EvidencePicker value={value} onChange={handleChangeValue} />

          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="info"
              size="small"
              fullWidth
              onClick={() => {
                insertTag(`evd${value}`);
              }}
              disabled={!value}
            >
              Insert Tag
            </Button>

            <Button
              variant="contained"
              color="success"
              size="small"
              fullWidth
              onClick={() => {
                insertTag('evdh');
              }}
            >
              Hide Tag
            </Button>
          </Stack>

          <Button
            variant="contained"
            color="violet"
            size="small"
            onClick={handleManageClick}
            fullWidth
          >
            Manage Evidence
          </Button>
        </Stack>
      </Popper>
    </>
  );
};
