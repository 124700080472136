import React, { useEffect, useRef, useState } from 'react';

interface DragResizeInputProps {
  value?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  onChange?: (rect: {
    top: number;
    left: number;
    width: number;
    height: number;
  }) => void;
  minWidth?: number;
  minHeight?: number;
}

// Component for dragging and resizing a div element within a parent container
export const DragResize = ({
  value = { top: 10, left: 10, width: 40, height: 30 },
  minWidth = 5,
  minHeight = 5,
  onChange,
}: DragResizeInputProps) => {
  const [selected, setSelected] = useState(true);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const isResizing = useRef<string | null>(null);
  const isDragging = useRef(false);
  const startPosition = useRef<{
    x: number;
    y: number;
    top: number;
    left: number;
    width: number;
    height: number;
    parentWidth: number;
    parentHeight: number;
  } | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target as Node)
      ) {
        setSelected(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleMouseDownDrag = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    setSelected(true);
    isDragging.current = true;
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const parent = parentRef.current?.getBoundingClientRect();
    if (parent) {
      startPosition.current = {
        x: clientX,
        y: clientY,
        top: value.top,
        left: value.left,
        width: value.width,
        height: value.height,
        parentWidth: parent.width,
        parentHeight: parent.height,
      };
    }

    document.addEventListener('mousemove', handleMouseMoveDrag);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMoveDrag);
    document.addEventListener('touchend', handleMouseUp);
  };

  const handleMouseMoveDrag = (e: MouseEvent | TouchEvent) => {
    if (isDragging.current && startPosition.current) {
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      const { x, y, top, left, parentWidth, parentHeight } =
        startPosition.current;
      const deltaX = ((clientX - x) / parentWidth) * 100;
      const deltaY = ((clientY - y) / parentHeight) * 100;

      onChange?.({
        ...value,
        top: Math.max(0, Math.min(100 - value.height, top + deltaY)),
        left: Math.max(0, Math.min(100 - value.width, left + deltaX)),
      });
    }
  };

  const handleMouseDownResize = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    direction: string,
  ) => {
    e.stopPropagation();
    setSelected(true);
    isResizing.current = direction;
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const parent = parentRef.current?.getBoundingClientRect();
    if (parent) {
      startPosition.current = {
        x: clientX,
        y: clientY,
        top: value.top,
        left: value.left,
        width: value.width,
        height: value.height,
        parentWidth: parent.width,
        parentHeight: parent.height,
      };
    }

    document.addEventListener('mousemove', handleMouseMoveResize);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMoveResize);
    document.addEventListener('touchend', handleMouseUp);
  };

  const handleMouseMoveResize = (e: MouseEvent | TouchEvent) => {
    if (isResizing.current && startPosition.current) {
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      const { x, y, width, height, top, left, parentWidth, parentHeight } =
        startPosition.current;
      const deltaX = ((clientX - x) / parentWidth) * 100;
      const deltaY = ((clientY - y) / parentHeight) * 100;

      const updates = { ...value };

      if (isResizing.current?.includes('e')) {
        updates.width = Math.max(
          minWidth,
          Math.min(100 - value.left, width + deltaX),
        );
      }
      if (isResizing.current?.includes('w')) {
        const newWidth = Math.max(
          minWidth,
          Math.min(100 - value.left, width - deltaX),
        );
        updates.width = newWidth;
        updates.left = Math.max(0, left + width - newWidth);
      }
      if (isResizing.current?.includes('s')) {
        updates.height = Math.max(
          minHeight,
          Math.min(100 - value.top, height + deltaY),
        );
      }
      if (isResizing.current?.includes('n')) {
        const newHeight = Math.max(
          minHeight,
          Math.min(100 - value.top, height - deltaY),
        );
        updates.height = newHeight;
        updates.top = Math.max(0, top + height - newHeight);
      }

      onChange?.(updates);
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    isResizing.current = null;

    document.removeEventListener('mousemove', handleMouseMoveDrag);
    document.removeEventListener('mousemove', handleMouseMoveResize);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchmove', handleMouseMoveDrag);
    document.removeEventListener('touchmove', handleMouseMoveResize);
    document.removeEventListener('touchend', handleMouseUp);
  };

  return (
    <div
      ref={parentRef}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: `${value.top}%`,
          left: `${value.left}%`,
          width: `${value.width}%`,
          height: `${value.height}%`,
          background: '#90caf9AA',
          border: '2px solid #42a5f5',
          boxSizing: 'border-box',
          cursor: 'move',
          pointerEvents: 'auto',
          touchAction: 'none',
        }}
        onMouseDown={handleMouseDownDrag}
        onTouchStart={handleMouseDownDrag}
      >
        {/* Resizing Handles */}
        {selected &&
          ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'].map((direction) => (
            <div
              key={direction}
              style={{
                position: 'absolute',
                width: '10px',
                height: '10px',
                background: '#fff',
                border: '1px solid #42a5f5',
                cursor: `${direction}-resize`,
                pointerEvents: 'auto',
                touchAction: 'none',
                ...getHandleStyles(direction),
              }}
              onMouseDown={(e) => handleMouseDownResize(e, direction)}
              onTouchStart={(e) => handleMouseDownResize(e, direction)}
            />
          ))}
      </div>
    </div>
  );
};

const getHandleStyles = (direction: string): React.CSSProperties => {
  const styles: Record<string, React.CSSProperties> = {
    nw: { top: '-5px', left: '-5px' },
    n: { top: '-5px', left: 'calc(50% - 5px)' },
    ne: { top: '-5px', right: '-5px' },
    e: { top: 'calc(50% - 5px)', right: '-5px' },
    se: { bottom: '-5px', right: '-5px' },
    s: { bottom: '-5px', left: 'calc(50% - 5px)' },
    sw: { bottom: '-5px', left: '-5px' },
    w: { top: 'calc(50% - 5px)', left: '-5px' },
  };
  return styles[direction];
};
