import { CourtRecord, CourtRecordEvidence } from '@shared/types';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { usePlayerMeta } from '../../providers/PlayerMetaProvider';
import { CaseState, EvidenceRecord } from '../../types/playerCase';

enum MENU {
  main,
  check,
  logs,
}

type CourtRecordContextType = {
  courtRecord: CourtRecord;
  menu: MENU;
  setMenu: Dispatch<SetStateAction<MENU>>;
  type: 'evidence' | 'profiles';
  setType: Dispatch<SetStateAction<'evidence' | 'profiles'>>;
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  records: EvidenceRecord[];
  selectedRecord?: EvidenceRecord;
  selectedEvidence?: CourtRecordEvidence;
  onPresent?: (value: EvidenceRecord) => void;
  fullscreenCheck: boolean;
  setFullscreenCheck: Dispatch<SetStateAction<boolean>>;
  messagesLog?: CaseState['messagesLog'];
};

const CourtRecordContext = createContext<CourtRecordContextType | undefined>(
  undefined,
);

export const useCourtRecordContext = () => {
  const context = useContext(CourtRecordContext);
  if (!context) {
    throw new Error(
      'useCourtRecordContext must be used within a CourtRecordProvider',
    );
  }
  return context;
};

export const CourtRecordProvider = ({
  courtRecord,
  onPresent,
  messagesLog,
  children,
}: {
  courtRecord: CourtRecord;
  onPresent?: (value: EvidenceRecord) => void;
  messagesLog?: CaseState['messagesLog'];
  children: React.ReactNode;
}) => {
  const [menu, setMenu] = useState<MENU>(MENU.main);
  const [type, setType] = useState<'evidence' | 'profiles'>('evidence');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fullscreenCheck, setFullscreenCheck] = useState(false);

  const playerMeta = usePlayerMeta();

  const records = useMemo<EvidenceRecord[]>(() => {
    const items =
      type === 'evidence' ? courtRecord.evidence : courtRecord.profiles;

    if (!playerMeta?.isPreview) {
      return items.filter((record) => !record.hide);
    } else {
      return items.map((m) => ({
        ...m,
        name: m.hide ? `[Hidden] ${m.name}` : m.name,
      }));
    }
  }, [courtRecord.evidence, courtRecord.profiles, playerMeta?.isPreview, type]);

  const selectedRecord = records[selectedIndex];
  const selectedEvidence =
    selectedRecord && 'type' in selectedRecord ? selectedRecord : undefined;

  const value = {
    courtRecord,
    menu,
    setMenu,
    type,
    setType,
    selectedIndex,
    setSelectedIndex,
    records,
    selectedRecord,
    selectedEvidence,
    onPresent,
    fullscreenCheck,
    setFullscreenCheck,
    messagesLog,
  };

  return (
    <CourtRecordContext.Provider value={value}>
      {children}
    </CourtRecordContext.Provider>
  );
};
