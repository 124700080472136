import {
  Box,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { useCallback } from 'react';

type CheckboxProps = {
  value: boolean;
  onChange: (checked: boolean) => void;
  label: string;
} & Omit<MuiCheckboxProps, 'value' | 'onChange'>;

export const Checkbox = ({
  value,
  onChange,
  label,
  ...props
}: CheckboxProps) => {
  const { disabled, ...rest } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (disabled) return;

      onChange(value);
    },
    [disabled, onChange],
  );

  const handleClickLabel = useCallback(
    (event: React.MouseEvent<HTMLLabelElement>) => {
      event.preventDefault();

      if (disabled) return;

      onChange(!value);
    },
    [disabled, onChange, value],
  );

  return (
    <Box>
      <FormControlLabel
        sx={{ mr: 0, userSelect: 'none' }}
        onClick={handleClickLabel}
        control={
          <MuiCheckbox
            checked={value}
            onChange={handleChange}
            disabled={disabled}
            {...rest}
          />
        }
        label={label}
        disabled={disabled}
      />
    </Box>
  );
};

type CheckboxNativeProps = {
  value: boolean;
  onChange: MuiCheckboxProps['onChange'];
  label: string;
} & Omit<MuiCheckboxProps, 'value' | 'onChange'>;

export const CheckboxNative = ({
  value,
  onChange,
  label,
  ...props
}: CheckboxNativeProps) => {
  return (
    <Box>
      <FormControlLabel
        sx={{ mr: 0 }}
        control={<MuiCheckbox checked={value} onChange={onChange} {...props} />}
        label={label}
      />
    </Box>
  );
};
