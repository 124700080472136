import { Character_Side } from '@shared/types';
import { Character } from '@web/types/character';
import { CSSProperties, useMemo } from 'react';

export const useCharacterExtraStyles = (character?: Character) => {
  const style = useMemo<CSSProperties>(
    () =>
      character?.isPreset && character.side === Character_Side.Gallery
        ? { width: '100%' }
        : {},
    [character],
  );

  return style;
};
