import { SceneTargets } from '@shared/types/scene-targets';
import { BackgroundDto } from '@web/api/api';
import { useEffect, useState } from 'react';
import { useFlipped } from './hooks/useFlipped';
import { usePlayerResetRegister } from './hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './hooks/usePlayerSaveLoadRegister';
import { useShake } from './hooks/useShake';
import { usePlayer } from './providers/PlayerProvider';
import { Background } from './ui/Background';
import { Desk } from './ui/Desk';

export const PlayerBackground = () => {
  const {
    state: { evidence },
    step,
    frame: { background: frameBackground, frame },
    playerEffects: { effectStyles },
    defaultBackground,
  } = usePlayer();

  const [background, setBackground] = useState(defaultBackground);
  const flipped = useFlipped(frame, SceneTargets.BACKGROUND);

  const shakeSx = useShake();
  const filter = effectStyles[SceneTargets.BACKGROUND];

  const isFullscreenEvidence = !!evidence?.id && !evidence?.isIcon;

  usePlayerSaveLoadRegister<BackgroundDto>({
    name: 'background',
    onSave: () => background,
    onLoad: setBackground,
  });

  usePlayerResetRegister({
    name: 'background',
    onReset: () => setBackground({ ...defaultBackground }),
  });

  useEffect(() => {
    if (step !== 'character') {
      return;
    }

    setBackground(frameBackground ?? defaultBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    // update default background if it changes
    // for now, we only want to update the default background if it's not speedlines (temporary fix)
    if (
      background.id === defaultBackground.id &&
      background.name !== 'Speedlines'
    ) {
      // must spread defaultBackground so react doesn't think it's the same object
      setBackground({ ...defaultBackground });
    }
  }, [background.id, background.name, defaultBackground]);

  return (
    <>
      <Background
        url={background.url}
        isWide={background.isWide && !isFullscreenEvidence}
        flipped={flipped}
        sx={{ ...shakeSx }}
        style={filter}
      />

      <Desk
        url={background.deskUrl}
        isWide={background.isWide && !isFullscreenEvidence}
        flipped={flipped}
        style={filter}
      />
    </>
  );
};
