import { Box, styled } from '@mui/material';
import React, { forwardRef } from 'react';

type ViewportProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  left?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const Viewport = forwardRef<HTMLDivElement, ViewportProps>(
  ({ children, fullWidth, left, ...props }, ref) => {
    return (
      <StyledViewport
        {...props}
        ref={ref}
        style={{
          width: fullWidth ? '100%' : 'auto',
          left: !fullWidth && left ? `${left}%` : 0,
          transform: !fullWidth && left ? `translateX(-${left}%)` : 'unset',
          pointerEvents: 'auto',
        }}
        className="ol_viewport"
      >
        {children}
      </StyledViewport>
    );
  },
);

const StyledViewport = styled(Box)({
  position: 'absolute',
  height: '100%',
  top: 0,
  userSelect: 'none',
  pointerEvents: 'none',
});
