import { Location } from '@shared/types';
import { useMemo } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import { LocationMoveDialog } from './dialogs/LocationMoveDialog';

export const PlayerLocationMoveDialog = () => {
  const {
    playerCase: {
      location,
      locations,
      state,
      actions: { onMove },
    },
    actions: { preloadThenNext, preloadFramesThenNext },
  } = usePlayer();

  const handleMove = async (value: Location) => {
    onClose();

    await onMove(preloadThenNext, preloadFramesThenNext, value.id);
  };

  const locationOptions = useMemo(
    () => locations.filter((f) => f.id !== location?.id && f.frames.length),
    [location?.id, locations],
  );

  const onClose = () => {
    state.showMove = false;
  };

  return (
    <LocationMoveDialog
      open={!!state.showMove}
      onClose={onClose}
      locations={locationOptions}
      onMove={handleMove}
    />
  );
};
