import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { rootStore, useRootStore } from '@web/store/root/state';

export const ConfirmationDialog = () => {
  const {
    dialogs: { confirmation },
    confirmationDialogOptions,
  } = useRootStore();

  const handleClose = () => {
    rootStore.dialogs.confirmation = false;
    if (confirmationDialogOptions?.onCancel) {
      confirmationDialogOptions.onCancel();
    }
  };

  const handleConfirm = () => {
    rootStore.dialogs.confirmation = false;
    if (confirmationDialogOptions?.onConfirm) {
      confirmationDialogOptions.onConfirm();
    }
  };

  if (!confirmationDialogOptions) return null;

  return (
    <Dialog open={confirmation} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography>{confirmationDialogOptions.message}</Typography>

          {confirmationDialogOptions.tip && (
            <Typography variant="caption" color="textSecondary">
              {confirmationDialogOptions.tip}
            </Typography>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
