import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Add,
  CenterFocusStrong,
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Examine } from '@shared/types';
import { BackgroundDto } from '@web/api/api';
import { disableParentRipple } from '@web/components/common';
import { BackMenu } from '@web/components/common/BackMenu';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { GameCategory } from '@web/components/player/types/state';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { assetStore } from '@web/store/assets/state';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { CSSProperties, MouseEvent, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { BackgroundAreaInput } from './ImageAreaInput';
import { InvestigationPopper } from './InvestigationPopper';

enum Menu {
  MAIN,
  FORM,
}

const state = proxy({
  menu: Menu.MAIN,
  form: {} as Pick<Examine, 'name' | 'hide' | 'shape'> & { id?: string },
  deleteId: '',
});

export const InvestigationExamineEditor = () => {
  const { menu } = useSnapshot(state);

  const onExit = () => {
    state.menu = Menu.MAIN;
  };

  return (
    <InvestigationPopper label="Examine" onExit={onExit}>
      {({ close }) => (
        <MultiHideView index={menu}>
          <ExamineMainEditor handleClose={close} />
          <ExamineFormEditor />
        </MultiHideView>
      )}
    </InvestigationPopper>
  );
};

const ExamineMainEditor = ({ handleClose }: { handleClose: () => void }) => {
  const handleClickAdd = () => {
    state.form = {
      name: '',
      hide: false,
      shape: { top: 10, left: 10, width: 10, height: 10 },
    };
    state.menu = Menu.FORM;
  };

  return (
    <>
      <ExamineList />

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button color="error" onClick={handleClose}>
          Close
        </Button>

        <Button color="success" startIcon={<Add />} onClick={handleClickAdd}>
          Add Examine
        </Button>
      </CardActions>
    </>
  );
};

const ExamineList = () => {
  const { location } = useMakerStore();

  const handleDragEnd = useCallback(
    (sourceId: UniqueIdentifier, destinationId?: UniqueIdentifier) => {
      if (!makerStore.location) return;

      const oldIndex = makerStore.location.examine.findIndex(
        (i) => i.id === sourceId,
      );
      const newIndex = makerStore.location.examine.findIndex(
        (i) => i.id === destinationId,
      );

      makerStore.location.examine = arrayMove(
        makerStore.location.examine,
        oldIndex,
        newIndex,
      );
    },
    [],
  );

  return (
    <List sx={{ maxHeight: '50vh', overflowY: 'auto' }} dense>
      <SortableDndProvider
        items={location?.examine || []}
        onDragEnd={handleDragEnd}
      >
        {location?.examine.length === 0 && (
          <>
            <ListItem>
              <ListItemText>
                <Typography variant="caption" color="textSecondary">
                  The areas the player can examine using the Examine menu.
                </Typography>
              </ListItemText>
            </ListItem>

            <Divider />
          </>
        )}

        {location?.examine.map((examine, index) => (
          <ExamineRow key={examine.id} examine={examine} />
        ))}
      </SortableDndProvider>

      <MoreExamine />
    </List>
  );
};

const ExamineRow = ({ examine }: { examine: Examine }) => {
  const { deleteId } = useSnapshot(state);
  const { investigationGroup } = useMakerStore();

  const onEditClick = (event: MouseEvent, examine: Examine) => {
    event.stopPropagation();

    state.form = {
      id: examine.id,
      name: examine.name,
      hide: examine.hide,
      shape: { ...examine.shape },
    };

    state.menu = Menu.FORM;
  };

  const onDeleteClick = (event: MouseEvent, examine: Examine) => {
    event.stopPropagation();

    state.deleteId = examine.id;
  };

  const onChangeExamine = () => {
    if (!investigationGroup) return;

    changeExamine(examine.id);
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: examine.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (deleteId === examine.id) {
    return <ExamineDeleteRow />;
  }

  return (
    <ListItemButton
      ref={setNodeRef}
      sx={{ height: 60 }}
      style={style}
      onClick={onChangeExamine}
    >
      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <Typography component="div" mb={0.75}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Box
                display="flex"
                sx={{ touchAction: 'none' }}
                {...attributes}
                {...listeners}
              >
                <DragHandle sx={{ cursor: 'move' }} />
              </Box>

              <Typography
                component="div"
                variant="subtitle2"
                fontWeight="bold"
                mb={0.5}
                noWrap
              >
                {examine.name}
              </Typography>
            </Stack>
          </Typography>
        }
        secondary={
          <Typography component="div" color="text.secondary">
            <Typography variant="caption" lineHeight="unset">
              {examine.frames.length} Frames
            </Typography>
          </Typography>
        }
      />

      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          onClick={(e) => onEditClick(e, examine)}
          {...disableParentRipple}
        >
          <Edit />
        </IconButton>

        <IconButton
          size="small"
          onClick={(e) => onDeleteClick(e, examine)}
          {...disableParentRipple}
        >
          <Delete />
        </IconButton>
      </Stack>
    </ListItemButton>
  );
};

const MoreExamine = () => {
  const { location } = useMakerStore();

  return (
    <ListItemButton sx={{ height: 60 }} onClick={goToExamineFailureFrames}>
      <ListItemText
        primary={
          <Typography variant="subtitle2" fontWeight="bold" mb={0.5}>
            Examine Anything Else
          </Typography>
        }
        secondary={
          <Typography variant="caption" color="text.secondary">
            {location?.examineFailureFrames?.length} Frames
          </Typography>
        }
      />
    </ListItemButton>
  );
};

const ExamineDeleteRow = () => {
  const handleDelete = () => {
    if (!makerStore.location) return;

    if (makerStore.framesTarget.examineId === state.deleteId) {
      const examineIndex = makerStore.location.examine.findIndex(
        (examine) => examine.id === state.deleteId,
      );

      const nextExamine = makerStore.location.examine[examineIndex + 1];
      const previousExamine = makerStore.location.examine[examineIndex - 1];

      if (nextExamine) {
        changeExamine(nextExamine.id);
      } else if (previousExamine) {
        changeExamine(previousExamine.id);
      }
    }

    makerStore.location.examine = makerStore.location.examine.filter(
      (examine) => examine.id !== state.deleteId,
    );
  };

  const handleCancel = () => {
    state.deleteId = '';
  };

  return (
    <ListItem sx={{ height: 60 }}>
      <Typography variant="caption" pr={0.5}>
        Delete this examine? All frames in this examine will be deleted.
      </Typography>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <XSmallButton variant="contained" color="error" onClick={handleDelete}>
          Delete
        </XSmallButton>

        <XSmallButton
          variant="contained"
          color="success"
          onClick={handleCancel}
        >
          Cancel
        </XSmallButton>
      </Stack>
    </ListItem>
  );
};

const ExamineFormEditor = () => {
  const { form } = useProxy(state);

  const handleSave = () => {
    if (!makerStore.location) return;

    if (state.form.id) {
      makerStore.location.examine = makerStore.location.examine.map(
        (examine) => {
          if (examine.id === state.form.id) {
            examine.name = state.form.name;
            examine.hide = state.form.hide;
            examine.shape = state.form.shape;
          }
          return examine;
        },
      );
    } else {
      const newId = uuidv4();

      makerStore.location.examine.push({
        ...state.form,
        id: newId,
        frames: [],
        right: false,
      });
    }

    state.menu = Menu.MAIN;
  };

  if (!makerStore.location) return null;

  const background =
    assetStore.background.cache[makerStore.location.backgroundId];

  const disableSave = !form.name || !background;

  return (
    <>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label="Examine Name"
            value={form.name || ''}
            onChange={(e) => {
              state.form.name = e.target.value;
            }}
            size="small"
            variant="standard"
            fullWidth
          />

          {background && <BackgroundArea background={background} />}

          <Checkbox
            value={!!form.hide}
            onChange={(value) => {
              state.form.hide = value;
            }}
            label="Hide"
            size="small"
          />

          {!background && (
            <Typography variant="caption" color="error">
              Please set a background for this location first
            </Typography>
          )}
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          color="error"
          onClick={() => {
            state.menu = Menu.MAIN;
          }}
        >
          Cancel
        </Button>

        <Button color="success" disabled={disableSave} onClick={handleSave}>
          {form.id ? 'Edit' : 'Add'}
        </Button>
      </CardActions>
    </>
  );
};

const BackgroundArea = ({ background }: { background: BackgroundDto }) => {
  const [settingLocation, setSettingLocation] = useState(false);
  const [left, setLeft] = useState(0);

  const { form } = useSnapshot(state);

  const handleChange = (value: Examine['shape']) => {
    state.form.shape = value;
  };

  const handleViewportClick = (x: number, y: number) => {
    if (!settingLocation) return;

    state.form.shape = {
      top: y,
      left: x,
      width: background.isWide ? 5 : 10,
      height: 10,
    };

    setSettingLocation(false);
  };

  return (
    <Stack spacing={1}>
      <BackgroundAreaInput
        background={background}
        value={form.shape}
        onChange={handleChange}
        left={left}
        onClick={handleViewportClick}
      />

      {settingLocation && (
        <BackMenu
          text="Click to move area"
          onClick={() => setSettingLocation(false)}
          buttonText="Cancel"
          small
        />
      )}

      {background.isWide && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <XSmallButton
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => setLeft(0)}
            disabled={left === 0}
          >
            <KeyboardArrowLeft />
          </XSmallButton>

          {!settingLocation && (
            <Tooltip title="Refocus area" placement="top">
              <XSmallButton
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => setSettingLocation(true)}
              >
                <CenterFocusStrong />
              </XSmallButton>
            </Tooltip>
          )}

          <XSmallButton
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => setLeft(99.98)}
            disabled={left === 99.98}
          >
            <KeyboardArrowRight />
          </XSmallButton>
        </Stack>
      )}
    </Stack>
  );
};

const changeExamine = (examineId: string) => {
  if (!makerStore.investigationGroup || !makerStore.location) return;

  makerStore.framesTarget = {
    groupId: makerStore.investigationGroup.id,
    locationId: makerStore.location.id,
    examineId,
  };

  makerStore.page = 1;
};

const goToExamineFailureFrames = () => {
  makerStore.framesTarget = {
    ...makerStore.framesTarget,
    category: GameCategory.InvestigationExamineFailure,
    examineId: undefined,
  };

  makerStore.page = 1;
};
