import { Edit, Share, Videocam } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  CaseProjectDto,
  ExportRequestDto,
  ExportStatusDto,
  ExportStatusDtoStatusEnum,
  SceneProjectDto,
} from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { CopyButton } from '@web/components/common/CopyButton';
import { Checkbox } from '@web/components/common/form/Checkbox';
import Form from '@web/components/common/form/Form';
import ReCaptcha from '@web/components/common/form/ReCaptcha';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '@web/components/common/ui/DraggableDialog';
import MultiView from '@web/components/common/ui/MultiView';
import { Player } from '@web/components/player/Player';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { getAspectRatioNumber } from '@web/components/player/utils/utils';
import { maxContainerWidth } from '@web/layouts/BaseLayout';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { settingsStore } from '@web/store/settings';
import { getErrorMessage } from '@web/utils/error';
import { AxiosError } from 'axios';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { proxy, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import * as yup from 'yup';

const state = proxy({
  project: undefined as SceneProjectDto | undefined,
  shareDialog: false,
  exportMP4Dialog: false,
  exportMp4: undefined as ExportRequestDto | undefined,
  exportMp4Status: undefined as ExportStatusDto | undefined,
  error: undefined as 404 | 500 | undefined,
});

const ObjectionPage = () => {
  const { project } = useProxy(state);

  const theme = useTheme();
  const breakpoint = useMediaQuery(
    theme.breakpoints.down((project?.options.width || 960) + 16),
  );

  return (
    <>
      <Box mt={breakpoint ? 0 : 1}>
        <ErrorAlert />
        <ExportStatus />
        <ObjectionPlayer />
      </Box>

      <ObjectionShareDialog />
      <ExportMP4Dialog />
    </>
  );
};

const ObjectionPlayer = memo(() => {
  const { id } = useParams();
  const { project } = useProxy(state);

  const ref = useRef<PlayerActionsType>(null);

  useEffect(() => {
    if (!id) return;

    const start = async () => {
      await fetchObjection(parseInt(id));

      // TODO: for now using setTimeout, need to fix init and usage of state instead of snapshot
      setTimeout(() => {
        if (state.error || !state.project) return;

        ref.current?.reset();
        ref.current?.init(state.project);
      });
    };

    state.error = undefined;
    state.exportMp4Status = undefined;
    state.exportMp4 = undefined;

    start();
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <Player
        ref={ref}
        controls={<ObjectionControls />}
        width={project?.options.width}
        aspectRatio={project?.options.aspectRatio}
      />
    </Stack>
  );
});

const ObjectionControls = () => {
  const { error } = useSnapshot(state);

  const handleClickShare = () => {
    state.shareDialog = true;
  };

  const handleClickExportMP4 = () => {
    state.exportMP4Dialog = true;
  };

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      <Button
        variant="contained"
        size="small"
        color="info"
        startIcon={<Share />}
        sx={{ height: 32 }}
        onClick={handleClickShare}
      >
        Share
      </Button>

      <Button
        variant="contained"
        size="small"
        color="success"
        startIcon={<Videocam />}
        sx={{ height: 32 }}
        onClick={handleClickExportMP4}
        disabled={error !== undefined}
      >
        Export MP4
      </Button>

      <EditSceneButton />
    </Stack>
  );
};

const ObjectionShareDialog = () => {
  const { project, shareDialog } = useProxy(state);

  const handleClose = () => {
    state.shareDialog = false;
  };

  return (
    <ShareDialog project={project} open={shareDialog} onClose={handleClose} />
  );
};

const ErrorAlert = () => {
  const { project, error } = useSnapshot(state);

  const theme = useTheme();
  const breakpoint = useMediaQuery(
    theme.breakpoints.down((project?.options.width || 960) + 16),
  );

  if (!error) return null;

  return (
    <Alert
      severity="error"
      variant="filled"
      sx={{ borderRadius: 0, mb: !breakpoint ? 1 : 0 }}
    >
      {error === 404
        ? 'Objection not found or has been deleted'
        : 'An error occurred while fetching the objection'}
    </Alert>
  );
};

const ExportStatus = () => {
  const { id } = useParams();
  const { project, exportMp4, exportMp4Status } = useSnapshot(state);

  const exportMp4Error =
    exportMp4Status?.status === ExportStatusDtoStatusEnum.NUMBER_3;
  const exportMp4Success =
    exportMp4Status?.status === ExportStatusDtoStatusEnum.NUMBER_2;

  const theme = useTheme();
  const breakpoint = useMediaQuery(
    theme.breakpoints.down((project?.options.width || 960) + 16),
  );

  const getExportStatus = useCallback(async () => {
    try {
      if (!exportMp4 || exportMp4Error || exportMp4Success) return;

      state.exportMp4Status = (
        await ApiClient.export.getExportStatus(exportMp4.requestId)
      ).data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === 400 &&
        state.exportMp4Status
      ) {
        state.exportMp4Status.status = ExportStatusDtoStatusEnum.NUMBER_3;
        state.exportMp4 = undefined;

        return;
      }

      console.error(error);
    }
  }, [exportMp4, exportMp4Error, exportMp4Success]);

  useEffect(() => {
    getExportStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!exportMp4 || !exportMp4Success || !id) return;

    downloadVideo(parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportMp4, exportMp4Success]);

  useInterval(
    getExportStatus,
    exportMp4 && !exportMp4Error && !exportMp4Success ? 2000 : null,
  );

  if (exportMp4Error) {
    return (
      <Alert severity="error" sx={{ borderRadius: 0, mb: !breakpoint ? 1 : 0 }}>
        An error occurred while exporting the objection.
      </Alert>
    );
  }

  if (exportMp4Success) {
    return (
      <Alert
        severity="success"
        sx={{ borderRadius: 0, mb: !breakpoint ? 1 : 0 }}
      >
        Export completed. Download has started.
      </Alert>
    );
  }

  if (!exportMp4) return null;

  return (
    <Alert
      icon={<CircularProgress color="inherit" size={24} />}
      severity="success"
      sx={{
        borderRadius: 0,
        mb: !breakpoint ? 1 : 0,
        alignItems: 'center',
      }}
    >
      <Typography color="inherit" variant="body2" pl={1}>
        {exportMp4Status?.queue
          ? `Export in queue. Position: ${exportMp4Status.queue}`
          : 'Export in progress. Download will start automatically.'}
      </Typography>
    </Alert>
  );
};

export const ShareDialog = ({
  open,
  onClose,
  project,
}: {
  open: boolean;
  onClose: () => void;
  project?: SceneProjectDto | CaseProjectDto;
}) => {
  const { id } = useParams();
  const [tab, setTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const embedCode = useMemo(() => {
    // TODO: width > 1280
    const width = Math.min(project?.options?.width || 960, maxContainerWidth);
    const height =
      Math.round(
        width / getAspectRatioNumber(project?.options?.aspectRatio || '3:2'),
      ) + (project?.type === 'case' ? 140 : 60);

    const embedUrl = `${window.location.origin}/embed/${id}`;

    return `<iframe src="${embedUrl}" width="100%" height="${height}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
  }, [project?.options, project?.type, id]);

  const handleCopyPageLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode);
  };

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      titleComponent={
        <DraggableDialogDefaultTitle title="Share" onClose={onClose} />
      }
      PaperProps={{ sx: { m: 0, width: 'calc(100% - 16px)' } }}
      maxWidth="xs"
      fullWidth
    >
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab label="Link" />
        <Tab label="Embed Code" />
      </Tabs>
      <Box p={2}>
        <MultiView index={tab}>
          <Box>
            <TextField
              value={window.location.href}
              InputProps={{
                endAdornment: <CopyButton onCopy={handleCopyPageLink} />,
              }}
              size="small"
              fullWidth
            />
          </Box>

          <Box>
            <TextField
              value={embedCode}
              InputProps={{
                endAdornment: <CopyButton onCopy={handleCopyEmbedCode} />,
              }}
              sx={{ '.MuiInputBase-root': { alignItems: 'start' } }}
              size="small"
              rows={5}
              multiline
              fullWidth
            />
          </Box>
        </MultiView>
      </Box>
    </DraggableDialog>
  );
};

type ExportMP4DialogForm = {
  useMyVolume: boolean;
  captcha: string;
};

const ExportMP4Dialog = () => {
  const { id } = useParams();

  const [error, setError] = useState<string | undefined>(undefined);

  const isBeta = import.meta.env.VITE_IS_BETA === 'true';

  const { exportMP4Dialog } = useSnapshot(state);

  const handleClose = () => {
    state.exportMP4Dialog = false;
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        useMyVolume: yup.boolean(),
      }),
    [],
  );

  const defaultValues = useMemo<ExportMP4DialogForm>(
    () => ({ useMyVolume: false, captcha: '' }),
    [],
  );

  const onSubmit = useCallback(
    async (data: ExportMP4DialogForm) => {
      try {
        const objectionId = parseInt(id || '');

        if (isNaN(objectionId) || objectionId <= 0) {
          throw new Error('Invalid objection ID');
        }

        setError(undefined);

        const audio = settingsStore.audio;

        const res = await ApiClient.export.exportObjection({
          objectionId,
          volumes: data.useMyVolume
            ? {
                master: audio.muted.master ? 0 : audio.volume.master,
                music: audio.muted.music ? 0 : audio.volume.music,
                sound: audio.muted.sound ? 0 : audio.volume.sound,
                blip: audio.muted.blip ? 0 : audio.volume.blip,
              }
            : undefined,
        });

        if (res.data.requestId === 0) {
          // already exported
          state.exportMP4Dialog = false;

          downloadVideo(objectionId);

          return;
        }

        state.exportMp4 = res.data;
        state.exportMp4Status = undefined;

        state.exportMP4Dialog = false;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 503) {
          setError(
            'Export service is currently under maintenance. Please try again later.',
          );

          return;
        }

        setError(getErrorMessage(error));
      }
    },
    [id],
  );

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={exportMP4Dialog}
      onClose={handleClose}
      titleComponent={
        <DraggableDialogDefaultTitle
          title="Export as MP4 Video"
          onClose={handleClose}
        />
      }
      maxWidth="xs"
      hideBackdrop={false}
      fullScreen={fullscreen}
      fullWidth
    >
      <Form
        schema={schema}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        mode="onChange"
      >
        {({ control, loading }) => (
          <>
            <Alert severity="info" variant="filled" sx={{ borderRadius: 0 }}>
              <Stack spacing={1}>
                <Typography variant="caption">
                  You can use the recording tool to export as MP4 on your
                  Windows machine.
                </Typography>

                <Box>
                  <Button
                    variant="text"
                    color="inherit"
                    size="small"
                    sx={{ textTransform: 'none' }}
                    href="https://github.com/objection-lol/objection-lol-recorder"
                    target="_blank"
                  >
                    Get Objection Recorder
                  </Button>
                </Box>
              </Stack>
            </Alert>

            {error && (
              <Alert severity="error" variant="filled" sx={{ borderRadius: 0 }}>
                {error}
              </Alert>
            )}

            <Stack spacing={2} p={2}>
              {isBeta ? (
                <Typography color="warning">
                  Export MP4 is unavailable during the beta.
                </Typography>
              ) : (
                <Typography>
                  Export the objection as a video file. Download will start
                  automatically.
                </Typography>
              )}

              <Controller
                name="useMyVolume"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} label="Use my volume settings" />
                )}
                disabled={isBeta}
              />

              {!isBeta && (
                <Controller
                  control={control}
                  name="captcha"
                  render={({ field }) => (
                    <ReCaptcha onChange={field.onChange} />
                  )}
                />
              )}
            </Stack>

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                color="primary"
                disabled={isBeta || loading}
              >
                Export
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </DraggableDialog>
  );
};

const EditSceneButton = () => {
  const { id } = useParams();

  const { loggedIn } = useAuth();

  const { data } = useQuery({
    queryKey: ['scene', 'isMine', id],
    queryFn: () => ApiClient.scene.isMine(parseInt(id!)),
    enabled: !!id && loggedIn,
    staleTime: Infinity,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    if (!id) return;

    navigate(`/edit/scene/${id}`);
  };

  if (!data?.data) return null;

  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      sx={{ height: 32 }}
      onClick={handleClick}
      startIcon={<Edit />}
    >
      Edit
    </Button>
  );
};

const downloadVideo = (objectionId: number) => {
  const a = document.createElement('a');
  a.href = `${import.meta.env.VITE_BACKEND_BASE_URL}/export/download/${objectionId}`;
  a.download = `objection-${objectionId}.mp4`;
  a.click();
};

const fetchObjection = async (id: number) => {
  try {
    state.error = undefined;

    const response = await ApiClient.scene.get(id);

    state.project = response.data.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      state.error = 404;

      return;
    }

    state.error = 500;
  }
};

export default ObjectionPage;
