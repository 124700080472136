import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SaveDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { memo, useCallback, useMemo, useState } from 'react';

type SavedGamesListProps = {
  actionType: 'save' | 'load';
  onSave?: (slot: number) => void;
  onLoad?: (id: SaveDto) => void;
  savingSlot?: number | null;
  isLoading?: boolean;
};

// Note: mostly done by AI

export const SavedGamesList = memo(
  ({
    actionType,
    onSave,
    onLoad,
    savingSlot,
    isLoading,
  }: SavedGamesListProps) => {
    const queryClient = useQueryClient();
    const { data: savedGames, isLoading: isLoadingData } = useQuery({
      queryKey: ['savedGames'],
      queryFn: async () => (await ApiClient.caseSave.getMine()).data,
      staleTime: 30000, // Consider data fresh for 30 seconds
    });

    const [confirmingSlot, setConfirmingSlot] = useState<number | null>(null);
    const [confirmingAction, setConfirmingAction] = useState<
      'save' | 'load' | 'delete' | null
    >(null);
    const [deletingSlot, setDeletingSlot] = useState<number | null>(null);

    // Create slots array only when actionType changes
    const orderedSlots = useMemo(() => {
      const slots = Array.from(
        { length: actionType === 'save' ? 8 : 9 },
        (_, i) => i + 1,
      );

      // For load action, move autosave (slot 9) to the beginning
      return actionType === 'load'
        ? [9, ...slots.filter((slot) => slot !== 9)]
        : slots;
    }, [actionType]);

    const handleActionClick = useCallback(
      (slot: number) => {
        const save = savedGames?.find((save) => save.slot === slot);

        // Skip confirmation if saving to an empty slot
        if (actionType === 'save' && !save) {
          onSave?.(slot);
          return;
        }

        setConfirmingSlot(slot);
        setConfirmingAction(actionType);
      },
      [actionType, savedGames, onSave],
    );

    const handleDeleteClick = useCallback(
      (slot: number, event: React.MouseEvent) => {
        event.stopPropagation();

        setConfirmingSlot(slot);
        setConfirmingAction('delete');
      },
      [],
    );

    const handleConfirm = useCallback(
      async (slot: number) => {
        if (confirmingAction === 'save' && onSave) {
          onSave(slot);
        } else if (confirmingAction === 'load' && onLoad) {
          const game = savedGames?.find((f) => f.slot === slot);

          if (!game) return;

          onLoad(game);
        } else if (confirmingAction === 'delete') {
          const save = savedGames?.find((f) => f.slot === slot);
          if (!save?.id) return;

          setDeletingSlot(slot);
          try {
            await ApiClient.caseSave.delete(save.id);
            // Refresh saved games data
            queryClient.invalidateQueries({ queryKey: ['savedGames'] });
          } catch (error) {
            console.error('Failed to delete save:', error);
          } finally {
            setDeletingSlot(null);
          }
        }
        setConfirmingSlot(null);
        setConfirmingAction(null);
      },
      [confirmingAction, onSave, onLoad, savedGames, queryClient],
    );

    const handleCancel = useCallback(() => {
      setConfirmingSlot(null);
      setConfirmingAction(null);
    }, []);

    if (isLoadingData) {
      return (
        <Stack alignItems="center" justifyContent="center" height="200px">
          <CircularProgress />
        </Stack>
      );
    }

    return (
      <Grid2 container spacing={2} mt={1}>
        {orderedSlots.map((slot) => {
          const save = savedGames?.find((save) => save.slot === slot);
          const isAutosave = slot === 9;
          const isConfirming = confirmingSlot === slot;
          const isEmpty = !save;
          const isSlotSaving = actionType === 'save' && savingSlot === slot;
          const isSlotDeleting = deletingSlot === slot;
          const isDisabled =
            (actionType === 'load' && isEmpty) ||
            (actionType === 'save' &&
              savingSlot !== null &&
              savingSlot !== slot) ||
            (actionType === 'load' && isLoading);

          return (
            <Grid2 key={slot} size={{ xs: 12 }}>
              <SaveSlotCard
                slot={slot}
                save={save}
                isAutosave={isAutosave}
                isConfirming={isConfirming}
                isDisabled={!!isDisabled}
                isSlotSaving={isSlotSaving}
                isSlotDeleting={isSlotDeleting}
                confirmingAction={confirmingAction}
                onActionClick={handleActionClick}
                onDeleteClick={handleDeleteClick}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            </Grid2>
          );
        })}
      </Grid2>
    );
  },
);

SavedGamesList.displayName = 'SavedGamesList';

type SaveSlotCardProps = {
  slot: number;
  save?: SaveDto;
  isAutosave: boolean;
  isConfirming: boolean;
  isDisabled: boolean;
  isSlotSaving: boolean;
  isSlotDeleting: boolean;
  confirmingAction: 'save' | 'load' | 'delete' | null;
  onActionClick: (slot: number) => void;
  onDeleteClick: (slot: number, event: React.MouseEvent) => void;
  onConfirm: (slot: number) => void;
  onCancel: () => void;
};

const SaveSlotCard = memo(
  ({
    slot,
    save,
    isAutosave,
    isConfirming,
    isDisabled,
    isSlotSaving,
    isSlotDeleting,
    confirmingAction,
    onActionClick,
    onDeleteClick,
    onConfirm,
    onCancel,
  }: SaveSlotCardProps) => {
    const slotTitle = isSlotSaving
      ? 'Saving...'
      : isSlotDeleting
        ? 'Deleting...'
        : slot === 9
          ? 'Autosave slot'
          : `Slot ${slot}`;

    const handleActionClick = useCallback(() => {
      if (isDisabled || isSlotSaving || isSlotDeleting) return;

      onActionClick(slot);
    }, [isDisabled, isSlotSaving, isSlotDeleting, onActionClick, slot]);

    return (
      <Card variant="outlined" sx={{ height: '100%' }}>
        {!isConfirming ? (
          <CardActionArea
            sx={{ height: '100%', p: 0.5 }}
            onClick={handleActionClick}
            disabled={isDisabled}
          >
            <CardContent sx={{ p: '8px !important' }}>
              <Stack spacing={0.5}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isAutosave ? (
                    <Chip
                      size="small"
                      label="Autosave"
                      color="primary"
                      sx={{ mb: 1 }}
                    />
                  ) : (
                    <Typography variant="caption" color="text.secondary">
                      {slotTitle}
                    </Typography>
                  )}

                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    {isSlotSaving && <CircularProgress size={16} />}
                    {isSlotDeleting && <CircularProgress size={16} />}

                    {save && !isSlotSaving && !isSlotDeleting && (
                      <IconButton
                        size="small"
                        onClick={(e) => onDeleteClick(slot, e)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>

                {save ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      {save.title || 'Unnamed Save'}
                    </Typography>

                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ ml: 1, flexShrink: 0 }}
                    >
                      {new Date(save.date).toLocaleString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        month: 'numeric',
                        day: 'numeric',
                      })}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Empty Slot
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </CardActionArea>
        ) : (
          <CardContent sx={{ py: 1, px: 1.5 }}>
            <Stack spacing={1}>
              <Typography variant="body2">
                {confirmingAction === 'save'
                  ? `Overwrite save in slot ${slot}?`
                  : confirmingAction === 'load'
                    ? `Load game from ${slot !== 9 ? `slot ${slot}` : `autosave slot`}?`
                    : `Delete save from ${slot !== 9 ? `slot ${slot}` : `autosave slot`}?`}
              </Typography>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  size="small"
                  variant="contained"
                  color={confirmingAction === 'delete' ? 'error' : 'primary'}
                  onClick={() => onConfirm(slot)}
                >
                  {confirmingAction === 'delete' ? 'Delete' : 'Confirm'}
                </Button>

                <Button size="small" onClick={onCancel}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        )}
      </Card>
    );
  },
);

SaveSlotCard.displayName = 'SaveSlotCard';
