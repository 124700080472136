import { PlayArrow } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { usePlayerMeta } from './providers/PlayerMetaProvider';
import { usePlayer } from './providers/PlayerProvider';
import { Background } from './ui/Background';

export const PlayerLoading = () => {
  const {
    playerLoading: { loadingPercentage, initialized, preloadStuck },
    playerCase: {
      state: { loading: playerCaseLoading },
    },
    state: { startedPlaying },
    actions: { start },
    defaultBackground,
  } = usePlayer();

  const playerMeta = usePlayerMeta();

  const [showPreloadStuck, setShowPreloadStuck] = useState(false);

  const handleStart = () => {
    if (!initialized) {
      return;
    }

    start();
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (preloadStuck && initialized) {
      timeout = setTimeout(() => {
        setShowPreloadStuck(true);
      }, 2000);
    } else {
      setShowPreloadStuck(false);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadStuck]);

  if (showPreloadStuck || playerCaseLoading) {
    return <PreloadStuckOverlay />;
  }

  if (startedPlaying) {
    return null;
  }

  return (
    <Box
      onClick={handleStart}
      width="100%"
      height="100%"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        cursor: initialized ? 'pointer' : 'default',
        pointerEvents: 'all',
      }}
    >
      {initialized !== undefined &&
        (!initialized ? (
          <>
            <Background url={defaultBackground.url} />

            <LoadingCircle color="inherit" />

            <LoadingBar
              variant="determinate"
              value={loadingPercentage}
              color="info"
            />

            <LoadingBarText>{Math.ceil(loadingPercentage)}%</LoadingBarText>
          </>
        ) : (
          !playerMeta?.isRecording && (
            <PlayButton onClick={handleStart} disableRipple>
              <PlayArrow sx={{ fontSize: { xs: '5rem', sm: '7.5rem' } }} />
            </PlayButton>
          )
        ))}
    </Box>
  );
};

const PreloadStuckOverlay = () => {
  return (
    <Backdrop
      open
      sx={{
        position: 'absolute',
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  color: 'white',
}));

const LoadingCircle = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  width: '40px',
  height: '40px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginLeft: '-20px',
  marginTop: '-20px',
  zIndex: 2,
}));

const LoadingBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 24,
  bottom: 0,
  zIndex: 2,
}));

const LoadingBarText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  textAlign: 'center',
  zIndex: 3,
}));
