import { memo } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import {
  PlayerCaseExamineCursor,
  PlayerCaseInputCursor,
} from './PlayerCaseCursor';

export const PlayerCaseViewportUserInput = memo(
  ({
    playerContainerRef,
  }: {
    playerContainerRef: React.RefObject<HTMLDivElement>;
  }) => {
    const { isCase } = usePlayer();

    if (!isCase) return null;

    return (
      <>
        <PlayerCaseExamineCursor containerRef={playerContainerRef} />
        <PlayerCaseInputCursor containerRef={playerContainerRef} />
      </>
    );
  },
);
