import {
  AccountBox,
  ArrowLeft,
  ArrowRight,
  Forum,
  Fullscreen,
  FullscreenExit,
  MenuBook,
  PanToolAlt,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogTransitionZoom } from '@web/components/common/ui/Dialog';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { Image } from '@web/components/common/ui/Image';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { useEffect, useMemo, useRef } from 'react';

import { CourtRecord } from '@shared/types';
import { Backward } from '@web/components/common/Icons';
import { Media } from '@web/components/common/Media';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { assetStore } from '@web/store/assets/state';
import 'swiper/css';
import 'swiper/css/pagination';
import { CaseState, EvidenceRecord } from '../../types/playerCase';
import {
  CourtRecordProvider,
  useCourtRecordContext,
} from '../providers/CourtRecordContext';

enum MENU {
  main,
  check,
  logs,
}

type CourtRecordDialogProps = {
  open: boolean;
  courtRecord: CourtRecord;
  messagesLog?: CaseState['messagesLog'];
  onPresent?: (value: EvidenceRecord) => void;
  onClose?: () => void;
};

export const CourtRecordDialog = ({
  open,
  courtRecord,
  messagesLog,
  onPresent,
  onClose,
}: CourtRecordDialogProps) => {
  return (
    <CourtRecordProvider
      courtRecord={courtRecord}
      onPresent={onPresent}
      messagesLog={messagesLog}
    >
      <DialogComponent open={open} onClose={onClose} />
    </CourtRecordProvider>
  );
};

type DialogComponentProps = {
  open: boolean;
  onClose?: () => void;
};

const DialogComponent = ({ open, onClose }: DialogComponentProps) => {
  const { menu, setMenu, fullscreenCheck } = useCourtRecordContext();

  const theme = useTheme();
  const fullscreen =
    useMediaQuery(theme.breakpoints.down('sm')) ||
    (menu === MENU.check && fullscreenCheck);

  const onExit = () => {
    setMenu(MENU.main);
  };

  return (
    <DraggableDialog
      open={open}
      onClose={onClose || (() => {})}
      titleComponent={<CourtRecordDialogTitle onClose={onClose} />}
      TransitionComponent={DialogTransitionZoom}
      transitionDuration={250}
      maxWidth="md"
      fullscreen={fullscreen}
      onTransitionExited={onExit}
      fullWidth
      disableDraggable
      disableEnforceFocus
      disableScrollLock
      disableAutoFocus
      denseToolbar
      persist
    >
      <MultiHideView index={menu}>
        <DialogMainContent />
        <DialogCheckContent />
        <DialogMessagesLogContent />
      </MultiHideView>
    </DraggableDialog>
  );
};

const DialogMainContent = () => {
  const { selectedRecord } = useCourtRecordContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogContent sx={{ p: { xs: 1, sm: 2 } }}>
      <Stack spacing={1}>
        {!isMobile && <ButtonControls vertical={false} />}

        {isMobile && (
          <Stack
            direction="row"
            spacing={1}
            flexGrow={1}
            justifyContent="space-between"
          >
            <Image
              src={selectedRecord?.iconUrl || ''}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                backgroundColor: theme.palette.divider,
                userSelect: 'none',
              }}
            />

            <ButtonControls vertical={true} />
          </Stack>
        )}

        <Stack direction="row" spacing={1}>
          {!isMobile && (
            <Image
              src={selectedRecord?.iconUrl || ''}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                backgroundColor: theme.palette.divider,
                userSelect: 'none',
              }}
            />
          )}

          <Stack spacing={1} flexGrow={1} height="100%">
            <Box p={1} bgcolor="#ffc31e">
              <Typography fontSize={16} color="black" fontWeight={700} noWrap>
                {selectedRecord?.name || ''}
              </Typography>
            </Box>

            <Stack
              border="thin solid hsla(0,0%,100%,.12)"
              flexGrow={1}
              p={1}
              height={102}
              overflow="auto"
            >
              <Typography
                variant="body2"
                fontSize={14}
                whiteSpace="pre-line"
                lineHeight="201%"
              >
                {selectedRecord?.description || ''}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <EvidenceSwiper />
      </Stack>
    </DialogContent>
  );
};

const ButtonControls = ({ vertical }: { vertical: boolean }) => {
  const {
    courtRecord,
    setMenu,
    type,
    onPresent,
    selectedRecord,
    selectedEvidence,
    setSelectedIndex,
    setType,
  } = useCourtRecordContext();

  const handleSwitchType = () => {
    setSelectedIndex(0);
    setType((prev) => (prev === 'evidence' ? 'profiles' : 'evidence'));
  };

  const enableSwitchType =
    (type === 'evidence' && courtRecord.profiles.length > 0) ||
    (type === 'profiles' && courtRecord.evidence.length > 0);

  return (
    <Stack
      direction={vertical ? 'column' : 'row'}
      spacing={1}
      alignItems={vertical ? 'flex-end' : 'center'}
      justifyContent={vertical ? 'space-between' : 'flex-start'}
    >
      {type === 'evidence' && (
        <Button
          variant="contained"
          color="info"
          size="small"
          startIcon={<Search />}
          onClick={() => setMenu(MENU.check)}
          disabled={!selectedEvidence?.url}
        >
          Check
        </Button>
      )}

      {!vertical && <Box flexGrow={1} />}

      {onPresent && (
        <Button
          variant="contained"
          color="info"
          size="small"
          startIcon={<PanToolAlt />}
          onClick={() => onPresent(selectedRecord!)}
          disabled={!selectedRecord}
        >
          Present
        </Button>
      )}

      <Button
        variant="contained"
        color="info"
        size="small"
        startIcon={type === 'evidence' ? <AccountBox /> : <MenuBook />}
        onClick={handleSwitchType}
        disabled={!enableSwitchType}
      >
        {type === 'evidence' ? 'Profiles' : 'Evidence'}
      </Button>
    </Stack>
  );
};

const EvidenceSwiper = () => {
  const ref = useRef<SwiperRef | null>(null);

  const { records, selectedIndex, setSelectedIndex } = useCourtRecordContext();

  // useEffect(() => {
  //   if (!ref.current) return;

  //   ref.current.swiper.update();

  //   setTimeout(() => {
  //     ref.current?.swiper.update();
  //   }, 1000);
  // }, []);

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="contained"
          size="small"
          color="info"
          sx={{ minWidth: 24 }}
          onClick={() => ref.current?.swiper.slidePrev()}
        >
          <ArrowLeft />
        </Button>
        <Button
          variant="contained"
          size="small"
          color="info"
          sx={{ minWidth: 24 }}
          onClick={() => ref.current?.swiper.slideNext()}
        >
          <ArrowRight />
        </Button>
      </Stack>

      <Swiper
        ref={ref}
        spaceBetween={8}
        slidesPerView="auto"
        slidesPerGroupAuto
        mousewheel={true}
        navigation={true}
        modules={[Mousewheel, Navigation]}
      >
        {records.map((item, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}>
            <EvidenceThumbnail
              key={index}
              src={item.iconUrl}
              selected={selectedIndex === index}
              onClick={() => setSelectedIndex?.(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
};

const EvidenceThumbnail = ({
  src,
  selected,
  onClick,
}: {
  src: string;
  selected?: boolean;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: { xs: 60, sm: 65, md: 80 },
        height: { xs: 60, sm: 65, md: 80 },
      }}
      style={{
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Image
        src={src}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.divider,
          userSelect: 'none',
          objectFit: 'cover',
        }}
      />

      {selected && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: '2px solid #ffc31e',
            pointerEvents: 'none',
          }}
        />
      )}
    </Box>
  );
};

const DialogCheckContent = () => {
  const { menu, selectedEvidence } = useCourtRecordContext();

  if (!selectedEvidence || !selectedEvidence.url || menu !== MENU.check) {
    return null;
  }

  return (
    <Media
      type={selectedEvidence.type}
      url={selectedEvidence.url}
      style={{ width: '100%', height: '100%' }}
      videoPlay={menu === MENU.check}
    />
  );
};

const DialogMessagesLogContent = () => {
  const { messagesLog, menu } = useCourtRecordContext();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menu === MENU.logs && messagesLog?.length && listRef.current) {
      const scrollToBottom = () => {
        if (listRef.current) {
          listRef.current.scrollTop = listRef.current.scrollHeight;
        }
      };

      scrollToBottom();

      // Try again in case the content is not fully loaded
      const timer = setTimeout(scrollToBottom, 50);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [menu, messagesLog]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const height = useMemo(
    () =>
      !isMobile ? 'calc(100dvh - 48px - 64px - 16px)' : 'calc(100dvh - 48px)',
    [isMobile],
  );

  if (!messagesLog?.length || menu !== MENU.logs) {
    return null;
  }

  return (
    <DialogContent
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        height,
      }}
    >
      <Box
        ref={listRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          px: { xs: 1, sm: 2 },
          py: 0,
        }}
      >
        <List dense sx={{ padding: 0 }}>
          {messagesLog.map((item, index) => (
            <MessageLogRow key={index} item={item} />
          ))}
        </List>
      </Box>
    </DialogContent>
  );
};

type MessageLogRowProps = {
  item: CaseState['messagesLog'][0];
};

const MessageLogRow = ({ item }: MessageLogRowProps) => {
  const isSystem = item.username === '.';
  const characterIconUrl = item.characterId
    ? assetStore.character.cache[item.characterId]?.iconUrl
    : undefined;

  return (
    <ListItem
      sx={{
        p: 0,
        py: 1,
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '&:last-child': { borderBottom: 'none' },
      }}
      alignItems="flex-start"
    >
      {characterIconUrl && !isSystem ? (
        <Box
          sx={{
            mr: 2,
            minWidth: 40,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            pt: 0.5,
          }}
        >
          <Image
            src={characterIconUrl}
            style={{
              width: 50,
              height: 50,
              objectFit: 'none',
              borderRadius: '50%',
            }}
          />
        </Box>
      ) : (
        <Box sx={{ mr: 2, minWidth: 40 }} />
      )}
      <Box
        sx={{
          flexGrow: 1,
          width: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          color={isSystem ? 'textSecondary' : 'info'}
          variant="body1"
          fontWeight={600}
          sx={{ mb: 0.5 }}
          noWrap
        >
          {isSystem ? 'System' : item.username}
        </Typography>

        <Typography
          variant="body2"
          whiteSpace="pre-line"
          sx={{
            backgroundColor: isSystem
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(25, 118, 210, 0.08)',
            p: 1,
            borderRadius: 1,
          }}
        >
          {item.message}
        </Typography>
      </Box>
    </ListItem>
  );
};

type CourtRecordDialogTitleProps = {
  onClose?: () => void;
};

const CourtRecordDialogTitle = ({ onClose }: CourtRecordDialogTitleProps) => {
  const { menu, setMenu, fullscreenCheck, setFullscreenCheck, messagesLog } =
    useCourtRecordContext();

  const handleClickFullscreen = () => {
    setFullscreenCheck((prev) => !prev);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      {menu === MENU.main ? (
        <Typography variant="h6" fontSize={16} noWrap>
          Court Record
        </Typography>
      ) : (
        <Button
          variant="text"
          color="inherit"
          startIcon={<Backward />}
          onClick={() => setMenu(MENU.main)}
        >
          Back
        </Button>
      )}

      <Stack direction="row" spacing={1}>
        {menu === MENU.main ? (
          <Button
            variant="text"
            color="inherit"
            startIcon={<Forum />}
            disabled={!messagesLog?.length}
            onClick={() => setMenu(MENU.logs)}
          >
            Log
          </Button>
        ) : menu === MENU.check ? (
          <Button
            variant="text"
            color="inherit"
            onClick={handleClickFullscreen}
          >
            {fullscreenCheck ? <FullscreenExit /> : <Fullscreen />}
          </Button>
        ) : undefined}

        {onClose && (
          <Button variant="text" color="inherit" onClick={onClose}>
            Close
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
