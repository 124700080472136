import BaseLayout from '@web/layouts/BaseLayout';
import PrimaryLayout from '@web/layouts/PrimaryLayout';
import CourtroomPage from '@web/pages/Courtroom';
import HomePage from '@web/pages/Home';
import MakerPage from '@web/pages/Maker';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CoreLayout from '@web/layouts/CoreLayout';
import PrimaryLayoutNoFooter from '@web/layouts/PrimaryLayoutNoFooter';
import AdminPage from '@web/pages/Admin';
import CasePage from '@web/pages/Case';
import EmbedPage from '@web/pages/Embed';
import ProviderLoginPage from '@web/pages/ProviderLogin';
import RecordPage from '@web/pages/Record';
import ResetPasswordPage from '@web/pages/ResetPassword';
import ScenePage from '@web/pages/Scene';
import { AuthProvider } from '@web/providers/auth/AuthProvider';
import AdminRoute from './AdminRoute';
import { useApp } from './hooks/useApp';

const App = () => {
  useApp();

  return (
    <BrowserRouter>
      <CoreLayout>
        <Routes>
          <Route element={<BaseLayout />}>
            <Route element={<PrimaryLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/maker" element={<MakerPage />} />
              <Route path="/edit/:type/:id/:title?" element={<MakerPage />} />
              <Route path="/reset-password/" element={<ResetPasswordPage />} />

              <Route
                path="/admin"
                element={
                  <AdminRoute>
                    <AdminPage />
                  </AdminRoute>
                }
              />
            </Route>

            <Route element={<PrimaryLayoutNoFooter />}>
              <Route path="/objection/:id" element={<ScenePage />} />
              <Route path="/case/:id/:title?" element={<CasePage />} />

              <Route path="/watch/:id" element={<RedirectToObjection />} />
              <Route path="/play/:id" element={<RedirectToObjection />} />
            </Route>

            <Route path="/embed/:id" element={<EmbedPage />} />
            <Route path="/courtroom" element={<CourtroomPage />} />
            <Route path="/provider/:provider" element={<ProviderLoginPage />} />
          </Route>

          <Route path="/record/:id" element={<RecordPage />} />
        </Routes>
      </CoreLayout>
    </BrowserRouter>
  );
};

const RedirectToObjection = () => {
  const { id } = useParams();

  return <Navigate to={`/objection/${id}`} />;
};

const AppWithAuth = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWithAuth;
