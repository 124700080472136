import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, Delete, DragHandle, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { disableParentRipple } from '@web/components/common';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { Background } from '@web/components/player/ui/Background';
import { Container } from '@web/components/player/ui/Container';
import { Desk } from '@web/components/player/ui/Desk';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { useAssetStore } from '@web/store/assets/state';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { Location } from '@web/types/project';
import { generateDefaultBackground } from '@web/utils/project';
import { CSSProperties, MouseEvent, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { BackgroundPicker } from '../ui/BackgroundPicker';
import { InvestigationPopper } from './InvestigationPopper';

enum Menu {
  MAIN,
  ADD,
  EDIT,
}

const state = proxy({
  menu: Menu.MAIN,
  add: {} as Pick<Location, 'name' | 'backgroundId' | 'hide' | 'revealed'>,
  edit: {} as Pick<
    Location,
    'id' | 'name' | 'backgroundId' | 'hide' | 'revealed'
  >,
  deleteId: '',
});

export const LocationsEditor = () => {
  const { menu } = useSnapshot(state);

  const onExit = () => {
    state.menu = Menu.MAIN;
  };

  return (
    <InvestigationPopper label="Locations" onExit={onExit} width={500}>
      {({ close }) => (
        <MultiHideView index={menu}>
          <LocationsMainEditor handleClose={close} />
          <LocationsAddEditor />
          <LocationsEditEditor />
        </MultiHideView>
      )}
    </InvestigationPopper>
  );
};

const LocationsMainEditor = ({ handleClose }: { handleClose: () => void }) => {
  const { locations } = useMakerStore();

  const handleClickAdd = () => {
    state.add = { backgroundId: 0, name: '', revealed: false, hide: false };
    state.menu = Menu.ADD;
  };

  return (
    <>
      {locations.length === 0 && (
        <CardContent sx={{ p: 1 }}>
          <Typography variant="h6" textAlign="center" noWrap>
            Add a location to get started
          </Typography>
        </CardContent>
      )}

      <LocationsList />

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button color="error" onClick={handleClose}>
          Close
        </Button>

        <Button color="success" startIcon={<Add />} onClick={handleClickAdd}>
          Add Location
        </Button>
      </CardActions>
    </>
  );
};

const LocationsList = () => {
  const { investigationGroup } = useMakerStore();

  const framesCountMap = useMemo(() => {
    if (!investigationGroup?.locations) return {};

    return investigationGroup.locations.reduce<Record<string, number>>(
      (acc, location) => {
        const frameSources = [
          location.frames,
          ...location.conversations.map((conv) => conv.frames),
          ...location.examine.map((exam) => exam.frames),
          location.presentFailureFrames,
          location.examineFailureFrames,
          location.completionFrames,
        ];

        acc[location.id] = frameSources.reduce(
          (sum, frames) => sum + frames.length,
          0,
        );

        return acc;
      },
      {},
    );
  }, [investigationGroup?.locations]);

  const handleDragEnd = useCallback(
    (sourceId: UniqueIdentifier, destinationId?: UniqueIdentifier) => {
      if (!makerStore.investigationGroup) return;

      const oldIndex = makerStore.investigationGroup.locations.findIndex(
        (i) => i.id === sourceId,
      );
      const newIndex = makerStore.investigationGroup.locations.findIndex(
        (i) => i.id === destinationId,
      );

      makerStore.investigationGroup.locations = arrayMove(
        makerStore.investigationGroup.locations,
        oldIndex,
        newIndex,
      );
    },
    [],
  );

  return (
    <List sx={{ maxHeight: '50vh', overflowY: 'auto' }} dense>
      <SortableDndProvider
        items={investigationGroup?.locations || []}
        onDragEnd={handleDragEnd}
      >
        {investigationGroup?.locations.map((location, index) => (
          <LocationRow
            key={location.id}
            location={location}
            index={index}
            framesCountMap={framesCountMap}
          />
        ))}
      </SortableDndProvider>
    </List>
  );
};

const LocationRow = ({
  location,
  index,
  framesCountMap,
}: {
  location: Location;
  index: number;
  framesCountMap: Record<string, number>;
}) => {
  const { deleteId } = useSnapshot(state);
  const { location: currentLocation, investigationGroup } = useMakerStore();

  const onEditClick = (event: MouseEvent, location: Location) => {
    event.stopPropagation();

    state.edit = {
      id: location.id,
      name: location.name,
      backgroundId: location.backgroundId,
      hide: location.hide,
      revealed: location.revealed,
    };

    state.menu = Menu.EDIT;
  };

  const onDeleteClick = (event: MouseEvent, location: Location) => {
    event.stopPropagation();

    state.deleteId = location.id;
  };

  const onChangeLocation = () => {
    if (!investigationGroup) return;

    changeLocation(location.id);
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: location.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (deleteId === location.id) {
    return <LocationDeleteRow />;
  }

  return (
    <ListItemButton
      ref={setNodeRef}
      selected={currentLocation?.id === location.id}
      sx={{ height: 60 }}
      style={style}
      onClick={onChangeLocation}
    >
      <ListItemAvatar sx={{ mr: { xs: 1, sm: 2 } }}>
        <BackgroundPreview backgroundId={location.backgroundId} height={50} />
      </ListItemAvatar>

      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <Typography component="div" mb={0.75}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Box
                display="flex"
                sx={{ touchAction: 'none' }}
                {...attributes}
                {...listeners}
              >
                <DragHandle sx={{ cursor: 'move' }} />
              </Box>

              <Typography
                component="div"
                variant="subtitle2"
                fontWeight="bold"
                mb={0.5}
                noWrap
              >
                {location.name}
              </Typography>
            </Stack>
          </Typography>
        }
        secondary={
          <Typography component="div" color="text.secondary">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption" lineHeight="unset">
                {framesCountMap[location.id]} Frames
              </Typography>
            </Stack>
          </Typography>
        }
      />

      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          onClick={(e) => onEditClick(e, location)}
          {...disableParentRipple}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => onDeleteClick(e, location)}
          {...disableParentRipple}
        >
          <Delete />
        </IconButton>
      </Stack>
    </ListItemButton>
  );
};

const LocationDeleteRow = () => {
  const handleDelete = () => {
    if (!makerStore.investigationGroup) return;

    if (makerStore.location?.id === state.deleteId) {
      const locationIndex = makerStore.locations.findIndex(
        (location) => location.id === state.deleteId,
      );

      const nextLocation = makerStore.locations[locationIndex + 1];
      const previousLocation = makerStore.locations[locationIndex - 1];

      if (nextLocation) {
        changeLocation(nextLocation.id);
      } else if (previousLocation) {
        changeLocation(previousLocation.id);
      }
    }

    makerStore.investigationGroup.locations =
      makerStore.investigationGroup.locations.filter(
        (location) => location.id !== state.deleteId,
      );
  };

  const handleCancel = () => {
    state.deleteId = '';
  };

  return (
    <ListItem sx={{ height: 60 }}>
      <Typography variant="caption" pr={0.5}>
        Delete this location? All frames in this location will be deleted.
      </Typography>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <XSmallButton variant="contained" color="error" onClick={handleDelete}>
          Delete
        </XSmallButton>
        <XSmallButton
          variant="contained"
          color="success"
          onClick={handleCancel}
        >
          Cancel
        </XSmallButton>
      </Stack>
    </ListItem>
  );
};

const LocationsAddEditor = () => {
  const { add } = useSnapshot(state);

  const handleClickAdd = () => {
    const newId = uuidv4();

    makerStore.investigationGroup?.locations.push({
      ...state.add,
      id: newId,
      conversations: [],
      examine: [],
      presentFailureFrames: [],
      examineFailureFrames: [],
      completionFrames: [],
      frames: [],
    });

    state.menu = Menu.MAIN;

    if (makerStore.locations.length === 0) {
      changeLocation(newId);
    }
  };

  const disableAdd = !add.name || !add.backgroundId;

  return (
    <>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, sm: 4 }}>
            <BackgroundPreview backgroundId={add.backgroundId} />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 8 }}>
            <Stack spacing={2}>
              <TextField
                label="Location Name"
                value={add.name || ''}
                onChange={(e) => {
                  state.add.name = e.target.value;
                }}
                size="small"
                fullWidth
              />

              <BackgroundPicker
                value={add.backgroundId}
                onChange={(id) => {
                  if (!id) return;
                  state.add.backgroundId = id;
                }}
                variant="outlined"
                placeholder="Location Background"
                fullWidth
                hideClear
              />

              <Stack direction="row" gap={1} justifyContent="space-between">
                <Checkbox
                  value={!!add.hide}
                  onChange={(value) => {
                    state.add.hide = value;
                  }}
                  label="Hide"
                  size="small"
                />

                <Checkbox
                  value={!!add.revealed}
                  onChange={(value) => {
                    state.add.revealed = value;
                  }}
                  label="Revealed"
                  size="small"
                />
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      </CardContent>

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          color="error"
          onClick={() => {
            state.menu = Menu.MAIN;
          }}
        >
          Cancel
        </Button>

        <Button color="success" disabled={disableAdd} onClick={handleClickAdd}>
          Add
        </Button>
      </CardActions>
    </>
  );
};

const LocationsEditEditor = () => {
  const { edit } = useSnapshot(state);

  const handleEdit = () => {
    if (!makerStore.investigationGroup) return;

    makerStore.investigationGroup.locations =
      makerStore.investigationGroup.locations.map((location) => {
        if (location.id === state.edit.id) {
          location.name = state.edit.name;
          location.backgroundId = state.edit.backgroundId;
          location.hide = state.edit.hide;
          location.revealed = state.edit.revealed;
        }

        return location;
      });

    state.menu = Menu.MAIN;
  };

  const disableEdit = !edit.name || !edit.backgroundId;

  return (
    <>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, sm: 4 }}>
            <BackgroundPreview backgroundId={edit.backgroundId} />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 8 }}>
            <Stack spacing={2}>
              <TextField
                label="Location Name"
                value={edit.name || ''}
                onChange={(e) => {
                  state.edit.name = e.target.value;
                }}
                size="small"
                fullWidth
              />

              <BackgroundPicker
                value={edit.backgroundId}
                onChange={(id) => {
                  if (!id) return;
                  state.edit.backgroundId = id;
                }}
                variant="outlined"
                placeholder="Location Background"
                fullWidth
                hideClear
              />

              <Stack direction="row" gap={1} justifyContent="space-between">
                <Checkbox
                  value={!!edit.hide}
                  onChange={(value) => {
                    state.edit.hide = value;
                  }}
                  label="Hide"
                  size="small"
                />

                <Checkbox
                  value={!!edit.revealed}
                  onChange={(value) => {
                    state.edit.revealed = value;
                  }}
                  label="Revealed"
                  size="small"
                />
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      </CardContent>

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          color="error"
          onClick={() => {
            state.menu = Menu.MAIN;
          }}
        >
          Cancel
        </Button>

        <Button color="success" disabled={disableEdit} onClick={handleEdit}>
          Edit
        </Button>
      </CardActions>
    </>
  );
};

const BackgroundPreview = ({
  backgroundId,
  height,
}: {
  backgroundId?: number;
  height?: number;
}) => {
  const {
    background: { cache },
  } = useAssetStore();

  const { aspectRatio } = useMakerStore();

  const background = backgroundId
    ? cache[backgroundId]
    : {
        url: generateDefaultBackground(480, aspectRatio),
        deskUrl: '',
        isWide: false,
      };

  return (
    <Container aspectRatio={aspectRatio} style={{ maxHeight: height }}>
      <Background url={background.url} isWide={background.isWide} />
      <Desk url={background.deskUrl} isWide={background.isWide} />
    </Container>
  );
};

const changeLocation = (locationId: string) => {
  if (!makerStore.investigationGroup) return;

  makerStore.framesTarget = {
    groupId: makerStore.investigationGroup.id,
    locationId,
  };

  makerStore.page = 1;
};
