import {
  MusicNote,
  PlayArrow,
  StopRounded,
  VolumeUp,
} from '@mui/icons-material';
import {
  Button,
  Chip,
  Divider,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import { MusicDto, SoundDto } from '@web/api/api';
import {
  AutocompleteWithFavorites,
  StyledAutocompletePopper,
} from '@web/components/common/form/AutocompleteWithFavorites';
import { Popper } from '@web/components/common/ui/Popper';
import { VirtualizedListbox } from '@web/components/common/ui/VirtualizedListbox';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useAudio } from '@web/hooks/useAudio';
import { useToggleValue } from '@web/hooks/useToggleValue';
import { rootStore } from '@web/store/root/state';
import React, { forwardRef, useEffect, useState } from 'react';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useAssetOptions } from '../hooks/useAssetOptions';
import { useFrameEditor } from '../providers/FrameEditorProvider';

type AudioTagProps = {
  type: 'sound' | 'music';
  favoriteList: (string | number)[];
  presetList: MusicDto[] | SoundDto[];
  userList: MusicDto[] | SoundDto[];
  width: number;
};

export const AudioTag = ({
  type,
  presetList,
  userList,
  favoriteList,
  width,
}: AudioTagProps) => {
  const [value, setValue] = useState<number>(0);
  const { anchorEl, toggle, close } = useAnchorMenu();

  const [filter, setFilter] = useToggleValue<'preset' | 'custom' | undefined>(
    undefined,
  );

  const { insertTag } = useFrameEditor();

  const { selectedValue, sortedOptions } = useAssetOptions({
    filter,
    value,
    type,
    presetList,
    userList,
  });

  const handleManageClick = () => {
    rootStore.assetsDialogTab = type;
    rootStore.dialogs.assets = true;

    close();
  };

  useEffect(() => {
    if (value === 0) {
      setValue((favoriteList[0] as number) || sortedOptions[0]?.id || 0);
    }
  }, [favoriteList, sortedOptions, value]);

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        {type === 'music' ? <MusicNote /> : <VolumeUp />}
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 320 }}
        placement="top"
      >
        <Stack p={1} spacing={1}>
          <AutocompleteWithFavorites
            type={type}
            label={type === 'music' ? 'Music' : 'Sound'}
            size="small"
            PaperComponent={AudioTagPaper}
            ListboxComponent={AudioTagVirtualizedListBox}
            ListboxProps={{ style: { maxHeight: '322px' } }}
            PopperComponent={StyledAutocompletePopper}
            slotProps={{
              paper: {
                filter,
                setFilter,
              } as PaperProps,
            }}
            value={selectedValue}
            onChange={(event, newValue) => {
              setValue(newValue?.id || 0);
            }}
            options={sortedOptions}
            disableClearable
          />

          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="info"
              size="small"
              onClick={() => {
                insertTag(
                  `${type === 'music' ? 'bgm' : 'bgs'}${selectedValue?.id}`,
                );
              }}
              fullWidth
            >
              Insert Tag
            </Button>

            <PreviewAudioButton audio={selectedValue} />
          </Stack>

          <Button
            variant="contained"
            color="violet"
            size="small"
            onClick={handleManageClick}
            fullWidth
          >
            Manage custom {type}
          </Button>

          <Divider />

          {type === 'music' && (
            <>
              <Typography textAlign="center">Stop/Fade music</Typography>

              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  fullWidth
                  onClick={() => {
                    insertTag('bgmfi');
                  }}
                >
                  Fade In
                </Button>

                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  fullWidth
                  onClick={() => {
                    insertTag('bgmfo');
                  }}
                >
                  Fade Out
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  fullWidth
                  onClick={() => {
                    insertTag('bgms');
                  }}
                >
                  Stop
                </Button>
              </Stack>
            </>
          )}

          {type === 'sound' && (
            <Button
              variant="contained"
              color="info"
              size="small"
              fullWidth
              onClick={() => {
                insertTag('bgss');
              }}
            >
              Stop sounds tag
            </Button>
          )}
        </Stack>
      </Popper>
    </>
  );
};

const PreviewAudioButton = ({
  audio,
}: {
  audio: MusicDto | SoundDto | null;
}) => {
  const { play, playing } = useAudio({
    url: audio?.url,
    volume: audio?.volume || 1,
  });

  return (
    <Button
      variant="contained"
      color="success"
      size="small"
      onClick={play}
      startIcon={!playing ? <PlayArrow /> : <StopRounded />}
      disabled={!audio?.url}
      fullWidth
    >
      {playing ? 'Stop' : 'Preview'}
    </Button>
  );
};

type AudioTagPaperProps = {
  filter: 'preset' | 'custom' | undefined;
  setFilter: (value: 'preset' | 'custom' | undefined) => void;
} & PaperProps;

const AudioTagPaper = (props: AudioTagPaperProps) => {
  const { children, filter, setFilter, ...other } = props;

  return (
    <Paper {...other} onMouseDown={(event) => event.preventDefault()}>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Chip
          label="Preset"
          color={filter === 'preset' ? 'info' : 'default'}
          onClick={() => setFilter('preset')}
          size="small"
          clickable
        />
        <Chip
          label="Custom"
          color={filter === 'custom' ? 'info' : 'default'}
          onClick={() => setFilter('custom')}
          size="small"
          clickable
        />
      </Stack>
      {children}
    </Paper>
  );
};

const AudioTagVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={46} height={46 * 7}>
      {props.children}
    </VirtualizedListbox>
  );
});
