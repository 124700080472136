/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alias, CaseFrame, Character_Side, Options } from '@shared/types';
import { BackgroundDto, CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { createContext, useContext } from 'react';
import { type usePlayerCase } from '../case/hooks/usePlayerCase';
import { usePlayerViewport } from '../case/hooks/usePlayerViewport';
import { useCharactersFades } from '../hooks/useCharactersFades';
import { useFrame } from '../hooks/useFrame';
import { usePlayerAudio } from '../hooks/usePlayerAudio';
import { usePlayerEffects } from '../hooks/usePlayerEffects';
import { usePlayerLoading } from '../hooks/usePlayerLoading';
import { usePlayerSaveLoad } from '../hooks/usePlayerSaveLoad';
import { usePlayerUi } from '../hooks/usePlayerUi';
import { useProjectPlayer } from '../hooks/useProjectPlayer';
import { useTimeline } from '../hooks/useTimeline';
import { PlayerDefaults } from '../types/playerDefaults';
import { EvidenceState, GameState } from '../types/state';

export type Step =
  | 'start'
  | 'fade_out'
  | 'fade_in'
  | 'speech_bubble'
  | 'transition'
  | 'transition_wait'
  | 'character'
  | 'delayed_actions'
  | 'dialogue'
  | 'pose_end_animation'
  | 'finished';

export type PlayerContextType = {
  state: {
    steps?: Step[];
    stepIndex?: number;
    characterStep?: 'idle' | 'talking' | 'animation';
    characterAnimationPlaying?: boolean;
    characterTalking?: boolean;
    options?: Options;
    startedPlaying?: boolean;
    isPlaying?: boolean;
    musicId?: number;
    evidence?: EvidenceState;
    presetPopupsImageUrls?: string[];
    showNextButton?: boolean;
    showPreviousButton?: boolean;
    showDialoguebox?: boolean;
    skipping?: boolean;
    galleryImageUrls: Record<Character_Side, string>;
    galleryImageUrlsAj: Record<Character_Side, string>;
    isAutoplay?: boolean;
    backgroundLeft?: number;
  };
  step?: Step;
  playerDefaults: PlayerDefaults;
  aliases: Alias[];
  frame: ReturnType<typeof useFrame>;
  playerUi: ReturnType<typeof usePlayerUi>;
  playerLoading: ReturnType<typeof usePlayerLoading>;
  playerViewport: ReturnType<typeof usePlayerViewport>;
  playerCharactersFades: ReturnType<typeof useCharactersFades>;
  playerEffects: ReturnType<typeof usePlayerEffects>;
  playerCase: ReturnType<typeof usePlayerCase>;
  playerContainerRef: React.RefObject<HTMLDivElement>;
  playerViewportRef: React.RefObject<HTMLDivElement>;
  actions: {
    init: (
      project: SceneProjectDto | CaseProjectDto,
      startingFrameOrState?: number | GameState,
      savedSessionData?: any,
    ) => void;
    start: () => void;
    next: () => void;
    preloadThenNext: (state: GameState | undefined) => Promise<void>;
    preloadFramesThenNext: (frames: CaseFrame[]) => Promise<void>;
    previous: () => void;
    update: (data: Partial<PlayerContextType['state']>) => void;
    reset: () => void;
    nextStep: () => void;
    playMusic: (id: number | undefined, url: string, volume: number) => void;
    stopMusic: () => void;
    endNext: () => void;
    onClick: (args: PlayerClickType) => void;
  };
  timeline: ReturnType<typeof useTimeline>;
  audio: ReturnType<typeof usePlayerAudio>;
  defaultBackground: BackgroundDto;
  isEnded?: boolean;
  isCase?: boolean;
  projectSnapshot: ReturnType<typeof useProjectPlayer>['snapshot'];
  projectActions: ReturnType<typeof useProjectPlayer>['actions'];
  playerSaveLoad: ReturnType<typeof usePlayerSaveLoad>;
  savedSession?: any;
};

export type PlayerStateType = PlayerContextType['state'];
export type PlayerActionsType = PlayerContextType['actions'];

export const PlayerContext = createContext<PlayerContextType>(
  {} as PlayerContextType,
);

export type PlayerClickType = {
  x: number;
  y: number;
  direction: 'next' | 'previous';
  isRightClick?: boolean;
};

export const usePlayer = (): PlayerContextType => {
  const context = useContext(PlayerContext);

  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }

  return context;
};
