import { Stack } from '@mui/material';
import { CaseAction_ModifyHealth } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { useMemo } from 'react';
import { useCaseActionInput } from '../CaseActionInputs';

export const ModifyHealthInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_ModifyHealth>();

  const options = useMemo(
    () => [
      { id: 'set', name: 'Set' },
      { id: 'increase', name: 'Increase' },
      { id: 'decrease', name: 'Decrease' },
    ],
    [],
  );

  const selectedValue = options.find((o) => o.id === caseAction.type) || null;

  return (
    <Stack direction="row" spacing={1}>
      <SimpleSelect
        options={options}
        value={selectedValue}
        onChange={(value) =>
          updateCaseAction({
            type: value?.id as 'set' | 'increase' | 'decrease',
          })
        }
        size="small"
        fullWidth
      />

      <NumberTextField
        label="Amount"
        value={caseAction.amount || 0}
        onChange={(event) =>
          updateCaseAction({ amount: parseInt(event.target.value) })
        }
        size="small"
        fullWidth
      />
    </Stack>
  );
};
