import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';

type HealthBarProps = {
  health: number;
  flashingHealth: number;
  width?: number;
};

export const HealthBar = ({
  health,
  flashingHealth,
  width,
}: HealthBarProps) => {
  const normalizedHealth = Math.max(0, Math.min(100, health));
  const normalizedFlashing = Math.max(
    0,
    Math.min(normalizedHealth, flashingHealth),
  );

  const safeWidth = `${normalizedHealth - normalizedFlashing}%`;
  const flashingWidth = `${normalizedFlashing}%`;

  return (
    <Box
      sx={{
        width: width ? `${width}px` : '100%',
        height: 24,
        border: '2px solid #333',
        borderRadius: 1,
        overflow: 'hidden',
        bgcolor: '#B71C1C',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: safeWidth,
          height: '100%',
          bgcolor: '#4CAF50', // Green color
          transition: 'width 0.3s ease',
        }}
      />

      <Box
        sx={{
          width: flashingWidth,
          height: '100%',
          bgcolor: '#FFC107', // Yellow color
          animation: `${flash} 1s infinite`,
          transition: 'width 0.3s ease',
        }}
      />
    </Box>
  );
};

const flash = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  `;
