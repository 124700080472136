import { Add, Remove, TextRotationNone } from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useState } from 'react';
import { FrameToolbarButton } from '../frame/FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';

export const TextSpeedTag = ({ width }: { width: number }) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const [value, setValue] = useState<number>(35);

  const { insertTag } = useFrameEditor();

  const handleAddValue = (val: number) => {
    setValue((prev) => Math.max(Math.min(prev + val, 200), 0));
  };

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <TextRotationNone />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 318 }}
        placement="top"
      >
        <Stack p={1}>
          <Typography>Text Speed</Typography>
          <Typography color="text.secondary" variant="body2">
            Change the text speed at a certain point
          </Typography>
        </Stack>

        <Divider />

        <Stack p={1} spacing={1}>
          <Stack direction="row">
            <Typography variant="h4">{value}</Typography>

            <TextRotationNone />
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton size="small" onClick={() => handleAddValue(-1)}>
              <Remove />
            </IconButton>

            <Slider
              value={value}
              onChange={(e, v) => setValue(v as number)}
              min={0}
              max={200}
            />

            <IconButton size="small" onClick={() => handleAddValue(1)}>
              <Add />
            </IconButton>
          </Stack>

          <Button
            color="accent"
            variant="contained"
            size="small"
            onClick={() => {
              insertTag(`ts${value}`);
            }}
            fullWidth
          >
            Apply
          </Button>
        </Stack>
      </Popper>
    </>
  );
};
