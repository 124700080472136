import { CaseAction_FlashingHealth } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { useCaseActionInput } from '../CaseActionInputs';

export const FlashingHealthInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_FlashingHealth>();

  return (
    <NumberTextField
      placeholder="Amount"
      value={caseAction.amount || 0}
      onChange={(event) =>
        updateCaseAction({ amount: parseInt(event.target.value) })
      }
      size="small"
      fullWidth
      inputProps={{ min: 0, max: 100 }}
    />
  );
};
