import { CaseFrame, Effect, FramePair, Pair } from '@shared/types';
import {
  BackgroundDto,
  PopupDto,
  PoseDto,
  SpeechBubbleDto,
} from '@web/api/api';
import { useCharacters } from '@web/components/player/hooks/useCharacters';
import { Character } from '@web/types/character';
import { createContext, useContext } from 'react';

type ThumbnailContext = {
  serverThumbnails?: boolean;
  background?: BackgroundDto;
  character?: Character;
  characters?: ReturnType<typeof useCharacters>;
  pairPoses?: FramePair['poseIds'];
  pair?: Pair;
  speechBubble?: SpeechBubbleDto;
  popup?: PopupDto;
  pose?: PoseDto;
  effect?: Effect;
  transition?: CaseFrame['transition'];
  flipTargets?: CaseFrame['flipped'];
};

const thumbnailContext = createContext<ThumbnailContext>({});

export const ThumbnailProvider = thumbnailContext.Provider;

export const useThumbnail = () => useContext(thumbnailContext);
