import {
  ContentCopy,
  Delete,
  FlashOn,
  Settings,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, updateFrame, useMakerStore } from '@web/store/maker/state';
import { isCrossExaminationGroup } from '@web/store/maker/types';
import { rootActions } from '@web/store/root/actions';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Switch from '../../common/form/Switch';
import { MenuList } from '../../common/ui/MenuList';
import { Popper } from '../../common/ui/Popper';
import { useFrameEditor } from '../providers/FrameEditorProvider';
import { FrameToolbarButton } from './FrameToolbarButton';

const mobileBreakpoint = 650;

export const FrameEditorAppBar = React.memo(() => {
  const { frameId, frameIndex } = useFrameEditor();
  const { showPressPresent } = useMakerStore();

  return (
    <AppBar position="static" color="secondary" elevation={0} enableColorOnDark>
      <Toolbar
        variant="dense"
        sx={{ minHeight: '32px' }}
        onClick={() => {
          makerStore.focusedFrameIndex = frameIndex;
          makerStore.sceneEditorFrameIndex = frameIndex;
          makerStore.frameActionsFrameIndex = frameIndex;
        }}
        disableGutters
      >
        <FrameIdCopyToolbarButton id={frameId} />

        <FrameMenuToolbarButton />

        <Box flexGrow={1} />

        <HideFrameToggle />

        <FrameSettingsToolbarButton />

        <PreviewFrameToolbarButton />

        <DeleteFrameToolbarButton />

        {showPressPresent && <PressButton />}
        {showPressPresent && <PresentButton />}

        <ActionsShortcutToolbarButton />
      </Toolbar>
    </AppBar>
  );
});

const ActionsShortcutToolbarButton = React.memo(() => {
  const { frameIndex } = useFrameEditor();

  const handleFrameActionsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameActions = true;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (isMobile) return null;

  return (
    <FrameToolbarButton
      sx={{ paddingInline: 2 }}
      width={48}
      height={32}
      onClick={handleFrameActionsClick}
    >
      Actions
    </FrameToolbarButton>
  );
});

const HideFrameToggle = React.memo(() => {
  const { frames, isCaseProject } = useMakerStore();
  const { frameIndex } = useFrameEditor();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (isMobile || !isCaseProject) return null;

  const handleHideFrameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFrame({ hide: event.target.checked }, frameIndex);
  };

  return (
    <Box mx={1}>
      <Switch
        checked={frames[frameIndex].hide || false}
        onChange={handleHideFrameChange}
        color="info"
        label="Hide"
        size="small"
        labelProps={{ style: { opacity: 0.8 } }}
      />
    </Box>
  );
});

const DeleteFrameToolbarButton = React.memo(() => {
  const { frameIndex, frameId } = useFrameEditor();

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    const pressFramesCount =
      makerStore.crossExaminationGroup?.pressFrames[frameId]?.length;

    const presentFramesCount =
      makerStore.crossExaminationGroup?.contradictions[frameId]?.length;

    if ((pressFramesCount || presentFramesCount) && !event.shiftKey) {
      rootActions.showConfirmationDialog({
        message:
          'This frame contains press frames or contradictions. They will be deleted, even if you undo. Do you want to proceed?',
        tip: !isMobile
          ? 'Tip: Hold Shift to skip this confirmation next time.'
          : undefined,
        onConfirm: () => makerActions.deleteFrame(frameIndex),
      });

      return;
    }

    makerActions.deleteFrame(frameIndex);
  };

  return (
    <FrameToolbarButton bl={true} width={48} height={32} onClick={handleDelete}>
      <Delete />
    </FrameToolbarButton>
  );
});

const FrameIdCopyToolbarButton = React.memo(({ id }: { id: number }) => {
  const [tooltipText, setTooltipText] = useState('Copy frame ID');

  const {
    project: { type },
  } = useMakerStore();
  const { frameIndex } = useFrameEditor();

  const handleCopy = () => {
    navigator.clipboard.writeText(String(id));

    setTooltipText('Copied!');

    setTimeout(() => {
      setTooltipText('Copy frame ID');
    }, 1500);
  };

  return (
    <Tooltip title={tooltipText} placement="top">
      <Box>
        <FrameToolbarButton
          br={true}
          width={48}
          height={32}
          onClick={handleCopy}
        >
          <Typography variant="h6">
            {type === 'case' ? id : frameIndex + 1}
          </Typography>
        </FrameToolbarButton>
      </Box>
    </Tooltip>
  );
});

const FrameSettingsToolbarButton = React.memo(() => {
  const { anchorEl, close, toggle } = useAnchorMenu();
  const { frames } = useMakerStore();
  const { frameIndex } = useFrameEditor();

  const handleFrameActionsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameActions = true;

    close();
  };

  const handleFrameEffectsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameEffects = true;

    close();
  };

  return (
    <>
      <FrameToolbarButton bl={true} width={48} height={32} onClick={toggle}>
        {frames[frameIndex].actions?.length || frames[frameIndex].caseAction ? (
          <FlashOn />
        ) : (
          <Settings />
        )}
      </FrameToolbarButton>

      <Popper
        sx={{ width: 300 }}
        placement="bottom-start"
        anchorEl={anchorEl}
        onClose={close}
        open={!!anchorEl}
      >
        <FrameSettingsToggles />

        <Divider />

        <MobilePressPresentButtons />

        <Stack direction="row" spacing={1} p={1}>
          <Button
            variant="contained"
            color="info"
            onClick={handleFrameActionsClick}
            fullWidth
          >
            Actions
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={handleFrameEffectsClick}
            fullWidth
          >
            Effects
          </Button>
        </Stack>
      </Popper>
    </>
  );
});

const FrameSettingsToggles = React.memo(() => {
  const { frameIndex } = useFrameEditor();
  const { frames, isCaseProject } = useMakerStore();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  const handleMoveToNextChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateFrame({ moveToNext: checked }, frameIndex);
  };

  const handleMergeNextChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateFrame({ mergeWithNext: checked }, frameIndex);
  };

  const handleHideFrameChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateFrame({ hide: checked }, frameIndex);
  };

  return (
    <Stack spacing={2} p={1}>
      <Typography fontWeight="bold">Frame Options</Typography>

      <Stack spacing={1}>
        {isMobile && isCaseProject && (
          <Switch
            color="info"
            label="Hide frame"
            checked={frames[frameIndex].hide || false}
            onChange={handleHideFrameChange}
          />
        )}

        <Switch
          color="info"
          label="Move to next frame immediately"
          checked={frames[frameIndex].moveToNext || false}
          onChange={handleMoveToNextChange}
        />

        <Switch
          color="info"
          label="Merge text with next"
          checked={frames[frameIndex].mergeWithNext || false}
          onChange={handleMergeNextChange}
        />
      </Stack>
    </Stack>
  );
});

const MobilePressPresentButtons = React.memo(() => {
  const { group } = useMakerStore();
  const { frameId, frameIndex } = useFrameEditor();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (!isMobile || !isCrossExaminationGroup(group)) return null;

  const pressCount =
    makerStore.crossExaminationGroup?.pressFrames[frameId]?.length || 0;
  const presentCount =
    makerStore.crossExaminationGroup?.contradictions[frameId]?.length || 0;

  return (
    <Stack direction="row" spacing={1} p={1} pb={0}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => SetPressFrame(frameId)}
        fullWidth
      >
        Press {pressCount ? `(${pressCount})` : ''}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => setPresentFrame(frameIndex)}
        fullWidth
      >
        Present {presentCount ? `(${presentCount})` : ''}
      </Button>
    </Stack>
  );
});

const FrameMenuToolbarButton = () => {
  const { frameIndex, frameId } = useFrameEditor();
  const { anchorEl, close, toggle } = useAnchorMenu();

  const comment = makerStore.group?.comments[frameId];

  const handleCopyFrame = (full: boolean) => {
    makerActions.copyFrame(frameIndex, full);

    close();
  };

  const handleToggleComment = () => {
    if (!makerStore.group) return;

    if (comment !== undefined) {
      delete makerStore.group?.comments[frameId];
    } else {
      makerStore.newCommentFrameId = frameId;
      makerStore.group.comments[frameId] = '';
    }

    close();
  };

  return (
    <>
      <FrameToolbarButton br={true} width={48} height={32} onClick={toggle}>
        <ContentCopy />
      </FrameToolbarButton>

      <MenuList
        placement="bottom-start"
        anchorEl={anchorEl}
        onClose={close}
        open={!!anchorEl}
      >
        <MenuItem onClick={() => handleCopyFrame(false)}>Copy frame</MenuItem>
        <MenuItem onClick={() => handleCopyFrame(true)}>
          Copy frame (full)
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleToggleComment}>
          {comment !== undefined ? 'Remove' : 'Add'} comment
        </MenuItem>
      </MenuList>
    </>
  );
};

const PreviewFrameToolbarButton = React.memo(() => {
  const { frameId, frameIndex } = useFrameEditor();

  const { frames } = useMakerStore();
  const frame = frames[frameIndex];

  const handlePreview = useCallback(() => {
    makerStore.previewFrameId = frameId;
    makerStore.dialogs.preview = true;
  }, [frameId]);

  return (
    <FrameToolbarButton
      bl={true}
      width={48}
      height={32}
      onClick={handlePreview}
      disabled={frame.hide}
    >
      {!frame.hide ? <Visibility /> : <VisibilityOff />}
    </FrameToolbarButton>
  );
});

const PressButton = React.memo(() => {
  const { frameId } = useFrameEditor();

  const handlePressClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (!isCrossExaminationGroup(makerStore.group)) return;

    if (!makerStore.group.pressFrames[frameId]) {
      makerStore.group.pressFrames[frameId] = [];
    }

    SetPressFrame(frameId);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (isMobile) return null;

  const pressFramesCount =
    makerStore.crossExaminationGroup?.pressFrames[frameId]?.length || 0;

  return (
    <StyledBadge badgeContent={pressFramesCount} max={99} color="accent">
      <FrameToolbarButton
        sx={{ paddingInline: 2 }}
        width={48}
        height={32}
        onClick={handlePressClick}
      >
        Press
      </FrameToolbarButton>
    </StyledBadge>
  );
});

const PresentButton = React.memo(() => {
  const { group } = useMakerStore();
  const { frameIndex, frameId } = useFrameEditor();

  const handlePresentClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    setPresentFrame(frameIndex);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (isMobile) return null;

  const presentFramesCount =
    group && isCrossExaminationGroup(group)
      ? group.contradictions[frameId]?.length || 0
      : 0;

  return (
    <StyledBadge badgeContent={presentFramesCount} max={99} color="accent">
      <FrameToolbarButton
        sx={{ paddingInline: 2 }}
        width={48}
        height={32}
        onClick={handlePresentClick}
      >
        Present
      </FrameToolbarButton>
    </StyledBadge>
  );
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const SetPressFrame = (frameId: number) => {
  makerStore.framesTarget.pressFrame = frameId;
  makerStore.page = 1;
};

const setPresentFrame = (frameIndex: number) => {
  if (makerStore.focusedFrameIndex !== frameIndex) {
    makerStore.focusedFrameIndex = frameIndex;
  }

  makerStore.dialogs.contradictions = true;
};
