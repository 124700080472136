import { Alias, CaseFrame, Pair } from '@shared/types';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import frameActions from '@web/data/frame-actions';
import { presetRepeatablePoses } from '@web/data/preset/preset-poses';
import { useMemo } from 'react';
import { type usePlayerCase } from '../case/hooks/usePlayerCase';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import { replaceVariablesInText } from '../utils/utils';
import { useBackground } from './useBackground';
import { useCharacter } from './useCharacter';
import { useCharacters } from './useCharacters';
import { usePopup } from './usePopup';
import { useSpeechBlip } from './useSpeechBlip';
import { useSpeechBubble } from './useSpeechBubble';

export const useFrame = (
  frame?: CaseFrame,
  previousFrame?: CaseFrame,
  aliases?: Alias[],
  pairs?: Pair[],
  variables?: ReturnType<typeof usePlayerCase>['variables'],
) => {
  const text = useMemo(() => {
    if (!frame) return '';

    let result = frame.text || '';

    if (variables && result.includes('[VAR:')) {
      result = replaceVariablesInText(result, variables);
    }

    return result;
  }, [frame, variables]);

  const plainText = useMemo(() => FrameTextUtils.getPlainText(text), [text]);

  const background = useBackground(frame);
  const previousBackground = useBackground(previousFrame);
  const speechBubble = useSpeechBubble(frame);
  const character = useCharacter(frame);
  const characters = useCharacters(frame, pairs);

  const popup = usePopup(frame);
  const speechBlipUrl = useSpeechBlip(frame);
  const pose = character?.poses.find((pose) => pose.id === frame?.poseId);

  const pair = useMemo(
    () => pairs?.find((f) => f.id === frame?.pair?.id),
    [frame?.pair?.id, pairs],
  );

  const meta = usePlayerMeta();

  const shouldShowSpeechBubble = !!frame?.speechBubble;

  const baseShouldPlayPoseAnimation = useMemo(() => {
    // If no frame or pose, or if animation is disabled, don't play animation
    if (!frame || !frame.poseId || frame.noPoseAnimation || !pose) {
      return false;
    }

    // No animations available, so don't play
    if (
      !pose.poseStates?.length &&
      !pose.poseAudioTicks?.length &&
      !pose.poseFunctionTicks?.length
    ) {
      return false;
    }

    // Normal character (not a pair) - check if pose changed or is a repeatable preset pose
    if (!frame.pair) {
      return (
        frame.poseId !== previousFrame?.poseId ||
        (!meta?.preventSamePoseAnimation &&
          character?.isPreset &&
          presetRepeatablePoses.includes(pose.id))
      );
    }

    // For paired characters
    // If previous frame had no pair or different pair ID, it's a new pair setup
    if (!previousFrame?.pair || previousFrame.pair.id !== frame.pair.id) {
      return true;
    }

    // Same pair - check if the specific character's pose has changed
    if (frame.characterId !== undefined) {
      // Get the current pose ID for this character
      const currentPoseId = frame.poseId;

      // Get the previous pose ID for this character from the pair.poseIds
      const previousPoseId =
        previousFrame.characterId === frame.characterId
          ? previousFrame.poseId
          : (previousFrame.pair.poseIds[frame.characterId] ?? null);

      // Compare the pose IDs for this specific character
      if (previousPoseId !== currentPoseId) {
        return true;
      }

      // Check if it's a repeatable preset pose
      return (
        !meta?.preventSamePoseAnimation &&
        character?.isPreset &&
        presetRepeatablePoses.includes(pose.id)
      );
    }

    // Default case - different active character in the pair or other cases
    // TODO: Check and fix it - still it should not replay the same pose animation if the pose is the same
    return true;
  }, [frame, previousFrame, pose, character, meta?.preventSamePoseAnimation]);

  const shouldPlayPoseAnimation = useMemo(
    () =>
      baseShouldPlayPoseAnimation &&
      pose &&
      (pose.poseStates?.filter((f) => !f.playAtTextEnd).length > 0 ||
        pose.poseAudioTicks?.filter((f) => !f.playAtTextEnd).length > 0 ||
        pose.poseFunctionTicks?.length > 0),
    [baseShouldPlayPoseAnimation, pose],
  );

  const shouldPlayEndPoseAnimation = useMemo(
    () =>
      baseShouldPlayPoseAnimation &&
      pose &&
      (pose.poseStates?.filter((f) => f.playAtTextEnd).length > 0 ||
        pose.poseAudioTicks?.filter((f) => f.playAtTextEnd).length > 0),
    [baseShouldPlayPoseAnimation, pose],
  );

  const shouldClearDialogue =
    !previousFrame?.mergeWithNext && plainText.length > 0;
  const shouldPlayPoseTalkingAnimation = useMemo(
    () =>
      !frame?.doNotTalk &&
      (!plainText.trim().startsWith('(') || !plainText.trim().endsWith(')')) &&
      plainText.replace(/\./g, '').replace(/ /g, '').length > 0,
    [frame?.doNotTalk, plainText],
  );
  const shouldWaitForPoseAnimation = !frame?.noPauseForAnimation;

  const nameplate = useMemo(() => {
    let result = '.';

    if (frame?.customName) {
      result = frame.customName;
    } else if (character) {
      const alias = aliases?.find((f) => f.from === character.nameplate);

      result = alias?.to || character.nameplate;
    }

    if (variables && result.includes('[VAR:')) {
      result = replaceVariablesInText(result, variables);
    }

    return result;
  }, [aliases, character, frame?.customName, variables]);

  const shouldRunStartActions = useMemo(
    () =>
      frame?.actions?.some(
        (action) =>
          frameActions.find((fa) => fa.id === action.actionId)?.runAtStart,
      ),
    [frame],
  );
  const shouldRunDelayedActions = useMemo(
    () =>
      frame?.actions?.some(
        (action) =>
          !frameActions.find((fa) => fa.id === action.actionId)?.runAtStart,
      ),
    [frame],
  );

  const offscreenFrame = useMemo(
    () => frame?.actions?.find((f) => f.actionId === 6),
    [frame],
  );

  const shouldPanBackground = background?.isWide && !!frame?.transition;
  const transitionDuration =
    shouldPanBackground &&
    !pose?.isSpeedlines &&
    frame.transition &&
    frame.transition.duration > 0 &&
    background?.id === previousBackground?.id
      ? frame.transition.duration
      : 0;

  return {
    background,
    speechBubble,
    character,
    characters,
    popup,
    pose,
    pair,
    text,
    plainText,
    shouldShowSpeechBubble,
    shouldPlayPoseAnimation,
    shouldPlayEndPoseAnimation,
    shouldClearDialogue,
    shouldPlayPoseTalkingAnimation,
    shouldWaitForPoseAnimation,
    shouldRunStartActions,
    shouldRunDelayedActions,
    speechBlipUrl,
    nameplate,
    frame,
    previousFrame,
    offscreenFrame,
    shouldPanBackground,
    transitionDuration,
  };
};
