import { Pair, PairedCharacter } from '@shared/types';
import { useAssetStore } from '@web/store/assets/state';
import { Character } from '@web/types/character';
import { Frame } from '@web/types/project';
import { useMemo } from 'react';

export type CharacterWithPair =
  | {
      character: Character;
      pair: PairedCharacter;
    }
  | undefined;

export const usePairedCharacters = (
  frame: Frame | undefined,
  pairs: Pair[] | undefined,
) => {
  const {
    character: { cache },
  } = useAssetStore();

  const pair = useMemo(
    () =>
      pairs && frame?.pair?.id
        ? pairs.find((f) => f.id === frame.pair?.id)
        : undefined,
    [frame?.pair?.id, pairs],
  );

  const pairedCharacters = useMemo<CharacterWithPair[]>(() => {
    if (!pair) {
      return [];
    }

    return pair.pairs.map((pair) => {
      const character = cache[pair.characterId || 0];

      if (!character) {
        return undefined;
      }

      return { character, pair };
    });
  }, [cache, pair]);

  return { pairedCharacters };
};
