import { useCallback, useEffect, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { usePlayer } from '../providers/PlayerProvider';

type usePlayerInteractionState = {
  leftClickTime: number;
  skipping: boolean;
};

const defaultState: usePlayerInteractionState = {
  leftClickTime: 0,
  skipping: false,
};

export const usePlayerInteraction = () => {
  const state = useRef(proxy<usePlayerInteractionState>(defaultState)).current;
  const snapshot = useSnapshot(state);
  const skipThreshold = 175;

  const skipTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const skipIntervalRef = useRef<ReturnType<typeof setInterval>>();

  const {
    actions: { onClick },
    isEnded,
    playerDefaults: { enableSkipping },
  } = usePlayer();

  const validTarget = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) =>
      e.target === e.currentTarget ||
      (e.target as HTMLElement).classList.contains('ol_viewport'),
    [],
  );

  const beginSkipping = useCallback(() => {
    if (isEnded) return;

    state.skipping = true;
  }, [isEnded, state]);

  const generalEnd = useCallback(() => {
    state.skipping = false;
  }, [state]);

  const startInteraction = useCallback(() => {
    state.leftClickTime = Date.now();

    if (skipIntervalRef.current) {
      clearInterval(skipIntervalRef.current);
    }

    if (skipTimeoutRef.current) {
      clearTimeout(skipTimeoutRef.current);
    }

    if (!enableSkipping) return;

    skipTimeoutRef.current = setTimeout(beginSkipping, skipThreshold);
  }, [beginSkipping, enableSkipping, state]);

  const endInteraction = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.PointerEvent<HTMLDivElement>,
    ) => {
      generalEnd();

      if (skipTimeoutRef.current) {
        clearTimeout(skipTimeoutRef.current);
      }

      const delay = Date.now() - snapshot.leftClickTime;

      const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      const clientX = event.clientX;
      const clientY = event.clientY;

      const x = ((clientX - rect.left) / rect.width) * 100;
      const y = ((clientY - rect.top) / rect.height) * 100;

      if (delay < skipThreshold || !enableSkipping) {
        onClick({
          x,
          y,
          direction: clientX > rect.left + rect.width / 2 ? 'next' : 'previous',
        });
      }
    },
    [enableSkipping, generalEnd, onClick, snapshot.leftClickTime],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (validTarget(e) && e.button === 0) {
        startInteraction();
      }
    },
    [startInteraction, validTarget],
  );

  const handleTouchStart = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (validTarget(e)) {
        startInteraction();
      }
    },
    [startInteraction, validTarget],
  );

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (validTarget(e) && e.button === 0) {
        endInteraction(e);
      }
    },
    [endInteraction, validTarget],
  );

  const handleTouchEnd = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (validTarget(e)) {
        endInteraction(e);
      }
    },
    [endInteraction, validTarget],
  );

  const preventContextMenu = (
    e: React.MouseEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>,
  ) => {
    if (validTarget(e)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!snapshot.skipping) {
      clearInterval(skipIntervalRef.current);

      return;
    }

    skipIntervalRef.current = setInterval(() => {
      if (snapshot.skipping && !isEnded) {
        onClick({
          x: 50,
          y: 50,
          direction: 'next',
        });
      }
    }, 120);

    return () => {
      clearInterval(skipIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnded, snapshot.skipping]);

  useEffect(() => {
    document.addEventListener('mouseup', generalEnd);
    document.addEventListener('touchend', generalEnd);

    return () => {
      document.removeEventListener('mouseup', generalEnd);
      document.removeEventListener('touchend', generalEnd);
    };
  }, [generalEnd]);

  return {
    handleMouseDown,
    handleMouseUp,
    handleTouchStart,
    handleTouchEnd,
    preventContextMenu,
  };
};
