import { CaseAction_SetGameOverRedirection, GroupType } from '@shared/types';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { useMakerStore } from '@web/store/maker/state';
import { useMemo } from 'react';
import { useCaseActionInput } from '../CaseActionInputs';

export const SetGameOverRedirectionInput = () => {
  const { caseAction, updateCaseAction } =
    useCaseActionInput<CaseAction_SetGameOverRedirection>();

  const { caseProject } = useMakerStore();

  const gameOverGroups = useMemo(
    () =>
      caseProject.groups
        .filter((group) => group.type === GroupType.Gameover)
        .map((group) => ({ id: group.id, name: group.name })),
    [caseProject.groups],
  );

  const selectedGroup =
    gameOverGroups.find((group) => group.id === caseAction.groupId) || null;

  return (
    <SimpleSelect
      label="Game Over Group"
      options={gameOverGroups}
      value={selectedGroup}
      onChange={(value) => updateCaseAction({ groupId: value?.id })}
      size="small"
      fullWidth
    />
  );
};
