import {
  Autocomplete,
  AutocompleteProps,
  TextFieldVariants,
} from '@mui/material';
import { AdornmentButton } from '@web/components/maker/ui/AdornmentButton';
import { RenderAutocompleteInput } from '../ui/RenderAutocompleteInput';

export type WithIdAndName = {
  id: string | number | null;
  name: string;
};

type SimpleSelectProps<
  T,
  DisableClearable extends boolean | undefined = boolean,
  Multiple extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  label?: string;
  value: T | null;
  onChange: (value: T | null) => void;
  options: T[];
  variant?: TextFieldVariants | undefined;
  error?: string;
  clearable?: boolean;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'onChange'
>;

export const SimpleSelect = <T extends WithIdAndName>({
  label,
  value,
  onChange,
  options,
  error,
  variant,
  clearable,
  renderOption: customRenderOption,
  ...props
}: SimpleSelectProps<T>) => {
  const { sx, ...other } = props;

  return (
    <Autocomplete
      {...other}
      options={options}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label={label}
          variant={variant ?? 'outlined'}
          error={!!error}
          helperText={error}
          InputProps={
            clearable && value !== null && value !== undefined
              ? {
                  endAdornment: (
                    <AdornmentButton
                      size="small"
                      sx={{ height: 28, width: 28 }}
                      onClick={() => onChange(null)}
                    />
                  ),
                }
              : {}
          }
        />
      )}
      getOptionLabel={(option) => option.name || ''}
      getOptionKey={(option) => option.id || 'null'}
      disableClearable={value !== null}
      sx={sx}
      // is this needed?
      // sx={{ ...sx, '.MuiAutocomplete-endAdornment': { overflow: 'hidden' } }}
      blurOnSelect
    />
  );
};
