import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Add,
  Close,
  Delete,
  Done,
  DragHandle,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { AnyGroup, GroupType } from '@shared/types';
import { BaseGroupDto } from '@web/api/api';
import { disableParentRipple } from '@web/components/common';
import { ControlledTextField } from '@web/components/common/form/ControlledTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { GameUtils } from '@web/components/player/utils/game-utils';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { isInvestigationGroup } from '@web/store/maker/types';
import { CSSProperties, MouseEvent, useCallback, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { InvestigationPopper } from './InvestigationPopper';

enum Menu {
  MAIN,
  ADD,
}

const state = proxy({
  menu: Menu.MAIN,
  add: {} as Pick<AnyGroup, 'name' | 'type'>,
  edit: {} as Pick<AnyGroup, 'id' | 'name'>,
  deleteId: '',
});

export const GroupsEditor = () => {
  const { menu } = useSnapshot(state);

  const onExit = () => {
    state.menu = Menu.MAIN;
  };

  return (
    <InvestigationPopper label="Groups" onExit={onExit}>
      {({ close }) => (
        <MultiHideView index={menu}>
          <GroupsMainEditor handleClose={close} />
          <GroupsAddEditor />
        </MultiHideView>
      )}
    </InvestigationPopper>
  );
};

const GroupsMainEditor = ({ handleClose }: { handleClose: () => void }) => {
  const {
    project: { groups },
  } = useMakerStore();

  const handleClickAdd = () => {
    state.add = { name: '', type: GroupType.Normal };
    state.menu = Menu.ADD;
  };

  return (
    <>
      <GroupsList />

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button color="error" onClick={handleClose}>
          Close
        </Button>

        <Button
          color="success"
          startIcon={<Add />}
          disabled={groups.length >= makerStore.groupsLimit}
          onClick={handleClickAdd}
        >
          Add Group
        </Button>
      </CardActions>
    </>
  );
};

const GroupsList = () => {
  const {
    project: { groups },
  } = useMakerStore();

  const framesCountMap = useMemo(() => {
    return groups.reduce((acc, group) => {
      acc[group.id] = GameUtils.getAllFramesInGroup(group as AnyGroup).length;

      return acc;
    }, {});
  }, [groups]);

  const handleDragEnd = useCallback(
    (sourceId: UniqueIdentifier, destinationId?: UniqueIdentifier) => {
      const oldIndex = makerStore.project.groups.findIndex(
        (i) => i.id === sourceId,
      );
      const newIndex = makerStore.project.groups.findIndex(
        (i) => i.id === destinationId,
      );

      makerStore.project.groups = arrayMove(
        makerStore.project.groups,
        oldIndex,
        newIndex,
      );
    },
    [],
  );

  return (
    <List sx={{ maxHeight: '50vh', overflowY: 'auto' }} dense>
      <SortableDndProvider items={groups} onDragEnd={handleDragEnd}>
        {groups.map((group, index) => (
          <GroupRow
            key={group.id}
            group={group}
            index={index}
            framesCountMap={framesCountMap}
          />
        ))}
      </SortableDndProvider>
    </List>
  );
};

const GroupRow = ({
  group,
  index,
  framesCountMap,
}: {
  group: BaseGroupDto;
  index: number;
  framesCountMap: Record<string, number>;
}) => {
  const { edit, deleteId } = useSnapshot(state);
  const {
    project: { groups },
    group: currentGroup,
  } = useMakerStore();

  const onEditClick = (event: MouseEvent, group: BaseGroupDto) => {
    event.stopPropagation();

    state.edit = { id: group.id, name: group.name };
  };

  const onDeleteClick = (event: MouseEvent, group: BaseGroupDto) => {
    event.stopPropagation();

    state.deleteId = group.id;
  };

  const onChangeGroup = () => {
    changeGroup(group.id);
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: group.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (edit.id === group.id) {
    return <GroupEditRow />;
  }

  if (deleteId === group.id) {
    return <GroupDeleteRow />;
  }

  return (
    <ListItemButton
      ref={setNodeRef}
      sx={{ height: 60 }}
      style={style}
      selected={currentGroup?.id === group.id}
      onClick={onChangeGroup}
    >
      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <Typography component="div" mb={0.75}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Box
                display="flex"
                sx={{ touchAction: 'none' }}
                {...attributes}
                {...listeners}
              >
                <DragHandle sx={{ cursor: 'move' }} />
              </Box>

              <Typography
                component="div"
                variant="subtitle2"
                fontWeight="bold"
                mb={0.5}
                noWrap
              >
                {group.name}
              </Typography>
            </Stack>
          </Typography>
        }
        secondary={
          <Typography component="div" color="text.secondary">
            <Stack direction="row" spacing={1} alignItems="center">
              <GroupTypeChip type={group.type} />

              <Typography variant="caption" lineHeight="unset">
                {framesCountMap[group.id]} Frames
              </Typography>
            </Stack>
          </Typography>
        }
      />

      <Stack direction="row" alignItems="center">
        <IconButton
          size="small"
          onClick={(e) => onEditClick(e, group)}
          {...disableParentRipple}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => onDeleteClick(e, group)}
          disabled={groups.length <= 1}
          {...disableParentRipple}
        >
          <Delete />
        </IconButton>
      </Stack>
    </ListItemButton>
  );
};

const GroupEditRow = () => {
  const { edit } = useSnapshot(state);

  const handleEdit = () => {
    makerStore.project.groups = makerStore.project.groups.map((group) => {
      if (group.id === state.edit.id) {
        group.name = state.edit.name;
      }

      return group;
    });

    handleCancel();
  };

  const handleCancel = () => {
    state.edit = {} as Pick<AnyGroup, 'id' | 'name'>;
  };

  return (
    <ListItem sx={{ height: 60 }}>
      <ControlledTextField
        value={edit.name}
        onChange={(e) => {
          state.edit.name = e.target.value;
        }}
        sx={{ pr: 1 }}
        placeholder="Group Name"
        size="small"
        fullWidth
      />

      <Stack direction="row" alignItems="center">
        <IconButton size="small" onClick={handleEdit}>
          <Done />
        </IconButton>

        <IconButton size="small" onClick={handleCancel}>
          <Close />
        </IconButton>
      </Stack>
    </ListItem>
  );
};

const GroupDeleteRow = () => {
  const handleDelete = () => {
    if (makerStore.group?.id === state.deleteId) {
      const groupIndex = makerStore.project.groups.findIndex(
        (group) => group.id === state.deleteId,
      );

      const nextGroup = makerStore.project.groups[groupIndex + 1];
      const previousGroup = makerStore.project.groups[groupIndex - 1];

      if (nextGroup) {
        changeGroup(nextGroup.id);
      } else if (previousGroup) {
        changeGroup(previousGroup.id);
      }
    }

    makerStore.project.groups = makerStore.project.groups.filter(
      (group) => group.id !== state.deleteId,
    );
  };

  const handleCancel = () => {
    state.deleteId = '';
  };

  return (
    <ListItem sx={{ height: 60 }}>
      <Typography variant="caption" pr={0.5}>
        Delete this group? All frames in this group will be deleted.
      </Typography>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <XSmallButton variant="contained" color="error" onClick={handleDelete}>
          Delete
        </XSmallButton>
        <XSmallButton
          variant="contained"
          color="success"
          onClick={handleCancel}
        >
          Cancel
        </XSmallButton>
      </Stack>
    </ListItem>
  );
};

const GroupsAddEditor = () => {
  const { add } = useSnapshot(state);

  const selectedType = useMemo(
    () => groupTypeOptions.find((f) => f.id === add.type) || null,
    [add.type],
  );

  const handleClickAdd = () => {
    makerActions.addGroup(state.add.name, state.add.type);

    state.menu = Menu.MAIN;
  };

  const disableAdd = !add.type || !add.name;

  return (
    <>
      <CardContent>
        <Stack spacing={2}>
          <ControlledTextField
            label="Group Name"
            value={add.name || ''}
            onChange={(e) => {
              state.add.name = e.target.value;
            }}
            size="small"
            fullWidth
          />

          <SimpleSelect
            label="Group Type"
            options={groupTypeOptions}
            value={selectedType}
            onChange={(value) => {
              state.add.type = value?.id || GroupType.Normal;
            }}
            size="small"
            fullWidth
          />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          color="error"
          onClick={() => {
            state.menu = Menu.MAIN;
          }}
        >
          Cancel
        </Button>

        <Button color="success" disabled={disableAdd} onClick={handleClickAdd}>
          Add
        </Button>
      </CardActions>
    </>
  );
};

const GroupTypeChip = ({ type }: { type: GroupType | string }) => {
  const color: Parameters<typeof XSmallChip>[0]['color'] =
    type === GroupType.Normal
      ? 'default'
      : type === GroupType.CrossExamination
        ? 'accent'
        : type === GroupType.Investigation
          ? 'violet'
          : 'secondary';

  const label =
    type === GroupType.Normal
      ? 'Normal'
      : type === GroupType.CrossExamination
        ? 'Cross Examination'
        : type === GroupType.Investigation
          ? 'Investigation'
          : 'Game Over';

  return <XSmallChip label={label} color={color} size="small" />;
};

const changeGroup = (groupId: string) => {
  const group = makerStore.project.groups.find((g) => g.id === groupId);

  makerStore.framesTarget = !isInvestigationGroup(group)
    ? { groupId }
    : { groupId, locationId: group.locations[0]?.id };
  makerStore.page = 1;
};

export const XSmallChip = styled(Chip)({
  height: 16,
  fontSize: '9.8px',
  fontWeight: 500,
  padding: 0,
  borderRadius: 4,
});

const groupTypeOptions = [
  { name: 'Normal', id: GroupType.Normal },
  { name: 'Cross Examination', id: GroupType.CrossExamination },
  { name: 'Investigation', id: GroupType.Investigation },
  { name: 'Game Over', id: GroupType.Gameover },
];
