/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { useSpringRef } from 'react-spring';

export const usePlayerViewport = () => {
  const api = useSpringRef();
  const [isAnimating, setIsAnimating] = useState(false);

  const startAnimation = (props: Parameters<typeof api.start>[0]) => {
    api.start({
      ...props,
      onStart: () => {
        setIsAnimating(true);

        // @ts-expect-error
        props?.onStart?.();
      },
      onRest: () => {
        setIsAnimating(false);

        // @ts-expect-error
        props?.onRest?.();
      },
    });
  };

  return { api, startAnimation, isAnimating };
};
