import {
  BackgroundDto,
  DialogueBoxDto,
  EvidenceDto,
  MusicDto,
  PopupDto,
  SoundDto,
} from '@web/api/api';
import { AssetType } from '@web/store/assets/state';
import { Character } from '@web/types/character';
import { createContext, useContext } from 'react';

type AssetRecords<T> = Record<string, T>;

type AssetMapping = {
  sound: AssetRecords<SoundDto>;
  music: AssetRecords<MusicDto>;
  evidence: AssetRecords<EvidenceDto>;
  background: AssetRecords<BackgroundDto>;
  popup: AssetRecords<PopupDto>;
  character: AssetRecords<Character>;
  dialogueBox: AssetRecords<DialogueBoxDto>;
};

type PlayerAssetsContextValue = {
  [K in AssetType]?: AssetMapping[K];
};

const context = createContext<PlayerAssetsContextValue | undefined>(undefined);

// This is for override assets in the player

export const PlayerAssetsProvider = context.Provider;

export const usePlayerAssets = () => useContext(context);
