/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { dashify } from '@web/utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import { usePlayerReset } from '../providers/PlayerResetProvider';
import { usePlayerSaveLoad as usePlayerSaveLoadContext } from '../providers/PlayerSaveLoadProvider';

export const usePlayerSaveLoad = (
  project: SceneProjectDto | CaseProjectDto | undefined,
) => {
  const playerSaveLoad = usePlayerSaveLoadContext();
  const playerMeta = usePlayerMeta();
  const playerReset = usePlayerReset();

  const { id: caseId } = useParams();

  const navigate = useNavigate();

  const saveGame = async (slot: number) => {
    if (!playerSaveLoad || !caseId || !project || project.type !== 'case')
      return;

    return (
      await ApiClient.caseSave.create({
        caseId,
        slot,
        title: playerMeta?.title || 'Untitled Case',
        data: { data: project, session: playerSaveLoad.save() },
      })
    ).data;
  };

  const loadGame = async (id: number) => {
    if (!playerSaveLoad || !caseId) return;

    const game = (await ApiClient.caseSave.get(id)).data;

    if (!game) return;

    playerReset?.reset();

    navigate(`/case/${game.caseId}/${dashify(game.title)}`, {
      state: { load: game },
    });
  };

  return { saveGame, loadGame };
};
