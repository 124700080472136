import { CaseFrame, Effect } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type Step } from '../providers/PlayerProvider';
import { getFilterCSS } from '../utils/filter';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

export const usePlayerEffects = (
  frame: CaseFrame | undefined,
  step: Step | undefined,
) => {
  const sceneTargets = useMemo(
    () => [
      SceneTargets.BACKGROUND,
      SceneTargets.CHARACTER_1,
      SceneTargets.CHARACTER_2,
      SceneTargets.CHARACTER_3,
      SceneTargets.CHARACTER_4,
      SceneTargets.CHARACTER_5,
    ],
    [],
  );

  const [effects, setEffects] = useState<Record<number, Effect | undefined>>(
    {},
  );

  usePlayerResetRegister({
    name: 'allEffects',
    onReset: () => {
      setEffects({});
    },
  });

  usePlayerSaveLoadRegister({
    name: 'allEffects',
    onSave: () => effects,
    onLoad: (savedEffects) => {
      setEffects(savedEffects || {});
    },
  });

  useEffect(() => {
    if (!frame || (step !== 'character' && step !== 'dialogue')) {
      return;
    }

    const frameEffect = frame.effect;
    const newEffects = { ...effects };

    sceneTargets.forEach((target) => {
      if (frameEffect && (frameEffect.target & target) === target) {
        newEffects[target] = frameEffect;
      } else {
        newEffects[target] = undefined;
      }
    });

    setEffects(newEffects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const getEffectStyle = useCallback(
    (target: SceneTargets): CSSProperties => {
      const effect = effects[target];

      return effect?.type ? { filter: getFilterCSS(effect) } : {};
    },
    [effects],
  );

  const effectStyles = useMemo(() => {
    const styles: Record<number, CSSProperties> = {};

    sceneTargets.forEach((target) => {
      styles[target] = getEffectStyle(target);
    });

    return styles;
  }, [getEffectStyle, sceneTargets]);

  return {
    effectStyles,
  };
};
