import { AppBar, Autocomplete, Container, Stack, Toolbar } from '@mui/material';
import { Pagination } from '@web/components/common/ui/Pagination';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { AddFrameButton } from '@web/components/maker/AddFrameButton';
import { FrameDeletionUndoSnackbar } from '@web/components/maker/FrameDeletionUndoSnackbar';
import { MakerAppBar } from '@web/components/maker/MakerAppBar';
import { MakerCaseMenu } from '@web/components/maker/MakerCaseMenu';
import { MakerHooks } from '@web/components/maker/MakerHooks';
import { AliasesDialog } from '@web/components/maker/dialogs/AliasesDialog';
import { FrameActionsDialog } from '@web/components/maker/dialogs/FrameActionsDialog';
import { FrameEffectsDialog } from '@web/components/maker/dialogs/FrameEffectsDialog';
import { MakerHelpDialog } from '@web/components/maker/dialogs/MakerHelpDialog';
import { MakerPreviewDialog } from '@web/components/maker/dialogs/PreviewDialog';
import { ProjectBackupsDialog } from '@web/components/maker/dialogs/ProjectBackupsDialog';
import { ProjectPairsDialog } from '@web/components/maker/dialogs/ProjectPairsDialog';
import { SceneEditorDialog } from '@web/components/maker/dialogs/SceneEditorDialog';
import { SubmitDialog } from '@web/components/maker/dialogs/SubmitDialog';
import { FrameEditor } from '@web/components/maker/frame/FrameEditor';
import { FrameEditorComment } from '@web/components/maker/frame/FrameEditorComment';
import { useMaker } from '@web/components/maker/hooks/useMaker';
import { useBetaDialog } from '@web/hooks/useBeta';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import React, { memo, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';

const MakerPage = () => {
  useBetaDialog();
  useMaker();

  return (
    <>
      <Stack spacing={2} pb={2}>
        <MakerAppBar />
        <MakerCaseMenu />
        <MakerFrames />
      </Stack>

      <MakerFramesLoader />
      <MakerBottomBar />
      <FrameDeletionUndoSnackbar />
      <MakerPreviewDialog />
      <SceneEditorDialog />
      <FrameActionsDialog />
      <FrameEffectsDialog />
      <AliasesDialog />
      <ProjectPairsDialog />
      <ProjectBackupsDialog />
      <MakerHelpDialog />
      <SubmitDialog />

      <MakerHooks />
    </>
  );
};

const MakerFramesLoader = () => {
  const { framesTarget, page, perPage } = useMakerStore();

  useEffect(() => {
    makerActions.loadFramesData(makerStore.pageFrames);
  }, [framesTarget, page, perPage]);

  return null;
};

const MakerFrames = memo(() => {
  const { pageFrames, focusedFrameIndex, page, perPage } = useMakerStore();

  const start = (page - 1) * perPage;

  return (
    <Stack spacing={1}>
      <AddFrameButton index={start} />

      {pageFrames.map((frame, index) => (
        <React.Fragment key={frame.id}>
          <FrameEditorComment frameId={frame.id} />

          <FrameEditor
            id={frame.id}
            index={index + start}
            focused={focusedFrameIndex === index + start}
          />

          <AddFrameButton index={index + start + 1} />
        </React.Fragment>
      ))}
    </Stack>
  );
});

const perPageOptions = [5, 10, 20, 30, 40, 50];

const MakerBottomBar = () => {
  const { perPage } = useSnapshot(makerStore);

  const perPageDropdown = useMemo(
    () => perPageOptions.map((v) => ({ label: String(v), value: v })),
    [],
  );

  const perPageDropdownValue = useMemo(
    () => perPageDropdown.find((v) => v.value === perPage),
    [perPageDropdown, perPage],
  );

  const handleChangePerPage = (value: number) => {
    makerStore.perPage = Number(value);

    const totalPages = Math.ceil(makerStore.frames.length / makerStore.perPage);

    if (makerStore.page > totalPages) {
      makerStore.page = totalPages;
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ position: 'fixed', bottom: 0, zIndex: 100 }}
      disableGutters
    >
      <AppBar color="default" position="sticky">
        <Toolbar disableGutters variant="dense">
          <Autocomplete
            options={perPageDropdown}
            value={perPageDropdownValue}
            size="small"
            renderInput={(params) => (
              <RenderAutocompleteInput
                params={params}
                label=""
                variant="outlined"
              />
            )}
            onChange={(e, v) => handleChangePerPage(v?.value || 10)}
            sx={{
              paddingLeft: { xs: '2px', sm: 1 },
              paddingRight: { xs: 0, sm: 1 },
              p: 1,
            }}
            disableClearable
          />

          <Stack alignItems="center" flexGrow={1}>
            <MakerPagination />
          </Stack>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

const MakerPagination = () => {
  const { perPage, page, frames } = useSnapshot(makerStore);

  if (!frames) return null;

  const count = Math.ceil(frames.length / perPage);

  return (
    <Pagination
      count={count}
      page={page}
      onChange={(e, v) => {
        makerStore.page = v;
      }}
    />
  );
};

export default MakerPage;
