import { Box, Button, Fade, Slide, styled, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';

export const PlayerCasePointArea = memo(() => {
  const {
    playerCase: {
      state: playerCaseState,
      actions: { onUserInput, onExamine },
    },
    actions: { preloadThenNext },
    playerViewport: { isAnimating },
  } = usePlayer();

  const { userInput, cursorPosition, showExamine } = playerCaseState;

  const [alwaysShowPrompt, setAlwaysShowPrompt] = useState(true);
  const [showPrompt, setShowPrompt] = useState(true);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const open = userInput?.id === 17 || showExamine;

  useEffect(() => {
    setShowPrompt(false);

    const timer = setTimeout(() => setShowPrompt(true), 3200);

    return () => clearTimeout(timer);
  }, [cursorPosition]);

  useEffect(() => {
    if (!open) return;

    setAlwaysShowPrompt(true);

    const timer = setTimeout(() => setAlwaysShowPrompt(false), 3200);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) return null;

  const prompt = userInput?.id === 17 ? userInput.prompt : undefined;

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (userInput?.id === 17) {
      onUserInput<17>(preloadThenNext, cursorPosition);
    } else if (showExamine) {
      onExamine(preloadThenNext, cursorPosition);
    }
  };

  const handleMouseEnter = () => {
    setIsButtonHovered(true);
  };

  const handleMouseLeave = () => {
    setIsButtonHovered(false);
  };

  return (
    <PlayerOverlay>
      <Slide
        in={(!showPrompt && !isAnimating) || isButtonHovered}
        direction="down"
        timeout={300}
      >
        <Box>
          <ConfirmButton
            variant="contained"
            color="secondary"
            size="small"
            disabled={isAnimating}
            onClick={handleSubmit}
            disableElevation={false}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Confirm
          </ConfirmButton>
        </Box>
      </Slide>

      {prompt && (
        <Fade in={alwaysShowPrompt || showPrompt} timeout={300}>
          <PromptContainer>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
              }}
            >
              {prompt}
            </Typography>
          </PromptContainer>
        </Fade>
      )}
    </PlayerOverlay>
  );
});

const PlayerOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: ZIndex.CaseInput,
});

const PromptContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 16,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  zIndex: ZIndex.CaseInput + 2,
  textAlign: 'center',
  width: '100%',
  maxWidth: '90%',
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%)',
  zIndex: ZIndex.CaseInput + 2,
  borderRadius: 0,
  pointerEvents: 'auto',
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.25, 0.5),
    fontSize: '0.9rem !important',
  },
  [theme.breakpoints.down(450)]: {
    fontSize: '0.75rem !important',
    lineHeight: 1.4,
  },
  [theme.breakpoints.down(360)]: {
    fontSize: '0.6rem !important',
    lineHeight: 1.3,
  },
}));
