import { Button } from '@mui/material';
import { CaseFrame } from '@shared/types';
import { makerStore } from '@web/store/maker/state';
import { defaultBackground, projectUtils } from '@web/utils/project';
import { useEffect, useMemo, useRef } from 'react';
import { Player } from '../player/Player';
import { PlayerActionsType } from '../player/providers/PlayerProvider';
import { Background } from '../player/ui/Background';
import { Container } from '../player/ui/Container';
import { getAspectRatioNumber } from '../player/utils/utils';

type FramePreviewerProps = {
  frame?: CaseFrame;
  onBack?: () => void;
};

export const FramePreviewer = ({ frame, onBack }: FramePreviewerProps) => {
  const ref = useRef<PlayerActionsType>(null);

  const width = makerStore.project.options.width || 960;
  const aspectRatio = makerStore.project.options.aspectRatio || '3:2';

  const project = useMemo(
    () => (frame ? projectUtils.getSceneProject([frame]) : undefined),
    [frame],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (project) {
        ref.current?.init(project);
      } else {
        ref.current?.reset();
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  if (!frame || !project) {
    return (
      <Container width={width} aspectRatio={getAspectRatioNumber(aspectRatio)}>
        <Background url={defaultBackground.url} />
      </Container>
    );
  }

  return (
    <Player
      ref={ref}
      width={width}
      aspectRatio={aspectRatio}
      controls={onBack ? <BackButton onBack={onBack} /> : null}
      autoStart
      hideAutoplay
    />
  );
};

const BackButton = ({ onBack }: { onBack: () => void }) => {
  return (
    <Button size="small" color="success" variant="contained" onClick={onBack}>
      Back
    </Button>
  );
};
