import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SceneProjectDto } from '@web/api/api';
import { ComboBox } from '@web/components/common/form/ComboBox';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { useAssetStore } from '@web/store/assets/state';
import { makerStore } from '@web/store/maker/state';
import React, { memo, useCallback, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

type stateType = {
  expanded: false | number;
};

const state = proxy<stateType>({
  expanded: false,
});

export const AliasesDialog = React.memo(() => {
  const snapshot = useSnapshot(makerStore);

  const handleAddAlias = useCallback(() => {
    if (!makerStore.project || makerStore.project.aliases.length > 300) return;

    makerStore.project.aliases.push({ from: '', to: '' });

    state.expanded = makerStore.project.aliases.length - 1;
  }, []);

  const handleClose = useCallback(() => {
    makerStore.dialogs.aliases = false;
  }, []);

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!snapshot.project) return null;

  return (
    <DraggableDialog
      titleComponent={<ProjectOptionsDialogTitle />}
      open={!!snapshot.dialogs.aliases}
      onClose={handleClose}
      maxWidth="xs"
      fullscreen={fullscreen}
      fullWidth
    >
      <DialogContent>
        <Stack spacing={2}>
          <Button
            variant="contained"
            color="info"
            startIcon={<Add />}
            onClick={handleAddAlias}
            disabled={snapshot.project.aliases.length >= 300}
          >
            Add Alias
          </Button>

          <Box minHeight="65vh" overflow="auto">
            {snapshot.project.aliases.map((alias, index) => (
              <AliasNameAccordion key={index} alias={alias} index={index} />
            ))}
          </Box>
        </Stack>
      </DialogContent>
    </DraggableDialog>
  );
});

type AliasNameAccordionProps = {
  alias: SceneProjectDto['aliases'][0];
  index: number;
};

const AliasNameAccordion = memo(({ alias, index }: AliasNameAccordionProps) => {
  const snapshot = useSnapshot(state);
  const {
    character: { cache },
  } = useAssetStore();
  const expanded = snapshot.expanded === index;

  const handleChange = useCallback(
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      state.expanded = isExpanded ? panel : false;
    },
    [],
  );

  const handleRemoveAlias = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      makerStore.project.aliases.splice(index, 1);
    },
    [],
  );

  const fromOptions = useMemo(() => {
    const uniqueNames = new Set();
    return Object.values(cache)
      .filter((character) => {
        if (uniqueNames.has(character.nameplate)) {
          return false;
        }
        uniqueNames.add(character.nameplate);
        return true;
      })
      .map((character) => ({
        id: String(character.id),
        name: character.nameplate,
        value: character.nameplate,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [cache]);

  const handleFromChange = useCallback(
    (value: string | null) => {
      if (!makerStore.project) return;

      makerStore.project.aliases[index].from = value ?? '';
    },
    [index],
  );

  const handleToChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!makerStore.project) return;

      makerStore.project.aliases[index].to = e.target.value;
    },
    [index],
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(index)}
      slotProps={{ transition: { unmountOnExit: true } }}
      square
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{alias.from || `Alias ${index + 1}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <ComboBox
            label="From"
            options={fromOptions}
            value={alias.from}
            onChange={handleFromChange}
            variant="standard"
          />

          <TextField
            label="To"
            value={alias.to}
            variant="standard"
            onChange={handleToChange}
            fullWidth
          />

          <Box alignSelf="end">
            <Button
              size="small"
              color="inherit"
              onClick={handleRemoveAlias(index)}
            >
              Remove Alias
            </Button>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
});

const ProjectOptionsDialogTitle = memo(() => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    makerStore.dialogs.aliases = false;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6">Alias Names</Typography>

      <Stack direction="row">
        <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
      </Stack>
    </Stack>
  );
});
