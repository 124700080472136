import { AnyGroup, CaseFrame } from '@shared/types';
import type { FramesTarget } from '@web/types/project';

export type GameState = {
  frameIndex: number;
  pressIndex?: number;
  presentIndex?: number;
  investigationMenu?: boolean;
  resumeExamine?: boolean;
  resumeConversations?: boolean;

  isEnded?: boolean;
  previousFrame?: CaseFrame;
} & FramesTarget;

export type GameDerivedState = {
  activeFrameIndex: number;
  frame?: CaseFrame;
  group?: AnyGroup;
};

export type EvidenceState = {
  id: number;
  isIcon: boolean;
};

export enum GameCategory {
  CrossExaminationCounsel = 'counsel',
  CrossExaminationEvidenceFailure = 'failure',
  InvestigationPresentFailure = 'presentFailure',
  InvestigationExamineFailure = 'examineFailure',
  InvestigationCompletion = 'completion',
}
