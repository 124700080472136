import { CaseAction_PointArea } from '@shared/types';

export const timeAgoLabel = (input: Date | string) => {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat('en');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };

  const now = new Date();
  const secondsElapsed = (date.getTime() - now.getTime()) / 1000;

  for (const key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(
        Math.round(delta),
        key as Intl.RelativeTimeFormatUnit,
      );
    }
  }
  return formatter.format(0, 'seconds');
};

export const dashify = (input: string) => {
  if (!input) return '';

  return input
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const pointWithinArea = (
  position: { x: number; y: number },
  shape: NonNullable<NonNullable<CaseAction_PointArea['areas']>[0]['shape']>,
) => {
  const { x, y } = position;

  return (
    x <= shape.left + shape.width &&
    x >= shape.left &&
    y <= shape.top + shape.height &&
    y >= shape.top
  );
};

export const clearObjectProperties = (obj: Record<string, unknown>) => {
  for (const key in obj) {
    delete obj[key];
  }
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};
