import { Pair, PairedCharacter } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { Character, Pose } from '@web/types/character';
import { Frame } from '@web/types/project';
import { useMemo } from 'react';
import { useCharacter } from './useCharacter';
import { usePairedCharacters } from './usePairedCharacter';

export type SceneCharacter =
  | {
      imageUrl?: string;
      pose?: Pose;
      character?: Character;
      pairedCharacter?: PairedCharacter;
      target: SceneTargets;
    }
  | undefined;

export const useCharacters = (
  frame: Frame | undefined,
  pairs: Pair[] | undefined,
) => {
  const character = useCharacter(frame);
  const { pairedCharacters } = usePairedCharacters(frame, pairs);

  const pose = useMemo(
    () => character?.poses?.find((pose) => pose.id === frame?.poseId),
    [character, frame],
  );

  const pair = useMemo(
    () => pairs?.find((f) => f.id === frame?.pair?.id),
    [frame?.pair?.id, pairs],
  );

  const characters = useMemo<SceneCharacter[]>(() => {
    if (!frame || !pairedCharacters) return [];

    return !pair || !frame.pair
      ? [
          {
            imageUrl: pose?.idleImageUrl,
            character: character,
            pose: pose,
            target: SceneTargets.CHARACTER_1,
          },
        ]
      : pairedCharacters.map((pairedCharacter, index) => {
          const characterPose =
            pairedCharacter &&
            (pairedCharacter.character.id === character?.id
              ? pose
              : pairedCharacter.character.poses.find(
                  (f) =>
                    f.id === frame.pair!.poseIds[pairedCharacter.character.id],
                ));

          return pairedCharacter
            ? ({
                imageUrl: characterPose?.idleImageUrl,
                character: pairedCharacter.character,
                pose: characterPose,
                pairedCharacter: pairedCharacter.pair,
                target: SceneTargets.CHARACTER_1 << index,
              } satisfies SceneCharacter)
            : undefined;
        });
  }, [character, frame, pair, pairedCharacters, pose]);

  return characters;
};
