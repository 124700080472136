import { Divider, Paper, PaperProps, Stack, Typography } from '@mui/material';
import { CaseAction, CaseFrame } from '@shared/types';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { Tip } from '@web/components/common/Tip';
import caseActions from '@web/data/case-actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { memo, useCallback, useMemo } from 'react';
import {
  FrameAndIndexContext,
  FrameAndIndexProvider,
  useFrameAndIndexContext,
} from '../providers/FrameContextProvider';
import { ChooseAnswerInput } from './case-action-inputs/ChooseAnswerInput';
import { ChooseEvidenceInput } from './case-action-inputs/ChooseEvidenceInput';
import { EvaluateExpressionInput } from './case-action-inputs/EvaluateExpressionInput';
import { EvaluateVariableInput } from './case-action-inputs/EvaluateVariableInput';
import { EvidenceInput } from './case-action-inputs/EvidenceInput';
import { FlashingHealthInput } from './case-action-inputs/FlashingHealthInput';
import { FrameJumpInput } from './case-action-inputs/FrameJumpInput';
import { IncrementVariableInput } from './case-action-inputs/IncrementVariableInput';
import { InvestigationConfigureLocationCompletionInput } from './case-action-inputs/InvestigationConfigureLocationCompletionInput';
import { InvestigationMarkUnvisitedInput } from './case-action-inputs/InvestigationMarkUnvisitedInput';
import { InvestigationSwapTalkConversationInput } from './case-action-inputs/InvestigationSwapTalkConversation';
import { InvestigationToggleVisibilityInput } from './case-action-inputs/InvestigationToggleVisibilityInput';
import { ModifyHealthInput } from './case-action-inputs/ModifyHealthInput';
import { PlayVideoInput } from './case-action-inputs/PlayVideoInput';
import { PointAreaInput } from './case-action-inputs/PointAreaInput';
import { SetGameOverRedirectionInput } from './case-action-inputs/SetGameOverRedirectionInput';
import { SetInputVariableInput } from './case-action-inputs/SetInputVariableInput';
import { SetVariableInput } from './case-action-inputs/SetVariableInput';
import { ToggleEvidenceVisibilityInput } from './case-action-inputs/ToggleEvidenceVisibilityInput';
import { ToggleFramesVisibilityInput } from './case-action-inputs/ToggleFramesVisibilityInput';

export const CaseActionEditor = () => {
  const {
    frames,
    frameActionsFrameIndex: index,
    isCaseProject,
  } = useMakerStore();

  if (!isCaseProject || (!index && index !== 0)) return null;

  const frame = frames[index];

  const contextValue: FrameAndIndexContext = {
    frame: frame || ({} as CaseFrame),
    index: index ?? 0,
  };

  return (
    <Stack spacing={1}>
      <FrameAndIndexProvider value={contextValue}>
        <CaseActionPicker />

        <CaseActionInputFields />

        <CaseActionTip />
      </FrameAndIndexProvider>
    </Stack>
  );
};

const CaseActionPicker = () => {
  const { investigationGroup } = useMakerStore();
  const { frame, index } = useFrameAndIndexContext();

  const options = useMemo(() => {
    const sortedActions = caseActions
      .filter((f) => !f.hide)
      .map((m) => ({
        id: m.id,
        name: m.text,
        group: m.group,
        input: m.input,
      }));

    if (investigationGroup) {
      return sortedActions.sort((a, b) => {
        if (a.group === 'Investigation' && b.group !== 'Investigation')
          return -1;
        if (a.group !== 'Investigation' && b.group === 'Investigation')
          return 1;
        return 0;
      });
    }

    return sortedActions;
  }, [investigationGroup]);

  const selectedOption = useMemo(
    () => options.find((f) => f.id === frame.caseAction?.id) || null,
    [frame.caseAction?.id, options],
  );

  const handleChange = useCallback(
    (value: typeof selectedOption) => {
      makerStore.frames[index].caseAction = value
        ? getDefaultValuesForCaseAction(value.id)
        : undefined;
    },
    [index],
  );

  // TODO: fix the automatic scrolling to the selected item when changing runOnce
  return (
    <SimpleSelect
      label="Case Action"
      value={selectedOption}
      options={options}
      onChange={handleChange}
      groupBy={(option) => option.group}
      ListboxProps={{ sx: { pt: 0 } }}
      size="small"
      slots={{ paper: CaseActionPickerPaperComponent }}
      clearable
      fullWidth
    />
  );
};

const CaseActionPickerPaperComponent = memo((props: PaperProps) => {
  const { children, ...other } = props;

  return (
    <Paper {...other} onMouseDown={(event) => event.preventDefault()}>
      <Stack px={2}>
        <RunOnceCheckbox />
      </Stack>

      {children}
    </Paper>
  );
});

const RunOnceCheckbox = () => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangeRunOnce = useCallback(
    (value: boolean) => {
      if (!makerStore.frames[index].caseAction) return;

      makerStore.frames[index].caseAction = {
        ...(makerStore.frames[index].caseAction || {}),
        runOnce: value,
      };
    },
    [index],
  );

  return (
    <Checkbox
      label="Run once"
      value={frame.caseAction?.runOnce || false}
      onChange={handleChangeRunOnce}
      size="small"
    />
  );
};

const CaseActionInputFields = () => {
  const { frame } = useFrameAndIndexContext();

  if (!frame.caseAction) return null;

  switch (frame.caseAction.id) {
    case 1:
    case 2:
      return <EvidenceInput />;
    case 3:
      return <ToggleFramesVisibilityInput />;
    case 4:
      return <FrameJumpInput />;
    case 6:
      return <ModifyHealthInput />;
    case 7:
      return <FlashingHealthInput />;
    case 8:
      return <ChooseEvidenceInput />;
    case 9:
      return <ChooseAnswerInput />;
    case 10:
      return <SetVariableInput />;
    case 11:
      return <IncrementVariableInput />;
    case 12:
      return <SetInputVariableInput />;
    case 13:
      return <EvaluateVariableInput />;
    case 14:
      return <EvaluateExpressionInput />;
    case 15:
      return <SetGameOverRedirectionInput />;
    case 16:
      return <ToggleEvidenceVisibilityInput />;
    case 17:
      return <PointAreaInput />;
    case 18:
      return <InvestigationToggleVisibilityInput />;
    case 19:
      return <InvestigationMarkUnvisitedInput />;
    case 20:
      return <InvestigationSwapTalkConversationInput />;
    case 21:
      return <InvestigationConfigureLocationCompletionInput />;
    case 22:
      return <PlayVideoInput />;
    default:
      return null;
  }
};

const CaseActionTip = () => {
  const { frame } = useFrameAndIndexContext();

  const tip = useMemo(() => {
    switch (frame.caseAction?.id) {
      case 3:
        return 'Enter frame id(s) separated by a comma. You can specify a range by using a hyphen (-)';
      case 6:
      case 7:
        return 'Health ranges from 0 to 100';
      case 10:
      case 12:
        return 'You can display a variable in the dialogue box using tag: [VAR:variable_name]';
      case 16:
        return 'Hidden evidence will still appear during preview marked with [hidden]';
      case 17:
        return 'You can use full screen evidence with this action';
      case 19:
        return 'Marking a location as unvisited will replay its introduction frames when moved to';
      case 20:
        return 'Replace a conversation with another conversation within the same location';
      case 22:
        return 'Enter a direct link to a video file';
      default:
        return undefined;
    }
  }, [frame.caseAction?.id]);

  if (!frame.caseAction || !tip) return null;

  return <Tip text={tip} pt={2} />;
};

const getDefaultValuesForCaseAction = (id: number): CaseAction => {
  switch (id) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 10:
    case 11:
    case 14:
    case 15:
    case 22:
      return { id };
    case 6:
      return { id, type: 'decrease', amount: 20 };
    case 7:
      return { id, amount: 20 };
    case 8:
      return {
        id,
        items: [{}],
        allowEvidence: true,
        allowProfiles: true,
      };
    case 9:
      return {
        id,
        answers: [{}],
      };
    case 12:
      return { id, name: '', type: 'int', lowercase: false };
    case 13:
      return { id, type: 'equals' };
    case 16:
      return { id, hide: [], show: [] };
    case 17:
      return { id, areas: [{}], color: '#FF0000' };
    case 18:
      return { id, items: [{ type: 'location', hide: [], show: [] }] };
    case 19:
      return { id, items: [{ type: 'location', items: [] }] };
    case 20:
      return { id, items: [{}] };
    case 21:
      return {
        id,
        required: [],
        autoHideConversations: true,
        autoHideExamine: true,
        fade: true,
      };
    default:
      return { id: 1, evidence: undefined };
  }
};

export const CaseActionDivider = () => {
  const { isCaseProject } = useMakerStore();

  if (!isCaseProject) return null;

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography color="textSecondary" mb={1}>
        Frame Actions
      </Typography>
    </>
  );
};
