import { getFrameIdsFromString } from '@shared/migration/utils';
import { CaseAction, GameoverGroup, GroupType } from '@shared/types';
import { CaseProjectDto } from '@web/api/api';
import { Location } from '@web/types/project';
import { enqueueSnackbar } from 'notistack';
import evaluate from 'simple-evaluate';
import { type usePlayer } from '../../hooks/usePlayer';
import { useProjectPlayer } from '../../hooks/useProjectPlayer';
import { GameUtils } from '../../utils/game-utils';
import { usePlayerCase } from './usePlayerCase';

export const runCaseActions = (
  frameId: number,
  action: CaseAction,
  project: CaseProjectDto,
  preloadThenNext: ReturnType<typeof usePlayer>['actions']['preloadThenNext'],
  projectPlayerActions: ReturnType<typeof useProjectPlayer>['actions'],
  playerCase: ReturnType<typeof usePlayerCase>,
  playerState: ReturnType<typeof usePlayer>['state'],
) => {
  if (action.runOnce) {
    if (playerCase.ranCaseActions.includes(frameId)) {
      return;
    }

    playerCase.ranCaseActions.push(frameId);
  }

  const jumpToFrame = (id: number) =>
    JumpToFrame(id, projectPlayerActions, preloadThenNext);

  switch (action.id) {
    case 1:
    case 2: {
      if (!action.evidence) return;

      const record = playerCase.state.courtRecord[
        action.evidence.evidenceType === 'evidence' ? 'evidence' : 'profiles'
      ].find((f) => f.id === action.evidence?.evidenceId);

      if (!record) return;

      record.hide = action.id === 1;

      break;
    }
    case 3: {
      const show = getFrameIdsFromString(action.show);
      const hide = getFrameIdsFromString(action.hide);

      const frames = GameUtils.getAllFrames(project.groups);

      frames.forEach((frame) => {
        if (show.includes(frame.id)) {
          frame.hide = false;
        }

        if (hide.includes(frame.id)) {
          frame.hide = true;
        }
      });

      break;
    }
    case 4: {
      if (!action.frameId) return;

      return jumpToFrame(action.frameId);
    }
    case 5: {
      projectPlayerActions.update({ isEnded: true });

      return true;
    }
    case 6: {
      if (
        !action.type ||
        action.amount === undefined ||
        isNaN(action.amount || 1)
      )
        return;

      let newHealth =
        action.type === 'set'
          ? action.amount
          : playerCase.state.health +
            (action.type === 'increase' ? action.amount : -action.amount);

      newHealth = Math.max(0, Math.min(100, newHealth));

      playerCase.state.health = newHealth;
      playerCase.state.healthFlashing = 0;

      if (newHealth <= 0) {
        const state = RedirectToGameoverGroup(
          project,
          projectPlayerActions,
          playerCase,
        );

        if (state) {
          preloadThenNext(state);
        } else {
          projectPlayerActions.update({ isEnded: true });
        }

        return true;
      }

      break;
    }
    case 7: {
      if (action.amount === undefined || isNaN(action.amount || 1)) return;

      playerCase.state.healthFlashing = action.amount;

      break;
    }
    case 8: {
      if (!action.items || action.items.length === 0) return;

      playerCase.state.userInput = action;
      playerState.showDialoguebox = false;

      return true;
    }
    case 9: {
      if (!action.answers || action.answers.length === 0) return;

      playerCase.state.userInput = action;
      playerState.showDialoguebox = false;

      return true;
    }
    case 10: {
      if (!action.name || action.value === undefined) return;

      const isNumber = !isNaN(parseInt(action.value));

      playerCase.variables[action.name] = isNumber
        ? parseInt(action.value)
        : action.value;

      break;
    }
    case 11: {
      if (!action.name || action.value === undefined) return;

      const isNumber =
        playerCase.variables[action.name] !== undefined &&
        !isNaN(Number(playerCase.variables[action.name]));

      if (!isNumber) return;

      playerCase.variables[action.name] =
        Number(playerCase.variables[action.name]) + action.value;

      break;
    }
    case 12: {
      if (!action.name || !action.type) return;

      playerCase.state.userInput = action;
      playerState.showDialoguebox = false;

      return true;
    }
    case 13: {
      if (!action.name || !action.type || !action.value) return;

      const { variables } = playerCase;

      const value = String(variables[action.name]);

      const isTrue =
        (action.type === 'equals' && value === action.value) ||
        (action.type === 'notEquals' && value !== action.value) ||
        (action.type === 'greaterThan' &&
          parseInt(value) > parseInt(action.value)) ||
        (action.type === 'lessThan' && value < action.value);

      if (isTrue && action.trueFrameId) {
        return jumpToFrame(action.trueFrameId);
      } else if (!isTrue && action.falseFrameId) {
        return jumpToFrame(action.falseFrameId);
      }

      break;
    }
    case 14: {
      if (!action.expression) return;

      try {
        const isTrue = evaluate(playerCase.variables, action.expression);

        if (isTrue && action.trueFrameId) {
          return jumpToFrame(action.trueFrameId);
        } else if (!isTrue && action.falseFrameId) {
          return jumpToFrame(action.falseFrameId);
        }
      } catch (e) {
        enqueueSnackbar('Error evaluating expression', {
          variant: 'error',
        });
      }

      break;
    }
    case 15: {
      if (!action.groupId) return;

      playerCase.state.gameoverGroupId = action.groupId;

      break;
    }
    case 16: {
      if (!action.show && !action.hide) return;

      const toggleVisibility = (
        items: { evidenceId: string }[] | undefined,
        list: { id: string; hide: boolean }[],
        hide: boolean,
      ) => {
        items?.forEach((item) => {
          const record = list.find((f) => f.id === item.evidenceId);

          if (record) {
            record.hide = hide;
          }
        });
      };

      toggleVisibility(
        action?.show?.filter((f) => f.evidenceType === 'evidence'),
        playerCase.state.courtRecord.evidence,
        false,
      );

      toggleVisibility(
        action?.hide?.filter((f) => f.evidenceType === 'evidence'),
        playerCase.state.courtRecord.evidence,
        true,
      );

      toggleVisibility(
        action?.show?.filter((f) => f.evidenceType === 'profile'),
        playerCase.state.courtRecord.profiles,
        false,
      );

      toggleVisibility(
        action?.hide?.filter((f) => f.evidenceType === 'profile'),
        playerCase.state.courtRecord.profiles,
        true,
      );

      break;
    }
    case 17: {
      if (!action.areas || action.areas.length === 0) return;

      playerCase.state.userInput = action;
      playerState.showDialoguebox = false;

      setTimeout(playerCase.actions.centerCursor, 1);

      return true;
    }
    case 18: {
      if (!action.items || action.items.length === 0) return;

      action.items.forEach((item) => {
        item.show.forEach((show) => {
          if (!('locationId' in show)) {
            playerCase.state.investigation.locationState[show.id].hidden =
              false;
          } else {
            if (
              item.type === 'conversation' &&
              typeof show.locationId === 'string'
            ) {
              playerCase.state.investigation.locationState[
                show.locationId
              ].conversations[show.id].hidden = false;
            } else if (
              item.type === 'examine' &&
              typeof show.locationId === 'string'
            ) {
              playerCase.state.investigation.locationState[
                show.locationId
              ].examines[show.id].hidden = false;
            }
          }
        });

        item.hide.forEach((show) => {
          if (!('locationId' in show)) {
            playerCase.state.investigation.locationState[show.id].hidden = true;
          } else {
            if (
              item.type === 'conversation' &&
              typeof show.locationId === 'string'
            ) {
              playerCase.state.investigation.locationState[
                show.locationId
              ].conversations[show.id].hidden = true;
            } else if (
              item.type === 'examine' &&
              typeof show.locationId === 'string'
            ) {
              playerCase.state.investigation.locationState[
                show.locationId
              ].examines[show.id].hidden = true;
            }
          }
        });
      });

      break;
    }
    case 19: {
      if (!action.items || action.items.length === 0) return;

      action.items.forEach((item) => {
        if (item.type === 'location') {
          item.items.forEach((locationItem) => {
            playerCase.state.investigation.locationState[
              locationItem.id
            ].visited = false;
          });
        } else if (item.type === 'conversation') {
          item.items.forEach((locationItem) => {
            playerCase.state.investigation.locationState[
              locationItem.locationId
            ].conversations[locationItem.id].visited = false;
          });
        } else if (item.type === 'examine') {
          item.items.forEach((locationItem) => {
            playerCase.state.investigation.locationState[
              locationItem.locationId
            ].examines[locationItem.id].visited = false;
          });
        }
      });

      break;
    }
    case 20: {
      if (!action.items || action.items.length === 0) return;

      action.items.forEach((item) => {
        if (
          !item.target ||
          !item.replacement ||
          item.target.groupId !== item.replacement.groupId ||
          item.target.locationId !== item.replacement.locationId
        )
          return;

        const location = GameUtils.getInvestigationElement<Location>(
          project.groups,
          'location',
          item.target.groupId,
          item.target.locationId,
          item.target.id,
        );

        if (!location) return;

        const index1 = location.conversations.findIndex(
          (x) => x.id === item.target?.id,
        );

        const index2 = location.conversations.findIndex(
          (x) => x.id === item.replacement?.id,
        );

        if (index1 < 0 || index2 < 0 || index1 === index2) return;

        location.conversations.splice(
          index1,
          1,
          location.conversations.splice(
            index2,
            1,
            location.conversations[index1],
          )[0],
        );
      });

      break;
    }
    case 21: {
      if (!action.required || !action.location) return;

      const { id, location, ...completion } = action;

      playerCase.state.investigation.locationState[
        action.location.id
      ].completion = completion;

      break;
    }
    case 22: {
      if (!action.videoUrl) return;

      playerCase.state.videoUrl = action.videoUrl;

      break;
    }
  }
};

const JumpToFrame = (
  id: number,
  projectPlayerActions: ReturnType<typeof useProjectPlayer>['actions'],
  preloadThenNext: ReturnType<typeof usePlayer>['actions']['preloadThenNext'],
) => {
  const state = projectPlayerActions.jumpToFrame(id);

  if (state) {
    preloadThenNext(state);

    return true;
  }

  return false;
};

const RedirectToGameoverGroup = (
  project: CaseProjectDto,
  projectPlayerActions: ReturnType<typeof useProjectPlayer>['actions'],
  playerCase: ReturnType<typeof usePlayerCase>,
) => {
  const group = project.groups.find(
    (f) =>
      f.id === playerCase.state.gameoverGroupId &&
      f.type === GroupType.Gameover,
  ) as GameoverGroup | undefined;

  if (group && group.frames.length > 0) {
    return projectPlayerActions.jumpToFrame(group.frames[0].id);
  }

  return undefined;
};
