import { CheckBox } from '@mui/icons-material';
import { Box, Button, styled, useTheme } from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { MuiColorInput, MuiColorInputProps } from 'mui-color-input';
import { Popper } from '../ui/Popper';

type ColorInputProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
} & Omit<MuiColorInputProps, 'value' | 'onChange'>;

export const ColorInput = ({ value, onChange, ...props }: ColorInputProps) => {
  const valueWithSymbol = value.includes('#') ? value : `#${value}`;

  const handleChange = (value: string) => {
    onChange(value.replace('#', ''));
  };

  return (
    <MuiColorInput
      value={valueWithSymbol}
      onChange={handleChange}
      format="hex"
      isAlphaHidden
      {...props}
    />
  );
};

export const PresetColorsInput = ({
  value,
  onChange,
  label,
  colors,
}: {
  value?: string;
  onChange: (value: string) => void;
  label: string;
  colors: string[][];
}) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const theme = useTheme();

  return (
    <>
      <Box>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          onClick={toggle}
          startIcon={
            <Box
              sx={{
                width: 16,
                height: 16,
                bgcolor: value || theme.palette.text.primary,
              }}
            />
          }
        >
          {label}
        </Button>
      </Box>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        disablePortal
      >
        <Box p={2}>
          {colors.map((colorGroup, index) => (
            <Box key={index} display="flex" mb={1}>
              {colorGroup.map((color) => (
                <Box key={color} mr={1}>
                  <ColorSwatch
                    color={color}
                    onClick={() => {
                      onChange(color);

                      close();
                    }}
                    selected={value === color}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Popper>
    </>
  );
};

const ColorSwatch = ({
  color,
  onClick,
  selected,
}: {
  color: string;
  onClick: () => void;
  selected?: boolean;
}) => {
  const theme = useTheme();
  const contrastColor = theme.palette.getContrastText(color);

  return (
    <StyledColorSwatch color={color} onClick={onClick}>
      {selected && (
        <CheckBox
          style={{
            color: contrastColor,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 16,
          }}
        />
      )}
    </StyledColorSwatch>
  );
};

const StyledColorSwatch = styled(Box)<{ color: string }>(({ color }) => ({
  width: 45,
  height: 18,
  backgroundColor: color,
  borderRadius: 2,
  cursor: 'pointer',
  position: 'relative',
}));
