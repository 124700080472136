import { Check } from '@mui/icons-material';
import {
  Button,
  darken,
  Fade,
  Slide,
  SlideProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ComponentProps, memo, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';

// Talk menu
export const PlayerCaseChooseConversation = memo(() => {
  const {
    playerCase: {
      state,
      location,
      locationState,
      actions: { onConversation },
    },
    projectSnapshot: { investigationMenu },
    actions: { preloadThenNext },
  } = usePlayer();

  const { showConversations } = state;
  const open = showConversations && investigationMenu;

  const options = useMemo(
    () =>
      location && locationState
        ? location.conversations
            .filter(
              (f) => !locationState?.conversations[f.id].hidden && !f.present,
            )
            .sort(
              (a, b) =>
                locationState.conversationsIdsOrder.indexOf(a.id) -
                locationState.conversationsIdsOrder.indexOf(b.id),
            )
            .slice(0, 4)
            .map((m) => ({
              id: m.id,
              text: m.name || '',
              visited: locationState?.conversations[m.id]?.visited || false,
            }))
        : [],

    [location, locationState],
  );

  const handleClick = (conversationId: string) => {
    onConversation(preloadThenNext, conversationId);
  };

  const handleClose = () => {
    // on mobile it has a bug where click carries over to talk menu
    if (isMobile) return;

    state.showConversations = false;
  };

  if (!open || !options.length) return null;

  return (
    <Fade in={open} timeout={200}>
      <Stack
        position="absolute"
        spacing={1.5}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.45)"
        zIndex={ZIndex.CaseInput}
        alignItems="center"
        justifyContent="center"
        style={{ pointerEvents: 'auto' }}
        onClick={handleClose}
      >
        {options.map((m, i) => (
          <PlayerChooseAnswerOption
            key={i}
            {...m}
            onClick={() => handleClick(m.id)}
            direction="down"
          />
        ))}
      </Stack>
    </Fade>
  );
});

// case action
export const PlayerCaseChooseAnswer = memo(() => {
  const {
    playerCase: {
      state: { userInput },
      actions: { onUserInput },
    },
    actions: { preloadThenNext },
  } = usePlayer();

  const open = userInput?.id === 9;

  const options = useMemo<ComponentProps<typeof PlayerChooseAnswerOption>[]>(
    () =>
      open && userInput.answers
        ? userInput.answers
            .filter((f) => f.text)
            .map((m) => ({ text: m.text || '', visited: false }))
        : [],
    [open, userInput],
  );

  const handleClick = (text: string) => {
    onUserInput<9>(preloadThenNext, text);
  };

  if (!open || !options) return null;

  return (
    <Fade in={open} timeout={200}>
      <Stack
        position="absolute"
        spacing={1.5}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.45)"
        zIndex={ZIndex.CaseInput}
        alignItems="center"
        justifyContent="center"
      >
        {options.map((m, i) => (
          <PlayerChooseAnswerOption
            key={i}
            {...m}
            onClick={() => handleClick(m.text)}
          />
        ))}
      </Stack>
    </Fade>
  );
});

const PlayerChooseAnswerOption = ({
  text,
  visited,
  direction,
  onClick,
}: {
  text: string;
  visited?: boolean;
  direction?: SlideProps['direction'];
  onClick?: () => void;
}) => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (animationComplete) {
      onClick?.();
    }
  };

  return (
    <Slide
      direction={direction || 'left'}
      in={true}
      timeout={200}
      onEntered={() => setAnimationComplete(true)}
    >
      <ChooseAnswerButton
        variant="contained"
        disableElevation={true}
        onClick={handleClick}
        sx={{
          opacity: animationComplete ? 1 : 0.8,
          transition: 'opacity 0.1s ease-in-out',
        }}
      >
        <Stack direction="row" spacing={0.5} alignItems="center" width="100%">
          {visited && <CheckedIcon />}

          <ChooseAnswerText noWrap>{text}</ChooseAnswerText>
        </Stack>
      </ChooseAnswerButton>
    </Slide>
  );
};

const ChooseAnswerButton = styled(Button)(({ theme }) => ({
  pointerEvents: 'auto',
  width: '100%',
  maxWidth: 400,
  textTransform: 'none',
  outline: `1px solid grey`,
  color: theme.palette.info.contrastText,
  backgroundColor: darken(theme.palette.info.main, 0.3),
  '&:hover': {
    backgroundColor: darken(theme.palette.info.main, 0.1),
  },
  [theme.breakpoints.down('sm')]: {
    padding: '4px 6px',
    width: '80%',
  },
  [theme.breakpoints.down(480)]: {
    padding: '2px 4px',
  },
}));

const ChooseAnswerText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  width: '100%',
  [theme.breakpoints.down(480)]: {
    fontSize: 14,
  },
}));

const CheckedIcon = styled(Check)(({ theme }) => ({
  marginBottom: 0,
  fontSize: 24,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));
